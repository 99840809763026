import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getFertigPackVStatistics } from '../../../helper';
import { ProductionRun } from '../../../model';
import { irisSpacing } from '../../../theme';
import { FertigPackVCategoriesComponent } from '../production-run/fertigpackv-categories.component';
import { ProductionRunAverageWeightComponent } from '../production-run/production-run-average-weight.component';
import { ProductionRunPieChartComponent } from '../production-run/production-run-pie-chart.component';

import { ProductionRunOpenChecksComponent } from './production-run-open-checks';

interface OwnProps {
  productionRun: ProductionRun;
}

export const ProductionRunRunningOverviewTab = (props: OwnProps) => {
  const { productionRun } = props;
  const { t } = useTranslation(['common', 'data']);
  const statistics = getFertigPackVStatistics(productionRun);
  const hasFertigPackVWeight =
    !!statistics?.checkSamplesAverageWeight || !!statistics?.checkSamplesAverageVolume;
  const hasFertigPackVSamples = !!statistics?.checkSamplesDone;

  return (
    <Grid container spacing={irisSpacing.container.space} data-testid={'OverviewTab'}>
      <Grid item xs={12} md={6} lg={4}>
        <ProductionRunOpenChecksComponent productionRun={productionRun} />
      </Grid>
      <Grid item xs={12}>
        <ProductionRunPieChartComponent
          productionRun={productionRun}
          noCheckResultsText={t('data:productionRun.noCurrentCheckResults')}
        />
      </Grid>
      {hasFertigPackVWeight && (
        <Grid item xs={12} lg={9} xl={6}>
          <ProductionRunAverageWeightComponent
            headlineText={t('data:productionRun.fertigPackVCurrentAverageResult')}
            productionRun={productionRun}
          />
        </Grid>
      )}
      {hasFertigPackVSamples && (
        <Grid item xs={12}>
          <FertigPackVCategoriesComponent productionRun={productionRun} />
        </Grid>
      )}
    </Grid>
  );
};
