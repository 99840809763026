import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContainerOutside } from '../../../components/structure';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../constants';
import { ProductionRun } from '../../../model';
import { checkResultsPageSelector, fetchProductionRunCheckResults } from '../../../store';
import { CheckResultsTableComponent } from '../production-run-finished/check-results-table.component';

interface OwnProps {
  productionRun: ProductionRun;
}

export const ProductionRunRunningCheckResultsTab = (props: OwnProps) => {
  const { productionRun } = props;
  const dispatch = useDispatch();
  const defaultPage = useSelector(checkResultsPageSelector);
  const [page, setPage] = useState<number>(defaultPage || 0);
  const productionRunId = productionRun.id;

  useEffect(() => {
    if (productionRunId) {
      dispatch(
        fetchProductionRunCheckResults(productionRunId, {
          pagination: { page, perPage: DEFAULT_ITEMS_PER_PAGE },
        })
      );
    }
  }, [dispatch, productionRunId, page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <ContainerOutside>
      <CheckResultsTableComponent handlePageChange={handlePageChange} page={page} />
    </ContainerOutside>
  );
};
