import WarningIcon from '@mui/icons-material/Warning';
import { Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18next';
import { IssueCode, IssueType, ProductionRunIssue } from '../../model';

interface OwnProps {
  issue: ProductionRunIssue;
  iconSize?: 'small' | 'inherit' | 'medium' | 'large';
}

const PREFIX = 'IssueComponent';
const classes = {
  resultBox: `${PREFIX}-resultBox`,
  messageBox: `${PREFIX}-messageBox`,
  messagesIcon: `${PREFIX}-messagesIcon`,
};

const Root = styled(Box)(({ theme }) => ({
  [`& .${classes.messageBox}`]: {
    display: 'flex',
    verticalAlign: 'middle',
    alignItems: 'center',
  },

  [`& .${classes.messagesIcon}`]: {
    marginRight: theme.spacing(1),
  },
}));

export const IssueComponent = (props: OwnProps) => {
  const { issue, iconSize } = props;
  const theme = useTheme();
  const { t } = useTranslation(['data']);

  const tu1Percentage = issue.issueValue
    ? ` ${t('data:productionRun.tu1.finalPercentageForPrint', {
        percentage: issue.issueValue.toLocaleString(i18n.language),
      })}`
    : ` ${t('data:productionRun.tu1.finalPercentageForPrintFallback')}`;

  const getColor = (issueType: IssueType) => {
    switch (issueType) {
      case IssueType.Error:
        return theme.palette.error.main;
      case IssueType.Warning:
        return theme.palette.warning.main;
      default:
        return theme.palette.error.main;
    }
  };

  const getMessage = (issueCode: IssueCode) => {
    switch (issueCode) {
      case IssueCode.AverageWeightBelowNominal:
        return (
          <Typography variant="body2">
            {t('data:productionRun.fertigPackVAverageBelowNominal')}
          </Typography>
        );
      case IssueCode.FertigPackVTU2Violation:
        return (
          <Typography variant="body2">
            <b>{t('data:productionRun.tu2.violation')}</b>
            {` ${t('data:productionRun.tu2.resultBelowMinimum')} `}
            <b>{t('data:productionRun.tu2.notForSaleShort')}</b>
          </Typography>
        );
      case IssueCode.FertigPackVTU1Violation:
        return (
          <Typography variant="body2">
            <b>{`${t('data:productionRun.tu1.violationWarning')} `}</b>
            {tu1Percentage}
          </Typography>
        );
      default:
        return <Typography variant="body2">{t('data:productionRun.unknownIssueCode')}</Typography>;
    }
  };

  return (
    <Root>
      <Box className={classes.messageBox} data-testid={'issueComponent'}>
        <WarningIcon
          data-testid={'warningIcon'}
          fontSize={iconSize || 'large'}
          htmlColor={getColor(issue.issueType)}
          className={`warning ${classes.messagesIcon}`}
        />
        {getMessage(issue.issueCode)}
      </Box>
    </Root>
  );
};
