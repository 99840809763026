import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getFertigPackVStatistics } from '../../helper';
import { ProductionRun } from '../../model';
import { getPixels, irisSpacing } from '../../theme';

import { BarChartComponent, minWidth } from './bar-chart.component';

const PREFIX = 'BarChartInfoComponent';

const classes = {
  typography: `${PREFIX}-typography`,
  paper: `${PREFIX}-paper`,
};

const StyledDiv = styled('div')(({ theme }) => ({
  minWidth: `${minWidth + 2 * getPixels(irisSpacing.container.p)}px`,
  [`& .${classes.typography}`]: {
    marginTop: theme.spacing(2),
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
  forPrint?: boolean;
}

export const BarChartInfoComponent = (props: OwnProps) => {
  const { t } = useTranslation(['data']);
  const { productionRun, forPrint } = props;
  const statistics = getFertigPackVStatistics(productionRun);

  return (
    <StyledDiv data-testid="barChartInfoComponent">
      <Typography variant="h4">{t('data:check.countsPerCategory')}</Typography>
      <Typography className={classes.typography} data-testid="samplesAmountCategories">
        {`${t('data:productionRun.amountSamples')} `}
        {statistics?.checkSamplesDone === undefined ? '0' : statistics?.checkSamplesDone}
      </Typography>
      <Box sx={{ marginTop: 3 }}>
        <BarChartComponent
          productionRun={productionRun}
          noCheckSamplesText={t('data:productionRun.noFertigPackVCheckSamples')}
          forPrint={forPrint}
        />
      </Box>
    </StyledDiv>
  );
};
