import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formClasses, FormRoot } from '../../../../components/components-styles/form.styles';
import { GridContainer, GridLevel } from '../../../../components/grid/grid.container';
import { GridItem } from '../../../../components/grid/grid.item';
import { ResponsiveImageInputPreviewComponent } from '../../../../components/image/responsive-image/responsive-image-input-preview.component';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../../components/structure';
import { CheckAttribute, CheckAttributeSpecification, CheckAttributeType } from '../../../../model';
import { CheckAttributeImages } from '../check-attribute-selection/check-attribute-select.component';

import { ButtonsFormComponent } from './buttons-form.component';
import { DescriptionFrameComponent } from './description-frame.component';
import { SampleSizeComponent } from './sample-size.component';

export interface OwnProps {
  checkAttribute?: CheckAttribute;
  submit: (
    checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>,
    images?: CheckAttributeImages
  ) => void;
  cancel: () => void;
}

type FormData = {
  checkAttributeName: string;
  specification: CheckAttributeSpecification | '';
  yesNoQuestion: string;
  yesNoSuccessAnswer: string;
  sampleSize: number;
};

const getFormValues = (checkAttribute?: CheckAttribute): FormData => ({
  checkAttributeName: checkAttribute?.name || '',
  specification: checkAttribute?.specification || '',
  yesNoQuestion: checkAttribute?.yesNoQuestion || '',
  yesNoSuccessAnswer: checkAttribute?.yesNoSuccessAnswer?.toString() || 'true',
  sampleSize: checkAttribute?.sampleSize || 1,
});

export const YesNoFormComponent = (props: OwnProps) => {
  const { t } = useTranslation(['form', 'data']);
  const { checkAttribute } = props;
  const [yesNoImageUrl, setYesNoImageUrl] = useState<string | undefined>(
    checkAttribute?.yesNoImageUrl
  );
  const [chosenYesNoImageFile, setChosenYesNoImageFile] = useState<File | undefined>();

  const methods = useForm<FormData>({
    defaultValues: getFormValues(checkAttribute),
  });
  const { handleSubmit, errors, control, watch } = methods;

  const removeYesNoImage = () => {
    setYesNoImageUrl(undefined);
    setChosenYesNoImageFile(undefined);
  };

  const onSubmit = handleSubmit((formData: FormData) => {
    const visual = Number(formData.specification) === CheckAttributeSpecification.Visual;

    if (formData.specification) {
      props.submit(
        {
          checkAttributeType: CheckAttributeType.YesNo,
          name: formData.checkAttributeName,
          specification: Number(formData.specification),
          sampleSize: Number(formData.sampleSize),
          yesNoQuestion: formData.yesNoQuestion,
          yesNoSuccessAnswer: formData.yesNoSuccessAnswer === 'true',
          yesNoImageUrl: yesNoImageUrl,
        },
        {
          yesNoImage: visual ? chosenYesNoImageFile : undefined,
        }
      );
    }
  });

  return (
    <FormRoot>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} className={formClasses.root}>
          <ContainerOutsideWithHeader>
            <Typography variant="h2">{t('data:checkAttribute.description')}</Typography>
            <Paper>
              <ContainerInside>
                <GridContainer>
                  <GridItem>
                    <GridContainer level={GridLevel.InputPaper}>
                      <DescriptionFrameComponent checkAttributeType={CheckAttributeType.YesNo} />
                      <GridItem>
                        <GridContainer level={GridLevel.InputPaper}>
                          <GridItem s={12} xl={8}>
                            <Controller
                              as={<TextField variant="outlined" fullWidth={true} />}
                              control={control}
                              defaultValue={''}
                              name="yesNoQuestion"
                              label={t('data:checkAttribute.question')}
                              inputProps={{
                                'aria-label': t('data:checkAttribute.question'),
                                'data-testid': 'yesNoQuestion-input',
                              }}
                              rules={{
                                maxLength: {
                                  value: 256,
                                  message: t('form:maxLength', { max: '256' }),
                                },
                              }}
                              error={errors.yesNoQuestion !== undefined}
                              helperText={errors.yesNoQuestion && errors.yesNoQuestion.message}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      {Number(watch('specification')) === CheckAttributeSpecification.Visual && (
                        <GridItem s={6} xl={4}>
                          <ResponsiveImageInputPreviewComponent
                            changeImage={setChosenYesNoImageFile}
                            chosenImageFile={chosenYesNoImageFile}
                            removeImage={removeYesNoImage}
                            imageUrl={yesNoImageUrl}
                          />
                        </GridItem>
                      )}
                      <GridItem s={6} xl={4}>
                        <FormControl fullWidth={true}>
                          <FormLabel id="yesNoSuccessAnswer-label" component="legend">
                            {t('data:checkAttribute.yesNoSuccessAnswer')}
                          </FormLabel>
                          <Controller
                            as={
                              <RadioGroup
                                aria-label={t('data:checkAttribute.yesNoSuccessAnswer')}
                                name="yesNoSuccessAnswer"
                                row
                              >
                                <FormControlLabel
                                  data-testid="yes-radio-btn"
                                  value={'true'}
                                  control={<Radio />}
                                  label={t('form:yes') as string}
                                />
                                <FormControlLabel
                                  data-testid="no-radio-btn"
                                  value={'false'}
                                  control={<Radio />}
                                  label={t('form:no') as string}
                                />
                              </RadioGroup>
                            }
                            control={control}
                            name="yesNoSuccessAnswer"
                            rules={{
                              required: { value: true, message: t('form:fieldIsRequired') },
                            }}
                          />
                          {errors.yesNoSuccessAnswer && errors.yesNoSuccessAnswer.message && (
                            <FormHelperText>{errors.yesNoSuccessAnswer.message}</FormHelperText>
                          )}
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </ContainerInside>
            </Paper>
          </ContainerOutsideWithHeader>
          <SampleSizeComponent />
          <GridItem>
            <ButtonsFormComponent cancel={props.cancel} />
          </GridItem>
        </form>
      </FormProvider>
    </FormRoot>
  );
};
