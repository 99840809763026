import { push } from 'connected-react-router';
import { Action } from 'redux';
import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { User } from '../../model';
import { tableDataQueryToUrl } from '../../model';
import { AppRoutePath } from '../../routes/routes';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  UsersActionType,
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFailure,
  fetchUser,
  fetchUserSuccess,
  fetchUserFailure,
  addUser as addUserAction,
  addUserSuccess,
  addUserFailure,
  editUser as editUserAction,
  editUserSuccess,
  editUserFailure,
  deleteUser as deleteUserAction,
  deleteUserSuccess,
  deleteUserFailure,
  resetUserPassword,
  resetUserPasswordSuccess,
  resetUserPasswordFailure,
  requestResetPassword,
  requestResetPasswordFailure,
  requestResetPasswordSuccess,
} from './users.actions';

const usersUrl = 'users';
const resetUrl = 'reset';
const requestResetUrl = 'request-reset';

function* getUsers(action: Action) {
  const { query } = (action as ReturnType<typeof fetchUsers>).payload;
  const tableDataQueryUrl = tableDataQueryToUrl(query);
  try {
    const response = yield* call<
      [boolean, string],
      SagaRequest<{ data: User[]; metadata: { total: number } }>
    >(SagaRequestHelper.get, true, `${usersUrl}${tableDataQueryUrl}`);
    yield put(fetchUsersSuccess(response.data, response.metadata.total));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchUsersFailure);
  }
}

function* getUserById(action: Action) {
  const { id } = (action as ReturnType<typeof fetchUser>).payload;
  try {
    const user = yield* call<[boolean, string], SagaRequest<User>>(
      SagaRequestHelper.get,
      true,
      `${usersUrl}/${id}`
    );
    yield put(fetchUserSuccess(user));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchUserFailure);
  }
}

function* addUser(action: Action) {
  try {
    const user = yield* call<[boolean, string, { body: string }], SagaRequest<User>>(
      SagaRequestHelper.post,
      true,
      usersUrl,
      {
        body: JSON.stringify((action as ReturnType<typeof addUserAction>).payload.user),
      }
    );

    yield put(addUserSuccess(user));
  } catch (e: any) {
    yield sagaErrorHandler(e, addUserFailure);
  }
}

function* editUser(action: Action) {
  const editAction = action as ReturnType<typeof editUserAction>;
  const { user } = editAction.payload;
  try {
    yield* call<[boolean, string, { body: string }], SagaRequest<User>>(
      SagaRequestHelper.put,
      true,
      `${usersUrl}/${user.id}`,
      {
        body: JSON.stringify(editAction.payload.user),
      }
    );
    yield put(editUserSuccess(user));
  } catch (e: any) {
    yield sagaErrorHandler(e, editUserFailure);
  }
}

function* deleteUser(action: Action) {
  const { id } = (action as ReturnType<typeof deleteUserAction>).payload;
  try {
    yield* call<[boolean, string, {}], SagaRequest<User>>(
      SagaRequestHelper.delete,
      true,
      `${usersUrl}/${id}`,
      {}
    );
    yield put(deleteUserSuccess(id));
  } catch (e: any) {
    yield sagaErrorHandler(e, deleteUserFailure);
  }
}

function* resetUserPasswordById(action: Action) {
  const { id } = (action as ReturnType<typeof resetUserPassword>).payload;
  try {
    const password = yield* call<[boolean, string], SagaRequest<string>>(
      SagaRequestHelper.post,
      true,
      `${usersUrl}/${id}/${resetUrl}`
    );
    yield put(resetUserPasswordSuccess(password));
  } catch (e: any) {
    yield sagaErrorHandler(e, resetUserPasswordFailure);
  }
}

function* redirectOnAddSuccess(action: Action) {
  const addSuccessAction = action as ReturnType<typeof addUserSuccess>;
  const { user } = addSuccessAction.payload;
  yield put(push(`/${AppRoutePath.users}/${user.id}/${AppRoutePath.edit}`));
}

function* redirectOnEditSuccess() {
  yield put(push(`/${AppRoutePath.users}`));
}

function* requestResetUserPasswordByUsername(action: Action) {
  const { username } = (action as ReturnType<typeof requestResetPassword>).payload;
  try {
    yield* call<[boolean, string, { body: string }], SagaRequest<string>>(
      SagaRequestHelper.post,
      false,
      `${usersUrl}/${requestResetUrl}`,
      {
        body: JSON.stringify({ username }),
      }
    );
    yield put(requestResetPasswordSuccess());
  } catch (e: any) {
    yield put(requestResetPasswordFailure());
  }
}

export function* redirectOnAddSuccessSaga() {
  yield takeLatest([UsersActionType.userAddSuccess], redirectOnAddSuccess);
}

export function* redirectOnEditSuccessSaga() {
  yield takeLatest([UsersActionType.userEditSuccess], redirectOnEditSuccess);
}

export function* fetchUsersSaga() {
  yield takeLatest(UsersActionType.usersFetchAll, getUsers);
}

export function* fetchUserSaga() {
  yield takeLatest(UsersActionType.userFetch, getUserById);
}

export function* addUserSaga() {
  yield takeLatest(UsersActionType.userAdd, addUser);
}

export function* editUserSaga() {
  yield takeLatest(UsersActionType.userEdit, editUser);
}

export function* deleteUserSaga() {
  yield takeLatest(UsersActionType.userDelete, deleteUser);
}

export function* resetUserPasswordSaga() {
  yield takeLatest(UsersActionType.userResetPassword, resetUserPasswordById);
}

export function* requestResetUserPasswordSaga() {
  yield takeLatest(UsersActionType.userRequestResetPassword, requestResetUserPasswordByUsername);
}

export default function* usersSaga() {
  yield fork(fetchUsersSaga);
  yield fork(fetchUserSaga);
  yield fork(addUserSaga);
  yield fork(editUserSaga);
  yield fork(deleteUserSaga);
  yield fork(resetUserPasswordSaga);
  yield fork(redirectOnAddSuccessSaga);
  yield fork(redirectOnEditSuccessSaga);
  yield fork(requestResetUserPasswordSaga);
}
