import { HttpError } from '../../http/http-error';
import { ConnectedScale, Device, Scale, TableDataQuery } from '../../model';

export enum DevicesActionType {
  devicesFetchAll = 'devices/fetchAll',
  devicesFetchAllSuccess = 'devices/fetchAll/success',
  devicesFetchAllFailure = 'devices/fetchAll/failure',
  deviceFetch = 'device/fetch',
  deviceFetchSuccess = 'device/fetch/success',
  deviceFetchFailure = 'device/fetch/failure',
  deviceAdd = 'device/add',
  deviceAddSuccess = 'device/add/success',
  deviceAddFailure = 'device/add/failure',
  deviceEdit = 'device/edit',
  deviceEditSuccess = 'device/edit/success',
  deviceEditFailure = 'device/edit/failure',
  deviceDelete = 'device/delete',
  deviceDeleteSuccess = 'device/delete/success',
  deviceDeleteFailure = 'device/delete/failure',
  connectedScalesFetchAll = 'connectedScales/fetchAll',
  connectedScalesFetchAllSuccess = 'connectedScales/fetchAll/success',
  connectedScalesFetchAllFailure = 'connectedScales/fetchAll/failure',
  scaleClearLineAlreadySet = 'device/clear/lineAlreadySet',
}

export const fetchDevices = (query?: TableDataQuery) => ({
  type: DevicesActionType.devicesFetchAll,
  payload: {
    query,
  },
});

export const fetchDevicesSuccess = (devices: Scale[] | Device[], total: number) => ({
  type: DevicesActionType.devicesFetchAllSuccess,
  payload: { devices, total },
});

export const fetchDevicesFailure = () => ({
  type: DevicesActionType.devicesFetchAllFailure,
});

export const fetchDevice = (id: string) => ({
  type: DevicesActionType.deviceFetch,
  payload: { id },
});

export const fetchDeviceSuccess = (device: Device) => ({
  type: DevicesActionType.deviceFetchSuccess,
  payload: { device },
});

export const fetchDeviceFailure = () => ({
  type: DevicesActionType.deviceFetchFailure,
});

export const addDevice = (device: Omit<Device, 'id' | 'lastModified'>) => ({
  type: DevicesActionType.deviceAdd,
  payload: { device },
});

export const addDeviceSuccess = (device: Device) => ({
  type: DevicesActionType.deviceAddSuccess,
  payload: { device },
});

export const addDeviceFailure = (e: HttpError) => ({
  type: DevicesActionType.deviceAddFailure,
  payload: { e },
});

export const editDevice = (device: Device) => ({
  type: DevicesActionType.deviceEdit,
  payload: { device },
});

export const editDeviceSuccess = (device: Device) => ({
  type: DevicesActionType.deviceEditSuccess,
  payload: { device },
});

export const editDeviceFailure = (e: HttpError) => ({
  type: DevicesActionType.deviceEditFailure,
  payload: { e },
});

export const deleteDevice = (id: string) => ({
  type: DevicesActionType.deviceDelete,
  payload: { id },
});

export const deleteDeviceSuccess = (id: string) => ({
  type: DevicesActionType.deviceDeleteSuccess,
  payload: { id },
});

export const deleteDeviceFailure = () => ({
  type: DevicesActionType.deviceDeleteFailure,
});

export const fetchConnectedScales = () => ({
  type: DevicesActionType.connectedScalesFetchAll,
});

export const fetchConnectedScalesSuccess = (connectedScales: ConnectedScale[]) => ({
  type: DevicesActionType.connectedScalesFetchAllSuccess,
  payload: { connectedScales },
});

export const fetchConnectedScalesFailure = () => ({
  type: DevicesActionType.connectedScalesFetchAllFailure,
});

export const clearScaleLineAlreadySet = () => ({
  type: DevicesActionType.scaleClearLineAlreadySet,
});
