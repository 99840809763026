import { createSelector } from 'reselect';

import { AppState } from '..';

export const articlesStateSelector = (state: AppState) => state.articles;

export const articleListSelector = createSelector(articlesStateSelector, (state) => state.articles);

export const currentArticleSelector = createSelector(
  articlesStateSelector,
  (state) => state.currentArticle
);

export const articlesTotalCountSelector = createSelector(
  articlesStateSelector,
  (state) => state.total
);

export const articlesFetchingSelector = createSelector(
  articlesStateSelector,
  (state) => !!state.fetchingAll
);

export const articleFertigPackTolerancesSelector = createSelector(
  articlesStateSelector,
  (state) => state.currentFertigPackVTolerances
);

export const articleHasFertigPackInputValuesSelector = createSelector(
  articlesStateSelector,
  (state) => state.hasFertigPackInputValues
);
