import { Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Check } from '../../../model';
import {
  editCheckForArticle,
  editableCheckAttributeListSelector,
  fetchCheckAttributes,
  isEditCheckDialogOpenSelector,
} from '../../../store';

import { CheckFormComponent } from './check-form.component';

const PREFIX = 'CheckEditDialogComponent';
const classes = {
  dialog: `${PREFIX}-dialog`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.dialog}`]: {
    '& .MuiDialog-paperWidthMd': {
      width: '680px',
    },
  },
}));

export interface OwnProps {
  handleCancel: () => void;
  checkPeriodicity: number;
  articleId: string;
  check?: Check;
}

export const CheckEditDialogComponent = (props: OwnProps) => {
  const { checkPeriodicity, articleId, check, handleCancel } = props;
  const dispatch = useDispatch();
  const editableCheckAttributes = useSelector(editableCheckAttributeListSelector);
  const isEditCheckDialogOpen = useSelector(isEditCheckDialogOpenSelector);

  useEffect(() => {
    check && dispatch(fetchCheckAttributes());
  }, [dispatch, check]);

  const submit = (editedCheck: Omit<Check, 'id' | 'lastModified'>) => {
    check && dispatch(editCheckForArticle(articleId, { ...editedCheck, id: check.id }));
  };

  const cancel = () => {
    handleCancel();
  };

  return (
    <>
      {check &&
        editableCheckAttributes &&
        editableCheckAttributes.find(
          (checkAttribute) => checkAttribute.id === check.checkAttribute.id
        ) && (
          <StyledDialog open={isEditCheckDialogOpen} maxWidth="md" className={classes.dialog}>
            <DialogContent>
              <CheckFormComponent
                submit={submit}
                cancel={cancel}
                articleCheckPeriodicity={checkPeriodicity}
                checkAttributes={editableCheckAttributes || []}
                check={check}
              ></CheckFormComponent>
            </DialogContent>
          </StyledDialog>
        )}
    </>
  );
};
