import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { irisCustomColors, irisSidebarWidth, sidebarBreakpoint } from '../../../theme';
import { SxDiv } from '../../helper';
import { height as licenseInfoHeight } from '../../license-info/license-expiration-info.component';
import { DrawerComponent } from '../drawer/drawer.component';

const PREFIX = 'SidebarComponent';
const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  lowMenu: `${PREFIX}-lowMenu`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up(sidebarBreakpoint)]: {
      width: irisSidebarWidth,
      flexShrink: 0,
    },
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`&.${classes.drawerPaper}`]: {
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: irisSidebarWidth,
      backgroundColor: irisCustomColors.irisBlueGray,
      zIndex: 5,
    },
  },
  [`&.${classes.lowMenu}`]: {
    '& .MuiDrawer-paper': {
      paddingTop: `${licenseInfoHeight}px`,
    },
  },
}));

export interface OwnProps {
  handleDrawerToggle: () => void;
  mobileOpen: boolean;
  licenseExpirationWarning: boolean;
  licenseExpired: boolean;
}

export const SidebarComponent = (props: OwnProps) => {
  const { licenseExpirationWarning, licenseExpired } = props;

  return (
    <Root>
      <nav className={classes.drawer}>
        <SxDiv sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none' } }}>
          <StyledDrawer
            variant="temporary"
            anchor={'left'}
            open={props.mobileOpen}
            onClose={props.handleDrawerToggle}
            className={`${classes.drawerPaper} ${
              licenseExpirationWarning ? classes.lowMenu : undefined
            }`}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            PaperProps={{ elevation: 4 }}
          >
            <DrawerComponent licenseExpired={licenseExpired} />
          </StyledDrawer>
        </SxDiv>
        <SxDiv sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
          <StyledDrawer
            className={`${classes.drawerPaper} ${
              licenseExpirationWarning ? classes.lowMenu : undefined
            }`}
            variant="permanent"
            open
            PaperProps={{ elevation: 4 }}
          >
            <DrawerComponent licenseExpired={licenseExpired} />
          </StyledDrawer>
        </SxDiv>
      </nav>
    </Root>
  );
};
