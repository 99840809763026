import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import { Action } from 'redux';
import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { CheckHistoryEntry, ProductionRun, tableDataQueryToUrl } from '../../model';
import { AppRoutePath } from '../../routes/routes';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  ProductionRunsActionType,
  fetchProductionRunsRunningSuccess,
  fetchProductionRunsRunningFailure,
  fetchProductionRunRunning,
  fetchProductionRunRunningSuccess,
  fetchProductionRunRunningFailure,
  fetchProductionRunFinished,
  fetchProductionRunFinishedSuccess,
  fetchProductionRunFinishedFailure,
  fetchProductionRunsFinished,
  fetchProductionRunsFinishedSuccess,
  fetchProductionRunsFinishedFailure,
  fetchProductionRunCheckResultsSuccess,
  fetchProductionRunCheckResultsFailure,
  fetchProductionRunCheckResults,
  exportProductionRunCheckResults,
  exportProductionRunCheckResultsSuccess,
  exportProductionRunCheckResultsFailure,
  exportProductionRunEvents,
  exportProductionRunEventsSuccess,
  exportProductionRunEventsFailure,
  stopProductionRun,
  stopProductionRunSuccess,
  stopProductionRunFailure,
  continueProductionRun,
  continueProductionRunSuccess,
  continueProductionRunFailure,
} from './production-runs.actions';

const productionRunsUrl = 'production/runs';
const exportProductionRunUrl = `export/${productionRunsUrl}`;
const runningFilter = '?running=1';
const finishedFilter = 'finished=1';
const productionRunCheckResultsUrl = 'checks/history';
const productionRunEventsUrl = 'events';
const stop = 'stop';
const disruptions = 'disruptions';
const resolve = 'resolve';

function* getProductionRunsRunning() {
  try {
    const response = yield* call<[boolean, string], SagaRequest<{ data: ProductionRun[] }>>(
      SagaRequestHelper.get,
      true,
      `${productionRunsUrl}${runningFilter}`
    );

    yield put(fetchProductionRunsRunningSuccess(response.data));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchProductionRunsRunningFailure);
  }
}

function* getProductionRunRunningById(action: Action) {
  const { id } = (action as ReturnType<typeof fetchProductionRunRunning>).payload;
  try {
    const productionRun = yield* call<[boolean, string], SagaRequest<ProductionRun>>(
      SagaRequestHelper.get,
      true,
      `${productionRunsUrl}/${id}`
    );
    yield put(fetchProductionRunRunningSuccess(productionRun));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchProductionRunRunningFailure);
  }
}

function* getProductionRunsFinished(action: Action) {
  const { query } = (action as ReturnType<typeof fetchProductionRunsFinished>).payload;
  const tableDataQueryUrl = tableDataQueryToUrl(query);
  const url = tableDataQueryUrl
    ? `${productionRunsUrl}${tableDataQueryUrl}&${finishedFilter}`
    : `${productionRunsUrl}?${finishedFilter}`;
  try {
    const response = yield* call<
      [boolean, string],
      SagaRequest<{ data: ProductionRun[]; metadata: { total: number } }>
    >(SagaRequestHelper.get, true, url);

    yield put(fetchProductionRunsFinishedSuccess(response.data, response.metadata.total));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchProductionRunsFinishedFailure);
  }
}

function* getProductionRunFinishedById(action: Action) {
  const { id } = (action as ReturnType<typeof fetchProductionRunFinished>).payload;
  try {
    const productionRun = yield* call<[boolean, string], SagaRequest<ProductionRun>>(
      SagaRequestHelper.get,
      true,
      `${productionRunsUrl}/${id}`
    );
    yield put(fetchProductionRunFinishedSuccess(productionRun));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchProductionRunFinishedFailure);
  }
}

function* stopProductionRunById(action: Action) {
  const { id, reason } = (action as ReturnType<typeof stopProductionRun>).payload;
  try {
    const productionRun = yield* call<
      [boolean, string, { body: string }],
      SagaRequest<ProductionRun>
    >(SagaRequestHelper.put, true, `${productionRunsUrl}/${id}/${stop}`, {
      body: JSON.stringify({ reason, forced: true }),
    });
    yield put(stopProductionRunSuccess(productionRun));
    yield put(push(`/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.running}/`));
  } catch (e: any) {
    yield sagaErrorHandler(e, stopProductionRunFailure);
  }
}

function* getProductionRunCheckResultsById(action: Action) {
  const { id, query } = (action as ReturnType<typeof fetchProductionRunCheckResults>).payload;
  const tableDataQueryUrl = tableDataQueryToUrl(query);
  try {
    const response = yield* call<
      [boolean, string],
      SagaRequest<{ data: CheckHistoryEntry[]; metadata: { total: number } }>
    >(
      SagaRequestHelper.get,
      true,
      `${productionRunsUrl}/${id}/${productionRunCheckResultsUrl}${tableDataQueryUrl}`
    );
    yield put(
      fetchProductionRunCheckResultsSuccess(
        response.data,
        response.metadata.total,
        query?.pagination?.page
      )
    );
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchProductionRunCheckResultsFailure);
  }
}

function* exportProductionRunCheckResultsById(action: Action) {
  const { id, filename } = (action as ReturnType<typeof exportProductionRunCheckResults>).payload;
  try {
    const exportUrl = `${exportProductionRunUrl}/${id}/${productionRunCheckResultsUrl}`;
    const response = yield* call<[boolean, string], SagaRequest<Blob>>(
      SagaRequestHelper.get,
      true,
      exportUrl
    );
    FileSaver.saveAs(response, filename);
    yield put(exportProductionRunCheckResultsSuccess());
  } catch (e: any) {
    yield sagaErrorHandler(e, exportProductionRunCheckResultsFailure);
  }
}

function* exportProductionRunEventsById(action: Action) {
  const { id, filename } = (action as ReturnType<typeof exportProductionRunEvents>).payload;
  try {
    const exportUrl = `${exportProductionRunUrl}/${id}/${productionRunEventsUrl}`;
    const response = yield* call<[boolean, string], SagaRequest<Blob>>(
      SagaRequestHelper.get,
      true,
      exportUrl
    );
    FileSaver.saveAs(response, filename);
    yield put(exportProductionRunEventsSuccess());
  } catch (e: any) {
    yield sagaErrorHandler(e, exportProductionRunEventsFailure);
  }
}

function* continueProductionRunById(action: Action) {
  const { id, reason } = (action as ReturnType<typeof continueProductionRun>).payload;
  try {
    const productionRun = yield* call<
      [boolean, string, { body: string }],
      SagaRequest<ProductionRun>
    >(SagaRequestHelper.put, true, `${productionRunsUrl}/${id}/${disruptions}/${resolve}`, {
      body: JSON.stringify({
        productionRunId: id,
        reason,
      }),
    });
    yield put(continueProductionRunSuccess(productionRun));
  } catch (e: any) {
    yield sagaErrorHandler(e, continueProductionRunFailure);
  }
}

export function* fetchProductionRunsRunningSaga() {
  yield takeLatest(
    ProductionRunsActionType.productionRunsRunningFetchAll,
    getProductionRunsRunning
  );
}

export function* fetchProductionRunRunningSaga() {
  yield takeLatest(ProductionRunsActionType.productionRunRunningFetch, getProductionRunRunningById);
}

export function* fetchProductionRunsFinishedSaga() {
  yield takeLatest(
    ProductionRunsActionType.productionRunsFinishedFetchAll,
    getProductionRunsFinished
  );
}

export function* fetchProductionRunFinishedSaga() {
  yield takeLatest(
    ProductionRunsActionType.productionRunFinishedFetch,
    getProductionRunFinishedById
  );
}

export function* stopProductionRunSaga() {
  yield takeLatest(ProductionRunsActionType.productionRunStop, stopProductionRunById);
}

export function* fetchProductionRunCheckResultsSaga() {
  yield takeLatest(
    ProductionRunsActionType.productionRunCheckResultsFetchAll,
    getProductionRunCheckResultsById
  );
}

export function* exportProductionRunCheckResultsSaga() {
  yield takeLatest(
    ProductionRunsActionType.productionRunCheckResultsExport,
    exportProductionRunCheckResultsById
  );
}

export function* exportProductionRunEventsSaga() {
  yield takeLatest(
    ProductionRunsActionType.productionRunEventsExport,
    exportProductionRunEventsById
  );
}

export function* continueProductionRunSaga() {
  yield takeLatest(ProductionRunsActionType.productionRunContinue, continueProductionRunById);
}

export default function* productionRunsSaga() {
  yield fork(fetchProductionRunsRunningSaga);
  yield fork(fetchProductionRunRunningSaga);
  yield fork(fetchProductionRunsFinishedSaga);
  yield fork(fetchProductionRunFinishedSaga);
  yield fork(stopProductionRunSaga);
  yield fork(fetchProductionRunCheckResultsSaga);
  yield fork(exportProductionRunCheckResultsSaga);
  yield fork(exportProductionRunEventsSaga);
  yield fork(continueProductionRunSaga);
}
