import { Box, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formClasses, FormRoot } from '../../../../components/components-styles/form.styles';
import { FormattedTextFieldComponent } from '../../../../components/formatted-text-field/formatted-text-field.component';
import { GridContainer, GridLevel } from '../../../../components/grid/grid.container';
import { GridItem } from '../../../../components/grid/grid.item';
import { InfoboxComponent } from '../../../../components/infobox/infobox.component';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../../components/structure';
import { TEMPERATURE_ENTITY } from '../../../../constants';
import { CheckAttribute, CheckAttributeSpecification, CheckAttributeType } from '../../../../model';

import { ButtonsFormComponent } from './buttons-form.component';
import { DescriptionFrameComponent } from './description-frame.component';
import { SampleSizeComponent } from './sample-size.component';

const PREFIX = 'TemperatureFormComponent';
const classes = {
  inputItemExtraShort: `${PREFIX}-inputItemExtraShort`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.inputItemExtraShort}`]: {
    width: 'inherit',
    maxWidth: 150,
  },
}));

export interface OwnProps {
  checkAttribute?: CheckAttribute;
  submit: (checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>) => void;
  cancel: () => void;
}

type FormData = {
  checkAttributeName: string;
  specification: CheckAttributeSpecification | '';
  temperatureDescription: string;
  temperatureNominalValue: number | string;
  temperatureMinValue: number | string;
  temperatureMaxValue: number | string;
  limitValues: string;
  sampleSize: number;
};

const getFormValues = (checkAttribute?: CheckAttribute): FormData => ({
  checkAttributeName: checkAttribute?.name || '',
  specification: checkAttribute?.specification || '',
  temperatureDescription: checkAttribute?.temperatureDescription || '',
  temperatureNominalValue:
    checkAttribute?.temperatureNominalValue !== undefined
      ? checkAttribute.temperatureNominalValue
      : '',
  temperatureMinValue:
    checkAttribute?.temperatureMinValue !== undefined ? checkAttribute.temperatureMinValue : '',
  temperatureMaxValue:
    checkAttribute?.temperatureMaxValue !== undefined ? checkAttribute.temperatureMaxValue : '',
  limitValues: '',
  sampleSize: checkAttribute?.sampleSize || 1,
});

export const TemperatureFormComponent = (props: OwnProps) => {
  const { t } = useTranslation(['form', 'data']);
  const { checkAttribute } = props;
  const methods = useForm<FormData>({
    defaultValues: getFormValues(checkAttribute),
  });
  const { handleSubmit, errors, control, register, getValues, setValue } = methods;
  const [temperatureNominalValueInput, setTemperatureNominalValueInput] = useState<
    string | undefined
  >(checkAttribute?.temperatureNominalValue?.toString() || '');

  const [temperatureMinValueInput, setTemperatureMinValueInput] = useState<string | undefined>(
    checkAttribute?.temperatureMinValue?.toString() || ''
  );

  const [temperatureMaxValueInput, setTemperatureMaxValueInput] = useState<string | undefined>(
    checkAttribute?.temperatureMaxValue?.toString() || ''
  );

  useEffect(() => {
    // Custom register for FormattedTextFields
    register(
      {
        name: 'temperatureNominalValue',
      },
      {
        required: { value: true, message: t('form:fieldIsRequired') },
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: { value: -500.0, message: t('form:minValue', { min: -500.0 }) },
        max: { value: 1000.0, message: t('form:maxValue', { max: 1000.0 }) },
      }
    );
    register(
      {
        name: 'temperatureMinValue',
      },
      {
        required: { value: true, message: t('form:fieldIsRequired') },
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: { value: -500.0, message: t('form:minValue', { min: -500.0 }) },
        max: { value: 1000.0, message: t('form:maxValue', { max: 1000.0 }) },
      }
    );
    register(
      {
        name: 'temperatureMaxValue',
      },
      {
        required: { value: true, message: t('form:fieldIsRequired') },
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: { value: -500.0, message: t('form:minValue', { min: -500.0 }) },
        max: { value: 1000.0, message: t('form:maxValue', { max: 1000.0 }) },
      }
    );
  }, [register, t]);

  const onSubmit = handleSubmit((formData: FormData) => {
    props.submit({
      checkAttributeType: CheckAttributeType.Temperature,
      name: formData.checkAttributeName,
      specification: CheckAttributeSpecification.Numeric,
      sampleSize: Number(formData.sampleSize),
      temperatureDescription: formData.temperatureDescription,
      temperatureNominalValue:
        temperatureNominalValueInput && temperatureNominalValueInput !== ''
          ? +temperatureNominalValueInput
          : undefined,
      temperatureMinValue:
        temperatureMinValueInput && temperatureMinValueInput !== ''
          ? +temperatureMinValueInput
          : undefined,
      temperatureMaxValue:
        temperatureMaxValueInput && temperatureMaxValueInput !== ''
          ? +temperatureMaxValueInput
          : undefined,
    });
  });

  const temperatureNominalValueInputChanged = (value: string) => {
    if (temperatureNominalValueInput !== value) {
      setTemperatureNominalValueInput(value);
      setValue('temperatureNominalValue', value);
    }
  };

  const temperatureMinValueInputChanged = (value: string) => {
    if (temperatureMinValueInput !== value) {
      setTemperatureMinValueInput(value);
      setValue('temperatureMinValue', value);
    }
  };

  const temperatureMaxValueInputChanged = (value: string) => {
    if (temperatureMaxValueInput !== value) {
      setTemperatureMaxValueInput(value);
      setValue('temperatureMaxValue', value);
    }
  };

  const validateLimitValues = (): boolean => {
    const formData = getValues();

    const hasMinValue = formData.temperatureMinValue && formData.temperatureMinValue !== '';
    const hasNominalValue =
      formData.temperatureNominalValue && formData.temperatureNominalValue !== '';
    const hasMaxValue = formData.temperatureMaxValue && formData.temperatureMaxValue !== '';

    if (!(hasMinValue && hasNominalValue && hasMaxValue)) return true;

    const minValue = Number(formData.temperatureMinValue);
    const nominalValue = Number(formData.temperatureNominalValue);
    const maxValue = Number(formData.temperatureMaxValue);

    const isValid = minValue < nominalValue && nominalValue < maxValue;

    const returnValue = !!isValid || false;
    return returnValue;
  };

  return (
    <Root>
      <FormRoot>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className={formClasses.root}>
            <ContainerOutsideWithHeader>
              <Typography variant="h2">{t('data:checkAttribute.description')}</Typography>
              <Paper>
                <ContainerInside>
                  <GridContainer>
                    <GridItem>
                      <GridContainer level={GridLevel.InputPaper}>
                        <DescriptionFrameComponent
                          checkAttributeType={CheckAttributeType.Temperature}
                        />
                        <GridItem s={12} xl={8}>
                          <Controller
                            as={<TextField variant="outlined" fullWidth={true} />}
                            control={control}
                            defaultValue={''}
                            name="temperatureDescription"
                            label={t('data:checkAttribute.temperatureDescription')}
                            inputProps={{
                              'aria-label': t('data:checkAttribute.temperatureDescription'),
                              'data-testid': 'temperatureDescription-input',
                            }}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: t('form:maxLength', { max: '256' }),
                              },
                            }}
                            error={errors.temperatureDescription !== undefined}
                            helperText={
                              errors.temperatureDescription && errors.temperatureDescription.message
                            }
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </ContainerInside>
              </Paper>
            </ContainerOutsideWithHeader>
            <ContainerOutsideWithHeader>
              <Typography variant="h2">{t('data:checkAttribute.limits')}</Typography>
              <Paper>
                <ContainerInside>
                  <GridContainer level={GridLevel.InputPaper}>
                    <input
                      type="hidden"
                      name="limitValues"
                      defaultValue={' '}
                      ref={register({
                        validate: () =>
                          validateLimitValues() ||
                          (t('data:checkAttribute.limitValuesNotValid') as string),
                      })}
                    />
                    {errors.limitValues && errors.limitValues.message && (
                      <GridItem>
                        <InfoboxComponent
                          headline={errors.limitValues.message}
                          type="error"
                        ></InfoboxComponent>
                      </GridItem>
                    )}
                    <GridItem>
                      <GridContainer level={GridLevel.InputPaper}>
                        <GridItem s={3} xl={2}>
                          <Box>
                            <FormattedTextFieldComponent
                              name="temperatureNominalValue"
                              onInputChange={temperatureNominalValueInputChanged}
                              defaultValue={
                                checkAttribute?.temperatureNominalValue?.toString() || ''
                              }
                              label={t('data:checkAttribute.nominalValue')}
                              ariaLabel={t('data:checkAttribute.nominalValue')}
                              required
                              fullWidth={true}
                              suffixUnit={TEMPERATURE_ENTITY}
                              hasError={errors.temperatureNominalValue !== undefined}
                              errorMessage={
                                errors.temperatureNominalValue &&
                                errors.temperatureNominalValue.message
                              }
                            />
                          </Box>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem>
                      <GridContainer level={GridLevel.InputPaper}>
                        <GridItem s={3} xl={2}>
                          <Box>
                            <FormattedTextFieldComponent
                              name="temperatureMinValue"
                              onInputChange={temperatureMinValueInputChanged}
                              defaultValue={checkAttribute?.temperatureMinValue?.toString() || ''}
                              label={t('data:checkAttribute.minValue')}
                              ariaLabel={t('data:checkAttribute.minValue')}
                              required
                              fullWidth={true}
                              suffixUnit={TEMPERATURE_ENTITY}
                              hasError={errors.temperatureMinValue !== undefined}
                              errorMessage={
                                errors.temperatureMinValue && errors.temperatureMinValue.message
                              }
                            />
                          </Box>
                        </GridItem>
                        <GridItem s={3} xl={2}>
                          <Box>
                            <FormattedTextFieldComponent
                              name="temperatureMaxValue"
                              onInputChange={temperatureMaxValueInputChanged}
                              defaultValue={checkAttribute?.temperatureMaxValue?.toString() || ''}
                              label={t('data:checkAttribute.maxValue')}
                              ariaLabel={t('data:checkAttribute.maxValue')}
                              required
                              fullWidth={true}
                              suffixUnit={TEMPERATURE_ENTITY}
                              hasError={errors.temperatureMaxValue !== undefined}
                              errorMessage={
                                errors.temperatureMaxValue && errors.temperatureMaxValue.message
                              }
                            />
                          </Box>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </ContainerInside>
              </Paper>
            </ContainerOutsideWithHeader>
            <SampleSizeComponent />
            <GridItem>
              <ButtonsFormComponent cancel={props.cancel} />
            </GridItem>
          </form>
        </FormProvider>
      </FormRoot>
    </Root>
  );
};
