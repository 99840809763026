import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GridContainer } from '../../../components/grid/grid.container';
import { GridItem } from '../../../components/grid/grid.item';
import { InfoboxComponent } from '../../../components/infobox/infobox.component';
import { ContainerOutside } from '../../../components/structure';
import { MILLIS_SECOND } from '../../../constants';
import { haveIssue } from '../../../helper';
import { IssueCode, ProductionRun } from '../../../model';
import {
  productionRunsRunningListSelector,
  startWsConnection,
  productionRunsWebsocketConfig,
  serverTimeDeltaSelector,
} from '../../../store';
import { irisSpacing } from '../../../theme';
import { ProductionRunRunningBoxComponent } from '../production-run-running/production-run-running-box.component';

export const ProductionRunsRunningGridComponent = () => {
  const dispatch = useDispatch();
  const productionRuns = useSelector(productionRunsRunningListSelector);
  const serverTimeDelta = useSelector(serverTimeDeltaSelector);
  const { t } = useTranslation(['data']);
  const hasTU2Violations =
    productionRuns && haveIssue(productionRuns, IssueCode.FertigPackVTU2Violation);
  const hasTU1Violations =
    productionRuns && haveIssue(productionRuns, IssueCode.FertigPackVTU1Violation);

  useEffect(() => {
    dispatch(startWsConnection(productionRunsWebsocketConfig));
  }, [dispatch]);

  const [clientTime, setClientTime] = useState<Date>(new Date());
  const [currentTime, setCurrentTime] = useState<Date | undefined>(undefined);

  function refreshTime() {
    setClientTime(new Date());
  }

  useEffect(() => {
    refreshTime();
    const timerId = setInterval(refreshTime, 10 * MILLIS_SECOND);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  useEffect(() => {
    const adjustedTime = serverTimeDelta
      ? new Date(clientTime.valueOf() + serverTimeDelta)
      : clientTime;
    setCurrentTime(adjustedTime);
  }, [serverTimeDelta, clientTime]);

  return (
    <>
      {productionRuns && productionRuns.length > 0 ? (
        <Box>
          {(hasTU1Violations || hasTU2Violations) && (
            <Box mb={irisSpacing.container.space}>
              <GridContainer>
                {hasTU2Violations && (
                  <GridItem>
                    <InfoboxComponent
                      type={'error'}
                      headline={t('data:productionRun.tu2.violation')}
                      subline={t('data:productionRun.tu2.resultsBelowMinimum')}
                      direction={'row'}
                    />
                  </GridItem>
                )}
                {hasTU1Violations && (
                  <GridItem>
                    <InfoboxComponent
                      type={'warning'}
                      headline={t('data:productionRun.tu1.violation')}
                      subline={t('data:productionRun.tu1.violationPresent')}
                      direction={'row'}
                    />
                  </GridItem>
                )}
              </GridContainer>
            </Box>
          )}
          <GridContainer>
            {productionRuns.map((productionRun: ProductionRun) => {
              return (
                <GridItem key={`run-${productionRun.id}`} xl={6}>
                  <ProductionRunRunningBoxComponent
                    productionRun={productionRun}
                    currentTime={currentTime}
                  />
                </GridItem>
              );
            })}
          </GridContainer>
        </Box>
      ) : (
        <ContainerOutside>
          <Typography>{t('data:productionRun.noProductionRuns')}</Typography>
        </ContainerOutside>
      )}
    </>
  );
};
