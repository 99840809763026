import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { freeTextDialogOpenSelector } from '../../store';

import { FreeTextComponent } from './free-text.component';

export const FreeTextDialogComponent = () => {
  const isFreeTextDialogOpen = useSelector(freeTextDialogOpenSelector);

  return (
    <Dialog open={isFreeTextDialogOpen} fullWidth maxWidth="sm">
      <DialogContent>
        <FreeTextComponent />
      </DialogContent>
    </Dialog>
  );
};
