import { CheckAttribute } from './check-attribute.model';
import { ProductionRunCheckExecutionSample } from './check-execution-sample.model';
import { DisruptionCode } from './disruption.model';

export interface ProductionRunCheckExecution {
  id: string;
  checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>;
  triggeredOn: string;
  checkOrder: number;
  checkedOn: string;
  passed: number;
  failed: number;
  sufficient: number;
  skipped: number;
  unrated: number;
  skipReason?: string;
  cancelledBy?: DisruptionCode;
  samples?: ProductionRunCheckExecutionSample[];
  status: ResultStatus;
}

export enum ResultStatus {
  Pending = 1,
  Done = 2,
  Cancelled = 3,
  Skipped = 4,
  Paused = 5,
}

export enum ResultType {
  Check = 0,
  Sample = 1,
}
