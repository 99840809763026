import produce from 'immer';
import { Action } from 'redux';

import { InternalErrorCode } from '../../http/http-error-codes';
import { ConnectedScale, Device } from '../../model';
import { addItemToFirstPositionInArray, deleteItemFromArray } from '../reducer-utils';

import {
  DevicesActionType,
  fetchDevicesSuccess,
  fetchDeviceSuccess,
  deleteDeviceSuccess,
  fetchConnectedScalesSuccess,
  addDeviceSuccess,
  editDeviceSuccess,
  addDeviceFailure,
  editDeviceFailure,
} from './devices.actions';

export interface DevicesState {
  fetchingAll?: boolean;
  devices?: Device[];
  total?: number;
  currentDevice?: Device;
  connectedScales?: ConnectedScale[];
  lineAlreadySet: boolean;
}

export const getInitialState = (): DevicesState => {
  return { lineAlreadySet: false };
};

export const devicesReducer = (
  previousState: DevicesState = getInitialState(),
  action: Action<DevicesActionType>
) => {
  let nextState;

  switch (action.type) {
    case DevicesActionType.devicesFetchAll:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAll = true;
      });
      break;
    case DevicesActionType.devicesFetchAllSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchDevicesSuccess>;
        draftState.devices = fetchSuccessAction.payload.devices;
        draftState.total = fetchSuccessAction.payload.total;
        draftState.fetchingAll = false;
      });
      break;
    case DevicesActionType.devicesFetchAllFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAll = false;
      });
      break;
    case DevicesActionType.deviceFetch:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentDevice;
        draftState.lineAlreadySet = false;
      });
      break;
    case DevicesActionType.deviceFetchSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchDeviceSuccess>;
        draftState.currentDevice = fetchSuccessAction.payload.device;
      });
      break;
    case DevicesActionType.deviceAddSuccess:
      nextState = produce(previousState, (draftState) => {
        const addSuccessAction = action as ReturnType<typeof addDeviceSuccess>;
        draftState.devices = addItemToFirstPositionInArray(
          draftState.devices,
          addSuccessAction.payload.device
        );
        draftState.total = draftState.total ? draftState.total + 1 : 1;
      });
      break;
    case DevicesActionType.deviceEditSuccess:
      nextState = produce(previousState, (draftState) => {
        const editSuccessAction = action as ReturnType<typeof editDeviceSuccess>;
        draftState.devices = addItemToFirstPositionInArray(
          draftState.devices,
          editSuccessAction.payload.device
        );
      });
      break;
    case DevicesActionType.deviceDeleteSuccess:
      nextState = produce(previousState, (draftState) => {
        const deleteSuccessAction = action as ReturnType<typeof deleteDeviceSuccess>;
        if (!draftState.devices) {
          return draftState;
        }
        draftState.devices = deleteItemFromArray(
          draftState.devices,
          deleteSuccessAction.payload.id
        );
        draftState.total = draftState.total ? draftState.total - 1 : 0;
      });
      break;
    case DevicesActionType.connectedScalesFetchAllSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchConnectedScalesSuccess>;
        draftState.connectedScales = fetchSuccessAction.payload.connectedScales;
      });
      break;
    case DevicesActionType.deviceAddFailure:
    case DevicesActionType.deviceEditFailure:
      nextState = produce(previousState, (draftState) => {
        const failureAction = action as ReturnType<
          typeof addDeviceFailure | typeof editDeviceFailure
        >;
        const { e } = failureAction.payload;
        if (e.internalErrorCode === InternalErrorCode.ProductionLineStaticScaleDeviceAlreadySet) {
          draftState.lineAlreadySet = true;
        }
      });
      break;
    case DevicesActionType.scaleClearLineAlreadySet:
      nextState = produce(previousState, (draftState) => {
        draftState.lineAlreadySet = false;
      });
      break;

    default:
      nextState = previousState;
  }

  return nextState;
};
