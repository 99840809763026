import { Link, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { CommandsMenuComponent } from '../../../components/commands-menu/commands-menu.component';
import { TablePaginationComponent } from '../../../components/table-pagination/table-pagination.component';
import { PaperTable } from '../../../components/table/paper-table.component';
import { AppRoutePath } from '../../../routes/routes';
import {
  checkAttributeListSelector,
  deleteCheckAttribute,
  duplicateCheckAttribute,
  checkAttributesTotalCountSelector,
} from '../../../store';

const PREFIX = 'CheckAttributesTableComponent';
const classes = {
  disabledCell: `${PREFIX}-disabledCell`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.disabledCell}`]: {
    color: theme.palette.action.disabled,
  },
}));

export interface OwnProps {
  handlePageChange: (newPage: number) => void;
  page: number;
}

export const CheckAttributesTableComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data', 'common']);
  const checkAttributes = useSelector(checkAttributeListSelector);
  const total = useSelector(checkAttributesTotalCountSelector);

  const goToEditCheckAttribute = (id: string) => {
    dispatch(push(`/${AppRoutePath.checkAttributes}/${id}/${AppRoutePath.edit}`));
  };

  const duplicateCheckAttributeById = (id: string) => {
    dispatch(duplicateCheckAttribute(id));
  };

  const deleteCheckAttributeById = (id: string) => {
    dispatch(deleteCheckAttribute(id));
  };

  return (
    <Root>
      <PaperTable dataTestId="checkAttributesTable" ariaLabel={t('data:checkAttribute.table')}>
        <TableHead>
          <TableRow>
            <TableCell>{t('data:checkAttribute.name')}</TableCell>
            <TableCell>{t('data:checkAttribute.specification')}</TableCell>
            <TableCell>{t('data:checkAttribute.checkAttributeType')}</TableCell>
            <TableCell>{t('')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {checkAttributes && checkAttributes.length > 0 ? (
            checkAttributes.map((checkAttribute) => {
              return (
                <TableRow key={checkAttribute.id}>
                  <TableCell className={checkAttribute.editable ? undefined : classes.disabledCell}>
                    {checkAttribute.editable ? (
                      <Link
                        component={NavLink}
                        to={`/${AppRoutePath.checkAttributes}/${checkAttribute.id}/${AppRoutePath.edit}`}
                        underline="hover"
                      >
                        {checkAttribute.name}
                      </Link>
                    ) : (
                      checkAttribute.name
                    )}
                  </TableCell>
                  <TableCell className={checkAttribute.editable ? undefined : classes.disabledCell}>
                    {t([
                      `data:checkAttribute.specificationCodes.${checkAttribute.specification}`,
                      `data:checkAttribute.specificationCodes.notFound`,
                    ])}
                  </TableCell>
                  <TableCell className={checkAttribute.editable ? undefined : classes.disabledCell}>
                    {t([
                      `data:checkAttribute.checkAttributeTypeCodes.${checkAttribute.checkAttributeType}`,
                      `data:checkAttribute.checkAttributeTypeCodes.notFound`,
                    ])}
                  </TableCell>
                  <TableCell>
                    <CommandsMenuComponent
                      handleEdit={() => goToEditCheckAttribute(checkAttribute.id)}
                      disableEdit={!checkAttribute.editable}
                      handleDuplicate={() => duplicateCheckAttributeById(checkAttribute.id)}
                      disableDuplicate={!checkAttribute.editable}
                      handleDelete={() => deleteCheckAttributeById(checkAttribute.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={3}>
                {checkAttributes ? t('table:noMatch') : t('common:noDataAvailable')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </PaperTable>
      <TablePaginationComponent
        currentItemsCount={checkAttributes?.length || 0}
        count={total || 0}
        page={props.page}
        onChangePage={(event: unknown, newPage: number) => props.handlePageChange(newPage)}
      />
    </Root>
  );
};
