import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { License } from '../../model';
import { fetchLicense, licenseSelector } from '../../store';

export const LicenseInfoComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['license']);
  const license: License | undefined | false = useSelector(licenseSelector);

  useEffect(() => {
    license === undefined && dispatch(fetchLicense());
  }, [dispatch, license]);

  return (
    <Box>
      <Typography>
        {license && !license.expired
          ? `${t('license:licenseInfoText')} ${license.customerName}`
          : t('license:noLicenseInfoText')}
      </Typography>
    </Box>
  );
};
