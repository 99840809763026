import { Typography } from '@mui/material';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AddButtonComponent } from '../../../components/buttons/add-button.component';
import { LoadingIndicatorComponent } from '../../../components/loading-indicator/loading-indicator.component';
import {
  HeaderContainer,
  BodyStructure,
  HeaderStructure,
  PageStructure,
  ContainerOutside,
} from '../../../components/structure';
import { AppRoutePath } from '../../../routes/routes';
import {
  fetchProductionOrders,
  productionOrderListSelector,
  productionOrdersFetchingSelector,
} from '../../../store';

import { ProductionOrdersTableComponent } from './production-orders-table.component';

export const ProductionOrdersOverviewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const loading = useSelector(productionOrdersFetchingSelector);
  const productionOrders = useSelector(productionOrderListSelector);
  const hasOrders = !!productionOrders && productionOrders.length > 0;

  useEffect(() => {
    dispatch(fetchProductionOrders());
  }, [dispatch]);

  const goToNewProductionOrder = () => {
    dispatch(
      push(
        `/${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}/${AppRoutePath.new}/`
      )
    );
  };

  return (
    <PageStructure>
      <HeaderStructure>
        <HeaderContainer>
          <Typography variant="h1">{t('data:productionOrder.plannedProductionOrders')}</Typography>
          <AddButtonComponent
            text={t('data:productionOrder.new')}
            onClick={goToNewProductionOrder}
          />
        </HeaderContainer>
      </HeaderStructure>
      <BodyStructure>
        <ContainerOutside>
          {loading && !hasOrders ? (
            <LoadingIndicatorComponent entity={t('data:productionOrder.productionOrders')} />
          ) : (
            <ProductionOrdersTableComponent />
          )}
        </ContainerOutside>
      </BodyStructure>
    </PageStructure>
  );
};
