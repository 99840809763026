import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { AppRoutePath } from '../../../routes/routes';
import {
  clearRequestResetPasswordFailed,
  requestResetPassword,
  requestResetPasswordFailed,
} from '../../../store';
import { irisSpacing } from '../../../theme';
import { InfoboxComponent } from '../../infobox/infobox.component';

const PREFIX = 'RequestResetPasswordFormComponent';
const classes = {
  backToLoginBtn: `${PREFIX}-backToLoginBtn`,
};

const Root = styled('div')(() => ({
  [`& .${classes.backToLoginBtn}`]: {
    '&.MuiLink-underlineHover:hover': {
      textDecoration: 'none',
    },
  },
}));

interface FormData {
  username: string;
}

export const RequestResetPasswordFormComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['login', 'form', 'common']);
  const methods = useForm<FormData>();
  const { handleSubmit, errors, control } = methods;
  const requestPasswordFailed = useSelector(requestResetPasswordFailed);

  useEffect(() => {
    return () => {
      dispatch(clearRequestResetPasswordFailed());
    };
  }, [dispatch]);

  const onSubmit = handleSubmit((formData: FormData) => {
    dispatch(requestResetPassword(formData.username));
  });

  return (
    <Root>
      {requestPasswordFailed === false ? (
        <>
          <Box display="flex" justifyContent="center">
            <Typography>{t('login:adminWasContacted')}</Typography>
          </Box>
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Link
              className={classes.backToLoginBtn}
              component={NavLink}
              to={`/${AppRoutePath.login}`}
              underline="hover"
            >
              <Button
                variant="contained"
                color="primary"
                data-testid="back-to-login-btn"
                type="submit"
              >
                {t('login:backToLogin')}
              </Button>
            </Link>
          </Box>
        </>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Box display="flex" flexDirection="column">
              <Controller
                defaultValue=""
                as={<TextField fullWidth={true} variant="outlined" autoComplete="username" />}
                control={control}
                name="username"
                label={t('login:username')}
                inputProps={{
                  'data-testid': 'username-input',
                }}
                rules={{
                  required: { value: true, message: t('form:fieldIsRequired') },
                  validate: (value: string) =>
                    value.trim() ? true : (t('form:fieldIsRequired') as string),
                }}
                error={errors.username !== undefined}
                helperText={errors.username && errors.username.message}
              />

              {requestPasswordFailed === true && (
                <Box mt={3}>
                  <InfoboxComponent type="error" headline={t('login:adminCouldNotBeContacted')} />
                </Box>
              )}
              <Box mt={irisSpacing.button.boxed.mt} display="flex" justifyContent="flex-end">
                {
                  <Button
                    variant="contained"
                    color="primary"
                    data-testid="submit-btn"
                    type="submit"
                  >
                    {t('login:contactAdmin')}
                  </Button>
                }
              </Box>
            </Box>
          </form>
        </FormProvider>
      )}
    </Root>
  );
};
