import { Box } from '@mui/material';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DateInfoComponent } from '../../../components/date-info/date-info.component';
import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { ProductionOrder } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import {
  editProductionOrder,
  currentProductionOrderSelector,
  fetchProductionOrder,
  articleListSelector,
  fetchArticles,
  fetchLines,
  lineListSelector,
} from '../../../store';

import { ProductionOrderFormComponent } from './production-order-form.component';

export const ProductionOrderEditPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(['data']);
  const productionOrderId = id;
  const currentProductionOrder = useSelector(currentProductionOrderSelector);
  useEffect(() => {
    dispatch(fetchArticles());
    dispatch(fetchLines());
    productionOrderId && dispatch(fetchProductionOrder(productionOrderId));
  }, [dispatch, productionOrderId]);
  const articles = useSelector(articleListSelector);
  const lines = useSelector(lineListSelector);

  const submit = (
    productionOrder: Omit<
      ProductionOrder,
      'id' | 'order' | 'lastModified' | 'startable' | 'startErrors'
    >
  ) => {
    currentProductionOrder &&
      dispatch(
        editProductionOrder({
          ...productionOrder,
          id: currentProductionOrder.id,
          order: currentProductionOrder.order,
          lastModified: currentProductionOrder.lastModified,
          startable: currentProductionOrder.startable,
          startErrors: currentProductionOrder.startErrors,
        })
      );
  };

  const cancel = () => {
    dispatch(push(`/${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}/`));
  };

  return (
    <DetailLayoutComponent
      backLinkText={t('data:productionOrder.backToOverview')}
      backLinkTarget={`/${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}/`}
      headlineText={t('data:productionOrder.edit')}
    >
      {currentProductionOrder &&
        articles &&
        articles.find((article) => article.id === currentProductionOrder.article.id) &&
        lines &&
        (!currentProductionOrder.productionLine ||
          (currentProductionOrder.productionLine &&
            lines.find((line) => line.id === currentProductionOrder.productionLine!.id))) && (
          <>
            <ProductionOrderFormComponent
              submit={submit}
              cancel={cancel}
              productionOrder={currentProductionOrder}
              articles={articles}
              lines={lines}
            ></ProductionOrderFormComponent>
            <Box ml={1}>
              <DateInfoComponent dateVal={new Date(currentProductionOrder.lastModified)} />
            </Box>
          </>
        )}
    </DetailLayoutComponent>
  );
};
