export interface License {
  customerName: string;
  validTo: string;
  expired: boolean;
  expireWarning: boolean;
  expiresInDays: number;
  moduleTypes: ModuleType;
}

export enum ModuleType {
  None = 0,
  StaticQualityControl = 1 << 0, //  1
  ManualCheck = 1 << 1, //  2
  CompleteCheck = 1 << 2, //  4
  MetalCheck = 1 << 3, //  8
  XRayCheck = 1 << 4, // 16
  OeeAnalysis = 1 << 5, // 32
  All = StaticQualityControl | ManualCheck | CompleteCheck | MetalCheck | XRayCheck | OeeAnalysis,
}
