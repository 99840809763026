import CheckIcon from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';
import React from 'react';

export interface OwnProps {
  infoText: String;
}

export const RoleInfoComponent = (props: OwnProps) => {
  return (
    <Box mt={1} display="flex" alignItems="center">
      <CheckIcon />
      <Typography variant="body2" style={{ marginLeft: '4px' }}>
        {props.infoText}
      </Typography>
    </Box>
  );
};
