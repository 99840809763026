import { useTheme } from '@mui/material/styles';
import { Pie, DefaultRawDatum, PieSvgProps, ComputedDatum } from '@nivo/pie';
import { BasicTooltip } from '@nivo/tooltip';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductionRun } from '../../model';
import { irisCustomColors, irisCheckResultColors } from '../../theme';
import { getPercentage } from '../../utils/calc-utils';

export interface OwnProps {
  productionRun: ProductionRun;
  height: number;
  width: number;
  interactive: boolean;
}

export const PieChartComponent = (props: OwnProps) => {
  const theme = useTheme();
  const { productionRun, height, width, interactive } = props;

  const { t } = useTranslation(['data']);

  const passedPercentage = `${getPercentage(
    productionRun ? productionRun.checkSamplesPassed : 0.0,
    productionRun ? productionRun.checkSamplesDone : 0.0
  )}%`;
  const sufficientPercentage = `${getPercentage(
    productionRun ? productionRun.checkSamplesSufficient : 0.0,
    productionRun ? productionRun.checkSamplesDone : 0.0
  )}%`;
  const failedPercentage = `${getPercentage(
    productionRun ? productionRun.checkSamplesFailed : 0.0,
    productionRun ? productionRun.checkSamplesDone : 0.0
  )}%`;
  const unratedPercentage = `${getPercentage(
    productionRun ? productionRun.checkSamplesUnrated : 0.0,
    productionRun ? productionRun.checkSamplesDone : 0.0
  )}%`;
  const skippedPercentage = `${getPercentage(
    productionRun ? productionRun.checkSamplesSkipped : 0.0,
    productionRun ? productionRun.checkSamplesDone : 0.0
  )}%`;

  const [labelPassed, setLabelPassed] = useState(`${t('data:productionRun.checkResultsPassed')}`);
  const [labelSufficient, setLabelSufficient] = useState(
    `${t('data:productionRun.checkResultsSufficient')}`
  );
  const [labelFailed, setLabelFailed] = useState(`${t('data:productionRun.checkResultsFailed')}`);
  const [labelUnrated, setLabelUnrated] = useState(
    `${t('data:productionRun.checkResultsUnrated')}`
  );
  const [labelSkipped, setLabelSkipped] = useState(
    `${t('data:productionRun.checkResultsSkipped')}`
  );

  const setLabels = () => {
    if (interactive === false) {
      setLabelPassed(`${passedPercentage} ${t('data:productionRun.checkResultsPassed')}`);
      setLabelSufficient(
        `${sufficientPercentage} ${t('data:productionRun.checkResultsSufficient')}`
      );
      setLabelFailed(`${failedPercentage} ${t('data:productionRun.checkResultsFailed')}`);
      setLabelUnrated(`${unratedPercentage} ${t('data:productionRun.checkResultsUnrated')}`);
      setLabelSkipped(`${skippedPercentage} ${t('data:productionRun.checkResultsSkipped')}`);
    }
  };

  useEffect(() => {
    setLabels();
  });

  const getPieProps = (): PieSvgProps<OwnProps> => {
    const spacingPx: string = theme.spacing(1);
    const spacing: number = +spacingPx.substring(0, spacingPx.length - 2);

    const props = {
      theme: {
        labels: {
          text: {
            fontFamily: theme.typography.fontFamily,
            fontSize: 12,
          },
        },
        legends: {
          text: {
            fontSize: theme.typography.body1.fontSize,
          },
        },
      },
      height: 370,
      width: 524,
      margin: {
        top: spacing * 4,
        right: spacing * 8,
        bottom: spacing * 4,
        left: spacing * 8,
      },
      colors: (d: DefaultRawDatum) => {
        switch (d.id) {
          case t('data:productionRun.checkResultsPassed'):
            return irisCheckResultColors.passed;
          case t('data:productionRun.checkResultsSufficient'):
            return irisCheckResultColors.sufficient;
          case t('data:productionRun.checkResultsFailed'):
            return irisCheckResultColors.failed;
          case t('data:productionRun.checkResultsUnrated'):
            return irisCheckResultColors.unrated;
          case t('data:productionRun.checkResultsSkipped'):
            return irisCheckResultColors.skipped;
          default:
            return irisCustomColors.irisGrayBright;
        }
      },
      animate: false,
      padAngle: interactive === true ? 1 : 0,
      data: [],
      innerRadius: interactive === false ? 0.8 : 0.7,
      isInteractive: interactive,
      enableArcLinkLabels: false,
      enableArcLabels: interactive,
      arcLabel: (d: DefaultRawDatum) =>
        `${getPercentage(d.value, productionRun ? productionRun.checkSamplesDone : 0.0)}%`,
      tooltip: <R,>({ datum }: { datum: ComputedDatum<R> }) => {
        var percentage = `${getPercentage(
          datum.value,
          productionRun ? productionRun.checkSamplesDone : 0.0
        )}%`;
        return <BasicTooltip id={datum.label} value={percentage} color={datum.color} enableChip />;
      },
      arcLabelsTextColor: (d: DefaultRawDatum) => {
        switch (d.id) {
          case t('data:productionRun.checkResultsUnrated'):
            return irisCustomColors.irisWhite;
          default:
            return theme.palette.text.primary;
        }
      },
      activeOuterRadiusOffset: 15,
    };

    // don't add 0 values so labels won't overlap
    if (productionRun) {
      const productionRunData = [];
      productionRun.checkSamplesPassed &&
        productionRunData.push({
          id: t('data:productionRun.checkResultsPassed') as string,
          label: labelPassed,
          value: productionRun.checkSamplesPassed,
        });

      productionRun.checkSamplesSufficient &&
        productionRunData.push({
          id: t('data:productionRun.checkResultsSufficient') as string,
          label: labelSufficient,
          value: productionRun.checkSamplesSufficient,
        });

      productionRun.checkSamplesFailed &&
        productionRunData.push({
          id: t('data:productionRun.checkResultsFailed') as string,
          label: labelFailed,
          value: productionRun.checkSamplesFailed,
        });

      productionRun.checkSamplesUnrated &&
        productionRunData.push({
          id: t('data:productionRun.checkResultsUnrated') as string,
          label: labelUnrated,
          value: productionRun.checkSamplesUnrated,
        });

      productionRun.checkSamplesSkipped &&
        productionRunData.push({
          id: t('data:productionRun.checkResultsSkipped') as string,
          label: labelSkipped,
          value: productionRun.checkSamplesSkipped,
        });
      props.data = productionRunData as any;
    }
    return props;
  };

  return (
    <Pie
      {...getPieProps()}
      height={height}
      width={width}
      legends={[
        {
          anchor: 'right',
          direction: 'column',
          justify: false,
          itemsSpacing: 16,
          symbolSize: 20,
          symbolShape: 'circle',
          itemWidth: 75,
          itemHeight: 16,
          translateX: interactive === false ? -30 : 0,
        },
      ]}
    />
  );
};
