import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import log from 'loglevel';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { getResetPassword, getUser } from './auth/auth.service';
import { AppLayoutComponent } from './components/layout/app-layout/app-layout.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { LoginPage } from './pages/login/login.page';
import { RequestResetPasswordPage } from './pages/request-reset-password/request-reset-password.page';
import { ChangePasswordRoute } from './routes/change-password-route';
import { PublicRoute } from './routes/public-route';
import { AppRoutePath } from './routes/routes';
import {
  stopWsConnections,
  changePasswordAfterResetSelector,
  loggedInUserSelector,
  fetchLicense,
  licenseSelector,
} from './store';
import { initLogin } from './store/authentication/authentication.actions';

const PREFIX = 'App';
const classes = {
  root: `${PREFIX}-root`,
  loadingBox: `${PREFIX}-loadingBox`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
  },
  [`& .${classes.loadingBox}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    height: '100vh',
  },
}));

const App = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector(loggedInUserSelector);
  const changePassword = useSelector(changePasswordAfterResetSelector);
  const [userInitialized, setUserInitialized] = useState<boolean | null>(null);
  const license = useSelector(licenseSelector);

  useEffect(() => {
    return () => {
      dispatch(stopWsConnections());
    };
  }, [dispatch]);

  useEffect(() => {
    license === undefined && dispatch(fetchLicense());
  }, [dispatch, license]);

  useEffect(() => {
    async function getUserFromStorage() {
      try {
        const user = await getUser();
        const changePassword = await getResetPassword();
        user && dispatch(initLogin(user, changePassword));
        setUserInitialized(!!user);
      } catch (e: any) {
        log.debug(e);
      }
    }
    getUserFromStorage();
  }, [dispatch]);

  return (
    <Root className={classes.root}>
      {/* Render routes only when user was initialized or else the original route will get lost on reload due to redirect*/}
      {userInitialized === null || license === undefined ? (
        <div className={classes.loadingBox}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Switch>
          <Route
            exact={true}
            path={`/${AppRoutePath.requestResetPassword}`}
            component={RequestResetPasswordPage}
          />
          <PublicRoute
            loggedInUser={loggedInUser}
            licenseExpired={license && license.expired}
            path={`/${AppRoutePath.login}`}
            component={LoginPage}
          />

          <ChangePasswordRoute
            isLoggedIn={!!loggedInUser}
            changePassword={changePassword}
            path={`/${AppRoutePath.changePassword}`}
          />
          <Route path="/">
            <SnackbarComponent />
            <AppLayoutComponent />
          </Route>
        </Switch>
      )}
    </Root>
  );
};

export default App;
