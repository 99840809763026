import { CheckAttributeTypesList } from '../../model/check-attribute-type.model';

export enum CheckAttributeTypesActionType {
  checkAttributeTypesFetchAll = 'checkAttributeTypes/fetchAll',
  checkAttributeTypesFetchAllSuccess = 'checkAttributeTypes/fetchAll/success',
  checkAttributeTypesFetchAllFailure = 'checkAttributeTypes/fetchAll/failure',
}

export const fetchCheckAttributeTypes = () => ({
  type: CheckAttributeTypesActionType.checkAttributeTypesFetchAll,
});

export const fetchCheckAttributeTypesSuccess = (
  checkAttributeTypesList: CheckAttributeTypesList
) => ({
  type: CheckAttributeTypesActionType.checkAttributeTypesFetchAllSuccess,
  payload: { checkAttributeTypesList },
});

export const fetchCheckAttributeTypesFailure = () => ({
  type: CheckAttributeTypesActionType.checkAttributeTypesFetchAllFailure,
});
