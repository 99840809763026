import { push } from 'connected-react-router';
import { Action } from 'redux';
import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { ProductionOrder } from '../../model';
import { AppRoutePath } from '../../routes/routes';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  ProductionOrdersActionType,
  fetchProductionOrdersSuccess,
  fetchProductionOrdersFailure,
  fetchProductionOrder,
  fetchProductionOrderSuccess,
  fetchProductionOrderFailure,
  addProductionOrder as addProductionOrderAction,
  addProductionOrderSuccess,
  addProductionOrderFailure,
  editProductionOrder as editProductionOrderAction,
  editProductionOrderSuccess,
  editProductionOrderFailure,
  deleteProductionOrder as deleteProductionOrderAction,
  deleteProductionOrderSuccess,
  deleteProductionOrderFailure,
  changeProductionOrderOrder as changeProductionOrderOrderAction,
  changeProductionOrderOrderSuccess,
  changeProductionOrderOrderFailure,
} from './production-orders.actions';

const productionOrdersUrl = 'production/orders';

function* getProductionOrders() {
  try {
    const response = yield* call<[boolean, string], SagaRequest<{ data: ProductionOrder[] }>>(
      SagaRequestHelper.get,
      true,
      productionOrdersUrl
    );
    yield put(fetchProductionOrdersSuccess(response.data));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchProductionOrdersFailure);
  }
}

function* getProductionOrderById(action: Action) {
  const { id } = (action as ReturnType<typeof fetchProductionOrder>).payload;
  try {
    const productionOrder = yield* call<[boolean, string], SagaRequest<ProductionOrder>>(
      SagaRequestHelper.get,
      true,
      `${productionOrdersUrl}/${id}`
    );
    yield put(fetchProductionOrderSuccess(productionOrder));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchProductionOrderFailure);
  }
}

function* addProductionOrder(action: Action) {
  try {
    const addAction = action as ReturnType<typeof addProductionOrderAction>;
    const productionOrder = addAction.payload.productionOrder;
    const updatedProductionOrder = yield* call<
      [boolean, string, { body: string }],
      SagaRequest<ProductionOrder>
    >(SagaRequestHelper.post, true, productionOrdersUrl, {
      body: JSON.stringify({
        orderNumber: productionOrder.orderNumber,
        batchNumber: productionOrder.batchNumber,
        amount: productionOrder.amount,
        articleId: productionOrder.article.id,
        productionLineId: productionOrder.productionLine
          ? productionOrder.productionLine.id
          : undefined,
        testRunAfterStart: productionOrder.testRunAfterStart,
        testRunAfterDisruption: productionOrder.testRunAfterDisruption,
      }),
    });
    yield put(addProductionOrderSuccess(updatedProductionOrder));
  } catch (e: any) {
    yield sagaErrorHandler(e, addProductionOrderFailure);
  }
}

function* editProductionOrder(action: Action) {
  try {
    const editAction = action as ReturnType<typeof editProductionOrderAction>;
    const { productionOrder } = editAction.payload;
    yield* call<[boolean, string, { body: string }], SagaRequest<ProductionOrder>>(
      SagaRequestHelper.put,
      true,
      `${productionOrdersUrl}/${productionOrder.id}`,
      {
        body: JSON.stringify({
          id: productionOrder.id,
          orderNumber: productionOrder.orderNumber,
          amount: productionOrder.amount,
          batchNumber: productionOrder.batchNumber,
          articleId: productionOrder.article.id,
          productionLineId: productionOrder.productionLine
            ? productionOrder.productionLine.id
            : undefined,
          testRunAfterStart: productionOrder.testRunAfterStart,
          testRunAfterDisruption: productionOrder.testRunAfterDisruption,
        }),
      }
    );
    yield put(editProductionOrderSuccess(productionOrder));
  } catch (e: any) {
    yield sagaErrorHandler(e, editProductionOrderFailure);
  }
}

function* deleteProductionOrder(action: Action) {
  const { id } = (action as ReturnType<typeof deleteProductionOrderAction>).payload;
  try {
    yield* call<[boolean, string, {}], SagaRequest<ProductionOrder>>(
      SagaRequestHelper.delete,
      true,
      `${productionOrdersUrl}/${id}`,
      {}
    );
    yield put(deleteProductionOrderSuccess(id));
  } catch (e: any) {
    yield sagaErrorHandler(e, deleteProductionOrderFailure);
  }
}

function* changeProductionOrderOrder(action: Action) {
  const { sourceProductionOrderId, destinationProductionOrderId } = (
    action as ReturnType<typeof changeProductionOrderOrderAction>
  ).payload;

  try {
    yield* call<[boolean, string, { body: string }], SagaRequest<ProductionOrder>>(
      SagaRequestHelper.put,
      true,
      productionOrdersUrl,
      {
        body: JSON.stringify({
          sourceProductionOrderId: sourceProductionOrderId,
          destinationProductionOrderId: destinationProductionOrderId,
        }),
      }
    );
    yield put(changeProductionOrderOrderSuccess());
  } catch (e: any) {
    yield sagaErrorHandler(e, changeProductionOrderOrderFailure);
  }
}

function* redirectOnSuccess() {
  yield put(push(`/${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}/`));
}

export function* redirectProductionOrderOnSuccessSaga() {
  yield takeLatest(
    [
      ProductionOrdersActionType.productionOrderAddSuccess,
      ProductionOrdersActionType.productionOrderEditSuccess,
    ],
    redirectOnSuccess
  );
}

export function* fetchProductionOrdersSaga() {
  yield takeLatest(ProductionOrdersActionType.productionOrdersFetchAll, getProductionOrders);
}

export function* fetchProductionOrderSaga() {
  yield takeLatest(ProductionOrdersActionType.productionOrderFetch, getProductionOrderById);
}

export function* addProductionOrderSaga() {
  yield takeLatest(ProductionOrdersActionType.productionOrderAdd, addProductionOrder);
}

export function* editProductionOrderSaga() {
  yield takeLatest(ProductionOrdersActionType.productionOrderEdit, editProductionOrder);
}

export function* deleteProductionOrderSaga() {
  yield takeLatest(ProductionOrdersActionType.productionOrderDelete, deleteProductionOrder);
}

export function* changeProductionOrderOrderSaga() {
  yield takeLatest(
    ProductionOrdersActionType.productionOrderChangeOrder,
    changeProductionOrderOrder
  );
}

export default function* productionOrdersSaga() {
  yield fork(fetchProductionOrdersSaga);
  yield fork(fetchProductionOrderSaga);
  yield fork(addProductionOrderSaga);
  yield fork(editProductionOrderSaga);
  yield fork(deleteProductionOrderSaga);
  yield fork(changeProductionOrderOrderSaga);
  yield fork(redirectProductionOrderOnSuccessSaga);
}
