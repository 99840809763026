import { HttpError } from '../../http';
import { Line, TableDataQuery } from '../../model';

export enum LinesActionType {
  linesFetchAll = 'lines/fetchAll',
  linesFetchAllSuccess = 'lines/fetchAll/success',
  linesFetchAllFailure = 'lines/fetchAll/failure',
  lineFetch = 'line/fetch',
  lineFetchSuccess = 'line/fetch/success',
  lineFetchFailure = 'line/fetch/failure',
  lineAdd = 'line/add',
  lineAddSuccess = 'line/add/success',
  lineAddFailure = 'line/add/failure',
  lineEdit = 'line/edit',
  lineEditSuccess = 'line/edit/success',
  lineEditFailure = 'line/edit/failure',
  lineDelete = 'line/delete',
  lineDeleteSuccess = 'line/delete/success',
  lineDeleteFailure = 'line/delete/failure',
}

export const fetchLines = (query?: TableDataQuery) => ({
  type: LinesActionType.linesFetchAll,
  payload: {
    query,
  },
});

export const fetchLinesSuccess = (lines: Line[], total: number) => ({
  type: LinesActionType.linesFetchAllSuccess,
  payload: { lines, total },
});

export const fetchLinesFailure = () => ({
  type: LinesActionType.linesFetchAllFailure,
});

export const fetchLine = (id: string) => ({
  type: LinesActionType.lineFetch,
  payload: { id },
});

export const fetchLineSuccess = (line: Line) => ({
  type: LinesActionType.lineFetchSuccess,
  payload: { line },
});

export const fetchLineFailure = () => ({
  type: LinesActionType.lineFetchFailure,
});

export const addLine = (line: Omit<Line, 'id' | 'lastModified'>) => ({
  type: LinesActionType.lineAdd,
  payload: { line },
});

export const addLineSuccess = (line: Line) => ({
  type: LinesActionType.lineAddSuccess,
  payload: { line },
});

export const addLineFailure = (e: HttpError) => ({
  type: LinesActionType.lineAddFailure,
  payload: { e },
});

export const editLine = (line: Line) => ({
  type: LinesActionType.lineEdit,
  payload: { line },
});

export const editLineSuccess = (line: Line) => ({
  type: LinesActionType.lineEditSuccess,
  payload: { line },
});

export const editLineFailure = (e: HttpError) => ({
  type: LinesActionType.lineEditFailure,
  payload: { e },
});

export const deleteLine = (id: string) => ({
  type: LinesActionType.lineDelete,
  payload: { id },
});

export const deleteLineSuccess = (id: string) => ({
  type: LinesActionType.lineDeleteSuccess,
  payload: { id },
});

export const deleteLineFailure = () => ({
  type: LinesActionType.lineDeleteFailure,
});
