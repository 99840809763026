import { Box, Tab, Tabs, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProductionRun } from '../../../model';
import { irisCustomColors, irisSpacing } from '../../../theme';

import { ProductionRunRunningCheckResultsTab } from './production-run-running-check-results.tab';
import { ProductionRunRunningEventsTab } from './production-run-running-events.tab';
import { ProductionRunRunningOverviewTab } from './production-run-running-overview.tab';

enum TabItem {
  Overview = 0,
  Events = 1,
  CheckResults = 2,
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  tabName: string;
  value: number;
}

function tabProps(index: number) {
  return {
    id: `tab${index}`,
    tabIndex: index,
    'aria-controls': `tabButton${index}`,
    'data-testid': `tabButton${index}`,
  };
}

interface StyledTabProps {
  label: string;
}

const StyledTabs = styled(Tabs)({
  borderBottom: `1px solid ${irisCustomColors.irisBlack}`,
  '& .MuiTabs-indicator': {
    backgroundColor: irisCustomColors.irisBlueGray,
  },
});

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: irisCustomColors.irisWhite,
    opacity: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textTransform: 'none',
    fontSize: '24px',
    '&.Mui-selected': {
      color: irisCustomColors.irisWhite,
      backgroundColor: irisCustomColors.irisBlueGray,
      fontWeight: theme.typography.fontWeightMedium,
      '&:hover': {
        backgroundColor: irisCustomColors.irisBlueGray,
      },
    },
    '&:hover': {
      backgroundColor: irisCustomColors.irisGrayLight,
    },
  })
);

const TabContent = (props: TabPanelProps) => {
  const { children, value, index, tabName, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      key={`tabPanel${index}`}
      id={`tabPanel${index}`}
      aria-labelledby={`tab${index}`}
      data-testid={`tabContent${tabName}`}
      {...other}
    >
      {value === index && <Box mt={irisSpacing.container.space}>{children}</Box>}
    </div>
  );
};

const PREFIX = 'ProductionRunRunningContentComponent';
const classes = {
  outerBox: `${PREFIX}-outerBox`,
  tabPanelContent: `${PREFIX}-tabPanelContent`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.outerBox}`]: {
    width: '100%',
  },

  [`& .${classes.tabPanelContent}`]: {
    marginTop: theme.spacing(irisSpacing.container.space),
  },
}));

interface OwnProps {
  productionRun: ProductionRun;
}

export const ProductionRunRunningContentComponent = (props: OwnProps) => {
  const { productionRun } = props;
  const { t } = useTranslation(['data']);

  const [selectedTab, setSelectedTab] = React.useState(TabItem.Overview);

  const tabPanel = () => {
    return (
      <Paper>
        {/* KNOWN ISSUE in MUIs Tab component is causing "The `value` provided to the Tabs component is invalid" error            
            Suggested workarounds do not take effect: https://github.com/mui/material-ui/issues/32749 */}
        <StyledTabs value={selectedTab} onChange={handleTabChange}>
          <StyledTab
            label={t('data:productionRun.tabs.overview')}
            {...tabProps(TabItem.Overview)}
          />
          <StyledTab label={t('data:productionRun.tabs.events')} {...tabProps(TabItem.Events)} />
          <StyledTab
            label={t('data:productionRun.tabs.checkResults')}
            {...tabProps(TabItem.CheckResults)}
          />
        </StyledTabs>
      </Paper>
    );
  };

  const tabOverview = () => {
    return (
      <TabContent value={selectedTab} index={TabItem.Overview} tabName={'Overview'}>
        {<ProductionRunRunningOverviewTab productionRun={productionRun} />}
      </TabContent>
    );
  };

  const tabEvents = () => {
    return (
      <TabContent value={selectedTab} index={TabItem.Events} tabName={'Events'}>
        {<ProductionRunRunningEventsTab productionRun={productionRun} />}
      </TabContent>
    );
  };

  const tabCheckResults = () => {
    return (
      <TabContent value={selectedTab} index={TabItem.CheckResults} tabName={'CheckResults'}>
        {<ProductionRunRunningCheckResultsTab productionRun={productionRun} />}
      </TabContent>
    );
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setSelectedTab(newValue);
  };

  return (
    <StyledBox className={classes.outerBox}>
      {tabPanel()}
      {tabOverview()}
      {tabEvents()}
      {tabCheckResults()}
    </StyledBox>
  );
};
