import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Grid, Link, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ApiKeyComponent } from '../../components/api-key/api-key.component';
import { dataTableClasses, DataTableRoot } from '../../components/data-table/data-table.styles';
import { InfoboxComponent } from '../../components/infobox/infobox.component';
import { LicenseInfoComponent } from '../../components/license-info/license-info.component';
import {
  HeaderContainer,
  BodyStructure,
  ContainerOutside,
  HeaderStructure,
  PageStructure,
  ContainerInside,
} from '../../components/structure';
import { isModuleLicensed } from '../../helper';
import { ModuleType } from '../../model';
import {
  addLicenseSuccessfulSelector,
  expiredLicenseFailureSelector,
  fetchLicense,
  licenseSelector,
  clearLicenseUploadStates,
  addLicense,
} from '../../store';
import { dateOptions } from '../../utils/date-utils';

const PREFIX = 'LicensePage';
const classes = {
  fileInput: `${PREFIX}-fileInput`,
  expired: `${PREFIX}-expired`,
  moduleContainer: `${PREFIX}-moduleContainer`,
  moduleIconContainer: `${PREFIX}-moduleIconContainer`,
  checkIcon: `${PREFIX}-checkIcon`,
  disabled: `${PREFIX}-disabled`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.fileInput}`]: {
    display: 'none',
  },
  [`& .${classes.expired}`]: {
    color: theme.palette.error.main,
  },
  [`& .${classes.moduleContainer}`]: {
    display: 'flex',
    minHeight: '30px',
  },
  [`& .${classes.moduleIconContainer}`]: {
    minWidth: theme.spacing(4),
  },
  [`& .${classes.checkIcon}`]: {
    marginRight: theme.spacing(1),
  },
  [`& .${classes.disabled}`]: {
    color: theme.palette.text.disabled,
  },
}));

export const LicensePage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['license', 'form', 'common']);
  const license = useSelector(licenseSelector);
  const addLicenseSuccessful = useSelector(addLicenseSuccessfulSelector);
  const attemptedLicenseExpired = useSelector(expiredLicenseFailureSelector);

  useEffect(() => {
    license === undefined && dispatch(fetchLicense());
  }, [dispatch, license]);

  useEffect(() => {
    return () => {
      dispatch(clearLicenseUploadStates());
    };
  }, [dispatch]);

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      dispatch(addLicense(e.target.files[0]));
    }
  };

  const getInfoBox = () => {
    if (addLicenseSuccessful === true && license && license.expired === false) {
      return <InfoboxComponent type="success" subline={t('license:addLicenseSuccessful')} />;
    } else if (attemptedLicenseExpired === true) {
      return <InfoboxComponent type="error" subline={t('license:licenseExpired')} />;
    } else if (addLicenseSuccessful === false) {
      const subline = (
        <Trans t={t} i18nKey="addLicenseFailed">
          The license could not be extended. Please try the upload again or contact your{' '}
          <Link href="mailto:service@hoefelmeyer.de" underline="hover">
            Höfelmeyer system consultant
          </Link>
          .
        </Trans>
      );
      return <InfoboxComponent type="error" subline={subline} />;
    }
    return <></>;
  };

  const getModuleItem = (moduleType: ModuleType, index: number) => {
    const licensed = isModuleLicensed(license, moduleType);

    return (
      <Box
        mb={1}
        className={classes.moduleContainer}
        data-testid={`module-container-${moduleType}`}
      >
        {licensed && (
          <Box className={classes.moduleIconContainer}>
            <CheckIcon className={classes.checkIcon} />
          </Box>
        )}
        <Typography className={!licensed ? classes.disabled : undefined}>
          {t([`license:moduleTypes.${index}`, `license:moduleTypes.notFound`])}
        </Typography>
      </Box>
    );
  };

  return (
    <PageStructure>
      <Root>
        <DataTableRoot>
          <HeaderStructure>
            <HeaderContainer>
              <Typography variant="h1">{t('license:licenseManagement')}</Typography>
            </HeaderContainer>
          </HeaderStructure>
          <BodyStructure>
            {(addLicenseSuccessful != null || attemptedLicenseExpired != null) && (
              <Box mb={4}>{getInfoBox()}</Box>
            )}
            {license && license.expired === true && (
              <Box mb={4}>
                <InfoboxComponent type="error" subline={t('license:licenseExpiredPleaseRenew')} />
              </Box>
            )}
            {license && (
              <ContainerOutside>
                <Paper>
                  <ContainerInside>
                    <Typography variant="h3">{t('license:yourIrisProducts')}</Typography>
                    <Grid container>
                      <Grid item xs={12} md={6} lg={3}>
                        <Box mb={2}>
                          <Typography>IRIS @office</Typography>
                        </Box>
                        <table className={dataTableClasses.dataTable}>
                          <tbody>
                            <tr>
                              <td>{t('license:licenseValidTo')}:</td>
                              <td className={license.expired ? classes.expired : ''}>
                                {new Date(license.validTo).toLocaleString(
                                  i18n.language,
                                  dateOptions
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <Box mb={2}>
                          <Typography>IRIS @production</Typography>
                        </Box>
                        <table className={dataTableClasses.dataTable}>
                          <tbody>
                            <tr>
                              <td>{t('license:licenseValidTo')}:</td>
                              <td className={license.expired ? classes.expired : ''}>
                                {new Date(license.validTo).toLocaleString(
                                  i18n.language,
                                  dateOptions
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Grid>
                    </Grid>
                  </ContainerInside>
                </Paper>
              </ContainerOutside>
            )}
            <ContainerOutside>
              <Paper>
                <ContainerInside>
                  <Typography variant="h3">{t('license:modules')}</Typography>
                  <Grid container>
                    <Grid item xs={12} md={6} lg={4} xl={3}>
                      {getModuleItem(ModuleType.StaticQualityControl, 1)}
                      {getModuleItem(ModuleType.ManualCheck, 2)}
                    </Grid>
                  </Grid>
                </ContainerInside>
              </Paper>
            </ContainerOutside>
            <ContainerOutside>
              <Paper>
                <ContainerInside>
                  <Typography variant="h3">{t('license:extendLicense')}</Typography>
                  <Box>
                    <input
                      accept=".license"
                      className={classes.fileInput}
                      id="license-upload"
                      multiple
                      type="file"
                      data-testid="license-upload-input"
                      onChange={uploadFile}
                    />
                    <label htmlFor="license-upload">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        data-testid="upload-license-btn"
                      >
                        {t('license:uploadLicense')}
                      </Button>
                    </label>
                  </Box>
                  <Box mt={3}>
                    <Typography>
                      <Trans t={t} i18nKey="contactSystemConsultant">
                        Contact{' '}
                        <Link href="mailto:service@hoefelmeyer.de" underline="hover">
                          Höfelmeyer system consultant
                        </Link>{' '}
                        now to get a license.
                      </Trans>
                    </Typography>
                  </Box>
                </ContainerInside>
              </Paper>
            </ContainerOutside>

            <ContainerOutside>
              <ApiKeyComponent />
            </ContainerOutside>
            <Box mt={3}>
              <LicenseInfoComponent />
            </Box>
          </BodyStructure>
        </DataTableRoot>
      </Root>
    </PageStructure>
  );
};
