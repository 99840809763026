import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { getFertigPackVStatistics } from '../../helper';
import { ProductionRun } from '../../model';
import { BarChartInfoComponent } from '../bar-chart/bar-chart-info.component';
import { EventsTableComponent } from '../events-table/events-table.component';
import { IssuesComponent } from '../issue/issues.component';
import { PieChartComponent } from '../pie-chart/pie-chart.component';
import { ProductionDetailsComponent } from '../production-details/production-details.component';
import { ToleranceBarInfoComponent } from '../tolerance-bar/tolerance-bar-info.component';

import { PrintContentComponentRoot, printContentComponentClasses } from './print-content.styles';

export interface OwnProps {
  productionRun: ProductionRun;
}

export const PrintContentComponent = React.forwardRef<HTMLDivElement, OwnProps>((props, ref) => {
  const { productionRun } = props;
  const statistics = getFertigPackVStatistics(productionRun);
  const { t } = useTranslation(['data']);

  return (
    <PrintContentComponentRoot ref={ref}>
      <Box>
        <Typography variant="h1" className={printContentComponentClasses.headlineSpacing}>
          {`${productionRun.orderNumber} | ${productionRun.article.productionLine.name}`}
        </Typography>
        <Box className={printContentComponentClasses.notificationBox}>
          <IssuesComponent issues={productionRun.issues} />
        </Box>
        <Box className={printContentComponentClasses.boxSpacing}>
          <ProductionDetailsComponent productionRun={productionRun} />
        </Box>
        <Typography variant="h2">{t('data:productionRun.currentCheckResults')}</Typography>
        <Box className={printContentComponentClasses.boxSpacing}>
          <Typography variant="h4">{t('data:productionRun.checkResultsOverview')}</Typography>
          {productionRun.checkSamplesDone && productionRun.checkSamplesDone > 0 ? (
            <Box className={printContentComponentClasses.pieChart} data-testid="pieChartBox">
              <PieChartComponent
                productionRun={productionRun}
                height={220}
                width={650}
                interactive={false}
              />
            </Box>
          ) : (
            <Box className={printContentComponentClasses.noCheckResultSpacing}>
              {t('data:productionRun.noCheckResults')}
            </Box>
          )}
        </Box>
        {statistics?.checkSamplesAverageWeight && (
          <Box
            className={`${printContentComponentClasses.boxSpacing} ${printContentComponentClasses.breakInsideAvoidPage}`}
          >
            <ToleranceBarInfoComponent
              productionRun={productionRun}
              headlineText={t('data:productionRun.fertigPackVCurrentAverageResult')}
              forPrint={true}
            />
          </Box>
        )}
        {statistics?.checkSamplesDone && (
          <Box
            className={`${printContentComponentClasses.boxSpacing} ${printContentComponentClasses.breakInsideAvoidPage}`}
          >
            <BarChartInfoComponent productionRun={productionRun} forPrint={true} />
          </Box>
        )}
        <Box
          className={
            statistics?.checkSamplesDone
              ? printContentComponentClasses.tablePageBreakAuto
              : printContentComponentClasses.breakInsideAvoidPage
          }
        >
          <Typography variant="h2">{t('data:productionRun.events.events')}</Typography>
          <EventsTableComponent
            events={productionRun.events}
            noEventsText={t('data:productionRun.events.noEvents')}
            forPrint={true}
          />
        </Box>
      </Box>
    </PrintContentComponentRoot>
  );
});
