import { push } from 'connected-react-router';
import { Action } from 'redux';
import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { ConnectedScale, Device } from '../../model';
import { tableDataQueryToUrl } from '../../model';
import { AppRoutePath } from '../../routes/routes';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  DevicesActionType,
  fetchDevicesSuccess,
  fetchDevicesFailure,
  fetchDeviceSuccess,
  fetchDeviceFailure,
  deleteDevice as deleteDeviceAction,
  deleteDeviceSuccess,
  deleteDeviceFailure,
  addDevice as addDeviceAction,
  addDeviceFailure,
  addDeviceSuccess,
  editDevice as editDeviceAction,
  editDeviceFailure,
  editDeviceSuccess,
  fetchDevice,
  fetchDevices,
  fetchConnectedScalesFailure,
  fetchConnectedScalesSuccess,
} from './devices.actions';

const devicesUrl = 'devices';
const connectedScalesUrl = `${devicesUrl}/scales/search`;

function* getDevices(action: Action) {
  const { query } = (action as ReturnType<typeof fetchDevices>).payload;
  const tableDataQueryUrl = tableDataQueryToUrl(query);
  try {
    const response = yield* call<
      [boolean, string],
      SagaRequest<{ data: Device[]; metadata: { total: number } }>
    >(SagaRequestHelper.get, true, `${devicesUrl}${tableDataQueryUrl}`);
    yield put(fetchDevicesSuccess(response.data, response.metadata.total));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchDevicesFailure);
  }
}

function* getDeviceById(action: Action) {
  const { id } = (action as ReturnType<typeof fetchDevice>).payload;
  try {
    const device = yield* call<[boolean, string], SagaRequest<Device>>(
      SagaRequestHelper.get,
      true,
      `${devicesUrl}/${id}`
    );
    yield put(fetchDeviceSuccess(device));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchDeviceFailure);
  }
}

function* addDevice(action: Action) {
  const addAction = action as ReturnType<typeof addDeviceAction>;
  try {
    const device = yield* call<[boolean, string, { body: string }], SagaRequest<Device>>(
      SagaRequestHelper.post,
      true,
      devicesUrl,
      {
        body: JSON.stringify({
          ...addAction.payload.device,
          deviceId: addAction.payload.device.connectedScale?.id,
          productionLineId: addAction.payload.device.productionLine?.id,
        }),
      }
    );
    yield put(addDeviceSuccess(device));
  } catch (e: any) {
    yield sagaErrorHandler(e, addDeviceFailure);
  }
}

function* editDevice(action: Action) {
  const editAction = action as ReturnType<typeof editDeviceAction>;
  const { device } = editAction.payload;
  try {
    yield* call<[boolean, string, { body: string }], SagaRequest<Device>>(
      SagaRequestHelper.put,
      true,
      `${devicesUrl}/${device.id}`,
      {
        body: JSON.stringify({
          ...device,
          deviceId: device.connectedScale?.id,
          productionLineId: device.productionLine?.id,
        }),
      }
    );
    yield put(editDeviceSuccess(device));
  } catch (e: any) {
    yield sagaErrorHandler(e, editDeviceFailure);
  }
}

function* deleteDevice(action: Action) {
  const { id } = (action as ReturnType<typeof deleteDeviceAction>).payload;
  try {
    yield* call<[boolean, string, {}], SagaRequest<Device>>(
      SagaRequestHelper.delete,
      true,
      `${devicesUrl}/${id}`,
      {}
    );
    yield put(deleteDeviceSuccess(id));
  } catch (e: any) {
    yield sagaErrorHandler(e, deleteDeviceFailure);
  }
}

function* redirectOnSuccess() {
  yield put(push(`/${AppRoutePath.devices}`));
}

function* getConnectedScales(action: Action) {
  try {
    const response = yield* call<[boolean, string], SagaRequest<ConnectedScale[]>>(
      SagaRequestHelper.get,
      true,
      `${connectedScalesUrl}`
    );
    yield put(fetchConnectedScalesSuccess(response));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchConnectedScalesFailure);
  }
}

export function* redirectDeviceOnSuccessSaga() {
  yield takeLatest(
    [DevicesActionType.deviceAddSuccess, DevicesActionType.deviceEditSuccess],
    redirectOnSuccess
  );
}

export function* fetchDevicesSaga() {
  yield takeLatest(DevicesActionType.devicesFetchAll, getDevices);
}

export function* fetchDeviceSaga() {
  yield takeLatest(DevicesActionType.deviceFetch, getDeviceById);
}

export function* addDeviceSaga() {
  yield takeLatest(DevicesActionType.deviceAdd, addDevice);
}

export function* editDeviceSaga() {
  yield takeLatest(DevicesActionType.deviceEdit, editDevice);
}

export function* deleteDeviceSaga() {
  yield takeLatest(DevicesActionType.deviceDelete, deleteDevice);
}

export function* fetchConnectedScalesSaga() {
  yield takeLatest(DevicesActionType.connectedScalesFetchAll, getConnectedScales);
}

export default function* devicesSaga() {
  yield fork(fetchDevicesSaga);
  yield fork(redirectDeviceOnSuccessSaga);
  yield fork(fetchDeviceSaga);
  yield fork(deleteDeviceSaga);
  yield fork(fetchConnectedScalesSaga);
  yield fork(addDeviceSaga);
  yield fork(editDeviceSaga);
}
