import { ProductionOverview } from '../../model/production-overview.model';
export enum ProductionActionType {
  productionGetTotals = 'production/getTotals/',
  productionGetTotalsSuccess = 'production/getTotals/success',
  productionGetTotalsFailure = 'production/getTotals/failure',

  // websocket events
  productionOverviewChanged = 'production/overviewChanged',
}
export const getProductionTotals = () => ({
  type: ProductionActionType.productionGetTotals,
});

export const getProductionTotalsSuccess = (productionOverview: ProductionOverview) => ({
  type: ProductionActionType.productionGetTotalsSuccess,
  payload: { productionOverview },
});

export const getProductionTotalsFailure = () => ({
  type: ProductionActionType.productionGetTotalsFailure,
});

// websocket events
export const changedProductionOverview = (productionOverview: ProductionOverview) => ({
  type: ProductionActionType.productionOverviewChanged,
  payload: { productionOverview },
});
