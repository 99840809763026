import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { FormHelperText } from '@mui/material';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MAX_IMG_SIZE } from '../../../constants';
import { irisCustomColors } from '../../../theme';
import { ImagePreviewComponent } from '../../image/image-preview/image-preview.component';

const PREFIX = 'ImageInputWithPreviewComponent';
const classes = {
  root: `${PREFIX}-root`,
  flexRoot: `${PREFIX}-flexRoot`,
  imageInput: `${PREFIX}-imageInput`,
  buttons: `${PREFIX}-buttons`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: '300px',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    width: '400px',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.flexRoot}`]: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },

  [`& .${classes.imageInput}`]: {
    display: 'none',
  },

  [`& .${classes.buttons}`]: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .MuiIconButton-root': {
      color: irisCustomColors.irisBlack,
      backgroundColor: `${irisCustomColors.irisWhite}99`,
      height: '80px',
      width: '80px',
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: `${irisCustomColors.irisWhite}77`,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
  },
}));

export interface OwnProps {
  changeImage: (file: File | undefined) => void;
  chosenImageFile: File | undefined;
  removeImage: () => void;
  imageUrl?: string;
  labelId?: string;
  flexible?: boolean;
}

export const ImageInputWithPreviewComponent = (props: OwnProps) => {
  const { register, errors } = useFormContext();
  const { t } = useTranslation(['form']);

  const { chosenImageFile, removeImage, imageUrl, labelId, flexible } = props;
  const inputName = labelId || 'image-input';

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const { changeImage: onInputChange } = props;
      onInputChange && onInputChange(e.target.files[0]);
    }
  };

  return (
    <Root>
      <input
        className={classes.imageInput}
        type="file"
        accept="image/*"
        title=""
        id={inputName}
        ref={register({
          validate: {
            maxImageSize: (value) => {
              if (props.chosenImageFile) {
                return (
                  props.chosenImageFile.size <= MAX_IMG_SIZE || (t('form:maxImageSize') as string)
                );
              }
              return true;
            },
          },
        })}
        data-testid={inputName}
        onChange={uploadFile}
        name={inputName}
      />

      <div className={flexible ? classes.flexRoot : classes.root}>
        <ImagePreviewComponent
          file={chosenImageFile}
          altText={t('form:image')}
          imageUrl={imageUrl}
          flexible={flexible}
        />
        <div className={classes.buttons}>
          {imageUrl || chosenImageFile ? (
            <>
              <label htmlFor={labelId ? labelId : 'image-input'}>
                <IconButton
                  className="chosenImage"
                  data-testid="edit-img-btn"
                  component="span"
                  title={t('form:editImage')}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </label>
              <IconButton
                className="chosenImage"
                data-testid="remove-img-btn"
                onClick={removeImage}
                title={t('form:removeImage')}
                size="large"
              >
                <DeleteForeverIcon />
              </IconButton>
            </>
          ) : (
            <label htmlFor={inputName}>
              <IconButton
                data-testid="new-img-btn"
                title={t('form:newImage')}
                component="span"
                size="large"
              >
                <AddAPhotoIcon />
              </IconButton>
            </label>
          )}
        </div>
      </div>
      {errors[inputName] && errors[inputName].message && (
        <FormHelperText error={true}>{errors[inputName].message}</FormHelperText>
      )}
    </Root>
  );
};
