import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BusinessIcon from '@mui/icons-material/Business';
import FlagIcon from '@mui/icons-material/Flag';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import PaymentIcon from '@mui/icons-material/Payment';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RouterIcon from '@mui/icons-material/Router';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { UserRole } from '../model';
import { ArticlesOverviewPage } from '../pages/articles/articles/articles-overview.page';
import { CheckAttributesOverviewPage } from '../pages/check-attributes/check-attributes/check-attributes-overview.page';
import { DevicesOverviewPage } from '../pages/devices/devices-overview.page';
import { LinesOverviewPage } from '../pages/lines/lines/lines-overview.page';
import { ProductionOrderNewPage } from '../pages/production/production-order/production-order-new.page';
import { ProductionOrdersOverviewPage } from '../pages/production/production-orders/production-orders-overview.page';
import { ProductionRunsFinishedOverviewPage } from '../pages/production/production-runs-finished/production-runs-finished-overview.page';
import { ProductionRunsRunningOverviewPage } from '../pages/production/production-runs-running/production-runs-running-overview.page';
import { ProductionOverviewPage } from '../pages/production/production/production-overview.page';
import { UsersOverviewPage } from '../pages/users/users/users-overview.page';

import { LicensePage } from './../pages/license/license.page';

export enum AppRoutePath {
  root = '/',
  articles = 'articles',
  new = 'new',
  edit = 'edit',
  checkAttributes = 'check-attributes',
  production = 'production',
  orders = 'orders',
  runs = 'runs',
  checks = 'checks',
  lines = 'lines',
  users = 'users',
  running = 'running',
  planned = 'planned',
  finished = 'finished',
  devices = 'devices',
  login = 'login',
  changePassword = 'change-password',
  me = 'me',
  requestResetPassword = 'request-reset-password',
  license = 'license',
}

export interface SidebarRoute {
  path: string;
  translationKeyName: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
  component: () => JSX.Element;
  minRole: UserRole;
  submenuRoutes?: SidebarRoute[];
  visibleWhenLicenseIsExpired: boolean;
}

export const configurationRoutes: SidebarRoute[] = [
  {
    path: AppRoutePath.articles,
    translationKeyName: 'data:article.articles',
    icon: LocalOfferIcon,
    component: ArticlesOverviewPage,
    minRole: UserRole.Supervisor,
    visibleWhenLicenseIsExpired: false,
  },
  {
    path: AppRoutePath.checkAttributes,
    translationKeyName: 'data:checkAttribute.checkAttributes',
    icon: OfflinePinIcon,
    component: CheckAttributesOverviewPage,
    minRole: UserRole.Supervisor,
    visibleWhenLicenseIsExpired: false,
  },
  {
    path: AppRoutePath.lines,
    translationKeyName: 'data:line.lines',
    icon: LinearScaleIcon,
    component: LinesOverviewPage,
    minRole: UserRole.Supervisor,
    visibleWhenLicenseIsExpired: false,
  },
  {
    path: AppRoutePath.devices,
    translationKeyName: 'data:device.deviceManager',
    icon: RouterIcon,
    component: DevicesOverviewPage,
    minRole: UserRole.Supervisor,
    visibleWhenLicenseIsExpired: false,
  },
  {
    path: AppRoutePath.users,
    translationKeyName: 'data:user.userAdministration',
    icon: SupervisorAccountIcon,
    component: UsersOverviewPage,
    minRole: UserRole.Admin,
    visibleWhenLicenseIsExpired: true,
  },
  {
    path: AppRoutePath.license,
    translationKeyName: 'license:licenseManagement',
    icon: PaymentIcon,
    component: LicensePage,
    minRole: UserRole.Admin,
    visibleWhenLicenseIsExpired: true,
  },
];

// submenu routes have to be added separately in index.ts
export const jobsRoutes: SidebarRoute[] = [
  {
    path: AppRoutePath.production,
    translationKeyName: 'data:productionOrder.productionOrders',
    icon: BusinessIcon,
    component: ProductionOverviewPage,
    minRole: UserRole.Supervisor,
    visibleWhenLicenseIsExpired: false,
    submenuRoutes: [
      {
        path: `${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}/${AppRoutePath.new}/`,
        translationKeyName: 'data:productionOrder.new',
        icon: AddCircleOutlineIcon,
        component: ProductionOrderNewPage,
        minRole: UserRole.Supervisor,
        visibleWhenLicenseIsExpired: false,
      },
      {
        path: `${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.running}/`,
        translationKeyName: 'data:productionRun.menuItemRunning',
        icon: PlayCircleOutlineIcon,
        component: ProductionRunsRunningOverviewPage,
        minRole: UserRole.Supervisor,
        visibleWhenLicenseIsExpired: false,
      },
      {
        path: `${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}/`,
        translationKeyName: 'data:productionOrder.menuItemPlanned',
        icon: SystemUpdateAltIcon,
        component: ProductionOrdersOverviewPage,
        minRole: UserRole.Supervisor,
        visibleWhenLicenseIsExpired: false,
      },
      {
        path: `${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.finished}/`,
        translationKeyName: 'data:productionRun.menuItemFinished',
        icon: FlagIcon,
        component: ProductionRunsFinishedOverviewPage,
        minRole: UserRole.Supervisor,
        visibleWhenLicenseIsExpired: false,
      },
    ],
  },
];
