import ArrowBack from '@mui/icons-material/ArrowBack';
import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { PrintButtonComponent } from '../../buttons/print-button.component';
import {
  HeaderContainer,
  BacklinkStructure,
  BodyStructure,
  HeaderStructure,
} from '../../structure';
import { PageStructure } from '../../structure/page.structure';

const PREFIX = 'DetailLayoutComponent';
const classes = {
  backLink: `${PREFIX}-backLink`,
  buttonBox: `${PREFIX}-buttonBox`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.backLink}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.buttonBox}`]: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),
  },
}));

export interface OwnProps {
  children: React.ReactNode;
  headlineText: string;
  backLinkTarget: string;
  backLinkText: string;
  print?: () => void;
}

export const DetailLayoutComponent = (props: OwnProps) => {
  const { t } = useTranslation(['common']);

  return (
    <PageStructure>
      <StyledBox>
        <BacklinkStructure>
          <Link
            className={classes.backLink}
            component={NavLink}
            to={props.backLinkTarget}
            underline="hover"
          >
            <ArrowBack />
            {props.backLinkText}
          </Link>
        </BacklinkStructure>
        <HeaderStructure>
          <HeaderContainer>
            <Typography variant="h1">{props.headlineText}</Typography>
            {props.print && (
              <Box className={classes.buttonBox}>
                <PrintButtonComponent
                  disabled={false}
                  onClick={props.print}
                  text={t('common:print')}
                />
              </Box>
            )}
          </HeaderContainer>
        </HeaderStructure>
        <BodyStructure>{props.children}</BodyStructure>
      </StyledBox>
    </PageStructure>
  );
};
