import { styled } from '@mui/material/styles';

import { getPixels, irisSpecialColors } from '../../../theme';

const PREFIX = 'CheckResultCellContentComponent';
export const checkResultsCellContentClasses = {
  outerBox: `${PREFIX}-outerBox`,
  dot: `${PREFIX}-dot`,
  entitySuffix: `${PREFIX}-entitySuffix`,
  limitText: `${PREFIX}-limitText`,
  resultBox: `${PREFIX}-resultBox`,
  sampleText: `${PREFIX}-sampleText`,
  disruptionIcon: `${PREFIX}-disruptionIcon`,
  center: `${PREFIX}-center`,
  seperator: `${PREFIX}-seperator`,
};

export const CheckResultsCellContentRoot = styled('div')(({ theme }) => ({
  [`& .${checkResultsCellContentClasses.outerBox}`]: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },

  [`& .${checkResultsCellContentClasses.dot}`]: {
    height: '25px',
    width: '25px',
    minWidth: '25px',
    maxWidth: '25px',
    borderRadius: '50%',
    display: 'inline-block',
    marginLeft: '5px',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${checkResultsCellContentClasses.entitySuffix}`]: {
    marginLeft: '5px',
  },

  [`& .${checkResultsCellContentClasses.limitText}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px',
  },

  [`& .${checkResultsCellContentClasses.resultBox}`]: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },

  [`& .${checkResultsCellContentClasses.sampleText}`]: {
    color: irisSpecialColors.attributeCaption,
    marginRight: theme.spacing(1),
  },

  [`& .${checkResultsCellContentClasses.disruptionIcon}`]: {
    color: theme.palette.error.main,
    marginRight: `${getPixels(1) + 3}px`,
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
  },

  [`& .${checkResultsCellContentClasses.center}`]: {
    marginLeft: `calc(25px + ${theme.spacing(2)})`,
  },

  [`& .${checkResultsCellContentClasses.seperator}`]: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
}));
