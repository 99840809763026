import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { dataTableClasses, DataTableRoot } from '../../components/data-table/data-table.styles';
import { PasswordInputComponent } from '../../components/form/password-input/password-input.component';
import { InfoboxComponent } from '../../components/infobox/infobox.component';
import {
  HeaderContainer,
  BodyStructure,
  ContainerOutside,
  HeaderStructure,
  PageStructure,
  ContainerInside,
} from '../../components/structure';
import {
  changePassword,
  changePasswordSuccessfulSelector,
  clearChangePasswordSuccessful,
  fetchProfileInfo,
  profileInfoSelector,
} from '../../store';
import { irisSpacing } from '../../theme';

interface FormData {
  password: string;
  passwordConfirmation: string;
  passwordsMismatchError: string;
  currentPassword: string;
}

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['login', 'form', 'common']);
  const methods = useForm<FormData>();
  const { handleSubmit, errors, getValues, register, reset } = methods;

  const profileInfo = useSelector(profileInfoSelector);
  const changePasswordSuccessful = useSelector(changePasswordSuccessfulSelector);

  useEffect(() => {
    dispatch(fetchProfileInfo());
    return () => {
      dispatch(clearChangePasswordSuccessful());
    };
  }, [dispatch]);

  const onSubmit = handleSubmit((formData: FormData) => {
    reset({
      password: undefined,
      passwordConfirmation: undefined,
      passwordsMismatchError: undefined,
      currentPassword: undefined,
    });
    dispatch(
      changePassword(formData.currentPassword, formData.password, formData.passwordConfirmation)
    );
  });

  const validatePasswordsMatch = () => {
    const formData = getValues();
    return formData.password === formData.passwordConfirmation;
  };

  return (
    <PageStructure>
      <DataTableRoot>
        {profileInfo && (
          <Box>
            <HeaderStructure>
              <HeaderContainer>
                <Typography variant="h1">
                  {t('login:welcomeUser', {
                    firstName: profileInfo.firstName,
                    lastName: profileInfo.lastName,
                  })}
                </Typography>
              </HeaderContainer>
            </HeaderStructure>
            <BodyStructure>
              <ContainerOutside>
                <Paper>
                  <ContainerInside>
                    <Typography variant="h3">{t('login:yourData')}</Typography>
                    <table className={dataTableClasses.dataTable}>
                      <tbody>
                        <tr>
                          <td>{t('data:user.username')}:</td>
                          <td>{profileInfo.username}</td>
                        </tr>
                        <tr>
                          <td>{t('data:user.firstAndLastName')}:</td>
                          <td>{`${profileInfo.firstName} ${profileInfo.lastName}`}</td>
                        </tr>
                        <tr>
                          <td>{t('data:user.workspace')}:</td>
                          <td>{profileInfo.workspace}</td>
                        </tr>
                        <tr>
                          <td>{t('data:user.role')}:</td>
                          <td>
                            {t([
                              `data:user.userRoles.${profileInfo.role}`,
                              `data:user.userRoles.notFound`,
                            ])}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ContainerInside>
                </Paper>
              </ContainerOutside>
              <ContainerOutside>
                <Typography variant="h2">{t('login:changePassword')}</Typography>
                <FormProvider {...methods}>
                  <form onSubmit={onSubmit}>
                    <Paper>
                      <ContainerInside>
                        <Grid container spacing={irisSpacing.input.space}>
                          <Grid item xs={12} md={6} lg={4}>
                            <Grid container spacing={irisSpacing.input.space}>
                              <Grid item xs={12}>
                                <PasswordInputComponent
                                  name="currentPassword"
                                  label={t('login:currentPassword')}
                                  testId="current-password-input"
                                  autocomplete="current-password"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <PasswordInputComponent
                                  name="password"
                                  label={t('login:newPassword')}
                                  testId="password-input"
                                  autocomplete="new-password"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <PasswordInputComponent
                                  name="passwordConfirmation"
                                  label={t('login:passwordConfirmation')}
                                  testId="password-confirmation-input"
                                  autocomplete="new-password"
                                />
                              </Grid>
                              {changePasswordSuccessful === false && (
                                <Grid item xs={12}>
                                  <InfoboxComponent
                                    type="error"
                                    headline={t('login:passwordCouldNotBeChanged')}
                                  />
                                </Grid>
                              )}
                              {changePasswordSuccessful === true && (
                                <Grid item xs={12}>
                                  <InfoboxComponent
                                    type="success"
                                    headline={t('login:passwordCouldBeChanged')}
                                  />
                                </Grid>
                              )}
                              <input
                                type="hidden"
                                name="passwordsMismatchError"
                                defaultValue={' '}
                                ref={register({
                                  validate: () =>
                                    validatePasswordsMatch() ||
                                    (t('login:passwordsMismatch') as string),
                                })}
                              />
                              {errors.passwordsMismatchError &&
                                errors.passwordsMismatchError.message && (
                                  <Grid item xs={12}>
                                    <InfoboxComponent
                                      type="error"
                                      headline={errors.passwordsMismatchError.message}
                                    />
                                  </Grid>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </ContainerInside>
                    </Paper>
                    <Box display="flex" justifyContent="flex-end" mt={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        data-testid="submit-btn"
                        type="submit"
                      >
                        {t('login:changePassword')}
                      </Button>
                    </Box>
                  </form>
                </FormProvider>
              </ContainerOutside>
            </BodyStructure>
          </Box>
        )}
      </DataTableRoot>
    </PageStructure>
  );
};
