import log from 'loglevel';
import { useState, useEffect } from 'react';

export function useImageLoader(file: File | undefined) {
  const [preview, setPreview] = useState<undefined | string>(undefined);

  useEffect(() => {
    let cancelled = false;

    if (file) {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          e.target && e.target.result && !cancelled && setPreview(e.target.result as string);
        };
        reader.readAsDataURL(file);
      } catch (e: any) {
        log.debug(e);
      }
    } else {
      !cancelled && setPreview(undefined);
    }

    return () => {
      cancelled = true;
    };
  }, [file]);

  return preview;
}
