import { styled } from '@mui/material/styles';

import { irisCustomColors } from '../../../theme';

const PREFIX = 'StyledList';
export const listClasses = {
  navLink: `${PREFIX}-navLink`,
  listItemIcon: `${PREFIX}-listItemIcon`,
  listItemText: `${PREFIX}-listItemText`,
  listItem: `${PREFIX}-listItem`,
  subMenu: `${PREFIX}-subMenu`,
};

export const ListRoot = styled('div')(({ theme }) => ({
  [`& .${listClasses.navLink}`]: {
    color: irisCustomColors.irisWhite,
    textDecoration: 'none',
    '&.active, &.active .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  [`& .${listClasses.listItemIcon}`]: {
    color: irisCustomColors.irisWhite,
    '&.MuiListItemIcon-root': {
      minWidth: '2.2rem',
    },
  },
  [`& .${listClasses.listItemText}`]: {
    '& .MuiTypography-colorTextPrimary': {
      color: irisCustomColors.irisWhite,
    },
    '& span': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  [`& .${listClasses.listItem}`]: {
    '&.MuiListItem-gutters': {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(3),
    },
  },
  [`& .${listClasses.subMenu}`]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(3),
  },
}));
