import { useMediaQuery } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import React from 'react';

import { irisSidebarWidth, responsiveness, sidebarBreakpoint } from '../../theme/theme';

const StyledPageStructure = styled('div', {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ theme, styleProps }) => ({
  margin: 'auto',
  padding: `${theme.spacing(responsiveness.page.padding.y)} ${theme.spacing(
    responsiveness.page.padding.x
  )}`,
  minWidth: responsiveness.page.width.min,
  maxWidth: responsiveness.page.width.max - (styleProps.smallScreen ? 0 : irisSidebarWidth),
}));

interface StylePropsWrapper {
  styleProps: {
    smallScreen: boolean;
  };
}
export interface OwnProps {
  children: React.ReactNode;
}

export const PageStructure = (props: OwnProps) => {
  const { children } = props;
  const smallScreen = !useMediaQuery((theme: Theme) => theme.breakpoints.up(sidebarBreakpoint));

  return <StyledPageStructure styleProps={{ smallScreen }}>{children}</StyledPageStructure>;
};
