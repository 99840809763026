import WarningIcon from '@mui/icons-material/Warning';
import { Box, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Disruption, DisruptionCode } from '../../../model';
import { irisCustomColors } from '../../../theme';
import { dateOptions, timeOptions, getDurationText } from '../../../utils/date-utils';

const PREFIX = 'CheckResultsTableDisruptionRowComponent';
const classes = {
  disruptionRow: `${PREFIX}-disruptionRow`,
  disruptionBox: `${PREFIX}-disruptionBox`,
  disruptionIcon: `${PREFIX}-disruptionIcon`,
  disruptionTextBold: `${PREFIX}-disruptionTextBold`,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${classes.disruptionRow}`]: {
    backgroundColor: irisCustomColors.irisRedLight,
    outlineStyle: 'solid',
    outlineWidth: 1,
    outlineOffset: -1,
    outlineColor: theme.palette.error.main,
  },

  [`& .${classes.disruptionBox}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.disruptionIcon}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.disruptionTextBold}`]: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export interface OwnProps {
  disruption: Disruption;
}

export const CheckResultsTableDisruptionRowComponent = (props: OwnProps) => {
  const { t, i18n } = useTranslation(['common', 'data']);
  const { disruption } = props;

  const getDisruptionHeader = (disruption: Disruption) => {
    return (
      <span className={classes.disruptionTextBold}>
        {disruption.disruptionCode === DisruptionCode.FertigPackVTU2Violation
          ? t('data:productionRun.tu2.violation')
          : t('data:disruption.disruption')}
      </span>
    );
  };

  const getDisruptionInfo = (disruption: Disruption) => {
    let info;

    if (disruption.disruptionCode === DisruptionCode.FertigPackVTU2Violation) {
      info = t('data:productionRun.tu2.resultBelowMinimum');
    } else if (disruption.started && disruption.resolved && disruption.duration) {
      const startDate = new Date(disruption.started);
      const endDate = new Date(disruption.resolved);
      info = t('data:disruption.disruptionDurationInfo', {
        dateStart: startDate.toLocaleString(i18n.language, dateOptions),
        timeStart: startDate.toLocaleString(i18n.language, timeOptions),
        dateEnd: endDate.toLocaleString(i18n.language, dateOptions),
        timeEnd: endDate.toLocaleString(i18n.language, timeOptions),
        duration: getDurationText(startDate, endDate, t('common:hours')),
      });
      if (disruption.reason) {
        info += ` | ${t('data:checkResult.reason')}: ${disruption.reason}`;
      }
    } else if (disruption.started) {
      const startDate = new Date(disruption.started);
      info = t('data:disruption.disruptionOpenInfo', {
        dateStart: startDate.toLocaleString(i18n.language, dateOptions),
        timeStart: startDate.toLocaleString(i18n.language, timeOptions),
      });
    }

    return info;
  };

  const getDisruptionRow = (disruption: Disruption) => {
    return (
      <StyledTableRow
        key={`disruption_row_${disruption.id}`}
        className={classes.disruptionRow}
        hover={false}
      >
        <TableCell colSpan={6}>
          <Box className={classes.disruptionBox}>
            <WarningIcon fontSize="large" className={classes.disruptionIcon} />
            {getDisruptionHeader(disruption)}
            {`${getDisruptionInfo(disruption)}`}
          </Box>
        </TableCell>
      </StyledTableRow>
    );
  };

  return getDisruptionRow(disruption);
};
