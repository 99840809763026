import { createSelector } from 'reselect';

import { AppState } from '..';

export const linesStateSelector = (state: AppState) => state.lines;
export const lineListSelector = createSelector(linesStateSelector, (state) => state.lines);
export const currentLineSelector = createSelector(linesStateSelector, (state) => state.currentLine);
export const linesTotalCountSelector = createSelector(linesStateSelector, (state) => state.total);
export const linesFetchingSelector = createSelector(
  linesStateSelector,
  (state) => !!state.fetchingAll
);
