import React from 'react';

import { Device, DeviceType } from '../../../model';
import { ScaleFormComponent } from '../scale/scale-form.component';
import { VirtualDeviceFormComponent } from '../virtual-device/virtual-device-form.component';

export interface DeviceFormProps {
  device?: Device;
  submit: (device: Omit<Device, 'id' | 'lastModified'>) => void;
  cancel: () => void;
  deviceType: DeviceType | undefined;
}

export const getDeviceFormComponent = (deviceFormProps: DeviceFormProps) => {
  switch (Number(deviceFormProps.deviceType)) {
    case DeviceType.scale:
      return <ScaleFormComponent {...deviceFormProps} />;
    case DeviceType.virtual:
      return <VirtualDeviceFormComponent {...deviceFormProps} />;
    default:
      return <></>;
  }
};
