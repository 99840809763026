import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { apiKeySelector, clearApiKey, createApiKey, fetchApiKey } from '../../store';
import { irisSpacing } from '../../theme';
import { ContainerInside } from '../structure';

const FlexColumn = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(3),
  gap: theme.spacing(3),
}));

const CopyContainer = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}));

export const ApiKeyComponent = () => {
  const { t } = useTranslation(['license']);
  const dispatch = useDispatch();
  const [showApiKey, setShowApiKey] = useState<boolean>(false);
  const apiKey = useSelector(apiKeySelector);

  useEffect(() => {
    dispatch(fetchApiKey());
    return () => {
      dispatch(clearApiKey());
    };
  }, [dispatch]);

  const handleCreateApiKey = () => {
    dispatch(createApiKey());
  };

  const handleCopyApiKey = () => {
    apiKey && navigator.clipboard.writeText(apiKey);
  };

  const handleClickShowApiKey = () => {
    setShowApiKey(!showApiKey);
  };

  const getCreateButton = () => {
    return (
      <Box>
        <Button
          variant="contained"
          color="primary"
          data-testid="createApiKeyBtn"
          onClick={handleCreateApiKey}
        >
          {t('license:createApiKey')}
        </Button>
      </Box>
    );
  };

  const getRenewButton = () => {
    return (
      <Box>
        <Button
          variant="contained"
          color="secondary"
          data-testid="renewApiKeyBtn"
          onClick={handleCreateApiKey}
        >
          {t('license:renewApiKey')}
        </Button>
      </Box>
    );
  };

  const getKeyPicker = () => {
    return (
      <Grid container spacing={irisSpacing.input.space}>
        <Grid item xs={12} md={8} lg={6} xl={4}>
          <TextField
            id="apiKey"
            type={showApiKey ? 'text' : 'password'}
            variant="outlined"
            fullWidth={true}
            value={apiKey}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    data-testid="visibilityToggle"
                    onClick={handleClickShowApiKey}
                    edge="end"
                  >
                    {showApiKey ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              'data-testid': 'apiKeyTextField',
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CopyContainer>
            <Button
              variant="contained"
              color="primary"
              data-testid="copyApiKeyBtn"
              onClick={handleCopyApiKey}
            >
              {t('license:copyApiKey')}
            </Button>
          </CopyContainer>
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper data-testid="apiKeyComponent">
      <ContainerInside>
        <Typography variant="h3">{t('license:apiKey')}</Typography>
        <Typography>{t('license:apiKeyDescription')}</Typography>
        <FlexColumn>
          {apiKey ? getKeyPicker() : getCreateButton()}
          {apiKey && getRenewButton()}
        </FlexColumn>
      </ContainerInside>
    </Paper>
  );
};
