import React from 'react';

import { CheckAttribute, CheckAttributeType } from '../../../../model';
import { BarcodeFormComponent } from '../check-attribute-form/barcode-form.component';
import { FertigPackVFormComponent } from '../check-attribute-form/fertigpackv-form.component';
import { FreeTextFormComponent } from '../check-attribute-form/free-text-form.component';
import { TemperatureFormComponent } from '../check-attribute-form/temperature-form.component';
import { TrafficLightFormComponent } from '../check-attribute-form/traffic-light-form.component';
import { VolumeFormComponent } from '../check-attribute-form/volume-form.component';
import { WeightFormComponent } from '../check-attribute-form/weight-form.component';
import { YesNoFormComponent } from '../check-attribute-form/yes-no-form.component';

import { CheckAttributeImages } from './check-attribute-select.component';

export interface CheckAttributeFormProps {
  checkAttributeType: CheckAttributeType | '';
  checkAttribute?: CheckAttribute;
  submit: (
    checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>,
    images?: CheckAttributeImages
  ) => void;
  cancel: () => void;
}

export const getCheckAttributeFormComponent = (
  checkAttributeFormProps: CheckAttributeFormProps
) => {
  switch (Number(checkAttributeFormProps.checkAttributeType)) {
    case CheckAttributeType.YesNo:
      return <YesNoFormComponent {...checkAttributeFormProps} />;
    case CheckAttributeType.TrafficLight:
      return <TrafficLightFormComponent {...checkAttributeFormProps} />;
    case CheckAttributeType.FertigPackV:
      return <FertigPackVFormComponent {...checkAttributeFormProps} />;
    case CheckAttributeType.Weight:
      return <WeightFormComponent {...checkAttributeFormProps} />;
    case CheckAttributeType.Temperature:
      return <TemperatureFormComponent {...checkAttributeFormProps} />;
    case CheckAttributeType.Volume:
      return <VolumeFormComponent {...checkAttributeFormProps} />;
    case CheckAttributeType.FreeText:
      return <FreeTextFormComponent {...checkAttributeFormProps} />;
    case CheckAttributeType.Barcode:
      return <BarcodeFormComponent {...checkAttributeFormProps} />;
    default:
      return <></>;
  }
};
