import { TFunction } from 'i18next';

import { CheckAttributeType } from '../../../model/check-attribute-type.model';

import { CheckAttribute } from './../../../model/check-attribute.model';

export const getCheckAttributeTypeInfo = (checkAttribute: CheckAttribute, t: TFunction) => {
  switch (checkAttribute.checkAttributeType) {
    case CheckAttributeType.YesNo:
      return [
        { text: t('data:checkAttribute.question'), value: checkAttribute.yesNoQuestion },
        {
          text: t('data:checkAttribute.yesNoSuccessAnswer'),
          value: checkAttribute.yesNoSuccessAnswer
            ? t('data:checkAttribute.yes')
            : t('data:checkAttribute.no'),
        },
      ];
    case CheckAttributeType.TrafficLight:
      return [
        {
          text: t('data:checkAttribute.description'),
          value: checkAttribute.trafficLightDescription,
        },
        {
          text: t('data:checkAttribute.trafficLightGreenDescription'),
          value: checkAttribute.trafficLightGreenDescription,
        },
        {
          text: t('data:checkAttribute.trafficLightYellowDescription'),
          value: checkAttribute.trafficLightYellowDescription,
        },
        {
          text: t('data:checkAttribute.trafficLightRedDescription'),
          value: checkAttribute.trafficLightRedDescription,
        },
      ];
    case CheckAttributeType.FertigPackV:
      return [
        {
          text: t('data:checkAttribute.fertigPackVDescription'),
          value: checkAttribute.fertigPackVDescription,
        },
      ];
    case CheckAttributeType.Weight:
      return [
        {
          text: t('data:checkAttribute.weightDescription'),
          value: checkAttribute.weightDescription,
        },
      ];
    case CheckAttributeType.Temperature:
      return [
        {
          text: t('data:checkAttribute.temperatureDescription'),
          value: checkAttribute.temperatureDescription,
        },
      ];
    case CheckAttributeType.Volume:
      return [
        {
          text: t('data:checkAttribute.volumeDescription'),
          value: checkAttribute.volumeDescription,
        },
      ];
    case CheckAttributeType.FreeText:
      return [
        {
          text: t('data:checkAttribute.freeTextDescription'),
          value: checkAttribute.freeTextDescription,
        },
      ];
    case CheckAttributeType.Barcode:
      return [
        {
          text: t('data:checkAttribute.barcodeDescription'),
          value: checkAttribute.barcodeDescription,
        },
      ];
  }
};
