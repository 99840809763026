import { InputAdornment, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

import useDebounce from '../../hooks/debounce.hook';

export interface OwnProps {
  debounce?: number;
  name: string;
  onInputChange: (value: string) => void;
  defaultValue: string;
  label: string;
  ariaLabel: string;
  required?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  suffixUnit?: string;
  hasError: boolean;
  errorMessage?: string;
  decimalScale?: number;
  value?: string;
}

interface NumericFormatCustomProps {
  inputRef: (instance: string | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  decimalScale?: number;
}

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props: NumericFormatCustomProps,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator=","
      allowedDecimalSeparators={['.', ',']}
      decimalScale={props.decimalScale ?? 3}
      valueIsNumericString
    />
  );
});

export const FormattedTextFieldComponent = (props: OwnProps) => {
  const {
    debounce,
    name,
    onInputChange,
    defaultValue,
    label,
    ariaLabel,
    required,
    disabled,
    fullWidth,
    suffixUnit,
    hasError,
    errorMessage,
    decimalScale,
    value,
  } = props;
  const [inputValue, setInputValue] = useState<string>(defaultValue);
  const inputName = name + '-input';
  const debouncedInput = useDebounce(inputValue, debounce || 0);

  useEffect(() => {
    onInputChange(debouncedInput);
  }, [debouncedInput, onInputChange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <TextField
      key={inputName}
      name={name}
      id={inputName}
      label={label}
      required={required}
      disabled={disabled}
      variant="outlined"
      fullWidth={fullWidth}
      defaultValue={defaultValue}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        endAdornment: <InputAdornment position="end">{suffixUnit}</InputAdornment>,
        'aria-label': ariaLabel,
        onChange: handleChange,
      }}
      inputProps={{
        'data-testid': inputName,
        decimalScale: decimalScale,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      error={hasError}
      helperText={errorMessage}
      value={value}
    />
  );
};
