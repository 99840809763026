import WarningIcon from '@mui/icons-material/Warning';
import { Box, Link, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { push } from 'connected-react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { CommandsMenuComponent } from '../../../components/commands-menu/commands-menu.component';
import { TablePaginationComponent } from '../../../components/table-pagination/table-pagination.component';
import { PaperTable } from '../../../components/table/paper-table.component';
import { AppRoutePath } from '../../../routes/routes';
import { userListSelector, usersTotalCountSelector, deleteUser } from '../../../store';

const PREFIX = 'UsersTableComponent';
const classes = {
  message: `${PREFIX}-message`,
  messageIcon: `${PREFIX}-messageIcon`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.message}`]: {
    display: 'flex',
    verticalAlign: 'middle',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },

  [`& .${classes.messageIcon}`]: {
    marginRight: theme.spacing(1),
  },
}));

export interface OwnProps {
  handlePageChange: (newPage: number) => void;
  page: number;
}

export const UsersTableComponent = (props: OwnProps) => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const { t } = useTranslation(['common', 'table', 'data']);
  const users = useSelector(userListSelector);
  const total = useSelector(usersTotalCountSelector);

  const goToEditUser = (id: string) => {
    dispatch(push(`/${AppRoutePath.users}/${id}/${AppRoutePath.edit}`));
  };

  const deleteUserById = (id: string) => {
    dispatch(deleteUser(id));
  };

  return (
    <Root>
      <PaperTable dataTestId="userTable" ariaLabel={t('data:user.table')}>
        <TableHead>
          <TableRow>
            <TableCell>{t('data:user.username')}</TableCell>
            <TableCell>{t('data:user.firstName')}</TableCell>
            <TableCell>{t('data:user.lastName')}</TableCell>
            <TableCell>{t('data:user.workspace')}</TableCell>
            <TableCell>{t('data:user.role')}</TableCell>
            <TableCell>{t('')}</TableCell>
            <TableCell>{t('')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users && users.length > 0 ? (
            users.map((user) => {
              return (
                <TableRow key={user.id}>
                  <TableCell>
                    <Link
                      component={NavLink}
                      to={`/${AppRoutePath.users}/${user.id}/${AppRoutePath.edit}`}
                      underline="hover"
                    >
                      {user.username}
                    </Link>
                  </TableCell>
                  <TableCell>{user.firstName}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell>{user.workspace}</TableCell>
                  <TableCell>
                    {t([`data:user.userRoles.${user.role}`, `data:user.userRoles.notFound`])}
                  </TableCell>
                  <TableCell>
                    {user.passwordResetRequested && (
                      <Box className={classes.message}>
                        <WarningIcon
                          htmlColor={theme.palette.error.main}
                          className={classes.messageIcon}
                        />
                        <Typography>{t(`data:user.passwordResetRequested`)}</Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    <CommandsMenuComponent
                      handleEdit={() => goToEditUser(user.id)}
                      handleDelete={() => deleteUserById(user.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={2}>
                {users ? t('table:noMatch') : t('common:noDataAvailable')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </PaperTable>
      <TablePaginationComponent
        currentItemsCount={users?.length || 0}
        count={total || 0}
        page={props.page}
        onChangePage={(event: unknown, newPage: number) => props.handlePageChange(newPage)}
      />
    </Root>
  );
};
