import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer } from 'react-virtualized';

import { PieChartComponent } from '../../../components/pie-chart/pie-chart.component';
import { ContainerInside } from '../../../components/structure';
import { ProductionRun } from '../../../model';
import { irisCustomColors } from '../../../theme';

const PREFIX = 'ProductionRunPieChartComponent';
const classes = {
  pieContainer: `${PREFIX}-pieContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.pieContainer}`]: {
    height: '370px',
    backgroundColor: irisCustomColors.irisWhite,
    width: '650px',
    minWidth: '650px',
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
  noCheckResultsText: string;
}

export const ProductionRunPieChartComponent = (props: OwnProps) => {
  const { productionRun, noCheckResultsText } = props;

  const { t } = useTranslation(['data']);

  return (
    <Root>
      {productionRun?.checkSamplesDone > 0 ? (
        <Paper>
          <ContainerInside>
            <Typography variant="h4">{t('data:productionRun.checkResultsOverview')}</Typography>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '150px',
              }}
            >
              <div className={classes.pieContainer}>
                <AutoSizer>
                  {({ height, width }) => (
                    <PieChartComponent
                      height={height}
                      width={width}
                      productionRun={productionRun}
                      interactive={true}
                    />
                  )}
                </AutoSizer>
              </div>
            </Box>
          </ContainerInside>
        </Paper>
      ) : (
        <Box>{noCheckResultsText}</Box>
      )}
    </Root>
  );
};
