import { HttpError } from '../../http/http-error';
import { License } from '../../model';

export enum LicenseActionType {
  licenseFetch = 'license/fetch',
  licenseFetchSuccess = 'license/fetch/success',
  licenseFetchFailure = 'license/fetch/failure',
  licenseAdd = 'license/add',
  licenseAddSuccess = 'license/add/success',
  licenseAddFailure = 'license/add/failure',
  licenseUploadStatesClear = 'license/uploadStates/clear',
}

export const fetchLicense = () => ({
  type: LicenseActionType.licenseFetch,
});

export const fetchLicenseSuccess = (license: License) => ({
  type: LicenseActionType.licenseFetchSuccess,
  payload: { license },
});

export const fetchLicenseFailure = () => ({
  type: LicenseActionType.licenseFetchFailure,
});

export const addLicense = (licenseFile: File) => ({
  type: LicenseActionType.licenseAdd,
  payload: { licenseFile },
});

export const addLicenseSuccess = (license: License) => ({
  type: LicenseActionType.licenseAddSuccess,
  payload: { license },
});

export const addLicenseFailure = (e: HttpError) => ({
  type: LicenseActionType.licenseAddFailure,
  payload: { e },
});

export const clearLicenseUploadStates = () => ({
  type: LicenseActionType.licenseUploadStatesClear,
});
