import { Typography } from '@mui/material';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AddButtonComponent } from '../../../components/buttons/add-button.component';
import { LoadingIndicatorComponent } from '../../../components/loading-indicator/loading-indicator.component';
import {
  HeaderContainer,
  BodyStructure,
  HeaderStructure,
  PageStructure,
  ContainerOutside,
} from '../../../components/structure';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../constants';
import { AppRoutePath } from '../../../routes/routes';
import { fetchUsers, userListSelector, usersFetchingSelector } from '../../../store';

import { UsersTableComponent } from './users-table.component';

export const UsersOverviewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data', 'common']);
  const [page, setPage] = useState<number>(0);
  const loading = useSelector(usersFetchingSelector);
  const users = useSelector(userListSelector);
  const hasUsers = !!users && users.length > 0;

  useEffect(() => {
    dispatch(fetchUsers({ pagination: { page, perPage: DEFAULT_ITEMS_PER_PAGE } }));
  }, [dispatch, page]);

  const goToNewUser = () => {
    dispatch(push(`/${AppRoutePath.users}/${AppRoutePath.new}/`));
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <PageStructure>
      <HeaderStructure>
        <HeaderContainer>
          <Typography variant="h1">{t('data:user.userAdministration')}</Typography>
          <AddButtonComponent text={t('data:user.new')} onClick={goToNewUser} />
        </HeaderContainer>
      </HeaderStructure>
      <BodyStructure>
        <ContainerOutside>
          {loading && !hasUsers ? (
            <LoadingIndicatorComponent entity={t('data:user.users')} />
          ) : (
            <UsersTableComponent handlePageChange={handlePageChange} page={page} />
          )}
        </ContainerOutside>
      </BodyStructure>
    </PageStructure>
  );
};
