import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { formClasses, FormRoot } from '../../../components/components-styles/form.styles';
import { ImageInputWithPreviewComponent } from '../../../components/form/image-input-with-preview/image-input-with-preview.component';
import { FormattedTextFieldComponent } from '../../../components/formatted-text-field/formatted-text-field.component';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../components/structure';
import { ToleranceBarComponent } from '../../../components/tolerance-bar/tolerance-bar.component';
import { DENSITY_ENTITY, VOLUME_ENTITY, WEIGHT_ENTITY } from '../../../constants';
import { Article, Line, TareMode } from '../../../model';
import {
  calcArticleFertigPackTolerances,
  articleFertigPackTolerancesSelector,
  changeArticleFertigPackInputValues,
  clearArticleFertigPackTolerances,
} from '../../../store';
import { irisSpacing } from '../../../theme';

import { articleFormClasses, ArticleFormRoot } from './article-form.component.styles';

export interface OwnProps {
  article?: Omit<Article, 'id' | 'lastModified'>;
  submit: (article: Omit<Article, 'id' | 'lastModified'>, image?: File) => void;
  cancel: () => void;
  lines: Line[];
}

type FormData = {
  articleName: string;
  articleNameSuffix: string;
  articleNumber: string;
  productionLine: Line | undefined;
  checkPeriodicity: number;
  nominalValue: number | string;
  nominalValueVolume: number | string;
  tareMode: TareMode;
  tareValue: number | string;
  tareSampleSize: number | string;
  measurementType: MeasurementOption;
  volumeValue: number | string;
  densityValue: number | string;
  lowerTolerance: number | string;
  upperTolerance: number | string;
  lowerToleranceVolume: number | string;
  upperToleranceVolume: number | string;
  singleTareMaxValue: number | string;
  singleTareMinValue: number | string;
  individualTareMaxValue: number | string;
  individualTareMinValue: number | string;
};

enum MeasurementOption {
  Weight = '1',
  Volume = '2',
}

const getFormValues = (article?: Omit<Article, 'id' | 'lastModified'>): FormData => ({
  articleName: article?.name || '',
  articleNameSuffix: article?.nameSuffix || '',
  articleNumber: article?.articleNumber || '',
  productionLine: article?.productionLine || undefined,
  checkPeriodicity: article?.checkPeriodicity || 60,
  nominalValue:
    !article?.volumeValue && article?.nominalValue !== undefined ? article.nominalValue : '',
  nominalValueVolume:
    article?.volumeValue && article?.nominalValue !== undefined ? article.nominalValue : '',
  tareMode: article?.tareMode ? article.tareMode : TareMode.Preset,
  tareValue:
    article?.tareValue !== undefined && article?.tareMode === TareMode.Preset
      ? article.tareValue
      : '',
  tareSampleSize:
    article?.tareSampleSize !== undefined && article?.tareMode === TareMode.Single
      ? article.tareSampleSize
      : '1',
  measurementType: article?.volumeValue ? MeasurementOption.Volume : MeasurementOption.Weight,
  volumeValue: article?.volumeValue !== undefined ? article.volumeValue : '',
  densityValue: article?.densityValue !== undefined ? article.densityValue : '',
  lowerTolerance:
    !article?.volumeValue && article?.lowerTolerance !== undefined ? article.lowerTolerance : '',
  upperTolerance:
    !article?.volumeValue && article?.upperTolerance !== undefined ? article.upperTolerance : '',
  lowerToleranceVolume:
    article?.volumeValue && article?.lowerTolerance !== undefined ? article.lowerTolerance : '',
  upperToleranceVolume:
    article?.volumeValue && article?.upperTolerance !== undefined ? article.upperTolerance : '',
  singleTareMaxValue:
    article?.tareMaxValue !== undefined && article?.tareMode === TareMode.Single
      ? article.tareMaxValue
      : '',
  singleTareMinValue:
    article?.tareMinValue !== undefined && article?.tareMode === TareMode.Single
      ? article.tareMinValue
      : '',
  individualTareMaxValue:
    article?.tareMaxValue !== undefined && article?.tareMode === TareMode.OrderedIndividual
      ? article.tareMaxValue
      : '',
  individualTareMinValue:
    article?.tareMinValue !== undefined && article?.tareMode === TareMode.OrderedIndividual
      ? article.tareMinValue
      : '',
});

export const ArticleFormComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { article, lines } = props;
  const { t } = useTranslation(['common', 'form', 'data']);
  const methods = useForm<FormData>({
    defaultValues: getFormValues(article),
  });
  const { handleSubmit, errors, control, reset, register, setValue, watch, getValues } = methods;
  const [imageUrl, setImageUrl] = useState<string | undefined>(article?.imageUrl);
  const [chosenImageFile, setChosenImageFile] = useState<File | undefined>();
  const currentTolerances = useSelector(articleFertigPackTolerancesSelector);
  const currentWeightTolerances = currentTolerances?.weight;
  const currentVolumeTolerances = currentTolerances?.volume;
  const [nominalValueInput, setNominalValueInput] = useState<string | undefined>(
    !article?.volumeValue ? article?.nominalValue?.toString() || '' : ''
  );
  const [nominalValueVolumeInput, setNominalValueVolumeInput] = useState<string | undefined>(
    article?.volumeValue ? article?.nominalValue?.toString() || '' : ''
  );
  const [tareValueInput, setTareValueInput] = useState<string | undefined>(
    article?.tareValue?.toString() || ''
  );
  const articleTareMode = article?.tareMode;
  const [tareModeSwitched, setTareModeSwitched] = useState<boolean>(false);
  const [volumeValueInput, setVolumeValueInput] = useState<string | undefined>(
    article?.volumeValue?.toString() || ''
  );
  const [densityValueInput, setDensityValueInput] = useState<string | undefined>(
    article?.densityValue?.toString() || ''
  );
  const [lowerToleranceInput, setLowerToleranceInput] = useState<string | undefined>(
    !article?.volumeValue ? article?.lowerTolerance?.toString() || '' : ''
  );
  const [lowerToleranceVolumeInput, setLowerToleranceVolumeInput] = useState<string | undefined>(
    article?.volumeValue ? article?.lowerTolerance?.toString() || '' : ''
  );
  const [upperToleranceInput, setUpperToleranceInput] = useState<string | undefined>(
    !article?.volumeValue ? article?.upperTolerance?.toString() || '' : ''
  );
  const [upperToleranceVolumeInput, setUpperToleranceVolumeInput] = useState<string | undefined>(
    article?.volumeValue ? article?.upperTolerance?.toString() || '' : ''
  );
  const [singleTareMaxValueInput, setSingleTareMaxValueInput] = useState<string | undefined>(
    article?.tareMode === TareMode.Single && article?.tareMaxValue
      ? article?.tareMaxValue?.toString() || ''
      : ''
  );
  const [singleTareMinValueInput, setSingleTareMinValueInput] = useState<string | undefined>(
    article?.tareMode === TareMode.Single && article?.tareMinValue
      ? article?.tareMinValue?.toString() || ''
      : ''
  );
  const [individualTareMaxValueInput, setIndividualTareMaxValueInput] = useState<
    string | undefined
  >(
    article?.tareMode === TareMode.OrderedIndividual && article?.tareMaxValue
      ? article?.tareMaxValue?.toString() || ''
      : ''
  );
  const [individualTareMinValueInput, setIndividualTareMinValueInput] = useState<
    string | undefined
  >(
    article?.tareMode === TareMode.OrderedIndividual && article?.tareMinValue
      ? article?.tareMinValue?.toString() || ''
      : ''
  );
  const [measurementOptionChanged, setMeasurementOptionChanged] = useState<boolean>(false);
  const [newNominalDefaultValue, setNewNominalDefaultValue] = useState<number | string>('');
  const [newNominalDefaultValueVolume, setNewNominalDefaultValueVolume] = useState<number | string>(
    ''
  );
  const [resetCountNominalValue, setResetCountNominalValue] = useState<number>(0);
  const [resetCountVolume, setResetCountVolume] = useState<number>(0);
  const [resetCountTolerances, setResetCountTolerances] = useState<number>(0);

  const {
    nominalValue: currentNominalValue,
    measurementType: measurementTypeWatched,
    volumeValue,
    densityValue,
    tareMode: tareModeWatched,
    singleTareMinValue: singleTareMinValueWatched,
    individualTareMinValue: individualTareMinValueWatched,
  } = watch();
  const tareMode = tareModeWatched || article?.tareMode || TareMode.Preset;
  const measurementType =
    measurementTypeWatched ||
    (article?.volumeValue && MeasurementOption.Volume) ||
    MeasurementOption.Weight;

  useEffect(() => {
    // wait for data to be loaded and reset default values
    article && reset(getFormValues(article));
  }, [article, lines, reset]);

  useEffect(() => {
    // Autocomplete needs custom register
    register({
      name: 'productionLine',
    });
    // FormattedTextField for nominalValue needs custom register
    register(
      {
        name: 'nominalValue',
      },
      {
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: { value: 5.0, message: t('form:minValue', { min: 5.0 }) },
        max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
      }
    );
    // FormattedTextField for nominalValueVolume needs custom register
    register(
      {
        name: 'nominalValueVolume',
      },
      {
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: { value: 5.0, message: t('form:minValue', { min: 5.0 }) },
        max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
      }
    );
    // FormattedTextField for tareValue needs custom register
    register(
      {
        name: 'tareValue',
      },
      {
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: { value: 0.0, message: t('form:minValue', { min: 0.0 }) },
        max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
      }
    );
  }, [register, t]);

  useEffect(() => {
    // FormattedTextField for lowerTolerance needs custom register
    register(
      {
        name: 'lowerTolerance',
      },
      {
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: {
          value: currentWeightTolerances?.tU1 || 0,
          message: t('form:minValue', {
            min: currentWeightTolerances?.tU1 || 0,
          }),
        },
        max: {
          value: Number(currentNominalValue) - 0.001,
          message: t('form:maxValue', { max: Number(currentNominalValue) - 0.001 }),
        },
      }
    );
    // FormattedTextField for lowerToleranceVolume needs custom register
    register(
      {
        name: 'lowerToleranceVolume',
      },
      {
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: {
          value: currentVolumeTolerances?.tU1 || 0,
          message: t('form:minValue', {
            min: currentVolumeTolerances?.tU1 || 0,
          }),
        },
        max: {
          value: Number(volumeValue) - 0.001,
          message: t('form:maxValue', { max: Number(volumeValue) - 0.001 }),
        },
      }
    );
    // FormattedTextField for upperTolerance needs custom register
    register(
      {
        name: 'upperTolerance',
      },
      {
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: {
          value: Number(currentNominalValue) + 0.001,
          message: t('form:minValue', { min: Number(currentNominalValue) + 0.001 }),
        },
        max: {
          value: currentWeightTolerances?.tO1 || 0,
          message: t('form:maxValue', {
            max: currentWeightTolerances?.tO1 || 0,
          }),
        },
      }
    );
    // FormattedTextField for upperToleranceVolume needs custom register
    register(
      {
        name: 'upperToleranceVolume',
      },
      {
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: {
          value: Number(volumeValue) + 0.001,
          message: t('form:minValue', { min: Number(volumeValue) + 0.001 }),
        },
        max: {
          value: currentVolumeTolerances?.tO1 || 0,
          message: t('form:maxValue', {
            max: currentVolumeTolerances?.tO1 || 0,
          }),
        },
      }
    );
    // FormattedTextField for singleTareMinValue needs custom register
    register(
      {
        name: 'singleTareMinValue',
      },
      {
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: { value: 0.0, message: t('form:minValue', { min: 0.0 }) },
        max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
      }
    );
    // FormattedTextField for singleTareMaxValue needs custom register
    register(
      {
        name: 'singleTareMaxValue',
      },
      {
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: {
          value:
            singleTareMinValueWatched || singleTareMinValueWatched === 0
              ? Number(singleTareMinValueWatched) + 0.001
              : 0.0,
          message: t('form:minValue', {
            min:
              singleTareMinValueWatched === 0
                ? 0.001
                : singleTareMinValueWatched
                ? (Number(singleTareMinValueWatched) + 0.001).toFixed(3)
                : 0.0,
          }),
        },
        max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
      }
    );
    // FormattedTextField for individualTareMinValue needs custom register
    register(
      {
        name: 'individualTareMinValue',
      },
      {
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: { value: 0.0, message: t('form:minValue', { min: 0.0 }) },
        max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
      }
    );
    // FormattedTextField for individualTareMaxValue needs custom register
    register(
      {
        name: 'individualTareMaxValue',
      },
      {
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: {
          value:
            individualTareMinValueWatched || individualTareMinValueWatched === 0
              ? Number(individualTareMinValueWatched) + 0.001
              : 0.0,
          message: t('form:minValue', {
            min:
              individualTareMinValueWatched === 0
                ? 0.001
                : individualTareMinValueWatched
                ? (Number(individualTareMinValueWatched) + 0.001).toFixed(3)
                : 0.0,
          }),
        },
        max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
      }
    );
  }, [
    register,
    t,
    currentVolumeTolerances,
    currentWeightTolerances,
    currentNominalValue,
    individualTareMinValueWatched,
    singleTareMinValueWatched,
    volumeValue,
  ]);

  useEffect(() => {
    // FormattedTextField for volumeValue needs custom register
    register(
      {
        name: 'volumeValue',
      },
      {
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        required: {
          value: densityValue && densityValue !== '' ? true : false,
          message: t('form:fieldIsRequired'),
        },
        min: { value: 5.0, message: t('form:minValue', { min: 5.0 }) },
        max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
      }
    );
  }, [register, t, densityValue]);

  useEffect(() => {
    // FormattedTextField for densityValue needs custom register
    register(
      {
        name: 'densityValue',
      },
      {
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        required: {
          value: volumeValue && volumeValue !== '' ? true : false,
          message: t('form:fieldIsRequired'),
        },
        min: { value: 0.001, message: t('form:minValue', { min: 0.001 }) },
        max: { value: 25.0, message: t('form:maxValue', { max: 25.0 }) },
      }
    );
  }, [register, t, volumeValue]);

  useEffect(() => {
    if (
      nominalValueVolumeInput &&
      densityValueInput &&
      measurementType === MeasurementOption.Volume
    ) {
      dispatch(
        calcArticleFertigPackTolerances(Number(nominalValueVolumeInput), Number(densityValueInput))
      );
    } else if (nominalValueInput && measurementType === MeasurementOption.Weight) {
      dispatch(calcArticleFertigPackTolerances(Number(nominalValueInput)));
    }
  }, [
    dispatch,
    article,
    nominalValueInput,
    nominalValueVolumeInput,
    volumeValueInput,
    densityValueInput,
    measurementType,
  ]);

  useEffect(() => {
    if (article) {
      let hasFertigPackInputValues;
      switch (+tareMode) {
        case TareMode.Preset:
          hasFertigPackInputValues =
            (measurementType === MeasurementOption.Weight &&
              !!nominalValueInput &&
              !!tareValueInput) ||
            (measurementType === MeasurementOption.Volume &&
              !!nominalValueVolumeInput &&
              !!tareValueInput);
          break;
        case TareMode.Single:
        case TareMode.OrderedIndividual:
          hasFertigPackInputValues =
            (measurementType === MeasurementOption.Weight && !!nominalValueInput) ||
            (measurementType === MeasurementOption.Volume && !!nominalValueVolumeInput);
          break;
        default:
          hasFertigPackInputValues = false;
          break;
      }
      dispatch(changeArticleFertigPackInputValues(hasFertigPackInputValues));
      return () => {
        dispatch(changeArticleFertigPackInputValues(false));
      };
    }
  }, [
    dispatch,
    article,
    nominalValueInput,
    tareValueInput,
    nominalValueVolumeInput,
    measurementType,
    tareMode,
  ]);

  useEffect(() => {
    if (tareModeSwitched) {
      setValue('tareValue', '');
      setValue('tareSampleSize', '1');
    }
  }, [tareModeSwitched, tareMode, setValue]);

  useEffect(() => {
    if (!tareModeSwitched && tareMode !== undefined && +tareMode !== articleTareMode) {
      setTareModeSwitched(true);
    }
  }, [tareModeSwitched, tareMode, articleTareMode, setTareModeSwitched]);

  const removeImage = () => {
    setImageUrl(undefined);
    setChosenImageFile(undefined);
  };

  const onSubmit = handleSubmit((formData: FormData) => {
    props.submit(
      {
        name: formData.articleName,
        nameSuffix: formData.articleNameSuffix,
        articleNumber: formData.articleNumber,
        productionLine: formData.productionLine || undefined,
        nominalValue:
          measurementType === MeasurementOption.Weight &&
          nominalValueInput &&
          nominalValueInput !== ''
            ? +nominalValueInput
            : measurementType === MeasurementOption.Volume &&
              nominalValueVolumeInput &&
              nominalValueVolumeInput !== ''
            ? +nominalValueVolumeInput
            : undefined,
        tareMode: Number(formData.tareMode),
        tareValue:
          Number(formData.tareMode) === TareMode.Preset && tareValueInput && tareValueInput !== ''
            ? +tareValueInput
            : undefined,
        tareSampleSize:
          Number(formData.tareMode) === TareMode.Single
            ? Number(formData.tareSampleSize)
            : undefined,
        tareMinValue:
          Number(formData.tareMode) === TareMode.Single &&
          singleTareMinValueInput &&
          singleTareMinValueInput !== ''
            ? +singleTareMinValueInput
            : Number(formData.tareMode) === TareMode.OrderedIndividual &&
              individualTareMinValueInput &&
              individualTareMinValueInput !== ''
            ? +individualTareMinValueInput
            : undefined,
        tareMaxValue:
          Number(formData.tareMode) === TareMode.Single &&
          singleTareMaxValueInput &&
          singleTareMaxValueInput !== ''
            ? +singleTareMaxValueInput
            : Number(formData.tareMode) === TareMode.OrderedIndividual &&
              individualTareMaxValueInput &&
              individualTareMaxValueInput !== ''
            ? +individualTareMaxValueInput
            : undefined,
        volumeValue: volumeValueInput && volumeValueInput !== '' ? +volumeValueInput : undefined,
        densityValue:
          densityValueInput && densityValueInput !== '' ? +densityValueInput : undefined,
        lowerTolerance:
          measurementType === MeasurementOption.Weight &&
          formData.lowerTolerance &&
          formData.lowerTolerance !== ''
            ? +formData.lowerTolerance
            : measurementType === MeasurementOption.Volume &&
              formData.lowerToleranceVolume &&
              formData.lowerToleranceVolume !== ''
            ? +formData.lowerToleranceVolume
            : undefined,
        upperTolerance:
          measurementType === MeasurementOption.Weight &&
          formData.upperTolerance &&
          formData.upperTolerance !== ''
            ? +formData.upperTolerance
            : measurementType === MeasurementOption.Volume &&
              formData.upperToleranceVolume &&
              formData.upperToleranceVolume !== ''
            ? +formData.upperToleranceVolume
            : undefined,
        checkPeriodicity: +formData.checkPeriodicity,
        imageUrl: imageUrl || undefined,
      },
      chosenImageFile
    );
  });

  const getCurrentValues = (): FormData => ({
    articleName: getValues('articleName'),
    articleNameSuffix: getValues('articleNameSuffix'),
    articleNumber: getValues('articleNumber'),
    productionLine: getValues('productionLine'),
    checkPeriodicity: getValues('checkPeriodicity'),
    nominalValue: getValues('nominalValue'),
    tareMode: getValues('tareMode'),
    nominalValueVolume: getValues('nominalValueVolume'),
    tareValue: getValues('tareValue'),
    tareSampleSize: getValues('tareSampleSize'),
    measurementType: getValues('measurementType'),
    volumeValue: getValues('volumeValue'),
    densityValue: getValues('densityValue'),
    lowerTolerance: getValues('lowerTolerance'),
    upperTolerance: getValues('upperTolerance'),
    lowerToleranceVolume: getValues('lowerToleranceVolume'),
    upperToleranceVolume: getValues('upperToleranceVolume'),
    singleTareMinValue: getValues('singleTareMinValue'),
    singleTareMaxValue: getValues('singleTareMaxValue'),
    individualTareMinValue: getValues('individualTareMinValue'),
    individualTareMaxValue: getValues('individualTareMaxValue'),
  });

  const clearTolerance = () => {
    dispatch(clearArticleFertigPackTolerances());
  };

  const resetTolerances = () => {
    clearTolerance();
    setResetCountTolerances(resetCountTolerances + 1);
    reset({
      ...getCurrentValues(),
      lowerTolerance: '',
      upperTolerance: '',
      lowerToleranceVolume: '',
      upperToleranceVolume: '',
    });
  };

  const setLowerTolerance = (value: string) => {
    setLowerToleranceInput(value);
    setValue('lowerTolerance', value);
  };

  const setUpperTolerance = (value: string) => {
    setUpperToleranceInput(value);
    setValue('upperTolerance', value);
  };

  const setUpperToleranceVolume = (value: string) => {
    setUpperToleranceVolumeInput(value);
    setValue('upperToleranceVolume', value);
  };

  const setLowerToleranceVolume = (value: string) => {
    setLowerToleranceVolumeInput(value);
    setValue('lowerToleranceVolume', value);
  };

  const setSingleTareMaxValue = (value: string) => {
    setSingleTareMaxValueInput(value);
    setValue('singleTareMaxValue', value);
  };

  const setSingleTareMinValue = (value: string) => {
    setSingleTareMinValueInput(value);
    setValue('singleTareMinValue', value);
  };

  const setIndividualTareMaxValue = (value: string) => {
    setIndividualTareMaxValueInput(value);
    setValue('individualTareMaxValue', value);
  };

  const setIndividualTareMinValue = (value: string) => {
    setIndividualTareMinValueInput(value);
    setValue('individualTareMinValue', value);
  };

  const handleLineChange = (e: React.ChangeEvent<{}>, value: Line | null) => {
    setValue('productionLine', value || undefined);
  };

  const nominalValueInputChanged = (value: string) => {
    if (nominalValueInput !== value) {
      setNominalValueInput(value);
      setValue('nominalValue', value);
      resetTolerances();
    }
  };

  const nominalValueVolumeInputChanged = (value: string) => {
    if (nominalValueVolumeInput !== value) {
      setNominalValueVolumeInput(value);
      setValue('nominalValueVolume', value);
      resetTolerances();
    }
  };

  const tareValueInputChanged = (value: string) => {
    if (tareValueInput !== value) {
      setTareValueInput(value);
      setValue('tareValue', value);
    }
  };

  const volumeInputChanged = (value: string) => {
    if (volumeValueInput !== value) {
      setVolumeValueInput(value);
      setValue('volumeValue', value);
    }
  };

  const densityInputChanged = (value: string) => {
    if (densityValueInput !== value) {
      setDensityValueInput(value);
      setValue('densityValue', value);
    }
  };

  const lowerToleranceInputChanged = (value: string) => {
    if (lowerToleranceInput !== value) {
      setLowerTolerance(value);
    }
  };

  const upperToleranceInputChanged = (value: string) => {
    if (upperToleranceInput !== value) {
      setUpperTolerance(value);
    }
  };

  const lowerToleranceVolumeInputChanged = (value: string) => {
    if (lowerToleranceVolumeInput !== value) {
      setLowerToleranceVolume(value);
    }
  };

  const upperToleranceVolumeInputChanged = (value: string) => {
    if (upperToleranceVolumeInput !== value) {
      setUpperToleranceVolume(value);
    }
  };

  const singleTareMinValueChanged = (value: string) => {
    if (singleTareMinValueInput !== value) {
      setSingleTareMinValue(value);
    }
  };

  const singleTareMaxValueChanged = (value: string) => {
    if (singleTareMaxValueInput !== value) {
      setSingleTareMaxValue(value);
    }
  };

  const individualTareMinValueChanged = (value: string) => {
    if (individualTareMinValueInput !== value) {
      setIndividualTareMinValue(value);
    }
  };

  const individualTareMaxValueChanged = (value: string) => {
    if (individualTareMaxValueInput !== value) {
      setIndividualTareMaxValue(value);
    }
  };

  useEffect(() => {
    if (measurementOptionChanged) {
      setMeasurementOptionChanged(false);
      setNewNominalDefaultValueVolume('');
      setNewNominalDefaultValue('');
      setResetCountNominalValue((r) => r + 1);
      return;
    }

    const hasVolume = volumeValue && volumeValue !== '';
    const hasDensity = densityValue && densityValue !== '';

    if (hasVolume && hasDensity) {
      const newVolume = hasVolume ? (volumeValue as number) : 0;
      const newDensity = hasDensity ? (densityValue as number) : 0;
      setNewNominalDefaultValueVolume(Math.round(newVolume * newDensity * 1000) / 1000);
      setResetCountNominalValue((r) => r + 1);
    } else if (hasVolume || hasDensity) {
      setNewNominalDefaultValueVolume('');
      setResetCountNominalValue((r) => r + 1);
    }
  }, [volumeValue, densityValue, measurementOptionChanged]);

  const lowerTolerance =
    measurementType === MeasurementOption.Weight && lowerToleranceInput
      ? Number(lowerToleranceInput)
      : measurementType === MeasurementOption.Volume && lowerToleranceVolumeInput
      ? Number(lowerToleranceVolumeInput)
      : undefined;
  const upperTolerance =
    measurementType === MeasurementOption.Weight && upperToleranceInput
      ? Number(upperToleranceInput)
      : measurementType === MeasurementOption.Volume && upperToleranceVolumeInput
      ? Number(upperToleranceVolumeInput)
      : undefined;

  const handleMeasurementTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMeasurementOptionChanged(true);
    setResetCountVolume((r) => r + 1);
    setResetCountTolerances((r) => r + 1);
    clearTolerance();
  };

  const nominalValueComponent = () => {
    return (
      <Grid item className={articleFormClasses.inputItemExtraShort}>
        <FormattedTextFieldComponent
          debounce={300}
          key={'nominalValueComponent_' + resetCountNominalValue}
          name={'nominalValue'}
          onInputChange={nominalValueInputChanged}
          defaultValue={
            resetCountNominalValue === 0 && measurementType === MeasurementOption.Weight
              ? article?.nominalValue?.toString() || ''
              : newNominalDefaultValue.toString()
          }
          label={t('data:article.nominalValue')}
          ariaLabel={t('data:article.nominalValue')}
          fullWidth={true}
          suffixUnit={WEIGHT_ENTITY}
          hasError={errors.nominalValue !== undefined}
          errorMessage={errors.nominalValue && errors.nominalValue.message}
        />
      </Grid>
    );
  };

  const lowerToleranceComponent = () => {
    return (
      <Grid item className={articleFormClasses.inputItemExtraShort}>
        <FormattedTextFieldComponent
          key={'lowerToleranceComponent_' + resetCountTolerances}
          name={'lowerTolerance'}
          onInputChange={lowerToleranceInputChanged}
          defaultValue={
            resetCountTolerances === 0 && measurementType === MeasurementOption.Weight
              ? article?.lowerTolerance?.toString() || ''
              : ''
          }
          label={t('data:article.lowerToleranceCustom')}
          ariaLabel={t('data:article.lowerToleranceCustom')}
          fullWidth={true}
          suffixUnit={WEIGHT_ENTITY}
          disabled={!currentNominalValue || currentNominalValue === '' || nominalValueInput === ''}
          hasError={errors.lowerTolerance !== undefined}
          errorMessage={errors.lowerTolerance && errors.lowerTolerance.message}
        />
      </Grid>
    );
  };

  const upperToleranceComponent = () => {
    return (
      <Grid item className={articleFormClasses.inputItemExtraShort}>
        <FormattedTextFieldComponent
          key={'upperToleranceComponent_' + resetCountTolerances}
          name={'upperTolerance'}
          onInputChange={upperToleranceInputChanged}
          defaultValue={
            resetCountTolerances === 0 && measurementType === MeasurementOption.Weight
              ? article?.upperTolerance?.toString() || ''
              : ''
          }
          label={t('data:article.upperToleranceCustom')}
          ariaLabel={t('data:article.upperToleranceCustom')}
          fullWidth={true}
          suffixUnit={WEIGHT_ENTITY}
          disabled={!currentNominalValue || currentNominalValue === '' || nominalValueInput === ''}
          hasError={errors.upperTolerance !== undefined}
          errorMessage={errors.upperTolerance && errors.upperTolerance.message}
        />
      </Grid>
    );
  };

  const nominalValueVolumeComponent = () => {
    return (
      <Grid item className={articleFormClasses.inputItemExtraShort}>
        <FormattedTextFieldComponent
          debounce={300}
          key={'nominalValueComponent_Volume_' + resetCountNominalValue}
          name={'nominalValueVolume'}
          onInputChange={nominalValueVolumeInputChanged}
          defaultValue={
            resetCountNominalValue === 0 && measurementType === MeasurementOption.Volume
              ? article?.nominalValue?.toString() || ''
              : newNominalDefaultValueVolume.toString()
          }
          label={t('data:article.nominalValue')}
          ariaLabel={t('data:article.nominalValue')}
          fullWidth={true}
          suffixUnit={WEIGHT_ENTITY}
          disabled={true}
          hasError={errors.nominalValueVolume !== undefined}
          errorMessage={errors.nominalValueVolume && errors.nominalValueVolume.message}
        />
      </Grid>
    );
  };

  const volumeValueComponent = () => {
    return (
      <Grid item className={articleFormClasses.inputItemExtraShort}>
        <FormattedTextFieldComponent
          debounce={300}
          key={'volumeValueInput_' + resetCountVolume}
          name={'volumeValue'}
          onInputChange={volumeInputChanged}
          defaultValue={resetCountVolume === 0 ? article?.volumeValue?.toString() || '' : ''}
          label={t('data:article.volume')}
          ariaLabel={t('data:article.volume')}
          fullWidth={true}
          suffixUnit={VOLUME_ENTITY}
          hasError={errors.volumeValue !== undefined}
          errorMessage={errors.volumeValue && errors.volumeValue.message}
        />
      </Grid>
    );
  };

  const densityValueComponent = () => {
    return (
      <Grid item className={articleFormClasses.inputItemExtraShort}>
        <FormattedTextFieldComponent
          debounce={300}
          key={'densityValueInput_' + resetCountVolume}
          name={'densityValue'}
          onInputChange={densityInputChanged}
          defaultValue={resetCountVolume === 0 ? article?.densityValue?.toString() || '' : ''}
          label={t('data:article.density')}
          ariaLabel={t('data:article.density')}
          fullWidth={true}
          suffixUnit={DENSITY_ENTITY}
          hasError={errors.densityValue !== undefined}
          errorMessage={errors.densityValue && errors.densityValue.message}
        />
      </Grid>
    );
  };

  const lowerToleranceVolumeComponent = () => {
    return (
      <Grid item className={articleFormClasses.inputItemExtraShort}>
        <FormattedTextFieldComponent
          key={'lowerToleranceComponent_Volume_' + resetCountTolerances}
          name={'lowerToleranceVolume'}
          onInputChange={lowerToleranceVolumeInputChanged}
          defaultValue={
            resetCountTolerances === 0 && measurementType === MeasurementOption.Volume
              ? article?.lowerTolerance?.toString() || ''
              : ''
          }
          label={t('data:article.lowerToleranceCustom')}
          ariaLabel={t('data:article.lowerToleranceCustom')}
          fullWidth={true}
          suffixUnit={VOLUME_ENTITY}
          disabled={!newNominalDefaultValueVolume || newNominalDefaultValueVolume === ''}
          hasError={errors.lowerToleranceVolume !== undefined}
          errorMessage={errors.lowerToleranceVolume && errors.lowerToleranceVolume.message}
        />
      </Grid>
    );
  };

  const upperToleranceVolumeComponent = () => {
    return (
      <Grid item className={articleFormClasses.inputItemExtraShort}>
        <FormattedTextFieldComponent
          key={'upperToleranceComponent_Volume_' + resetCountTolerances}
          name={'upperToleranceVolume'}
          onInputChange={upperToleranceVolumeInputChanged}
          defaultValue={
            resetCountTolerances === 0 && measurementType === MeasurementOption.Volume
              ? article?.upperTolerance?.toString() || ''
              : ''
          }
          label={t('data:article.upperToleranceCustom')}
          ariaLabel={t('data:article.upperToleranceCustom')}
          fullWidth={true}
          suffixUnit={VOLUME_ENTITY}
          disabled={!newNominalDefaultValueVolume || newNominalDefaultValueVolume === ''}
          hasError={errors.upperToleranceVolume !== undefined}
          errorMessage={errors.upperToleranceVolume && errors.upperToleranceVolume.message}
        />
      </Grid>
    );
  };

  const tareValueComponent = () => {
    return (
      <Grid item className={articleFormClasses.inputItemExtraShort}>
        <FormattedTextFieldComponent
          key={'tareValueInput_' + tareMode}
          name={'tareValue'}
          onInputChange={tareValueInputChanged}
          defaultValue={
            tareModeSwitched || article?.tareMode !== TareMode.Preset
              ? ''
              : article?.tareValue?.toString() || ''
          }
          label={t('data:article.tare')}
          ariaLabel={t('data:article.tare')}
          fullWidth={true}
          suffixUnit={WEIGHT_ENTITY}
          hasError={errors.tareValue !== undefined}
          errorMessage={errors.tareValue && errors.tareValue.message}
        />
      </Grid>
    );
  };

  const tareSampleSizeComponent = () => {
    return (
      <Grid item className={articleFormClasses.inputItemExtraShort}>
        <Controller
          as={<TextField variant="outlined" fullWidth={true} required />}
          control={control}
          name="tareSampleSize"
          label={t('data:article.tareSampleSize')}
          inputProps={{ 'aria-label': t('data:article.tareSampleSize') }}
          defaultValue={
            article?.tareMode === TareMode.Single && article?.tareSampleSize
              ? article?.tareSampleSize?.toString()
              : '1'
          }
          rules={{
            required: { value: true, message: t('form:fieldIsRequired') },
            validate: (value: string) =>
              Number.isInteger(Number(value)) ? true : (t('form:integerValue') as string),
            min: { value: 1, message: t('form:minValue', { min: 1 }) },
            max: { value: 20, message: t('form:maxValue', { max: 20 }) },
          }}
          error={errors.tareSampleSize !== undefined}
          helperText={errors.tareSampleSize && errors.tareSampleSize.message}
        />
      </Grid>
    );
  };

  const tareIndividualComponent = () => {
    return (
      <Grid item mt={-3}>
        <Typography>{t('data:article.tareIndividualDescription')}</Typography>
      </Grid>
    );
  };

  const singleTareMaxValueComponent = () => {
    return (
      <Grid item className={articleFormClasses.inputItemExtraShort}>
        <FormattedTextFieldComponent
          key={'singleTareMaxValueComponent' + tareMode}
          name={'singleTareMaxValue'}
          onInputChange={singleTareMaxValueChanged}
          defaultValue={
            tareModeSwitched || article?.tareMode !== TareMode.Single
              ? ''
              : article?.tareMaxValue?.toString() || ''
          }
          label={t('data:article.tareMaxValueCustom')}
          ariaLabel={t('data:article.tareMaxValueCustom')}
          fullWidth={true}
          suffixUnit={WEIGHT_ENTITY}
          hasError={errors.singleTareMaxValue !== undefined}
          errorMessage={errors.singleTareMaxValue && errors.singleTareMaxValue.message}
        />
      </Grid>
    );
  };

  const singleTareMinValueComponent = () => {
    return (
      <Grid item className={articleFormClasses.inputItemExtraShort}>
        <FormattedTextFieldComponent
          key={'singleTareMinValueComponent' + tareMode}
          name={'singleTareMinValue'}
          onInputChange={singleTareMinValueChanged}
          defaultValue={
            tareModeSwitched || article?.tareMode !== TareMode.Single
              ? ''
              : article?.tareMinValue?.toString() || ''
          }
          label={t('data:article.tareMinValueCustom')}
          ariaLabel={t('data:article.tareMinValueCustom')}
          fullWidth={true}
          suffixUnit={WEIGHT_ENTITY}
          hasError={errors.singleTareMinValue !== undefined}
          errorMessage={errors.singleTareMinValue && errors.singleTareMinValue.message}
        />
      </Grid>
    );
  };

  const individualTareMaxValueComponent = () => {
    return (
      <Grid item className={articleFormClasses.inputItemExtraShort}>
        <FormattedTextFieldComponent
          key={'individualTareMaxValueComponent' + tareMode}
          name={'individualTareMaxValue'}
          onInputChange={individualTareMaxValueChanged}
          defaultValue={
            tareModeSwitched || article?.tareMode !== TareMode.OrderedIndividual
              ? ''
              : article?.tareMaxValue?.toString() || ''
          }
          label={t('data:article.tareMaxValueCustom')}
          ariaLabel={t('data:article.tareMaxValueCustom')}
          fullWidth={true}
          suffixUnit={WEIGHT_ENTITY}
          hasError={errors.individualTareMaxValue !== undefined}
          errorMessage={errors.individualTareMaxValue && errors.individualTareMaxValue.message}
        />
      </Grid>
    );
  };

  const individualTareMinValueComponent = () => {
    return (
      <Grid item className={articleFormClasses.inputItemExtraShort}>
        <FormattedTextFieldComponent
          key={'individualTareMinValueComponent' + tareMode}
          name={'individualTareMinValue'}
          onInputChange={individualTareMinValueChanged}
          defaultValue={
            tareModeSwitched || article?.tareMode !== TareMode.OrderedIndividual
              ? ''
              : article?.tareMinValue?.toString() || ''
          }
          label={t('data:article.tareMinValueCustom')}
          ariaLabel={t('data:article.tareMinValueCustom')}
          fullWidth={true}
          suffixUnit={WEIGHT_ENTITY}
          hasError={errors.individualTareMinValue !== undefined}
          errorMessage={errors.individualTareMinValue && errors.individualTareMinValue.message}
        />
      </Grid>
    );
  };

  return (
    <FormRoot>
      <ArticleFormRoot>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className={formClasses.root}>
            <ContainerOutsideWithHeader>
              <Paper>
                <ContainerInside>
                  <Grid container spacing={irisSpacing.input.space}>
                    <Grid item>
                      <ImageInputWithPreviewComponent
                        chosenImageFile={chosenImageFile}
                        changeImage={setChosenImageFile}
                        removeImage={removeImage}
                        imageUrl={imageUrl}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="h3">{t('data:article.baseData')}</Typography>
                      <Grid
                        container
                        spacing={irisSpacing.input.space}
                        marginBottom={irisSpacing.input.space}
                      >
                        <Grid item className={articleFormClasses.inputItem}>
                          <Controller
                            as={<TextField variant="outlined" fullWidth={true} required />}
                            control={control}
                            name="articleNumber"
                            label={t('data:article.number')}
                            inputProps={{ 'aria-label': t('data:article.number') }}
                            rules={{
                              required: { value: true, message: t('form:fieldIsRequired') },
                              validate: (value: string) =>
                                value.trim() ? true : (t('form:fieldIsRequired') as string),
                              minLength: { value: 1, message: t('form:minLength', { min: '1' }) },
                              maxLength: {
                                value: 30,
                                message: t('form:maxLength', { max: '30' }),
                              },
                            }}
                            error={errors.articleNumber !== undefined}
                            helperText={errors.articleNumber && errors.articleNumber.message}
                          />
                        </Grid>
                        <Grid item className={articleFormClasses.inputItem}>
                          <Controller
                            as={<TextField variant="outlined" fullWidth={true} required />}
                            control={control}
                            name="articleName"
                            label={t('data:article.name')}
                            inputProps={{ 'aria-label': t('data:article.name') }}
                            rules={{
                              required: { value: true, message: t('form:fieldIsRequired') },
                              validate: (value: string) =>
                                value.trim() ? true : (t('form:fieldIsRequired') as string),
                              minLength: { value: 2, message: t('form:minLength', { min: '2' }) },
                              maxLength: {
                                value: 100,
                                message: t('form:maxLength', { max: '100' }),
                              },
                            }}
                            error={errors.articleName !== undefined}
                            helperText={errors.articleName && errors.articleName.message}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={irisSpacing.input.space}>
                        <Grid item className={articleFormClasses.inputItem}>
                          <Controller
                            as={<TextField variant="outlined" fullWidth={true} />}
                            control={control}
                            name="articleNameSuffix"
                            label={t('data:article.nameSuffix')}
                            inputProps={{ 'aria-label': t('data:article.nameSuffix') }}
                            rules={{
                              minLength: { value: 2, message: t('form:minLength', { min: '2' }) },
                              maxLength: {
                                value: 100,
                                message: t('form:maxLength', { max: '100' }),
                              },
                            }}
                            error={errors.articleNameSuffix !== undefined}
                            helperText={
                              errors.articleNameSuffix && errors.articleNameSuffix.message
                            }
                          />
                        </Grid>
                        <Grid item className={articleFormClasses.inputItem}>
                          {/* Does not work with Controller yet because of it's nested structure */}
                          <Autocomplete
                            options={props.lines || []}
                            getOptionLabel={(line: Line) => line.name}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                            defaultValue={article?.productionLine || undefined}
                            isOptionEqualToValue={(option: Line, value: Line) =>
                              option.id === value.id
                            }
                            clearText={t('common:delete')}
                            noOptionsText={t('common:noDataAvailable')}
                            openText={t('common:open')}
                            closeText={t('common:close')}
                            id="productionLineAutocomplete"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="productionLine"
                                label={t('data:article.defaultLine')}
                                inputProps={{
                                  ...params.inputProps,
                                  'data-testid': 'line-input',
                                }}
                                variant="outlined"
                                error={!!errors.productionLine}
                                helperText={
                                  errors.productionLine && (errors.productionLine as any).message
                                }
                              />
                            )}
                            onChange={handleLineChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ContainerInside>
              </Paper>
              <Typography variant="h2">{t('data:article.nominalCapacity')}</Typography>
              <Grid container spacing={irisSpacing.container.space}>
                <Grid item xs={12} xl={6}>
                  <Paper>
                    <ContainerInside>
                      <Grid container spacing={irisSpacing.input.space}>
                        <Grid item>
                          <FormControl fullWidth={true}>
                            <Typography variant="h3">
                              {t('data:article.measurementType')}
                            </Typography>
                            <Controller
                              as={
                                <RadioGroup
                                  aria-label={t('data:article.measurementType')}
                                  name="measurementType"
                                >
                                  <FormControlLabel
                                    data-testid="weight-radio-btn"
                                    value={MeasurementOption.Weight}
                                    control={<Radio onChange={handleMeasurementTypeChange} />}
                                    label={t('data:article.weight') as string}
                                  />
                                  <Box
                                    className={
                                      measurementType === MeasurementOption.Weight
                                        ? articleFormClasses.radioContent
                                        : articleFormClasses.hidden
                                    }
                                    data-testid="weightRadioContent"
                                  >
                                    <Grid
                                      container
                                      className={articleFormClasses.itemContainer}
                                      spacing={irisSpacing.input.space}
                                    >
                                      {nominalValueComponent()}
                                    </Grid>
                                    <Grid
                                      container
                                      className={articleFormClasses.itemContainer}
                                      spacing={irisSpacing.input.space}
                                    >
                                      {lowerToleranceComponent()}
                                      {upperToleranceComponent()}
                                    </Grid>
                                  </Box>
                                  <FormControlLabel
                                    data-testid="volume-radio-btn"
                                    value={MeasurementOption.Volume}
                                    control={<Radio onChange={handleMeasurementTypeChange} />}
                                    label={t('data:article.volume') as string}
                                  />
                                  <Box
                                    className={
                                      measurementType === MeasurementOption.Volume
                                        ? articleFormClasses.radioContent
                                        : articleFormClasses.hidden
                                    }
                                    data-testid="volumeRadioContent"
                                  >
                                    <Grid
                                      container
                                      className={articleFormClasses.itemContainer}
                                      spacing={irisSpacing.input.space}
                                    >
                                      {nominalValueVolumeComponent()}
                                      {volumeValueComponent()}
                                      {densityValueComponent()}
                                    </Grid>
                                    <Grid
                                      container
                                      className={articleFormClasses.itemContainer}
                                      spacing={irisSpacing.input.space}
                                    >
                                      {lowerToleranceVolumeComponent()}
                                      {upperToleranceVolumeComponent()}
                                    </Grid>
                                  </Box>
                                </RadioGroup>
                              }
                              control={control}
                              defaultValue={MeasurementOption.Weight}
                              name="measurementType"
                              rules={{
                                required: { value: true, message: t('form:fieldIsRequired') },
                              }}
                            />
                            {errors.measurementType && errors.measurementType.message && (
                              <FormHelperText>{errors.measurementType.message}</FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </ContainerInside>
                  </Paper>
                </Grid>
                <Grid item xs={12} xl={6}>
                  <Paper>
                    <ContainerInside>
                      <FormControl fullWidth={true}>
                        <Typography variant="h3">{t('data:article.tare')}</Typography>
                        <Controller
                          as={
                            <RadioGroup aria-label={t('data:article.tareMode')} name="tareMode">
                              <FormControlLabel
                                data-testid="tare-preset-radio-btn"
                                value={TareMode.Preset}
                                control={<Radio />}
                                label={t('data:article.tarePreset') as string}
                              />
                              <Box
                                className={
                                  +tareMode === TareMode.Preset
                                    ? articleFormClasses.radioContent
                                    : articleFormClasses.hidden
                                }
                              >
                                <Grid
                                  container
                                  className={articleFormClasses.itemContainer}
                                  spacing={irisSpacing.input.space}
                                >
                                  {tareValueComponent()}
                                </Grid>
                              </Box>
                              <FormControlLabel
                                data-testid="tare-determination-radio-btn"
                                value={TareMode.Single}
                                control={<Radio />}
                                label={t('data:article.tareDetermination') as string}
                              />
                              <Box
                                className={
                                  +tareMode === TareMode.Single
                                    ? articleFormClasses.radioContent
                                    : articleFormClasses.hidden
                                }
                              >
                                <Grid
                                  container
                                  className={articleFormClasses.itemContainer}
                                  spacing={irisSpacing.input.space}
                                >
                                  {tareSampleSizeComponent()}
                                  {singleTareMinValueComponent()}
                                  {singleTareMaxValueComponent()}
                                </Grid>
                              </Box>
                              <FormControlLabel
                                data-testid="tare-individual-radio-btn"
                                value={TareMode.OrderedIndividual}
                                control={<Radio />}
                                label={t('data:article.tareIndividual') as string}
                              />
                              <Box
                                className={
                                  +tareMode === TareMode.OrderedIndividual
                                    ? articleFormClasses.radioContent
                                    : articleFormClasses.hidden
                                }
                              >
                                <Grid
                                  container
                                  className={articleFormClasses.itemContainer}
                                  spacing={irisSpacing.input.space}
                                  direction={'column'}
                                >
                                  {tareIndividualComponent()}
                                  <Grid item>
                                    <Grid
                                      container
                                      spacing={irisSpacing.input.space}
                                      direction={'row'}
                                    >
                                      {individualTareMinValueComponent()}
                                      {individualTareMaxValueComponent()}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </RadioGroup>
                          }
                          control={control}
                          name="tareMode"
                          rules={{
                            required: { value: true, message: t('form:fieldIsRequired') },
                          }}
                        />
                      </FormControl>
                    </ContainerInside>
                  </Paper>
                </Grid>
              </Grid>
            </ContainerOutsideWithHeader>

            <Typography variant="h2">{t('data:article.checkSettings')}</Typography>
            <Grid container spacing={irisSpacing.container.space}>
              <Grid item md={12} lg={8}>
                <Paper className={articleFormClasses.settingsContainer} data-testid={'fpvo-paper'}>
                  <ContainerInside>
                    <Typography variant="h3">{t('data:article.FertigPackV')}</Typography>
                    <ToleranceBarComponent
                      nominalValue={
                        nominalValueInput &&
                        nominalValueInput !== '' &&
                        measurementType === MeasurementOption.Weight
                          ? +nominalValueInput
                          : nominalValueVolumeInput &&
                            nominalValueVolumeInput !== '' &&
                            volumeValueInput &&
                            measurementType === MeasurementOption.Volume
                          ? +volumeValueInput
                          : undefined
                      }
                      tolerances={currentTolerances}
                      lowerTolerance={lowerTolerance}
                      upperTolerance={upperTolerance}
                      hasVolumeValues={measurementType === MeasurementOption.Volume}
                    />
                  </ContainerInside>
                </Paper>
              </Grid>

              <Grid item lg={4}>
                <Paper className={articleFormClasses.settingsContainer}>
                  <ContainerInside>
                    <Typography variant="h3">{t('data:article.checkPeriodicity')}</Typography>
                    <Box display="flex" alignItems="center">
                      <Box mr={2} mt={2}>
                        {t('data:article.repeatCheck_A')}
                      </Box>
                      <Box className={articleFormClasses.checkPeriodicityItemContainer}>
                        <Box className={articleFormClasses.checkPeriodicityItem}>
                          <Controller
                            as={<TextField variant="outlined" fullWidth={true} required />}
                            control={control}
                            name="checkPeriodicity"
                            label={t('data:article.minutes')}
                            inputProps={{ 'aria-label': t('data:article.checkPeriodicity') }}
                            rules={{
                              required: { value: true, message: t('form:fieldIsRequired') },
                              validate: (value: string) =>
                                Number.isInteger(Number(value))
                                  ? true
                                  : (t('form:integerValue') as string),
                              min: { value: 5, message: t('form:minValue', { min: 5 }) },
                              max: { value: 480, message: t('form:maxValue', { max: 480 }) },
                            }}
                            error={errors.checkPeriodicity !== undefined}
                          />
                        </Box>
                        {errors.checkPeriodicity && errors.checkPeriodicity.message && (
                          <Box className={articleFormClasses.validationError}>
                            {errors.checkPeriodicity.message}
                          </Box>
                        )}
                      </Box>
                      <Box ml={2} mt={2}>
                        {t('data:article.repeatCheck_B')}
                      </Box>
                    </Box>
                  </ContainerInside>
                </Paper>
              </Grid>
            </Grid>
            <Grid item>
              <Box className={formClasses.buttons}>
                <Button
                  data-testid="cancel-btn"
                  color="secondary"
                  variant="contained"
                  onClick={() => props.cancel()}
                >
                  {t('form:cancel')}
                </Button>
                <Button data-testid="submit-btn" color="primary" type="submit" variant="contained">
                  {t('form:submit')}
                </Button>
              </Box>
            </Grid>
          </form>
        </FormProvider>
      </ArticleFormRoot>
    </FormRoot>
  );
};
