export interface Disruption {
  id: string;
  disruptionCode: DisruptionCode;
  disruptionType: DisruptionType;
  started: string;
  resolved?: string;
  reason?: string;
  duration?: string;
}

export enum DisruptionCode {
  Manual = 1,
  FertigPackVTU2Violation = 2,
  FertigPackVTU1Violation = 3,
}

export enum DisruptionType {
  Error = 1,
  Warning = 2,
}
