import { createSelector } from 'reselect';

import { AppState } from '..';

export const licenseStateSelector = (state: AppState) => state.license;
export const licenseSelector = createSelector(licenseStateSelector, (state) => state.license);
export const addLicenseSuccessfulSelector = createSelector(
  licenseStateSelector,
  (state) => state.addLicenseSuccessful
);

export const licenseExpireWarningSelector = createSelector(
  licenseStateSelector,
  (state) => state.license && state.license.expireWarning
);

export const licenseExpirationDaysSelector = createSelector(
  licenseStateSelector,
  (state) => state.license && state.license.expiresInDays
);

export const licenseExpiredSelector = createSelector(
  licenseStateSelector,
  (state) => state.license && state.license.expired
);

export const expiredLicenseFailureSelector = createSelector(
  licenseStateSelector,
  (state) => state.expiredLicenseFailure
);
