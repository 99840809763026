import { ProductionRunCheckExecution } from './check-result.model';
import { Disruption } from './disruption.model';

export interface CheckHistoryEntry {
  eventTime: string;
  type: CheckHistoryEntryType;
  execution?: ProductionRunCheckExecution;
  disruption?: Disruption;
}

export enum CheckHistoryEntryType {
  CheckResult = 1,
  Disruption = 2,
}
