import { Dialog, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Check } from '../../../model';
import {
  addCheckForArticle,
  editableCheckAttributeListSelector,
  fetchCheckAttributes,
  isAddCheckDialogOpenSelector,
} from '../../../store';

import { CheckFormComponent } from './check-form.component';

const PREFIX = 'CheckNewDialogComponent';
const classes = {
  dialog: `${PREFIX}-dialog`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`&.${classes.dialog}`]: {
    '& .MuiDialog-paperWidthMd': {
      width: '680px',
    },
  },
}));

export interface OwnProps {
  handleCancel: () => void;
  checkPeriodicity: number;
  articleId: string;
}

export const CheckNewDialogComponent = (props: OwnProps) => {
  const { articleId, checkPeriodicity, handleCancel } = props;

  const dispatch = useDispatch();
  const editableCheckAttributes = useSelector(editableCheckAttributeListSelector);
  const isAddCheckDialogOpen = useSelector(isAddCheckDialogOpenSelector);

  useEffect(() => {
    dispatch(fetchCheckAttributes());
  }, [dispatch]);

  const submit = (check: Omit<Check, 'id'>) => {
    dispatch(addCheckForArticle(articleId, check));
  };

  const cancel = () => {
    handleCancel();
  };

  return (
    <StyledDialog open={isAddCheckDialogOpen} maxWidth="md" className={classes.dialog}>
      <DialogContent>
        <CheckFormComponent
          submit={submit}
          cancel={cancel}
          articleCheckPeriodicity={checkPeriodicity}
          checkAttributes={editableCheckAttributes || []}
        ></CheckFormComponent>
      </DialogContent>
    </StyledDialog>
  );
};
