import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import i18n from '../../i18next';
import { closeProductionRunFreeTextDialog, currentCheckResultSelector } from '../../store';
import { irisCustomColors } from '../../theme';
import { dateAndTimeOptions } from '../../utils/date-utils';

const PREFIX = 'FreeTextComponent';
const classes = {
  headline: `${PREFIX}-headline`,
  freeText: `${PREFIX}-freeText`,
  subline: `${PREFIX}-subline`,
  closeButton: `${PREFIX}-closeButton`,
  spacing: `${PREFIX}-spacing`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.headline}`]: {
    fontSize: '1.75rem',
    marginTop: theme.spacing(6),
  },

  [`& .${classes.freeText}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.subline}`]: {
    fontWeight: 'bold',
  },

  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(1),
    color: irisCustomColors.irisBlack,
  },

  [`& .${classes.spacing}`]: {
    marginBottom: theme.spacing(3),
  },
}));

export const FreeTextComponent = () => {
  const { t } = useTranslation(['data']);
  const dispatch = useDispatch();

  const currentCheckResult = useSelector(currentCheckResultSelector);
  const execution = currentCheckResult?.execution;

  const handleClose = () => {
    dispatch(closeProductionRunFreeTextDialog());
  };

  return (
    <>
      <StyledBox>
        {execution && (
          <Box>
            <IconButton
              aria-label={t('common:close')}
              className={classes.closeButton}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
            <Typography data-testid={'headlineFreeText'} className={classes.headline}>
              {execution.checkAttribute.name}
            </Typography>
            <Box className={classes.freeText}>
              {`${t('data:checkResult.checkedOn')}: `}
              {new Date(execution.checkedOn).toLocaleString(i18n.language, dateAndTimeOptions)}
            </Box>
          </Box>
        )}
        {execution &&
          execution.samples &&
          execution.samples.map((sample, index) => {
            return (
              <Box key={index}>
                {execution.samples!.length > 1 && (
                  <span className={classes.subline}>{`${t('data:checkAttribute.sample')} ${
                    index + 1
                  }:`}</span>
                )}
                <Typography data-testid="sampleFreeText" className={classes.spacing}>
                  {sample.freeText}
                </Typography>
              </Box>
            );
          })}
      </StyledBox>
    </>
  );
};
