import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Check, CheckAttribute, CheckAttributeType } from '../../../model';
import { irisSpacing } from '../../../theme';

const PREFIX = 'CheckFormComponent';
const classes = {
  root: `${PREFIX}-root`,
  buttons: `${PREFIX}-buttons`,
  subline: `${PREFIX}-subline`,
  validationError: `${PREFIX}-validationError`,
  minutesInput: `${PREFIX}-minutesInput`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    '&>*': {
      marginBottom: theme.spacing(2),
    },
  },

  [`& .${classes.buttons}`]: {
    display: 'flex',
    '& button:not(:last-child)': {
      marginRight: theme.spacing(irisSpacing.button.space),
    },
  },

  [`& .${classes.subline}`]: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    '&.Mui-focused': {
      color: theme.palette.text.primary,
    },
  },

  [`& .${classes.minutesInput}`]: {
    paddingLeft: theme.spacing(4),
    '& .MuiFilledInput-root': {
      width: '127px',
    },
  },
}));

export interface OwnProps {
  articleCheckPeriodicity: number;
  check?: Check;
  submit: (check: Omit<Check, 'id'>) => void;
  cancel: () => void;
  checkAttributes: CheckAttribute[];
}

enum PeriodicityValue {
  standard = 'standard',
  custom = 'custom',
  no = 'no',
}

type FormData = {
  checkAttribute: CheckAttribute | undefined | null;
  checkTime: null;
  checkPeriodicity: PeriodicityValue;
  customCheckPeriodicity: number | string;
};

const getFormValues = (check?: Check): FormData => {
  let checkPeriodicity = PeriodicityValue.standard;
  if (check) {
    checkPeriodicity = check.checkPeriodicity
      ? PeriodicityValue.custom
      : check.periodically
      ? PeriodicityValue.standard
      : PeriodicityValue.no;
  }

  return {
    checkAttribute: check?.checkAttribute || undefined,
    checkTime: null,
    checkPeriodicity,
    customCheckPeriodicity: check?.checkPeriodicity || '',
  };
};

export const CheckFormComponent = (props: OwnProps) => {
  const { articleCheckPeriodicity, check } = props;
  const { t } = useTranslation(['form', 'data', 'common']);
  const [state, setState] = React.useState({
    afterStart: check ? check.afterStart : true,
    afterDisruption: check ? check.afterDisruption : true,
    afterProduction: check ? check.afterProduction : false,
  });
  const { afterDisruption, afterStart, afterProduction } = state;

  const { handleSubmit, errors, control, reset, setValue, register, watch, getValues } =
    useForm<FormData>({
      defaultValues: getFormValues(check),
    });

  useEffect(() => {
    // Autocomplete needs custom register
    register(
      {
        name: 'checkAttribute',
      },
      {
        required: { value: true, message: t('form:fieldIsRequired') },
      }
    );
  }, [register, t]);

  useEffect(() => {
    // wait for data to be loaded and reset default values
    check && reset(getFormValues(check));
  }, [check, reset]);

  const selectedCheckAttribute = watch('checkAttribute');
  const selectedCheckPeriodicity = watch('checkPeriodicity');
  const customCheckPeriodicity = watch('customCheckPeriodicity');

  const isFertigPackV =
    selectedCheckAttribute?.checkAttributeType === CheckAttributeType.FertigPackV;

  const onSubmit = handleSubmit((formData: FormData) => {
    if (formData.checkAttribute) {
      props.submit({
        afterStart: afterStart || false,
        afterDisruption: afterDisruption || false,
        periodically: formData.checkPeriodicity === PeriodicityValue.standard,
        checkPeriodicity:
          formData.checkPeriodicity === PeriodicityValue.custom && formData.customCheckPeriodicity
            ? Number(formData.customCheckPeriodicity)
            : undefined,
        afterProduction: (afterProduction && !isFertigPackV) || false,
        checkAttribute: formData.checkAttribute,
      });
    }
  });

  const handleCheckAttributeChange = (_e: React.ChangeEvent<{}>, value: CheckAttribute | null) => {
    setValue('checkAttribute', value, { shouldValidate: true, shouldDirty: true });
  };

  const handleAfterDisruptionChange = (event: React.ChangeEvent<{}>, checked: boolean) => {
    setState({ ...state, afterDisruption: checked });
  };

  const handleAfterStartChange = (event: React.ChangeEvent<{}>, checked: boolean) => {
    setState({ ...state, afterStart: checked });
  };

  const handleAfterProductionChange = (event: React.ChangeEvent<{}>, checked: boolean) => {
    setState({ ...state, afterProduction: checked });
  };

  useEffect(() => {
    if (customCheckPeriodicity) {
      setValue('checkPeriodicity', PeriodicityValue.custom);
    }
  }, [customCheckPeriodicity, getValues, setValue]);

  return (
    <Root>
      <form onSubmit={onSubmit} className={classes.root}>
        <Grid container direction="column" spacing={irisSpacing.input.space}>
          <Grid item xs={12}>
            {/* Does not work with Controller yet because of it's nested structure */}
            <Autocomplete
              options={props.checkAttributes}
              getOptionLabel={(checkAttribute: CheckAttribute) => checkAttribute.name}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              defaultValue={check?.checkAttribute || undefined}
              isOptionEqualToValue={(option: CheckAttribute, value: CheckAttribute) =>
                option.id === value.id
              }
              clearText={t('common:delete')}
              noOptionsText={t('common:noDataAvailable')}
              openText={t('common:open')}
              closeText={t('common:close')}
              id="checkAttributeAutocomplete"
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="checkAttribute"
                  variant="outlined"
                  label={t('data:checkAttribute.chooseCheckAttribute')}
                  required
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': 'check-attribute-input',
                    'aria-label': t('data:checkAttribute.attribute'),
                  }}
                  error={!!errors.checkAttribute}
                  helperText={errors.checkAttribute && errors.checkAttribute.message}
                />
              )}
              onChange={handleCheckAttributeChange}
            />
          </Grid>
          {selectedCheckAttribute && (
            <Grid item xs={12}>
              <Box>
                {`${t('data:checkAttribute.specification')}: `}
                {t([
                  `data:checkAttribute.specificationCodes.${selectedCheckAttribute.specification}`,
                  `data:checkAttribute.specificationCodes.notFound`,
                ])}
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid container direction="row" spacing={irisSpacing.input.space}>
              {/* Radio Button */}
              <Grid item xs={6}>
                <FormControl fullWidth={true}>
                  <FormLabel
                    id="checkPeriodicity-label"
                    component="legend"
                    className={classes.subline}
                  >
                    {t('data:check.checkPeriodicity')}
                  </FormLabel>
                  <Controller
                    as={
                      <RadioGroup
                        aria-label={t('data:check.checkPeriodicity')}
                        name="checkPeriodicity"
                      >
                        <FormControlLabel
                          data-testid="standard-periodicity-radio-btn"
                          value={PeriodicityValue.standard}
                          control={<Radio />}
                          label={
                            t('data:check.standardPeriodicity', {
                              minutes: articleCheckPeriodicity || 'x',
                            }) as string
                          }
                        />
                        <FormControlLabel
                          data-testid="custom-periodicity-radio-btn"
                          value={PeriodicityValue.custom}
                          control={<Radio />}
                          label={t('data:check.customPeriodicity') as string}
                        />
                        <Box className={classes.minutesInput}>
                          <Controller
                            as={<TextField variant="outlined" fullWidth={true} required />}
                            control={control}
                            label={t('data:article.minutes')}
                            required={selectedCheckPeriodicity === PeriodicityValue.custom}
                            error={errors.customCheckPeriodicity !== undefined}
                            helperText={
                              errors.customCheckPeriodicity && errors.customCheckPeriodicity.message
                            }
                            inputProps={{
                              'aria-label': t('data:check.customPeriodicity'),
                              'data-testid': 'custom-periodicity-input',
                            }}
                            name="customCheckPeriodicity"
                            rules={{
                              validate: (value: string) => {
                                const checkPeriodicity = getValues()['checkPeriodicity'];
                                if (checkPeriodicity !== PeriodicityValue.custom) {
                                  return true;
                                }
                                if (
                                  !value ||
                                  (value && typeof value === 'string' && !value.trim())
                                ) {
                                  return t('form:fieldIsRequired') as string;
                                }
                                return Number.isInteger(Number(value))
                                  ? true
                                  : (t('form:integerValue') as string);
                              },
                              min: { value: 5, message: t('form:minValue', { min: 5 }) },
                              max: { value: 480, message: t('form:maxValue', { max: 480 }) },
                            }}
                          />
                        </Box>

                        <FormControlLabel
                          data-testid="no-periodicity-radio-btn"
                          value={PeriodicityValue.no}
                          control={<Radio />}
                          label={t('data:check.noPeriodicity') as string}
                        />
                      </RadioGroup>
                    }
                    control={control}
                    name="checkPeriodicity"
                    rules={{
                      required: { value: true, message: t('form:fieldIsRequired') },
                    }}
                  />
                </FormControl>
              </Grid>
              {/* checkboxes Button */}
              <Grid item xs={6}>
                <FormControl>
                  <FormLabel id="checkTime-label" component="legend" className={classes.subline}>
                    {t('data:check.checkTimes')}
                  </FormLabel>
                  <Controller
                    as={
                      <FormGroup aria-label={t('data:check.checkTimes')}>
                        <FormControlLabel
                          data-testid="after-start-checkbox"
                          aria-label={t('data:check.afterStart')}
                          checked={afterStart}
                          onChange={handleAfterStartChange}
                          name="afterStart"
                          control={<Checkbox />}
                          label={t('data:check.afterStart') as string}
                          labelPlacement={'end'}
                        />
                        <FormControlLabel
                          data-testid="after-production-checkbox"
                          aria-label={t('data:check.afterProduction')}
                          checked={afterProduction && !isFertigPackV}
                          onChange={handleAfterProductionChange}
                          name="afterProduction"
                          control={<Checkbox />}
                          label={t('data:check.afterProduction') as string}
                          labelPlacement={'end'}
                          disabled={isFertigPackV}
                        />
                        <FormControlLabel
                          data-testid="after-disruption-checkbox"
                          aria-label={t('data:check.afterDisruption')}
                          checked={afterDisruption}
                          onChange={handleAfterDisruptionChange}
                          name="afterDisruption"
                          control={<Checkbox />}
                          label={t('data:check.afterDisruption') as string}
                          labelPlacement={'end'}
                        />
                      </FormGroup>
                    }
                    control={control}
                    name="checkTime"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.buttons}>
            <Button
              data-testid="cancel-btn"
              color="secondary"
              variant="contained"
              onClick={() => props.cancel()}
            >
              {t('form:cancel')}
            </Button>
            <Button data-testid="submit-btn" color="primary" type="submit" variant="contained">
              {t('form:submitAndClose')}
            </Button>
          </Box>
        </Grid>
      </form>
    </Root>
  );
};
