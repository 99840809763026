import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button } from '@mui/material';
import React from 'react';

export interface OwnProps {
  onClick: () => void;
  disabled?: boolean;
  text?: string;
}
export const AddButtonComponent = (props: OwnProps) => {
  const { text, onClick, disabled } = props;
  return (
    <Button
      data-testid="addBtn"
      sx={{ height: '50px' }}
      color="primary"
      variant="contained"
      startIcon={<AddCircleOutlineIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
