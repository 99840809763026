import { push } from 'connected-react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { Line } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import { addLine } from '../../../store';

import { LineFormComponent } from './line-form.component';

export const LineNewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const submit = (line: Omit<Line, 'id' | 'lastModified'>) => {
    dispatch(addLine(line));
  };

  const cancel = () => {
    dispatch(push(`/${AppRoutePath.lines}`));
  };

  return (
    <DetailLayoutComponent
      backLinkText={t('data:line.backToLines')}
      backLinkTarget={`/${AppRoutePath.lines}`}
      headlineText={t('data:line.new')}
    >
      <LineFormComponent submit={submit} cancel={cancel}></LineFormComponent>
    </DetailLayoutComponent>
  );
};
