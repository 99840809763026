import { Box, Typography } from '@mui/material';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AddButtonComponent } from '../../../components/buttons/add-button.component';
import { LoadingIndicatorComponent } from '../../../components/loading-indicator/loading-indicator.component';
import { SearchFieldComponent } from '../../../components/search-field/search-field.component';
import {
  HeaderContainer,
  BodyStructure,
  HeaderStructure,
  PageStructure,
  ContainerOutside,
} from '../../../components/structure';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../constants';
import { AppRoutePath } from '../../../routes/routes';
import {
  checkAttributeListSelector,
  checkAttributesFetchingSelector,
  fetchCheckAttributes,
} from '../../../store';

import { CheckAttributesTableComponent } from './check-attributes-table.component';

export const CheckAttributesOverviewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data', 'common']);
  const loading = useSelector(checkAttributesFetchingSelector);
  const checkAttributes = useSelector(checkAttributeListSelector);
  const hasCheckAttributes = !!checkAttributes && checkAttributes.length > 0;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    dispatch(
      fetchCheckAttributes({
        search: searchTerm,
        pagination: { page, perPage: DEFAULT_ITEMS_PER_PAGE },
      })
    );
  }, [dispatch, searchTerm, page]);

  const goToNewCheckAttribute = () => {
    dispatch(push(`/${AppRoutePath.checkAttributes}/${AppRoutePath.new}/`));
  };

  const searchValueChanged = (value: string) => {
    if (searchTerm !== value) {
      setPage(0);
      setSearchTerm(value);
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <PageStructure>
      <HeaderStructure>
        <HeaderContainer>
          <Typography variant="h1">{t('data:checkAttribute.checkAttributes')}</Typography>
          <AddButtonComponent text={t('data:checkAttribute.new')} onClick={goToNewCheckAttribute} />
        </HeaderContainer>
      </HeaderStructure>
      <BodyStructure>
        <Box display="flex" justifyContent="flex-end">
          <SearchFieldComponent onSearch={searchValueChanged} />
        </Box>
        <ContainerOutside>
          {loading && !hasCheckAttributes ? (
            <LoadingIndicatorComponent entity={t('data:checkAttribute.checkAttributes')} />
          ) : (
            <CheckAttributesTableComponent handlePageChange={handlePageChange} page={page} />
          )}
        </ContainerOutside>
      </BodyStructure>
    </PageStructure>
  );
};
