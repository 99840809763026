import React from 'react';

import { LoginFormComponent } from '../../components/form/login-form/login-form.component';
import { StartScreenLayoutComponent } from '../../components/layout/start-screen-layout/start-screen-layout.component';

export const LoginPage = () => {
  return (
    <StartScreenLayoutComponent>
      <LoginFormComponent />
    </StartScreenLayoutComponent>
  );
};
