import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingIndicatorComponent } from '../../../components/loading-indicator/loading-indicator.component';
import {
  HeaderContainer,
  BodyStructure,
  HeaderStructure,
  PageStructure,
} from '../../../components/structure';
import {
  fetchProductionRunsRunning,
  fetchSystemTime,
  productionRunsRunningFetchingSelector,
  productionRunsRunningListSelector,
} from '../../../store';

import { ProductionRunsRunningGridComponent } from './production-runs-running-grid.component';

export const ProductionRunsRunningOverviewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const loading = useSelector(productionRunsRunningFetchingSelector);
  const productionRuns = useSelector(productionRunsRunningListSelector);
  const hasRuns = !!productionRuns && productionRuns.length > 0;

  useEffect(() => {
    dispatch(fetchSystemTime());
    dispatch(fetchProductionRunsRunning());
  }, [dispatch]);

  return (
    <PageStructure>
      <HeaderStructure>
        <HeaderContainer>
          <Typography variant="h1">{t('data:productionRun.runningProductionRunsShort')}</Typography>
        </HeaderContainer>
      </HeaderStructure>
      <BodyStructure>
        {loading && !hasRuns ? (
          <LoadingIndicatorComponent entity={t('data:productionRun.productionRuns')} />
        ) : (
          <ProductionRunsRunningGridComponent />
        )}
      </BodyStructure>
    </PageStructure>
  );
};
