import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { formClasses } from '../../../../components/components-styles/form.styles';

export interface OwnProps {
  cancel: () => void;
}

export const ButtonsFormComponent = (props: OwnProps) => {
  const { t } = useTranslation(['form']);

  return (
    <Box className={formClasses.buttons}>
      <Button
        data-testid="cancelBtn"
        color="secondary"
        variant="contained"
        onClick={() => props.cancel()}
      >
        {t('form:cancel')}
      </Button>
      <Button data-testid="submitBtn" color="primary" type="submit" variant="contained">
        {t('form:submit')}
      </Button>
    </Box>
  );
};
