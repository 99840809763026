export interface PaginationQuery {
  page: number;
  perPage: number;
}

export interface TableDataQuery {
  search?: string | null;
  pagination?: PaginationQuery;
}

export const tableDataQueryToUrl = (tableDataQuery?: TableDataQuery) => {
  let url = '';
  if (!tableDataQuery) {
    return url;
  }
  if (tableDataQuery.search) {
    url += `?search=${encodeURIComponent(tableDataQuery.search)}`;
  }
  if (tableDataQuery.pagination) {
    url += tableDataQuery.search ? '&' : '?';
    url += `page=${tableDataQuery.pagination.page + 1}&per_page=${
      tableDataQuery.pagination?.perPage
    }`;
  }
  return url;
};
