import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import logo from '../../../assets/img/iris-office-logo.png';
import LogoutIcon from '../../../assets/img/logout-icon.svg';
import { AppRoutePath, configurationRoutes, jobsRoutes } from '../../../routes/routes';
import { logout, loggedInUserSelector } from '../../../store';
import { irisCustomColors } from '../../../theme';

import { DrawerItemComponent } from './drawer-item.component';
import { listClasses, ListRoot } from './list-styles';

const PREFIX = 'DrawerComponent';
const classes = {
  logoImage: `${PREFIX}-logoImage`,
  drawerContainer: `${PREFIX}-drawerContainer`,
  appName: `${PREFIX}-appName`,
  menuList: `${PREFIX}-menuList`,
  divider: `${PREFIX}-divider`,
  categories: `${PREFIX}-categories`,
};

const Root = styled('div')(({ theme }) => ({
  backgroundColor: irisCustomColors.irisBlueGray,
  [`& .${classes.logoImage}`]: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4),
    maxWidth: '100%',
  },
  [`& .${classes.drawerContainer}`]: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  [`& .${classes.appName}`]: {
    color: irisCustomColors.irisBlack,
    fontSize: '22px',
    textAlign: 'center',
    paddingTop: '15px',
    paddingBottom: '10px',
  },
  [`& .${classes.menuList}`]: {
    width: '100%',
    backgroundColor: irisCustomColors.irisBlueGray,
  },
  [`& .${classes.divider}`]: {
    backgroundColor: irisCustomColors.irisWhite,
    marginLeft: theme.spacing(4),
  },
  [`& .${classes.categories}`]: {
    color: irisCustomColors.irisGold,
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(2),
    fontSize: '0.75rem',
  },
}));

export interface OwnProps {
  licenseExpired: boolean;
}

export const DrawerComponent = (props: OwnProps) => {
  const { t } = useTranslation(['common', 'data', 'license']);
  const { licenseExpired } = props;
  const dispatch = useDispatch();
  const currentUser = useSelector(loggedInUserSelector);
  const currentUserRole = currentUser?.role;
  const allowedJobsRoutes = jobsRoutes.filter(
    (route) => (route.minRole as Number) >= (currentUserRole as Number)
  );
  const allowedConfigurationRoutes = configurationRoutes.filter(
    (route) => (route.minRole as Number) >= (currentUserRole as Number)
  );

  const logoutUser = () => {
    dispatch(logout());
  };

  return (
    <Root>
      <ListRoot>
        {currentUser && (
          <>
            <Box>
              <Link to="/">
                <img className={classes.logoImage} src={logo} alt={t('common:iris')} />
              </Link>
            </Box>
            <div className={classes.drawerContainer}>
              <List className={classes.menuList}>
                <div className={classes.categories}>{t('common:irisJobs')}</div>
                <Divider className={classes.divider} />
                {allowedJobsRoutes.map((route) => {
                  return (
                    <DrawerItemComponent
                      key={route.translationKeyName}
                      route={route}
                      licenseExpired={licenseExpired}
                    />
                  );
                })}
                <div className={classes.categories}>{t('common:irisConfiguration')}</div>
                <Divider className={classes.divider} />
                {allowedConfigurationRoutes.map((route) => {
                  return (
                    <DrawerItemComponent
                      key={route.translationKeyName}
                      route={route}
                      licenseExpired={licenseExpired}
                    />
                  );
                })}
                {/* Logout */}
                <div className={classes.categories}>{t('common:loggedInAs')}</div>
                <Divider className={classes.divider} />
                <NavLink
                  className={listClasses.navLink}
                  to={`/${AppRoutePath.me}`}
                  activeClassName="active"
                >
                  <ListItem button disableTouchRipple={true} className={`${listClasses.listItem}`}>
                    <ListItemText
                      className={`${listClasses.listItemText}`}
                      primary={`${currentUser.firstName} ${currentUser.lastName}`}
                    />
                  </ListItem>
                </NavLink>
                <div className={listClasses.navLink}>
                  <ListItem
                    button
                    disableTouchRipple={true}
                    className={`${listClasses.listItem}`}
                    onClick={logoutUser}
                  >
                    <ListItemIcon className={listClasses.listItemIcon}>
                      <img src={LogoutIcon} alt={t('common:logout')} />
                    </ListItemIcon>
                    <ListItemText
                      className={`${listClasses.listItemText}`}
                      primary={t('common:logout')}
                    />
                  </ListItem>
                </div>
              </List>
            </div>
          </>
        )}
      </ListRoot>
    </Root>
  );
};
