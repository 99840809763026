import { HttpError } from '../../http';
import { Article, TableDataQuery } from '../../model';

import { ToleranceResult } from './../../model/tolerance-result.model';

export enum ArticlesActionType {
  articlesFetchAll = 'articles/fetchAll',
  articlesFetchAllSuccess = 'articles/fetchAll/success',
  articlesFetchAllFailure = 'articles/fetchAll/failure',
  articleFetch = 'article/fetch',
  articleFetchSuccess = 'article/fetch/success',
  articleFetchFailure = 'article/fetch/failure',
  articleAdd = 'article/add',
  articleAddSuccess = 'article/add/success',
  articleAddFailure = 'article/add/failure',
  articleEdit = 'article/edit',
  articleEditSuccess = 'article/edit/success',
  articleEditFailure = 'article/edit/failure',
  articleDelete = 'article/delete',
  articleDeleteSuccess = 'article/delete/success',
  articleDeleteFailure = 'article/delete/failure',
  articleDuplicate = 'article/duplicate',
  articleDuplicateSuccess = 'article/duplicate/success',
  articleDuplicateFailure = 'article/duplicate/failure',
  articleCurrentClear = 'article/clearCurrent',
  articleCalcFertigPackTolerances = 'article/calcFertigPackTolerances',
  articleCalcFertigPackTolerancesSuccess = 'article/calcFertigPackTolerances/success',
  articleCalcFertigPackTolerancesFailure = 'article/calcFertigPackTolerances/failure',
  articleFertigPackTolerancesClear = 'article/clearFertigPackTolerances',
  articleFertigPackInputValuesChange = 'article/changeFertigPackInputValues',
}

export const fetchArticles = (query?: TableDataQuery) => ({
  type: ArticlesActionType.articlesFetchAll,
  payload: {
    query,
  },
});

export const fetchArticlesSuccess = (articles: Article[], total: number) => ({
  type: ArticlesActionType.articlesFetchAllSuccess,
  payload: { articles, total },
});

export const fetchArticlesFailure = () => ({
  type: ArticlesActionType.articlesFetchAllFailure,
});

export const fetchArticle = (id: string) => ({
  type: ArticlesActionType.articleFetch,
  payload: { id },
});

export const fetchArticleSuccess = (article: Article) => ({
  type: ArticlesActionType.articleFetchSuccess,
  payload: { article },
});

export const fetchArticleFailure = () => ({
  type: ArticlesActionType.articleFetchFailure,
});

export const addArticle = (article: Omit<Article, 'id' | 'lastModified'>, image?: File) => ({
  type: ArticlesActionType.articleAdd,
  payload: { article, image },
});

export const addArticleSuccess = (article: Article) => ({
  type: ArticlesActionType.articleAddSuccess,
  payload: { article },
});

export const addArticleFailure = (e: HttpError) => ({
  type: ArticlesActionType.articleAddFailure,
  payload: { e },
});

export const editArticle = (article: Article, image?: File) => ({
  type: ArticlesActionType.articleEdit,
  payload: { article, image },
});

export const editArticleSuccess = (article: Article) => ({
  type: ArticlesActionType.articleEditSuccess,
  payload: { article },
});

export const editArticleFailure = (e: HttpError) => ({
  type: ArticlesActionType.articleEditFailure,
  payload: { e },
});

export const deleteArticle = (id: string) => ({
  type: ArticlesActionType.articleDelete,
  payload: { id },
});

export const deleteArticleSuccess = (id: string) => ({
  type: ArticlesActionType.articleDeleteSuccess,
  payload: { id },
});

export const deleteArticleFailure = () => ({
  type: ArticlesActionType.articleDeleteFailure,
});

export const duplicateArticle = (id: string) => ({
  type: ArticlesActionType.articleDuplicate,
  payload: { id },
});

export const duplicateArticleSuccess = (article: Article) => ({
  type: ArticlesActionType.articleDuplicateSuccess,
  payload: { article },
});

export const duplicateArticleFailure = () => ({
  type: ArticlesActionType.articleDuplicateFailure,
});

export const clearArticleCurrent = () => ({
  type: ArticlesActionType.articleCurrentClear,
});

export const calcArticleFertigPackTolerances = (nominalValue: number, densityValue?: number) => ({
  type: ArticlesActionType.articleCalcFertigPackTolerances,
  payload: { nominalValue, densityValue },
});

export const calcArticleFertigPackTolerancesSuccess = (tolerances: ToleranceResult) => ({
  type: ArticlesActionType.articleCalcFertigPackTolerancesSuccess,
  payload: { tolerances },
});

export const calcArticleFertigPackTolerancesFailure = () => ({
  type: ArticlesActionType.articleCalcFertigPackTolerancesFailure,
});

export const clearArticleFertigPackTolerances = () => ({
  type: ArticlesActionType.articleFertigPackTolerancesClear,
});

export const changeArticleFertigPackInputValues = (hasInputValues: boolean) => ({
  type: ArticlesActionType.articleFertigPackInputValuesChange,
  payload: { hasInputValues },
});
