import { TablePagination, LabelDisplayedRowsArgs } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_ITEMS_PER_PAGE } from '../../constants';
export interface OwnProps {
  page: number;
  count: number;
  onChangePage: (event: unknown, newPage: number) => void;
  currentItemsCount: number;
}
export const TablePaginationComponent = (props: OwnProps) => {
  const { t } = useTranslation(['table']);

  const labelDisplayedRows = (paginationInfo: LabelDisplayedRowsArgs) =>
    paginationInfo.count !== -1
      ? t('table:fromTo', {
          from: paginationInfo.from,
          to:
            paginationInfo.from + props.currentItemsCount - 1 < 0
              ? 0
              : paginationInfo.from + props.currentItemsCount - 1,
          count: paginationInfo.count,
        })
      : t('table:moreThan', {
          from: paginationInfo.from,
          to: paginationInfo.from + props.currentItemsCount - 1,
        });

  const getIconButtonAriaLabel = (type: 'first' | 'last' | 'next' | 'previous') => {
    switch (type) {
      case 'previous':
        return t('table:backIconButtonText');
      case 'next':
        return t('table:nextIconButtonText');
      default:
        return '';
    }
  };

  return (
    <TablePagination
      rowsPerPageOptions={[DEFAULT_ITEMS_PER_PAGE]}
      component="div"
      count={props.count}
      rowsPerPage={DEFAULT_ITEMS_PER_PAGE}
      page={props.page}
      onPageChange={props.onChangePage}
      labelDisplayedRows={labelDisplayedRows}
      getItemAriaLabel={getIconButtonAriaLabel}
    />
  );
};
