import { Box, Button, Grid, Paper, TextField } from '@mui/material';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formClasses, FormRoot } from '../../../components/components-styles/form.styles';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../components/structure';
import { Line } from '../../../model';
import { irisSpacing } from '../../../theme';

export interface OwnProps {
  line?: Line;
  submit: (line: Omit<Line, 'id' | 'lastModified'>) => void;
  cancel: () => void;
}

type FormData = {
  lineName: string;
};

const getFormValues = (line?: Line): FormData => ({
  lineName: line?.name || '',
});

export const LineFormComponent = (props: OwnProps) => {
  const { line } = props;

  const { t } = useTranslation(['form', 'data']);
  const { handleSubmit, errors, control, reset } = useForm<FormData>({
    defaultValues: getFormValues(line),
  });
  React.useEffect(() => {
    // wait for data to be loaded and reset default values
    line && reset(getFormValues(line));
  }, [line, reset]);

  const onSubmit = handleSubmit(({ lineName }) => {
    props.submit({
      name: lineName,
    });
  });

  return (
    <FormRoot>
      <form onSubmit={onSubmit} className={formClasses.root}>
        <ContainerOutsideWithHeader>
          <Paper>
            <ContainerInside>
              <Grid container spacing={irisSpacing.input.space}>
                <Grid item xs={12} sm={4}>
                  <Grid container spacing={irisSpacing.input.space}>
                    <Grid item xs={12}>
                      <Controller
                        as={<TextField variant="outlined" fullWidth={true} required />}
                        control={control}
                        name="lineName"
                        label={t('data:line.name')}
                        inputProps={{ 'aria-label': t('data:line.name') }}
                        rules={{
                          required: { value: true, message: t('form:fieldIsRequired') },
                          validate: (value: string) =>
                            value.trim() ? true : (t('form:fieldIsRequired') as string),
                          minLength: { value: 2, message: t('form:minLength', { min: '2' }) },
                          maxLength: { value: 100, message: t('form:maxLength', { max: '100' }) },
                        }}
                        error={errors.lineName !== undefined}
                        helperText={errors.lineName && errors.lineName.message}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ContainerInside>
          </Paper>
        </ContainerOutsideWithHeader>
        <Grid item xs={12} sm={12}>
          <Box className={formClasses.buttons}>
            <Button
              data-testid="cancel-btn"
              color="secondary"
              variant="contained"
              onClick={() => props.cancel()}
            >
              {t('form:cancel')}
            </Button>
            <Button data-testid="submit-btn" color="primary" type="submit" variant="contained">
              {t('form:submit')}
            </Button>
          </Box>
        </Grid>
      </form>
    </FormRoot>
  );
};
