import { styled } from '@mui/material/styles';

import { irisCustomColors } from '../../../theme';

const PREFIX = 'StyledStateItem';
export const stateItemClasses = {
  prodStateItemContainer: `${PREFIX}-prodStateItemContainer`,
  resultIconContainer: `${PREFIX}-resultIconContainer`,
  caption: `${PREFIX}-caption`,
  icon: `${PREFIX}-icon`,
  iconPassed: `${PREFIX}-iconPassed`,
  iconSufficient: `${PREFIX}-iconSufficient`,
  iconFailed: `${PREFIX}-iconFailed`,
  textElement: `${PREFIX}-textElement`,
  whiteBackground: `${PREFIX}-whiteBackground`,
  iconContainer: `${PREFIX}-iconContainer`,
  captionBox: `${PREFIX}-captionBox`,
};

export const StateItemRoot = styled('div')(({ theme }) => ({
  [`& .${stateItemClasses.prodStateItemContainer}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minHeight: '30px',
  },
  [`& .${stateItemClasses.resultIconContainer}`]: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '30px',
    minWidth: '30px',
  },
  [`& .${stateItemClasses.caption}`]: {
    marginRight: theme.spacing(1.5),
    fontWeight: 'bold',
  },
  [`& .${stateItemClasses.captionBox}`]: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(6),
    flexWrap: 'wrap',
    textOverflow: 'clip',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  [`& .${stateItemClasses.whiteBackground}`]: {
    minHeight: '30px',
    minWidth: '30px',
    backgroundColor: irisCustomColors.irisWhite,
    borderRadius: '50%',
    zIndex: 1,
    position: 'absolute',
  },
  [`& .${stateItemClasses.icon}`]: {
    color: irisCustomColors.irisWhite,
    zIndex: 4,
    borderRadius: '50%',
    position: 'absolute',
    minHeight: '24px',
    minWidth: '24px',
  },
  [`& .${stateItemClasses.iconPassed}`]: {
    backgroundColor: irisCustomColors.irisGreen,
  },
  [`& .${stateItemClasses.iconSufficient}`]: {
    backgroundColor: irisCustomColors.irisYellow,
  },
  [`& .${stateItemClasses.iconFailed}`]: {
    backgroundColor: irisCustomColors.irisRed,
  },
  [`& .${stateItemClasses.textElement}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [`& .${stateItemClasses.iconContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    minWidth: '30px',
    minHeight: '30px',
  },
}));
