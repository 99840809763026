import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { dateAndTimeOptions } from '../../utils/date-utils';

export interface OwnProps {
  dateVal: Date;
}

export const DateInfoComponent = (props: OwnProps) => {
  const { i18n } = useTranslation(['common']);
  const { t } = useTranslation('form');

  return (
    <Box mb={2} display="flex" alignItems="center">
      <EventAvailableIcon />
      {
        <Typography variant="body2">
          {`${t('common:lastEditTimestamp')}: ${props.dateVal.toLocaleString(
            i18n.language,
            dateAndTimeOptions
          )}`}
        </Typography>
      }
    </Box>
  );
};
