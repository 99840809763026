import { createSelector } from 'reselect';

import { AppState } from '..';

export const usersStateSelector = (state: AppState) => state.users;
export const userListSelector = createSelector(usersStateSelector, (state) => state.users);
export const currentUserSelector = createSelector(usersStateSelector, (state) => state.currentUser);
export const usersTotalCountSelector = createSelector(usersStateSelector, (state) => state.total);
export const tempUserPasswordSelector = createSelector(
  usersStateSelector,
  (state) => state.currentUserTempPassword
);
export const usernameAlreadyInUseSelector = createSelector(
  usersStateSelector,
  (state) => state.usernameAlreadyInUse
);

export const requestResetPasswordFailed = createSelector(
  usersStateSelector,
  (state) => state.requestResetPasswordWasFailed
);

export const usersFetchingSelector = createSelector(
  usersStateSelector,
  (state) => !!state.fetchingAll
);
