import log from 'loglevel';
import { call } from 'typed-redux-saga';

import { getAccessToken } from '../auth/auth.service';

import { HttpError } from './http-error';
import { fetchData, requestNewTokens } from './request';
import {
  HttpRequestMethod,
  HttpRequestType,
  HttpService,
  Options,
  SagaRequestMethod,
} from './types';

export const HttpRequest: HttpService<HttpRequestMethod> = {
  get: <T>(withAuthentication: boolean, url: string, _requestData?: RequestInit) =>
    doHttpRequestWrapped<T>(withAuthentication, url, { method: 'GET' }),

  post: <T>(withAuthentication: boolean, url: string, requestData?: RequestInit) =>
    doHttpRequestWrapped<T>(withAuthentication, url, { method: 'POST', ...requestData }),

  put: <T>(withAuthentication: boolean, url: string, requestData?: RequestInit) =>
    doHttpRequestWrapped<T>(withAuthentication, url, { method: 'PUT', ...requestData }),

  delete: <T>(withAuthentication: boolean, url: string, requestData?: RequestInit) =>
    doHttpRequestWrapped<T>(withAuthentication, url, { method: 'DELETE', ...requestData }),
};

export const SagaRequestHelper: HttpService<SagaRequestMethod> = {
  get: function* <T>(withAuthentication: boolean, url: string, requestData?: RequestInit) {
    return yield* doSagaRequest<T>(HttpRequestType.GET, withAuthentication, url, requestData);
  },

  post: function* <T>(withAuthentication: boolean, url: string, requestData?: RequestInit) {
    return yield* doSagaRequest<T>(HttpRequestType.POST, withAuthentication, url, requestData);
  },

  put: function* <T>(withAuthentication: boolean, url: string, requestData?: RequestInit) {
    return yield* doSagaRequest<T>(HttpRequestType.PUT, withAuthentication, url, requestData);
  },

  delete: function* <T>(withAuthentication: boolean, url: string, requestData?: RequestInit) {
    return yield* doSagaRequest<T>(HttpRequestType.DELETE, withAuthentication, url, requestData);
  },
};

function* doSagaRequest<T>(
  requestType: HttpRequestType,
  withAuthentication: boolean,
  url: string,
  requestData?: RequestInit
) {
  return yield* call<[boolean, string, RequestInit | undefined], (...args: any[]) => Promise<T>>(
    HttpRequest[requestType],
    withAuthentication,
    url,
    requestData
  );
}

function doHttpRequest<T>(
  withAuthentication: boolean,
  url: string,
  requestData: RequestInit
): Promise<T> {
  log.debug(`${requestData.method} ${url}`);

  const requestOptions: Options = {
    ...requestData,
  };

  return new Promise(async function (resolve, reject) {
    try {
      if (withAuthentication) {
        const token = await getAccessToken();
        requestOptions.token = token;
      }
      const response = await fetchData(url, requestOptions);
      log.debug(`Response for ${requestData.method} ${url} ${response.status}`, response.data);
      resolve(response.data);
    } catch (e: any) {
      const httpError = new HttpError(e.message, e.status, e.internalErrorCode, e.data);
      log.error(`Response for ${requestData.method} ${url} ${e.status}`, e, httpError);
      reject(httpError);
    }
  });
}

// Wraps requests with refresh
function doHttpRequestWrapped<T>(
  withAuthentication: boolean,
  url: string,
  requestData: RequestInit
): Promise<T> {
  return new Promise(async function (resolve, reject) {
    try {
      const responseData = await doHttpRequest(withAuthentication, url, requestData);
      resolve(responseData as any);
    } catch (e: any) {
      if (withAuthentication && e.status === 401 && e.message === 'Unauthorized') {
        try {
          await requestNewTokens();
          const responseData = await doHttpRequest(withAuthentication, url, requestData);
          resolve(responseData as any);
        } catch (refreshError: any) {
          reject(e);
        }
      } else {
        reject(e);
      }
    }
  });
}
