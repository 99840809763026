import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { InfoboxComponent } from '../../../components/infobox/infobox.component';
import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { ContainerOutside } from '../../../components/structure';
import { User } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import { addUser, usernameAlreadyInUseSelector, clearUsernameAlreadyInUse } from '../../../store';
import { irisSpacing } from '../../../theme';

import { UserFormComponent } from './user-form.component';

export const UserNewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const usernameAlreadyInUse = useSelector(usernameAlreadyInUseSelector);
  useEffect(() => {
    return () => {
      dispatch(clearUsernameAlreadyInUse());
    };
  }, [dispatch]);
  const submit = (user: Omit<User, 'id' | 'lastModified' | 'passwordResetRequested'>) => {
    dispatch(addUser(user));
  };

  const cancel = () => {
    dispatch(push(`/${AppRoutePath.users}`));
  };

  return (
    <DetailLayoutComponent
      backLinkText={t('data:user.backToOverview')}
      backLinkTarget={`/${AppRoutePath.users}`}
      headlineText={t('data:user.new')}
    >
      <>
        {usernameAlreadyInUse && (
          <ContainerOutside sx={{ marginBottom: irisSpacing.container.space }}>
            <InfoboxComponent subline={t('data:user.usernameAlreadyInUseError')} type={'error'} />
          </ContainerOutside>
        )}
        <UserFormComponent submit={submit} cancel={cancel}></UserFormComponent>
      </>
    </DetailLayoutComponent>
  );
};
