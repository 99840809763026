import React, { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { ChangePasswordPage } from '../pages/change-password/change-password.page';

import { AppRoutePath } from './routes';

export interface OwnProps extends RouteProps {
  isLoggedIn: boolean;
  changePassword: boolean;
}

export const ChangePasswordRoute: FunctionComponent<OwnProps> = ({
  isLoggedIn,
  changePassword,
  ...rest
}) => {
  if (!isLoggedIn) {
    return <Redirect to={`/${AppRoutePath.login}`} />;
  }
  if (changePassword) {
    return <Route {...rest} component={ChangePasswordPage} />;
  }
  return <Redirect to={`/${AppRoutePath.articles}`} />;
};
