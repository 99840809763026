import produce from 'immer';
import { Action } from 'redux';

import { InternalErrorCode } from '../../http/http-error-codes';
import { License } from '../../model';

import {
  LicenseActionType,
  fetchLicenseSuccess,
  addLicenseSuccess,
  addLicenseFailure,
} from './license.actions';

export interface LicenseState {
  license?: License | false;
  addLicenseSuccessful?: boolean;
  expiredLicenseFailure?: boolean;
}

export const getInitialState = (): LicenseState => {
  return {};
};

export const licenseReducer = (
  previousState: LicenseState = getInitialState(),
  action: Action<LicenseActionType>
) => {
  let nextState;

  switch (action.type) {
    case LicenseActionType.licenseFetch:
      nextState = produce(previousState, (draftState) => {
        delete draftState.license;
        delete draftState.addLicenseSuccessful;
      });
      break;
    case LicenseActionType.licenseFetchSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchLicenseSuccess>;
        draftState.license = fetchSuccessAction.payload.license;
      });
      break;
    case LicenseActionType.licenseFetchFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.license = false;
      });
      break;
    case LicenseActionType.licenseAddSuccess:
      nextState = produce(previousState, (draftState) => {
        const addSuccessAction = action as ReturnType<typeof addLicenseSuccess>;
        draftState.license = addSuccessAction.payload.license;
        draftState.addLicenseSuccessful = true;
      });
      break;
    case LicenseActionType.licenseAddFailure:
      nextState = produce(previousState, (draftState) => {
        const failureAction = action as ReturnType<typeof addLicenseFailure>;
        const { e } = failureAction.payload;
        draftState.expiredLicenseFailure = e.internalErrorCode === InternalErrorCode.LicenseExpired;
        draftState.addLicenseSuccessful = false;
      });
      break;
    case LicenseActionType.licenseUploadStatesClear:
      nextState = produce(previousState, (draftState) => {
        delete draftState.addLicenseSuccessful;
        delete draftState.expiredLicenseFailure;
      });
      break;

    default:
      nextState = previousState;
  }

  return nextState;
};
