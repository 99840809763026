import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { ApiData, SystemData } from '../../model/system-data.model';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  createApiKeyFailure,
  createApiKeySuccess,
  fetchApiKeyFailure,
  fetchApiKeySuccess,
  fetchSystemTimeFailure,
  fetchSystemTimeSuccess,
  SystemActionType,
} from './system.actions';

const systemUrl = 'system';
const apiKeyUrl = 'api-key';
const generateUrl = 'generate';

function* getSystemTime() {
  try {
    const systemTime = yield* call<[boolean, string], SagaRequest<SystemData>>(
      SagaRequestHelper.get,
      true,
      `${systemUrl}`
    );
    yield put(fetchSystemTimeSuccess(systemTime));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchSystemTimeFailure);
  }
}

function* getSystemApiKey() {
  try {
    const apiData = yield* call<[boolean, string], SagaRequest<ApiData>>(
      SagaRequestHelper.get,
      true,
      `${systemUrl}/${apiKeyUrl}`
    );
    yield put(fetchApiKeySuccess(apiData.apiKey));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchApiKeyFailure);
  }
}

function* createSystemApiKey() {
  try {
    const apiData = yield* call<[boolean, string], SagaRequest<ApiData>>(
      SagaRequestHelper.post,
      true,
      `${systemUrl}/${apiKeyUrl}/${generateUrl}`
    );
    yield put(createApiKeySuccess(apiData.apiKey));
  } catch (e: any) {
    yield sagaErrorHandler(e, createApiKeyFailure);
  }
}

export function* fetchSystemTimeSaga() {
  yield takeLatest(SystemActionType.systemTimeFetch, getSystemTime);
}

export function* fetchApiKeySaga() {
  yield takeLatest(SystemActionType.systemApiKeyFetch, getSystemApiKey);
}

export function* createApiKeySaga() {
  yield takeLatest(SystemActionType.systemApiKeyCreate, createSystemApiKey);
}

export default function* systemSaga() {
  yield fork(fetchSystemTimeSaga);
  yield fork(fetchApiKeySaga);
  yield fork(createApiKeySaga);
}
