import WarningIcon from '@mui/icons-material/Warning';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommandsMenuComponent } from '../../../components/commands-menu/commands-menu.component';
import { ContainerInside, ContainerOutside } from '../../../components/structure';
import { Check } from '../../../model';
import { irisSpecialColors } from '../../../theme';
import { irisSpacing } from '../../../theme';

import { getCheckAttributeTypeInfo } from './check-attribute-type-info';

const PREFIX = 'ChecksListItemComponent';
const classes = {
  headlineContainer: `${PREFIX}-headlineContainer`,
  headlineNameContainer: `${PREFIX}-headlineNameContainer`,
  container: `${PREFIX}-container`,
  tableItem: `${PREFIX}-tableItem`,
  dataTable: `${PREFIX}-dataTable`,
  disabled: `${PREFIX}-disabled`,
  warningIcon: `${PREFIX}-warningIcon`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`& .${classes.headlineContainer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  [`& .${classes.headlineNameContainer}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.container}`]: {
    flexWrap: 'nowrap',
  },

  [`& .${classes.tableItem}`]: {
    width: 'inherit',
  },

  [`& .${classes.dataTable}`]: {
    '& tr': {
      verticalAlign: 'text-top',
    },
    '& td': {
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    '& td:nth-of-type(1)': {
      color: irisSpecialColors.attributeCaption,
    },
    '& td:nth-of-type(2)': {
      wordBreak: 'break-word',
    },
  },

  [`& .${classes.disabled}`]: {
    color: theme.palette.text.disabled,
  },

  [`& .${classes.warningIcon}`]: {
    marginRight: theme.spacing(1),
  },
}));

export interface OwnProps {
  check: Check;
  checks: Check[];
  checkPeriodicity: number;
  editCheck: (check: Check) => void;
  moveUp: (check: Check) => void;
  getPredecessor: (checks: Check[], check: Check) => string;
  moveDown: (check: Check) => void;
  getSuccessor: (checks: Check[], check: Check) => string;
  deleteCheckById: (checkId: string) => void;
}

export const ChecksListItemComponent = (props: OwnProps) => {
  const {
    check,
    checks,
    checkPeriodicity,
    editCheck,
    moveUp,
    getPredecessor,
    moveDown,
    getSuccessor,
    deleteCheckById,
  } = props;
  const { checkAttribute } = check;

  const theme = useTheme();
  const { t } = useTranslation(['common', 'data']);
  return (
    <ContainerOutside>
      <StyledPaper>
        <ContainerInside>
          <Box className={classes.headlineContainer}>
            <Box className={classes.headlineNameContainer}>
              {!check.checkAttribute.editable && (
                <Box title={t('license:licenseMissing')}>
                  <WarningIcon
                    className={classes.warningIcon}
                    htmlColor={theme.palette.warning.main}
                  />
                </Box>
              )}
              <Typography
                variant="h3"
                className={!checkAttribute.editable ? classes.disabled : undefined}
              >
                {checkAttribute.name}
              </Typography>
            </Box>
            <Box mb={irisSpacing.h3.mb}>
              <CommandsMenuComponent
                handleEdit={() => editCheck(check)}
                disableEdit={!check.checkAttribute.editable}
                handleMoveUp={() => moveUp(check)}
                disableMoveUp={
                  !check.checkAttribute.editable || getPredecessor(checks, check) === check.id
                }
                handleMoveDown={() => moveDown(check)}
                disableMoveDown={
                  !check.checkAttribute.editable || getSuccessor(checks, check) === check.id
                }
                handleDelete={() => deleteCheckById(check.id)}
              />
            </Box>
          </Box>
          <Grid container className={classes.container}>
            <Grid item className={classes.tableItem}>
              <table className={classes.dataTable}>
                <tbody>
                  <tr>
                    <td>{t('data:checkAttribute.specification')}:</td>
                    <td className={!checkAttribute.editable ? classes.disabled : undefined}>
                      {t([
                        `data:checkAttribute.specificationCodes.${check.checkAttribute.specification}`,
                        `data:checkAttribute.specificationCodes.notFound`,
                      ])}
                    </td>
                  </tr>
                  {getCheckAttributeTypeInfo(checkAttribute, t).map((info) => (
                    <tr key={info.text}>
                      <td>{info.text}:</td>
                      <td className={!checkAttribute.editable ? classes.disabled : undefined}>
                        {info.value || '-'}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>{t('data:check.checkTimes')}:</td>
                    <td className={!checkAttribute.editable ? classes.disabled : undefined}>
                      {!check.afterStart &&
                      !check.afterDisruption &&
                      !check.periodically &&
                      !check.afterProduction &&
                      !check.checkPeriodicity ? (
                        '-'
                      ) : (
                        <>
                          <div>{check.afterStart && t(`data:check.afterStart`)}</div>
                          <div>{check.afterDisruption && t(`data:check.afterDisruption`)}</div>
                          <div>{check.afterProduction && t(`data:check.afterProduction`)}</div>
                          <div>
                            {check.periodically &&
                              t(`data:check.periodically`, { minutes: checkPeriodicity })}
                          </div>
                          <div>
                            {check.checkPeriodicity &&
                              t(`data:check.periodically`, { minutes: check.checkPeriodicity })}
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
        </ContainerInside>
      </StyledPaper>
    </ContainerOutside>
  );
};
