import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { CheckAttributeTypesList } from '../../model';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  CheckAttributeTypesActionType,
  fetchCheckAttributeTypesFailure,
  fetchCheckAttributeTypesSuccess,
} from './check-attribute-types.actions';

const checkAttributeTypesUrl = 'checkAttributeTypes';

function* getCheckAttributeTypes() {
  try {
    const response = yield* call<[boolean, string], SagaRequest<CheckAttributeTypesList>>(
      SagaRequestHelper.get,
      true,
      checkAttributeTypesUrl
    );
    yield put(fetchCheckAttributeTypesSuccess(response));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchCheckAttributeTypesFailure);
  }
}

export function* fetchCheckAttributeTypesSaga() {
  yield takeLatest(
    CheckAttributeTypesActionType.checkAttributeTypesFetchAll,
    getCheckAttributeTypes
  );
}

export default function* checkAttributeTypesSaga() {
  yield fork(fetchCheckAttributeTypesSaga);
}
