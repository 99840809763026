import { Link, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { push } from 'connected-react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { CommandsMenuComponent } from '../../../components/commands-menu/commands-menu.component';
import { CenteredImageInTableComponent } from '../../../components/image/centered-image/centered-image.component';
import { TablePaginationComponent } from '../../../components/table-pagination/table-pagination.component';
import { PaperTable } from '../../../components/table/paper-table.component';
import { AppRoutePath } from '../../../routes/routes';
import {
  articleListSelector,
  deleteArticle,
  duplicateArticle,
  articlesTotalCountSelector,
} from '../../../store';

export interface OwnProps {
  handlePageChange: (newPage: number) => void;
  page: number;
}

export const ArticlesTableComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['table', 'data', 'common']);
  const articles = useSelector(articleListSelector);
  const total = useSelector(articlesTotalCountSelector);

  const goToEditArticle = (id: string) => {
    dispatch(push(`/${AppRoutePath.articles}/${id}/${AppRoutePath.edit}`));
  };

  // Do not remove! Duplicate function will be reactivated with future story. --> https://dev.azure.com/HoefelmeyerWaagen/IRIS/_workitems/edit/5438
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const duplicateArticleById = (id: string) => {
    dispatch(duplicateArticle(id));
  };
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const deleteArticleById = (id: string) => {
    dispatch(deleteArticle(id));
  };

  return (
    <>
      <PaperTable dataTestId="articlesTable" ariaLabel={t('data:article.table')}>
        <TableHead>
          <TableRow>
            <TableCell> </TableCell>
            <TableCell>{t('data:article.number')}</TableCell>
            <TableCell>{t('data:article.name')}</TableCell>
            <TableCell>{t('data:article.nameSuffix')}</TableCell>
            <TableCell>{t('data:article.defaultLine')}</TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {articles && articles.length > 0 ? (
            articles.map((article) => {
              return (
                <TableRow key={article.id}>
                  <TableCell>
                    <CenteredImageInTableComponent
                      imageUrl={article.thumbnailUrl}
                      alttext={t('data:article.image')}
                    />
                  </TableCell>
                  <TableCell>{article.articleNumber}</TableCell>
                  <TableCell>
                    <Link
                      component={NavLink}
                      to={`/${AppRoutePath.articles}/${article.id}/${AppRoutePath.edit}`}
                      underline="hover"
                    >
                      {article.name}
                    </Link>
                  </TableCell>
                  <TableCell>{article.nameSuffix}</TableCell>
                  <TableCell>{article.productionLine?.name}</TableCell>
                  <TableCell>
                    <CommandsMenuComponent
                      handleEdit={() => goToEditArticle(article.id)}
                      // Do not remove! Duplicate function will be reactivated with future story. --> https://dev.azure.com/HoefelmeyerWaagen/IRIS/_workitems/edit/5438
                      // handleDuplicate={() => duplicateArticleById(article.id)}
                      handleDelete={() => deleteArticleById(article.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={4}>
                {articles ? t('table:noMatch') : t('common:noDataAvailable')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </PaperTable>
      <TablePaginationComponent
        count={total || 0}
        page={props.page}
        currentItemsCount={articles?.length || 0}
        onChangePage={(event: unknown, newPage: number) => props.handlePageChange(newPage)}
      />
    </>
  );
};
