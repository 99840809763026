import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  Link,
  Paper,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { push } from 'connected-react-router';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FormattedNumberOutputComponent } from '../../../components/form/formatted-number-output/formatted-number-output.component';
import { MessageFlagComponent } from '../../../components/message-flag/message-flag.component';
import { ProductionDetailsComponent } from '../../../components/production-details/production-details.component';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../components/structure';
import { getFertigPackVStatistics, getSuffix, hasIssue } from '../../../helper';
import { IssueCode, ProductionRun } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import { irisCustomColors } from '../../../theme';
import { getDurationMinutes } from '../../../utils/date-utils';

import { productionRunBoxClasses, ProductionRunBoxRoot } from './production-run-running-box.styles';
import { IconState, ProductionRunStateItemComponent } from './production-run-state-item.component';

export interface OwnProps {
  productionRun: ProductionRun;
  currentTime?: Date;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: irisCustomColors.irisWhite,
    color: irisCustomColors.irisBlack,
    boxShadow: theme.shadows[1],
    fontSize: 18,
    borderRadius: 0,
    maxWidth: 'max-content',
  },
}));

export const ProductionRunRunningBoxComponent = (props: OwnProps) => {
  const { productionRun, currentTime } = props;
  const { t } = useTranslation(['data', 'form']);
  const dispatch = useDispatch();
  const [disruptionMinutes, setDisruptionMinutes] = useState<number | undefined>(undefined);
  const statistics = getFertigPackVStatistics(productionRun);
  const hasTU2Violation = hasIssue(productionRun, IssueCode.FertigPackVTU2Violation);
  const hasTU1Violation = hasIssue(productionRun, IssueCode.FertigPackVTU1Violation);
  const suffix = getSuffix(!!productionRun.article.volumeValue);
  const averageValue = statistics?.checkSamplesAverageVolume
    ? statistics?.checkSamplesAverageVolume
    : statistics?.checkSamplesAverageWeight;
  const standardDeviation = statistics?.checkSamplesStandardVolumeDeviation
    ? statistics?.checkSamplesStandardVolumeDeviation
    : statistics?.checkSamplesStandardDeviation;
  const hasDeviation =
    (!!statistics?.checkSamplesStandardDeviation &&
      statistics?.checkSamplesStandardDeviation > 0) ||
    (!!statistics?.checkSamplesStandardVolumeDeviation &&
      statistics.checkSamplesStandardVolumeDeviation > 0);

  const ref = createRef<HTMLDivElement>();
  const [isOverflow, setOverflow] = useState(false);

  useEffect(() => {
    const started: Date | undefined =
      productionRun.disrupted &&
      productionRun.disruptions.length > 0 &&
      !productionRun.disruptions[0].resolved
        ? new Date(productionRun.disruptions[0].started)
        : undefined;

    if (started && currentTime) {
      const minutes = getDurationMinutes(started, currentTime);
      setDisruptionMinutes(minutes);
    }
  }, [currentTime, productionRun]);

  const onMouseOverHeadline = () => {
    if (ref.current) {
      if (ref.current.clientWidth < ref.current.scrollWidth) {
        setOverflow(true);
      } else {
        setOverflow(false);
      }
    }
  };

  const goToProductionRunRunning = () => {
    dispatch(
      push(
        `/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.running}/${productionRun.id}`
      )
    );
  };

  const fillingStatisticsElement = () => {
    return (
      <div data-testid="fillingStatistics">
        <span className={productionRunBoxClasses.averageWeightSymbolSpacing}>{`x̄`}</span>
        <FormattedNumberOutputComponent value={averageValue} suffix={suffix} />
        {hasDeviation && (
          <>
            <span className={productionRunBoxClasses.deviationSymbolSpacing}>{'| s'}</span>
            <FormattedNumberOutputComponent value={standardDeviation} suffix={suffix} />
          </>
        )}
      </div>
    );
  };

  const tu1CheckPercentage = () => {
    return (
      <div data-testid="percentageNumber">
        <FormattedNumberOutputComponent
          value={statistics?.percentageSamplesTU1}
          decimalScale={2}
          suffix={'%'}
        />
      </div>
    );
  };

  return (
    <ProductionRunBoxRoot>
      <Box data-testid={'runningProductionRunBox_' + productionRun.id}>
        <Box>
          <StyledTooltip
            title={
              isOverflow === true
                ? `${productionRun.orderNumber} | ${productionRun.article.productionLine.name}`
                : ''
            }
            followCursor
            data-testid="orderNumberLineTooltip"
          >
            <Typography
              variant="h2"
              ref={ref}
              className={productionRunBoxClasses.textOverflow}
              data-testid="orderNumberLineHeadline"
              onMouseOver={onMouseOverHeadline}
            >
              {`${productionRun.orderNumber} | ${productionRun.article.productionLine.name}`}
            </Typography>
          </StyledTooltip>
        </Box>
        <ContainerOutsideWithHeader>
          <Paper className={productionRunBoxClasses.paper}>
            <Box
              data-testid={'productionRunBoxBackgroundLayer'}
              sx={{
                backgroundColor: hasTU2Violation ? irisCustomColors.irisRedLight : undefined,
              }}
            >
              <ContainerInside>
                <MessageFlagComponent
                  productionRun={productionRun}
                  disruptionMinutes={disruptionMinutes}
                />
                <Box className={productionRunBoxClasses.productionDetailsBox}>
                  <ProductionDetailsComponent productionRun={productionRun} extended={true} />
                </Box>
                <Box className={productionRunBoxClasses.reverseFlexRow}>
                  <Box className={productionRunBoxClasses.prodStateContainer}>
                    {(statistics?.checkSamplesAverageWeight ||
                      statistics?.checkSamplesAverageVolume) && (
                      <ProductionRunStateItemComponent
                        iconState={
                          statistics.checkSamplesAverageWeightPassed
                            ? IconState.passed
                            : statistics.checkSamplesAverageWeightSufficient
                            ? IconState.sufficient
                            : IconState.failed
                        }
                        caption={t('data:productionRun.fillingStatistics')}
                        textElement={fillingStatisticsElement()}
                      />
                    )}
                    {statistics?.checkSamplesDone && (
                      <ProductionRunStateItemComponent
                        iconState={hasTU1Violation ? IconState.sufficient : IconState.passed}
                        caption={t('data:check.fertigPackVCategories.4')}
                        textElement={tu1CheckPercentage()}
                      />
                    )}
                  </Box>
                  <Box className={productionRunBoxClasses.buttonBox}>
                    <Link
                      className={productionRunBoxClasses.link}
                      component="button"
                      variant="body2"
                      underline="hover"
                      onClick={() => goToProductionRunRunning()}
                      data-testid="details-btn"
                    >
                      {t('form:details')}
                      <ArrowForwardIcon
                        className={productionRunBoxClasses.arrowIcon}
                        fontSize="medium"
                      />
                    </Link>
                  </Box>
                </Box>
              </ContainerInside>
            </Box>
          </Paper>
        </ContainerOutsideWithHeader>
      </Box>
    </ProductionRunBoxRoot>
  );
};
