import React, { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { User, UserRole } from '../model';

import { AppRoutePath } from './routes';

export interface OwnProps extends RouteProps {
  loggedInUser?: User;
  minRole: UserRole;
  changePassword: boolean;
  licenseExpired: boolean;
  visibleWhenLicenseIsExpired: boolean;
}

export const PrivateRoute: FunctionComponent<OwnProps> = ({
  component: Component,
  loggedInUser,
  changePassword,
  minRole,
  licenseExpired,
  visibleWhenLicenseIsExpired,
  ...rest
}) => {
  if (!!loggedInUser) {
    if (changePassword) {
      return <Redirect to={`/${AppRoutePath.changePassword}`} />;
    } else if (licenseExpired) {
      if (loggedInUser.role === UserRole.Admin && visibleWhenLicenseIsExpired) {
        return <Route {...rest} component={Component} />;
      }
    } else if ((loggedInUser.role as Number) <= (minRole as Number)) {
      return <Route {...rest} component={Component} />;
    }
  }
  return <Redirect to={`/${AppRoutePath.login}`} />;
};
