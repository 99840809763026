export interface CheckAttributeTypesList {
  ids: CheckAttributeType[];
}

export enum CheckAttributeType {
  YesNo = 1,
  TrafficLight,
  FertigPackV,
  Weight,
  Temperature,
  Volume,
  FreeText,
  Barcode,
}

export enum CheckAttributeSpecification {
  Visual = 1,
  Haptic,
  Acoustic,
  Smell,
  Taste,
  Numeric,
  Volume,
  Weight,
  CCP,
  CP,
}
