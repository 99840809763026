import { Action } from 'redux';
import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { License } from '../../model';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  LicenseActionType,
  fetchLicenseSuccess,
  fetchLicenseFailure,
  addLicense as addLicenseAction,
  addLicenseSuccess,
  addLicenseFailure,
} from './license.actions';

const licenseUrl = 'license';

function* getLicense() {
  try {
    const license = yield* call<[boolean, string], SagaRequest<License>>(
      SagaRequestHelper.get,
      false,
      licenseUrl
    );
    yield put(fetchLicenseSuccess(license));
  } catch (e: any) {
    yield put(fetchLicenseFailure());
  }
}

function* addLicense(action: Action) {
  const { licenseFile } = (action as ReturnType<typeof addLicenseAction>).payload;
  const formData = new FormData();
  formData.append('License', licenseFile);
  try {
    const license = yield* call<[boolean, string, { body: FormData }], SagaRequest<License>>(
      SagaRequestHelper.post,
      true,
      licenseUrl,
      {
        body: formData,
      }
    );
    yield put(addLicenseSuccess(license));
  } catch (e: any) {
    yield sagaErrorHandler(e, addLicenseFailure);
  }
}

export function* addLicenseSaga() {
  yield takeLatest(LicenseActionType.licenseAdd, addLicense);
}

export function* fetchLicenseSaga() {
  yield takeLatest(LicenseActionType.licenseFetch, getLicense);
}

export default function* licensesSaga() {
  yield fork(fetchLicenseSaga);
  yield fork(addLicenseSaga);
}
