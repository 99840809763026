import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { skipReasonDialogOpenSelector } from '../../store';

import { SkipReasonComponent } from './skip-reason.component';

export const SkipReasonDialogComponent = () => {
  const isSkipReasonDialogOpen = useSelector(skipReasonDialogOpenSelector);

  return (
    <Dialog open={isSkipReasonDialogOpen} maxWidth="md">
      <DialogContent>
        <SkipReasonComponent />
      </DialogContent>
    </Dialog>
  );
};
