import produce from 'immer';
import { Action } from 'redux';

import { ProductionOrder } from '../../model';
import { getProductionTotalsSuccess, ProductionActionType } from '../production/production.actions';
import { changedProductionOverview as changedProductionOverviewAction } from '../production/production.actions';
import {
  addItemToFirstPositionInArray,
  mergeItemIntoArray,
  deleteItemFromArray,
} from '../reducer-utils';

import {
  ProductionOrdersActionType,
  fetchProductionOrdersSuccess,
  fetchProductionOrderSuccess,
  addProductionOrderSuccess,
  editProductionOrderSuccess,
  deleteProductionOrderSuccess,
  changedProductionOrder as changedProductionOrderAction,
  deletedProductionOrder as deletedProductionOrderAction,
} from './production-orders.actions';

export interface ProductionOrdersState {
  fetchingTotal?: boolean;
  fetchingAll?: boolean;
  productionOrders?: ProductionOrder[];
  currentProductionOrder?: ProductionOrder;
  productionOrdersTotal?: number;
}

export const getInitialState = (): ProductionOrdersState => {
  return {};
};

export const productionOrdersReducer = (
  previousState: ProductionOrdersState = getInitialState(),
  action: Action
) => {
  const { type } = action;
  let nextState;

  switch (type) {
    case ProductionActionType.productionGetTotals:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingTotal = true;
      });
      break;
    case ProductionActionType.productionGetTotalsSuccess:
      nextState = produce(previousState, (draftState) => {
        const getTotalsSuccessAction = action as ReturnType<typeof getProductionTotalsSuccess>;
        draftState.productionOrdersTotal =
          getTotalsSuccessAction.payload.productionOverview.planned;
        draftState.fetchingTotal = false;
      });
      break;
    case ProductionActionType.productionGetTotalsFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingTotal = false;
      });
      break;
    case ProductionOrdersActionType.productionOrdersFetchAll:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAll = true;
      });
      break;
    case ProductionOrdersActionType.productionOrdersFetchAllSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchProductionOrdersSuccess>;
        draftState.productionOrders = fetchSuccessAction.payload.productionOrders;
        draftState.fetchingAll = false;
      });
      break;
    case ProductionOrdersActionType.productionOrdersFetchAllFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAll = false;
      });
      break;
    case ProductionOrdersActionType.productionOrderFetch:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentProductionOrder;
      });
      break;
    case ProductionOrdersActionType.productionOrderFetchSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchProductionOrderSuccess>;
        draftState.currentProductionOrder = fetchSuccessAction.payload.productionOrder;
      });
      break;
    case ProductionOrdersActionType.productionOrderAddSuccess:
      nextState = produce(previousState, (draftState) => {
        const addSuccessAction = action as ReturnType<typeof addProductionOrderSuccess>;
        draftState.productionOrders = addItemToFirstPositionInArray(
          draftState.productionOrders,
          addSuccessAction.payload.productionOrder
        );
      });
      break;
    case ProductionOrdersActionType.productionOrderEditSuccess:
      nextState = produce(previousState, (draftState) => {
        const editSuccessAction = action as ReturnType<typeof editProductionOrderSuccess>;
        draftState.productionOrders = addItemToFirstPositionInArray(
          draftState.productionOrders,
          editSuccessAction.payload.productionOrder
        );
      });
      break;
    case ProductionOrdersActionType.productionOrderDeleteSuccess:
      nextState = produce(previousState, (draftState) => {
        const deleteSuccessAction = action as ReturnType<typeof deleteProductionOrderSuccess>;
        if (!draftState.productionOrders) {
          return;
        }
        draftState.productionOrders = deleteItemFromArray(
          draftState.productionOrders,
          deleteSuccessAction.payload.id
        );
      });
      break;
    case ProductionActionType.productionOverviewChanged:
      nextState = produce(previousState, (draftState) => {
        const changedAction = action as ReturnType<typeof changedProductionOverviewAction>;
        const changedProductionOverview = changedAction.payload.productionOverview;
        draftState.productionOrdersTotal = changedProductionOverview.planned;
      });
      break;
    case ProductionOrdersActionType.productionOrderChanged:
      nextState = produce(previousState, (draftState) => {
        const changedOrderAction = action as ReturnType<typeof changedProductionOrderAction>;
        const changedOrder = changedOrderAction.payload.productionOrder;
        draftState.productionOrders = mergeItemIntoArray(draftState.productionOrders, changedOrder);
      });
      break;
    case ProductionOrdersActionType.productionOrderDeleted:
      nextState = produce(previousState, (draftState) => {
        const deletedAction = action as ReturnType<typeof deletedProductionOrderAction>;
        draftState.productionOrders = deleteItemFromArray(
          draftState.productionOrders,
          deletedAction.payload.id
        );
      });
      break;
    default:
      nextState = previousState;
  }

  return nextState;
};
