import { Article } from './article.model';
import { Line } from './line.model';
export interface ProductionOrder {
  id: string;
  order: number;
  orderNumber: string;
  batchNumber?: string;
  amount?: number;
  article: Article;
  productionLine?: Line;
  startable: boolean;
  startErrors: ProductionOrderStartError[];
  testRunAfterStart?: boolean;
  testRunAfterDisruption?: boolean;
  lastModified: string;
}

export enum ProductionOrderStartError {
  MissingArticle = 1,
  MissingProductionLine = 2,
  MissingNominalValue = 3,
  MissingTareValue = 4,
  MissingScale = 5,
  MissingLicense = 6,
  TestrunIncompatible = 7,
  InvalidScaleData = 8,
}
