import { Typography } from '@mui/material';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AddButtonComponent } from '../../components/buttons/add-button.component';
import { LoadingIndicatorComponent } from '../../components/loading-indicator/loading-indicator.component';
import {
  HeaderContainer,
  BodyStructure,
  HeaderStructure,
  PageStructure,
  ContainerOutside,
} from '../../components/structure';
import { DEFAULT_ITEMS_PER_PAGE } from '../../constants';
import { AppRoutePath } from '../../routes/routes';
import { deviceListSelector, devicesFetchingSelector, fetchDevices } from '../../store';

import { DevicesTableComponent } from './devices-table.component';

export const DevicesOverviewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data', 'common']);
  const [page, setPage] = useState<number>(0);
  const loading = useSelector(devicesFetchingSelector);
  const devices = useSelector(deviceListSelector);
  const hasDevices = !!devices && devices.length > 0;

  useEffect(() => {
    dispatch(fetchDevices({ pagination: { page, perPage: DEFAULT_ITEMS_PER_PAGE } }));
  }, [dispatch, page]);

  const goToNewDevice = () => {
    dispatch(push(`/${AppRoutePath.devices}/${AppRoutePath.new}/`));
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <PageStructure>
      <HeaderStructure>
        <HeaderContainer>
          <Typography variant="h1">{t('data:device.deviceManager')}</Typography>
          <AddButtonComponent text={t('data:device.new')} onClick={goToNewDevice} />
        </HeaderContainer>
      </HeaderStructure>
      <BodyStructure>
        <ContainerOutside>
          {loading && !hasDevices ? (
            <LoadingIndicatorComponent entity={t('data:device.devices')} />
          ) : (
            <DevicesTableComponent handlePageChange={handlePageChange} page={page} />
          )}
        </ContainerOutside>
      </BodyStructure>
    </PageStructure>
  );
};
