import { Grid, Paper, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { FormattedNumberOutputComponent } from '../../../components/form/formatted-number-output/formatted-number-output.component';
import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { MessageFlagComponent } from '../../../components/message-flag/message-flag.component';
import { ProductionDetailsComponent } from '../../../components/production-details/production-details.component';
import { ContainerInside, ContainerOutside } from '../../../components/structure';
import { MILLIS_SECOND, WEIGHT_ENTITY } from '../../../constants';
import { getFertigPackVStatistics, getSuffix, hasIssue } from '../../../helper';
import { IssueCode, ProductionRunStatus } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import {
  productionRunRunningSelector,
  fetchProductionRunRunning,
  startWsConnection,
  productionRunsWebsocketConfig,
  calcArticleFertigPackTolerances,
  clearProductionRunCheckResults,
  clearProductionRunRunning,
  fetchSystemTime,
  serverTimeDeltaSelector,
} from '../../../store';
import { irisSpecialColors } from '../../../theme';
import { getPixels, irisSpacing } from '../../../theme';
import { getDurationMinutes } from '../../../utils/date-utils';

import { ProductionRunRunningContentComponent } from './production-run-content.component';
import { ViolationFormComponent } from './production-run-violation.component';

const PREFIX = 'ProductionRunRunningPage';
const classes = {
  container: `${PREFIX}-container`,
  flexColumn: `${PREFIX}-flexColumn`,
  averageRow: `${PREFIX}-averageRow`,
  average: `${PREFIX}-average`,
  font: `${PREFIX}-font`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.container}`]: {
    height: 200 - getPixels(irisSpacing.container.p) * 2,
  },
  [`& .${classes.flexColumn}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.averageRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.average}`]: {
    color: irisSpecialColors.attributeCaption,
    fontSize: '32px',
    fontWeight: 'bold',
  },

  [`& .${classes.font}`]: {
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '36px',
  },
}));

export const ProductionRunRunningPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const productionRunId = id;
  useEffect(() => {
    if (productionRunId) {
      dispatch(fetchProductionRunRunning(productionRunId));
      dispatch(startWsConnection(productionRunsWebsocketConfig));
    }
    return () => {
      dispatch(clearProductionRunRunning());
      dispatch(clearProductionRunCheckResults());
    };
  }, [dispatch, productionRunId]);
  const { t } = useTranslation(['common', 'data']);
  const productionRun = useSelector(productionRunRunningSelector);
  const serverTimeDelta = useSelector(serverTimeDeltaSelector);
  const statistics = productionRun && getFertigPackVStatistics(productionRun);
  const suffix = getSuffix(!!productionRun?.article.volumeValue);
  const [clientTime, setClientTime] = useState<Date>(new Date());
  const [currentTime, setCurrentTime] = useState<Date | undefined>(undefined);
  const [disruptionMinutes, setDisruptionMinutes] = useState<number | undefined>(undefined);

  function refreshTime() {
    setClientTime(new Date());
  }

  useEffect(() => {
    refreshTime();
    const timerId = setInterval(refreshTime, 10 * MILLIS_SECOND);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  useEffect(() => {
    const adjustedTime = serverTimeDelta
      ? new Date(clientTime.valueOf() + serverTimeDelta)
      : clientTime;
    setCurrentTime(adjustedTime);
  }, [serverTimeDelta, clientTime]);

  useEffect(() => {
    if (productionRun?.article.nominalValue && productionRun?.article.densityValue) {
      dispatch(
        calcArticleFertigPackTolerances(
          Number(productionRun.article.nominalValue),
          Number(productionRun.article.densityValue)
        )
      );
    } else if (productionRun?.article.nominalValue)
      dispatch(calcArticleFertigPackTolerances(Number(productionRun.article.nominalValue)));
    if (productionRun?.disrupted) dispatch(fetchSystemTime());
  }, [dispatch, productionRun]);

  useEffect(() => {
    if (productionRun) {
      const started: Date | undefined =
        productionRun.disrupted &&
        productionRun.disruptions.length > 0 &&
        !productionRun.disruptions[0].resolved
          ? new Date(productionRun.disruptions[0].started)
          : undefined;

      if (started && currentTime) {
        const minutes = getDurationMinutes(started, currentTime);
        setDisruptionMinutes(minutes);
      }
    }
  }, [currentTime, productionRun]);

  return (
    <Root>
      <DetailLayoutComponent
        backLinkText={t('data:productionRun.backToOverview')}
        backLinkTarget={`/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.running}/`}
        headlineText={productionRun ? productionRun.orderNumber! : ''}
      >
        {productionRun && (
          <>
            <Grid container spacing={irisSpacing.container.space}>
              {(!!productionRun.fertigPackVData.hasCriticalTU1Violation ||
                hasIssue(productionRun, IssueCode.FertigPackVTU2Violation)) &&
                productionRun.status === ProductionRunStatus.Paused && (
                  <Grid item xs={12}>
                    <ViolationFormComponent productionRun={productionRun} />
                  </Grid>
                )}
              <Grid item xs={12}>
                <Paper sx={{ position: 'relative' }}>
                  <ContainerInside>
                    <MessageFlagComponent
                      productionRun={productionRun}
                      disruptionMinutes={disruptionMinutes}
                    />
                    <ProductionDetailsComponent productionRun={productionRun} />
                  </ContainerInside>
                </Paper>
              </Grid>
              {(statistics?.checkSamplesAverageWeight || statistics?.checkSamplesAverageVolume) && (
                <Grid item xs={12} data-testid="fertigpackVBox">
                  <Paper>
                    <ContainerInside>
                      <Box className={`${classes.container} ${classes.flexColumn}`}>
                        <Typography variant="h3">{t('data:article.FertigPackV')}</Typography>
                        <Box className={classes.averageRow}>
                          <Box className={classes.average}>{'x̄'}</Box>
                          <Box data-testid={'averageValue'} className={classes.font}>
                            <FormattedNumberOutputComponent
                              value={
                                statistics.checkSamplesAverageVolume
                                  ? statistics.checkSamplesAverageVolume
                                  : statistics.checkSamplesAverageWeight
                              }
                            />
                            {` ${suffix}`}
                          </Box>
                        </Box>
                        <Box mt={1} data-testid={'nominalValueTareValue'}>
                          {`${t('data:article.nominalValue')}: ${
                            productionRun.article.volumeValue
                              ? productionRun.article.volumeValue
                              : productionRun.article.nominalValue
                              ? productionRun.article.nominalValue
                              : '-'
                          } ${suffix} | ${t('data:checkAttribute.tareValue')}: ${
                            productionRun.article.tareValue ? productionRun.article.tareValue : '-'
                          } ${WEIGHT_ENTITY}`}
                        </Box>
                      </Box>
                    </ContainerInside>
                  </Paper>
                </Grid>
              )}
            </Grid>
            <ContainerOutside>
              <ProductionRunRunningContentComponent productionRun={productionRun} />
            </ContainerOutside>
          </>
        )}
      </DetailLayoutComponent>
    </Root>
  );
};
