import PrintIcon from '@mui/icons-material/Print';
import { Button } from '@mui/material';
import React from 'react';

export interface OwnProps {
  onClick: () => void;
  disabled?: boolean;
  text?: string;
}
export const PrintButtonComponent = (props: OwnProps) => {
  const { onClick, disabled, text } = props;

  return (
    <Button
      data-testid="printBtn"
      color="secondary"
      variant="contained"
      startIcon={<PrintIcon />}
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};
