import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { ProductionRun } from '../../model';
import { irisSpacing } from '../../theme';
import { CenteredImageProductionDetailsComponent } from '../image/centered-image/centered-image.component';
import { ResponsiveImageComponent } from '../image/responsive-image/responsive-image.component';
import { ProductionDetailsDataComponent } from '../production-details/production-details-data.component';

const PREFIX = 'ProductionDetailsComponent';
const classes = {
  overflow: `${PREFIX}-overflow`,
  limitText: `${PREFIX}-limitText`,
};

const Root = styled('div')((theme) => ({
  '& .MuiTypography-root.MuiTypography-h3': {
    marginBottom: 0,
  },
  [`& .${classes.overflow}`]: {
    overflow: 'hidden',
  },
  [`& .${classes.limitText}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const ExtendedImageContainer = styled('div')((theme) => ({
  minWidth: '150px',
  maxWidth: '150px',
}));

interface OwnProps {
  productionRun: ProductionRun;
  extended?: boolean;
}

export const ProductionDetailsComponent = (props: OwnProps) => {
  const { t } = useTranslation(['common', 'data']);
  const { productionRun, extended } = props;

  return (
    <Root>
      <Grid container direction="row" wrap="nowrap" spacing={irisSpacing.container.p}>
        <Grid item>
          {extended ? (
            <ExtendedImageContainer>
              <ResponsiveImageComponent
                imageUrl={productionRun.article.thumbnailUrl}
                altText={t('data:article.image')}
              />
            </ExtendedImageContainer>
          ) : (
            <CenteredImageProductionDetailsComponent
              imageUrl={productionRun.article.imageUrl}
              alttext={t('data:article.image')}
            />
          )}
        </Grid>
        <Grid item className={classes.overflow}>
          <Typography data-testid="articleName" className={classes.limitText} variant="h3">
            {productionRun.article.name}
          </Typography>
          <Box data-testid="article" className={classes.limitText}>
            {productionRun.article.articleNumber}
            {productionRun.article.nameSuffix ? ' | ' + productionRun.article.nameSuffix : ''}
          </Box>
          <Box>
            <ProductionDetailsDataComponent productionRun={productionRun} extended={extended} />
          </Box>
        </Grid>
      </Grid>
    </Root>
  );
};
