import { createSelector } from 'reselect';

import { AppState } from '..';

export const checkAttributesStateSelector = (state: AppState) => state.checkAttributes;
export const checkAttributeListSelector = createSelector(
  checkAttributesStateSelector,
  (state) => state.checkAttributes
);
export const editableCheckAttributeListSelector = createSelector(
  checkAttributesStateSelector,
  (state) => state.checkAttributes?.filter((ca) => ca.editable)
);
export const currentCheckAttributeSelector = createSelector(
  checkAttributesStateSelector,
  (state) => state.currentCheckAttribute
);
export const checkAttributesTotalCountSelector = createSelector(
  checkAttributesStateSelector,
  (state) => state.total
);

export const checkAttributesFetchingSelector = createSelector(
  checkAttributesStateSelector,
  (state) => !!state.fetchingAll
);
