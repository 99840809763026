import log from 'loglevel';

import { login, logout } from '../auth/auth.service';

import { getRefreshToken } from './../auth/auth.service';
import { HttpError } from './http-error';
import { Options } from './types';
import { buildApiUrl } from './url-builder';

enum ContentTypes {
  json = 'application/json; charset=utf-8',
  csv = 'text/csv',
}

export const createHeadersFromOptions = (options: Options): Headers => {
  const requestHeaders = (options.headers ||
    new Headers({
      Accept: 'application/json',
    })) as Headers;
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && (!options.method || options.method === 'GET')) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json');
  }

  if (options.token) {
    requestHeaders.set('Authorization', `Bearer ${options.token}`);
  }

  return requestHeaders;
};

export const fetchData = (url: string, options: Options = {}) => {
  const requestUrl = buildApiUrl(url);
  const requestHeaders = createHeadersFromOptions(options);

  const promise = fetch(requestUrl, { ...options, headers: requestHeaders });
  return promise.then((response) => {
    const contentType = response.headers.has('content-type')
      ? response.headers.get('content-type')
      : undefined;
    switch (contentType) {
      case ContentTypes.json:
        return readJson(url, options, response);
      case ContentTypes.csv:
        return readBlob(url, options, response);
      default:
        log.debug(`Unknown content-type ${contentType}`, url);
        return readJson(url, options, response);
    }
  });
};

const readJson = (url: string, options: Options = {}, response: Response) => {
  return response
    .text()
    .then((text) => ({
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      body: text as any,
    }))
    .then(({ status, statusText, headers, body }) => {
      let data;
      try {
        if (body) data = JSON.parse(body);
      } catch (e: any) {
        log.error(`Response for ${options.method} ${url} ${status}`, e, body);
      }
      return handleResult(status, statusText, headers, body, data);
    });
};

const readBlob = (url: string, options: Options = {}, response: Response) => {
  return response
    .blob()
    .then((blob) => ({
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
      body: blob as any,
    }))
    .then(({ status, statusText, headers, body }) => {
      let data;
      try {
        data = body;
      } catch (e: any) {
        log.error(`Response for ${options.method} ${url} ${status}`, e, body);
      }
      return handleResult(status, statusText, headers, body, data);
    });
};

const handleResult = (
  status: number,
  statusText: string,
  headers: Headers,
  body: any,
  data: any
) => {
  if (status < 200 || status >= 300) {
    return Promise.reject(
      new HttpError(
        (data && data.message) || statusText,
        status,
        data && data.internalErrorCode,
        data
      )
    );
  }
  return Promise.resolve({ status, headers, body, data });
};

export const requestNewTokens = async (keepLogin?: boolean): Promise<any> => {
  const refreshToken = await getRefreshToken();
  return fetchData('auth/refresh-token', {
    method: 'POST',
    body: JSON.stringify({ refreshToken }),
  })
    .then((response: any) => {
      login(response.data);
    })
    .catch((error: any) => {
      // Clear token and continue with the Promise catch chain
      !keepLogin && logout();
      throw error;
    });
};
