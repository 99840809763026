import { HttpError } from '../../http';
import { ProductionOrder } from '../../model';

export enum ProductionOrdersActionType {
  productionOrdersFetchAll = 'productionOrders/fetchAll/',
  productionOrdersFetchAllSuccess = 'productionOrders/fetchAll/success',
  productionOrdersFetchAllFailure = 'productionOrders/fetchAll/failure',
  productionOrderFetch = 'productionOrder/fetch/',
  productionOrderFetchSuccess = 'productionOrder/fetch/success',
  productionOrderFetchFailure = 'productionOrder/fetch/failure',
  productionOrderAdd = 'productionOrder/add/',
  productionOrderAddSuccess = 'productionOrder/add/success',
  productionOrderAddFailure = 'productionOrder/add/failure',
  productionOrderEdit = 'productionOrder/edit/',
  productionOrderEditSuccess = 'productionOrder/edit/success',
  productionOrderEditFailure = 'productionOrder/edit/failure',
  productionOrderDelete = 'productionOrder/delete/',
  productionOrderDeleteSuccess = 'productionOrder/delete/success',
  productionOrderDeleteFailure = 'productionOrder/delete/failure',
  productionOrderChangeOrder = 'productionOrder/changeOrder',
  productionOrderChangeOrderSuccess = 'productionOrder/changeOrder/success',
  productionOrderChangeOrderFailure = 'productionOrder/changeOrder/failure',

  // websocket events
  productionOrderChanged = 'productionOrder/changed',
  productionOrderDeleted = 'productionOrder/deleted/',
}
export const fetchProductionOrders = () => ({
  type: ProductionOrdersActionType.productionOrdersFetchAll,
});

export const fetchProductionOrdersSuccess = (productionOrders: ProductionOrder[]) => ({
  type: ProductionOrdersActionType.productionOrdersFetchAllSuccess,
  payload: { productionOrders },
});

export const fetchProductionOrdersFailure = () => ({
  type: ProductionOrdersActionType.productionOrdersFetchAllFailure,
});

export const fetchProductionOrder = (id: string) => ({
  type: ProductionOrdersActionType.productionOrderFetch,
  payload: { id },
});

export const fetchProductionOrderSuccess = (productionOrder: ProductionOrder) => ({
  type: ProductionOrdersActionType.productionOrderFetchSuccess,
  payload: { productionOrder },
});

export const fetchProductionOrderFailure = () => ({
  type: ProductionOrdersActionType.productionOrderFetchFailure,
});

export const addProductionOrder = (
  productionOrder: Omit<
    ProductionOrder,
    'id' | 'order' | 'lastModified' | 'status' | 'startable' | 'startErrors'
  >
) => ({
  type: ProductionOrdersActionType.productionOrderAdd,
  payload: { productionOrder },
});

export const addProductionOrderSuccess = (productionOrder: ProductionOrder) => ({
  type: ProductionOrdersActionType.productionOrderAddSuccess,
  payload: { productionOrder },
});

export const addProductionOrderFailure = (e: HttpError) => ({
  type: ProductionOrdersActionType.productionOrderAddFailure,
  payload: { e },
});

export const editProductionOrder = (productionOrder: ProductionOrder) => ({
  type: ProductionOrdersActionType.productionOrderEdit,
  payload: { productionOrder },
});

export const editProductionOrderSuccess = (productionOrder: ProductionOrder) => ({
  type: ProductionOrdersActionType.productionOrderEditSuccess,
  payload: { productionOrder },
});

export const editProductionOrderFailure = (e: HttpError) => ({
  type: ProductionOrdersActionType.productionOrderEditFailure,
  payload: { e },
});

export const deleteProductionOrder = (id: string) => ({
  type: ProductionOrdersActionType.productionOrderDelete,
  payload: { id },
});

export const deleteProductionOrderSuccess = (id: string) => ({
  type: ProductionOrdersActionType.productionOrderDeleteSuccess,
  payload: { id },
});

export const deleteProductionOrderFailure = () => ({
  type: ProductionOrdersActionType.productionOrderDeleteFailure,
});

export const changeProductionOrderOrder = (
  sourceProductionOrderId: string,
  destinationProductionOrderId: string
) => ({
  type: ProductionOrdersActionType.productionOrderChangeOrder,
  payload: { sourceProductionOrderId, destinationProductionOrderId },
});

export const changeProductionOrderOrderSuccess = () => ({
  type: ProductionOrdersActionType.productionOrderChangeOrderSuccess,
});

export const changeProductionOrderOrderFailure = () => ({
  type: ProductionOrdersActionType.productionOrderChangeOrderFailure,
});

// websocket events
export const changedProductionOrder = (productionOrder: ProductionOrder) => ({
  type: ProductionOrdersActionType.productionOrderChanged,
  payload: { productionOrder },
});

export const deletedProductionOrder = (response: { productionOrderId: string }) => ({
  type: ProductionOrdersActionType.productionOrderDeleted,
  payload: { id: response.productionOrderId },
});
