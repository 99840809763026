import { SystemData } from '../../model/system-data.model';

export enum SystemActionType {
  systemTimeFetch = 'systemTime/fetch',
  systemTimeFetchSuccess = 'systemTime/fetch/success',
  systemTimeFetchFailure = 'systemTime/fetch/failure',
  systemApiKeyFetch = 'apiKey/fetch',
  systemApiKeyFetchSuccess = 'apiKey/fetch/success',
  systemApiKeyFetchFailure = 'apiKey/fetch/failure',
  systemApiKeyCreate = 'apiKey/create',
  systemApiKeyCreateSuccess = 'apiKey/create/success',
  systemApiKeyCreateFailure = 'apiKey/create/failure',
  systemApiKeyClear = 'apiKey/clear',
}

export const fetchSystemTime = () => ({
  type: SystemActionType.systemTimeFetch,
});

export const fetchSystemTimeSuccess = (systemData: SystemData) => ({
  type: SystemActionType.systemTimeFetchSuccess,
  payload: { systemData },
});

export const fetchSystemTimeFailure = () => ({
  type: SystemActionType.systemTimeFetchFailure,
});

export const fetchApiKey = () => ({
  type: SystemActionType.systemApiKeyFetch,
});

export const fetchApiKeySuccess = (apiKey: string) => ({
  type: SystemActionType.systemApiKeyFetchSuccess,
  payload: { apiKey },
});

export const fetchApiKeyFailure = () => ({
  type: SystemActionType.systemApiKeyFetchFailure,
});

export const createApiKey = () => ({
  type: SystemActionType.systemApiKeyCreate,
});

export const createApiKeySuccess = (apiKey: string) => ({
  type: SystemActionType.systemApiKeyCreateSuccess,
  payload: { apiKey },
});

export const createApiKeyFailure = () => ({
  type: SystemActionType.systemApiKeyCreateFailure,
});

export const clearApiKey = () => ({
  type: SystemActionType.systemApiKeyClear,
});
