import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import React from 'react';

import { stateItemClasses, StateItemRoot } from './production-run-state-item.styles';

export interface OwnProps {
  iconState: IconState;
  caption: string;
  textElement?: React.ReactNode;
}

export enum IconState {
  failed = 0,
  sufficient = 1,
  passed = 2,
}

export const ProductionRunStateItemComponent = (props: OwnProps) => {
  const { iconState, caption, textElement } = props;

  return (
    <StateItemRoot>
      <Box className={stateItemClasses.prodStateItemContainer} data-testid="iconStatus">
        <Box className={stateItemClasses.iconContainer}>
          <span className={stateItemClasses.whiteBackground}></span>
          <Box className={stateItemClasses.resultIconContainer}>
            {iconState === IconState.failed ? (
              <CloseIcon className={`${stateItemClasses.icon} ${stateItemClasses.iconFailed}`} />
            ) : (
              <CheckIcon
                data-testid="checkIcon"
                fontSize="small"
                className={`${stateItemClasses.icon} ${
                  iconState === IconState.sufficient
                    ? stateItemClasses.iconSufficient
                    : stateItemClasses.iconPassed
                }`}
              />
            )}
          </Box>
        </Box>
        <Box className={stateItemClasses.captionBox}>
          <Typography className={stateItemClasses.caption}>{caption}</Typography>
          {textElement}
        </Box>
      </Box>
    </StateItemRoot>
  );
};
