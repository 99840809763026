import { ListItem, ListItemText } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { SidebarRoute, jobsRoutes } from '../../../routes/routes';

import { listClasses, ListRoot } from './list-styles';

export interface OwnProps {
  route: SidebarRoute;
  subMenuItem?: boolean;
  licenseExpired: boolean;
}

export const DrawerItemComponent = (props: OwnProps) => {
  const { route, subMenuItem, licenseExpired } = props;
  const { t } = useTranslation(['common', 'data', 'license']);

  const getListItem = (route: SidebarRoute, disabled?: boolean) => {
    return (
      <ListItem
        button
        disabled={!!disabled}
        disableTouchRipple={true}
        className={`${subMenuItem ? listClasses.subMenu : listClasses.listItem}`}
      >
        <ListItemText
          className={`${listClasses.listItemText}`}
          primary={t(route.translationKeyName)}
        />
      </ListItem>
    );
  };

  return (
    <ListRoot>
      {licenseExpired && !route.visibleWhenLicenseIsExpired ? (
        <div key={route.translationKeyName} className={`${listClasses.navLink}`}>
          {getListItem(route, true)}
        </div>
      ) : (
        <NavLink
          key={route.translationKeyName}
          activeClassName="active"
          to={`/${route.path}`}
          className={`${listClasses.navLink}`}
          isActive={(match, location) => {
            if (!match) {
              return false;
            }
            if (!match.isExact) {
              const submenuRoutes = jobsRoutes.flatMap((jobRoute) => jobRoute.submenuRoutes);
              const notActive = () => {
                const submenuRoute = submenuRoutes.find((submenuRoute) => {
                  const url = location.pathname;
                  const menuPath = `/${route.path}`;
                  const submenuPath = `/${submenuRoute?.path}`;

                  const isSubmenuItem = menuPath === submenuPath;
                  const matchesSubmenuPath = url === submenuPath;
                  const containsSubmenuPath = url.startsWith(submenuPath);

                  return matchesSubmenuPath || (containsSubmenuPath && !isSubmenuItem);
                });
                return !submenuRoute;
              };

              // Do not mark parent items of submenu entries (or of paths belonging to submenu entries) as active
              return notActive();
            }
            return true;
          }}
        >
          {getListItem(route)}
        </NavLink>
      )}
      {route.submenuRoutes?.map((route) => {
        return (
          <DrawerItemComponent
            key={route.translationKeyName}
            route={route}
            subMenuItem={true}
            licenseExpired={licenseExpired}
          />
        );
      })}
    </ListRoot>
  );
};
