import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useImageLoader } from '../../../hooks/image-loader.hook';
import { backendUrl } from '../../../http';
import { irisCustomColors } from '../../../theme';

const PREFIX = 'ImagePreviewComponent';
const classes = {
  image: `${PREFIX}-image`,
  flexImage: `${PREFIX}-flexImage`,
  placeholder: `${PREFIX}-placeholder`,
  flexPlaceholder: `${PREFIX}-flexPlaceholder`,
};

const StyledImg = styled('img')(({ theme }) => ({
  [`&.${classes.image}`]: {
    maxHeight: '100%',
    maxWidth: '100%',
  },

  [`&.${classes.flexImage}`]: {
    height: '100%',
    width: '100%',
    minHeight: 100,
    minWidth: 100,
    alignSelf: 'center',
  },
}));

const StyledDiv = styled('div')(({ theme }) => ({
  [`&.${classes.placeholder}`]: {
    height: 'inherit',
    width: '455px',
    backgroundColor: irisCustomColors.irisGrayBright,
  },

  [`&.${classes.flexPlaceholder}`]: {
    height: '100%',
    width: '100%',
    minHeight: 200,
    minWidth: 200,
    alignSelf: 'center',
    backgroundColor: irisCustomColors.irisGrayBright,
  },
}));

export interface OwnProps {
  file?: File;
  altText?: string;
  imageUrl?: string;
  flexible?: boolean;
}

export const ImagePreviewComponent = (props: OwnProps) => {
  const { file, altText, imageUrl, flexible } = props;
  const loadedImage = useImageLoader(file);
  const { t } = useTranslation(['form']);

  const getImageSrc = () =>
    loadedImage ? loadedImage : imageUrl ? `${backendUrl}${imageUrl}` : undefined;

  return getImageSrc() ? (
    <StyledImg
      className={flexible ? classes.flexImage : classes.image}
      alt={altText || ''}
      src={getImageSrc()}
    />
  ) : (
    <StyledDiv
      className={flexible ? classes.flexPlaceholder : classes.placeholder}
      title={t('form:noImage')}
    />
  );
};
