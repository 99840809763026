import { Line } from './line.model';

export interface Article {
  id: string;
  articleNumber: string;
  name: string;
  nameSuffix?: string;
  checkPeriodicity: number;
  productionLine?: Line;
  nominalValue?: number;
  tareMode: TareMode;
  tareValue?: number;
  tareSampleSize?: number;
  tareMaxValue?: number;
  tareMinValue?: number;
  volumeValue?: number;
  densityValue?: number;
  lowerTolerance?: number;
  upperTolerance?: number;
  imageUrl?: string;
  thumbnailUrl?: string;
  lastModified: string;
}

export enum TareMode {
  Preset = 1,
  Single = 2,
  OrderedIndividual = 3,
}
