import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { DateInfoComponent } from '../../../components/date-info/date-info.component';
import { InfoboxComponent } from '../../../components/infobox/infobox.component';
import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { ContainerOutside } from '../../../components/structure';
import { Device } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import {
  currentDeviceSelector,
  editDevice,
  fetchDevice,
  lineAlreadySetSelector,
} from '../../../store';
import { irisSpacing } from '../../../theme';

import { DeviceSelectComponent } from './device-select.component';

export const DeviceEditPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(['data']);
  const deviceId = id;
  const currentDevice = useSelector(currentDeviceSelector);
  const lineAlreadySet = useSelector(lineAlreadySetSelector);

  useEffect(() => {
    deviceId && dispatch(fetchDevice(deviceId));
  }, [dispatch, deviceId]);

  const submit = (device: Omit<Device, 'id' | 'lastModified'>) => {
    currentDevice &&
      dispatch(
        editDevice({
          ...device,
          id: currentDevice.id,
          lastModified: currentDevice.lastModified,
        })
      );
  };

  const cancel = () => {
    dispatch(push(`/${AppRoutePath.devices}`));
  };

  return (
    <DetailLayoutComponent
      backLinkText={t('data:device.backToDeviceManager')}
      backLinkTarget={`/${AppRoutePath.devices}`}
      headlineText={t('data:device.edit')}
    >
      {currentDevice && (
        <>
          {lineAlreadySet && (
            <ContainerOutside sx={{ marginBottom: irisSpacing.container.space }}>
              <InfoboxComponent subline={t('data:device.lineAlreadySetErrorText')} type={'error'} />
            </ContainerOutside>
          )}
          <DeviceSelectComponent submit={submit} cancel={cancel} device={currentDevice} />
          <DateInfoComponent dateVal={new Date(currentDevice.lastModified)} />
        </>
      )}
    </DetailLayoutComponent>
  );
};
