import { HttpError } from '../../http/http-error';
import { User, TableDataQuery } from '../../model';

export enum UsersActionType {
  usersFetchAll = 'users/fetchAll',
  usersFetchAllSuccess = 'users/fetchAll/success',
  usersFetchAllFailure = 'users/fetchAll/failure',
  userFetch = 'user/fetch',
  userFetchSuccess = 'user/fetch/success',
  userFetchFailure = 'user/fetch/failure',
  userAdd = 'user/add',
  userAddSuccess = 'user/add/success',
  userAddFailure = 'user/add/failure',
  userEdit = 'user/edit',
  userEditSuccess = 'user/edit/success',
  userEditFailure = 'user/edit/failure',
  userDelete = 'user/delete',
  userDeleteSuccess = 'user/delete/success',
  userDeleteFailure = 'user/delete/failure',
  userResetPassword = 'user/resetPassword',
  userResetPasswordSuccess = 'user/resetPassword/success',
  userResetPasswordFailure = 'user/resetPassword/failure',
  userTempPasswordClear = 'user/clearTempPassword',
  userUsernameAlreadyInUseClear = 'user/clearUsernameAlreadyInUse',
  userRequestResetPassword = 'user/requestResetPassword',
  userRequestResetPasswordSuccess = 'user/requestResetPassword/success',
  userRequestResetPasswordFailure = 'user/requestResetPassword/failure',
  userRequestResetPasswordFailedClear = 'user/requestResetPasswordFailed/clear',
}

export const fetchUsers = (query?: TableDataQuery) => ({
  type: UsersActionType.usersFetchAll,
  payload: {
    query,
  },
});

export const fetchUsersSuccess = (users: User[], total: number) => ({
  type: UsersActionType.usersFetchAllSuccess,
  payload: { users, total },
});

export const fetchUsersFailure = () => ({
  type: UsersActionType.usersFetchAllFailure,
});

export const fetchUser = (id: string) => ({
  type: UsersActionType.userFetch,
  payload: { id },
});

export const fetchUserSuccess = (user: User) => ({
  type: UsersActionType.userFetchSuccess,
  payload: { user },
});

export const fetchUserFailure = () => ({
  type: UsersActionType.userFetchFailure,
});

export const addUser = (user: Omit<User, 'id' | 'lastModified' | 'passwordResetRequested'>) => ({
  type: UsersActionType.userAdd,
  payload: { user },
});

export const addUserSuccess = (user: User) => ({
  type: UsersActionType.userAddSuccess,
  payload: { user },
});

export const addUserFailure = (e: HttpError) => ({
  type: UsersActionType.userAddFailure,
  payload: { e },
});

export const editUser = (user: User) => ({
  type: UsersActionType.userEdit,
  payload: { user },
});

export const editUserSuccess = (user: User) => ({
  type: UsersActionType.userEditSuccess,
  payload: { user },
});

export const editUserFailure = (e: HttpError) => ({
  type: UsersActionType.userEditFailure,
  payload: { e },
});

export const deleteUser = (id: string) => ({
  type: UsersActionType.userDelete,
  payload: { id },
});

export const deleteUserSuccess = (id: string) => ({
  type: UsersActionType.userDeleteSuccess,
  payload: { id },
});

export const deleteUserFailure = () => ({
  type: UsersActionType.userDeleteFailure,
});

export const clearUsernameAlreadyInUse = () => ({
  type: UsersActionType.userUsernameAlreadyInUseClear,
});

export const resetUserPassword = (id: string) => ({
  type: UsersActionType.userResetPassword,
  payload: { id },
});

export const resetUserPasswordSuccess = (password: string) => ({
  type: UsersActionType.userResetPasswordSuccess,
  payload: { password },
});

export const resetUserPasswordFailure = () => ({
  type: UsersActionType.userResetPasswordFailure,
});

export const clearTempUserPassword = () => ({
  type: UsersActionType.userTempPasswordClear,
});

export const requestResetPassword = (username: string) => ({
  type: UsersActionType.userRequestResetPassword,
  payload: { username },
});

export const requestResetPasswordSuccess = () => ({
  type: UsersActionType.userRequestResetPasswordSuccess,
});

export const requestResetPasswordFailure = () => ({
  type: UsersActionType.userRequestResetPasswordFailure,
});

export const clearRequestResetPasswordFailed = () => ({
  type: UsersActionType.userRequestResetPasswordFailedClear,
});
