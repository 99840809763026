import { WebsocketConfig } from './../websockets';
import { changedProductionOrder, deletedProductionOrder } from './production-orders.actions';
export const productionOrdersWebsocketConfig: WebsocketConfig = {
  url: 'production/orders',
  actions: [
    {
      methodName: 'ProductionOrderChanged',
      actionCreator: changedProductionOrder,
    },
    {
      methodName: 'ProductionOrderDeleted',
      actionCreator: deletedProductionOrder,
    },
  ],
};
