import { WebsocketConfig } from './../websockets';
import {
  changedProductionRunRunning,
  changedProductionTestRun,
  finishedProductionRunRunning,
  occuredProductionRunIssue,
} from './production-runs.actions';
export const productionRunsWebsocketConfig: WebsocketConfig = {
  url: 'production/runs',
  actions: [
    {
      methodName: 'ProductionRunChanged',
      actionCreator: changedProductionRunRunning,
    },
    {
      methodName: 'ProductionTestRunChanged',
      actionCreator: changedProductionTestRun,
    },
    {
      methodName: 'ProductionRunFinished',
      actionCreator: finishedProductionRunRunning,
    },
  ],
};

export const productionRunIssuesWebsocketConfig: WebsocketConfig = {
  url: 'production/issues',
  actions: [
    {
      methodName: 'ProductionRunIssueOccured',
      actionCreator: occuredProductionRunIssue,
    },
  ],
};
