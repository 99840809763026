import { push } from 'connected-react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { CheckAttribute } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import { addCheckAttribute } from '../../../store';
import {
  CheckAttributeImages,
  CheckAttributeSelectComponent,
} from '../check-attribute/check-attribute-selection/check-attribute-select.component';

export const CheckAttributeNewPage = () => {
  const dispatch = useDispatch();
  const submit = (
    checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>,
    images?: CheckAttributeImages
  ) => {
    dispatch(
      addCheckAttribute(
        checkAttribute,
        images?.yesNoImage,
        images?.trafficLightGreenImage,
        images?.trafficLightYellowImage,
        images?.trafficLightRedImage,
        images?.freeTextImage
      )
    );
  };

  const cancel = () => {
    dispatch(push(`/${AppRoutePath.checkAttributes}`));
  };

  const { t } = useTranslation(['data']);
  return (
    <DetailLayoutComponent
      backLinkText={t('data:checkAttribute.backToCheckAttributes')}
      backLinkTarget={`/${AppRoutePath.checkAttributes}`}
      headlineText={t('data:checkAttribute.createNew')}
    >
      <CheckAttributeSelectComponent
        submit={submit}
        cancel={cancel}
      ></CheckAttributeSelectComponent>
    </DetailLayoutComponent>
  );
};
