import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerInside } from '../../../components/structure';
import { ProductionRun } from '../../../model';
import { getPixels, irisSpacing } from '../../../theme';

const PREFIX = 'ProductionRunOpenChecksComponent';
const classes = {
  outerBox: `${PREFIX}-outerBox`,
  boldNumber: `${PREFIX}-boldNumber`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`& .${classes.outerBox}`]: {
    height: 100 - getPixels(irisSpacing.container.space) * 2,
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.boldNumber}`]: {
    fontWeight: 'bold',
    fontSize: 40,
    paddingRight: theme.spacing(3),
  },
}));

interface OwnProps {
  productionRun: ProductionRun;
}

export const ProductionRunOpenChecksComponent = (props: OwnProps) => {
  const { productionRun } = props;
  const { t } = useTranslation('data');

  return (
    <StyledPaper data-testid="openChecks">
      <ContainerInside>
        <Box className={classes.outerBox}>
          <Box className={classes.boldNumber} data-testid={'checkCount'}>
            {productionRun.openCheckExecutions.length
              ? productionRun.openCheckExecutions.length
              : 0}
          </Box>
          <Typography>
            {productionRun.openCheckExecutions.length === 1
              ? t('data:productionRun.openCheckExecution')
              : t('data:productionRun.openCheckExecutions')}
          </Typography>
        </Box>
      </ContainerInside>
    </StyledPaper>
  );
};
