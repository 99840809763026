import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { formClasses } from '../../../components/components-styles/form.styles';
import { ContainerInside } from '../../../components/structure';
import { SRA_PREFIX } from '../../../constants';
import { DeviceType, Line, VirtualDevice } from '../../../model';
import { fetchLines, lineListSelector } from '../../../store';
import { irisSpacing } from '../../../theme';

export interface OwnProps {
  device?: VirtualDevice;
  submit: (device: Omit<VirtualDevice, 'id' | 'lastModified' | 'weighingBridges'>) => void;
  cancel: () => void;
}

type FormData = {
  deviceName: string;
  productionLine: Line | undefined;
  sraNumber?: string;
  serialNumber?: string;
};

const getFormValues = (device?: VirtualDevice): FormData => ({
  deviceName: device?.name || '',
  productionLine: device?.productionLine || undefined,
  sraNumber: (device?.sraNumber && device?.sraNumber.replace(SRA_PREFIX, '')) || '',
  serialNumber: device?.serialNumber || '',
});

export const VirtualDeviceFormComponent = (props: OwnProps) => {
  const { device } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(['form', 'data']);
  const { handleSubmit, errors, control, reset, setValue, register } = useForm<FormData>({
    defaultValues: getFormValues(device),
  });
  const lines = useSelector(lineListSelector);

  useEffect(() => {
    dispatch(fetchLines());
  }, [dispatch]);

  useEffect(() => {
    // Autocomplete needs custom register
    register({
      name: 'productionLine',
    });
  });

  useEffect(() => {
    // wait for data to be loaded and reset default values
    device && reset(getFormValues(device));
  }, [device, reset]);

  const onSubmit = handleSubmit((formData: FormData) => {
    props.submit({
      name: formData.deviceName,
      sraNumber: formData.sraNumber ? `${SRA_PREFIX}${formData.sraNumber}` : '',
      serialNumber: formData.serialNumber,
      productionLine: formData.productionLine,
      deviceType: DeviceType.virtual,
    });
  });

  const handleLineChange = (e: React.ChangeEvent<{}>, value: Line | null) => {
    setValue('productionLine', value || undefined);
  };

  return (
    <form onSubmit={onSubmit} className={formClasses.root}>
      <Typography variant="h2">{t('data:device.baseData')}</Typography>
      <Paper data-testid="virtualDeviceBaseData">
        <ContainerInside>
          <Grid container spacing={irisSpacing.input.space}>
            <Grid item xs={12} md={6} lg={4}>
              <Grid container spacing={irisSpacing.input.space} direction={'column'}>
                <Grid item xs={12}>
                  <Controller
                    as={<TextField variant="outlined" fullWidth={true} required />}
                    control={control}
                    name="deviceName"
                    label={t('data:device.name')}
                    inputProps={{
                      'aria-label': t('data:device.name'),
                      'data-testid': 'virtual-device-name-input',
                    }}
                    rules={{
                      required: { value: true, message: t('form:fieldIsRequired') },
                      validate: (value: string) =>
                        value.trim() ? true : (t('form:fieldIsRequired') as string),
                      minLength: {
                        value: 2,
                        message: t('form:minLength', { min: '2' }),
                      },
                      maxLength: {
                        value: 100,
                        message: t('form:maxLength', { max: '100' }),
                      },
                    }}
                    error={errors.deviceName !== undefined}
                    helperText={errors.deviceName && errors.deviceName.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={irisSpacing.input.space}>
                    <Grid item xs={12} xl={6}>
                      <Controller
                        as={<TextField variant="outlined" fullWidth={true} />}
                        control={control}
                        name="serialNumber"
                        label={t('data:device.serialNumber')}
                        inputProps={{
                          'aria-label': t('data:device.serialNumber'),
                          'data-testid': 'serial-number-input',
                        }}
                        rules={{
                          maxLength: {
                            value: 64,
                            message: t('form:maxLength', { max: '64' }),
                          },
                        }}
                        error={errors.serialNumber !== undefined}
                        helperText={errors.serialNumber && errors.serialNumber.message}
                      />
                    </Grid>
                    <Grid item xs={12} xl={6}>
                      <Controller
                        as={
                          <TextField
                            variant="outlined"
                            fullWidth={true}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">{SRA_PREFIX}</InputAdornment>
                              ),
                            }}
                          />
                        }
                        control={control}
                        name="sraNumber"
                        label={t('data:device.sraNumber')}
                        inputProps={{
                          'aria-label': t('data:device.sraNumber'),
                          'data-testid': 'sra-number-input',
                        }}
                        rules={{
                          maxLength: {
                            value: 64,
                            message: t('form:maxLength', { max: '64' }),
                          },
                        }}
                        error={errors.sraNumber !== undefined}
                        helperText={errors.sraNumber && errors.sraNumber.message}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={lines || []}
                    getOptionLabel={(line: Line) => line.name}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    defaultValue={device?.productionLine || undefined}
                    isOptionEqualToValue={(option: Line, value: Line) => option.id === value.id}
                    clearText={t('common:delete')}
                    noOptionsText={t('common:noDataAvailable')}
                    openText={t('common:open')}
                    closeText={t('common:close')}
                    id="productionLineAutocomplete"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="productionLine"
                        label={t('data:line.line')}
                        inputProps={{
                          ...params.inputProps,
                          'data-testid': 'line-input',
                        }}
                        variant="outlined"
                        error={!!errors.productionLine}
                        helperText={errors.productionLine && (errors.productionLine as any).message}
                      />
                    )}
                    onChange={handleLineChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContainerInside>
      </Paper>
      <Box className={formClasses.buttons}>
        <Button
          data-testid="cancel-btn"
          color="secondary"
          variant="contained"
          onClick={() => props.cancel()}
        >
          {t('form:cancel')}
        </Button>
        <Button data-testid="submit-btn" color="primary" variant="contained" type="submit">
          {t('form:submit')}
        </Button>
      </Box>
    </form>
  );
};
