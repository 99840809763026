import { StandardTextFieldProps, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ResultTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<ExtendedProps>(({ theme, bordercolor }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: bordercolor,
      borderWidth: 5,
      borderRadius: 0,
    },
    '&:hover fieldset': {
      borderColor: bordercolor,
      borderWidth: 4,
    },
    '&.Mui-focused fieldset': {
      borderColor: bordercolor,
      borderWidth: 4,
    },
  },
}));

interface ExtendedProps extends StandardTextFieldProps {
  bordercolor: string;
}
