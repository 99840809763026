interface ItemWithId {
  id: string;
}

export const getPredecessor = <T extends ItemWithId>(itemArray: T[] | undefined, item: T) => {
  if (itemArray && itemArray.length > 0) {
    const currentIndex = itemArray.findIndex((foundItem: T) => foundItem.id === item.id);
    if (currentIndex > 0 && itemArray.length > 1) {
      return itemArray[currentIndex - 1].id;
    }
  }
  return item.id;
};

export const getSuccessor = <T extends ItemWithId>(itemArray: T[] | undefined, item: T) => {
  if (itemArray && itemArray.length > 0) {
    const currentIndex = itemArray.findIndex((foundItem: T) => foundItem.id === item.id);
    if (currentIndex !== -1 && itemArray.length > currentIndex + 1) {
      return itemArray[currentIndex + 1].id;
    }
  }
  return item.id;
};
