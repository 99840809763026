import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GridItem } from '../../../../components/grid/grid.item';
import { CheckAttributeSpecification, CheckAttributeType } from '../../../../model';

export interface OwnProps {
  checkAttributeType: CheckAttributeType;
}

export const DescriptionFrameComponent = (props: OwnProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['form', 'data']);
  const { errors, control } = useFormContext();

  const getSpecification = (type: CheckAttributeType): CheckAttributeSpecification => {
    switch (type) {
      case CheckAttributeType.Temperature:
        return CheckAttributeSpecification.Numeric;
      case CheckAttributeType.Volume:
        return CheckAttributeSpecification.Volume;
      case CheckAttributeType.Barcode:
        return CheckAttributeSpecification.Visual;
      default:
        return CheckAttributeSpecification.Weight;
    }
  };

  return (
    <>
      <GridItem s={6} xl={4}>
        {props.checkAttributeType === CheckAttributeType.Weight ||
        props.checkAttributeType === CheckAttributeType.FertigPackV ||
        props.checkAttributeType === CheckAttributeType.Volume ||
        props.checkAttributeType === CheckAttributeType.Temperature ||
        props.checkAttributeType === CheckAttributeType.Barcode ? (
          <FormControl fullWidth={true} id="fixedSpecificationSelect">
            <InputLabel
              id="specification-fixed-label"
              variant="outlined"
              required
              sx={{ color: theme.palette.text.disabled }}
            >
              {t('data:checkAttribute.specification')}
            </InputLabel>
            <Select
              labelId="specification-fixed-label"
              label={`${t('data:checkAttribute.specification')} *`}
              variant="outlined"
              disabled={true}
              inputProps={{
                'aria-label': t('data:checkAttribute.specification'),
                'data-testid': 'fixed-specification-select',
              }}
              defaultValue={getSpecification(props.checkAttributeType)}
            >
              <MenuItem value={getSpecification(props.checkAttributeType)}>
                {t([
                  `data:checkAttribute.specificationCodes.${getSpecification(
                    props.checkAttributeType
                  )}`,
                  `data:checkAttribute.specificationCodes.notFound`,
                ])}
              </MenuItem>
            </Select>
          </FormControl>
        ) : (
          <FormControl error={errors.specification !== undefined} fullWidth={true}>
            <InputLabel id="specification-label" variant="outlined" required>
              {t('data:checkAttribute.specification')}
            </InputLabel>
            <Controller
              as={
                <Select>
                  {Object.keys(CheckAttributeSpecification).map((specification: string) =>
                    Number(specification) ? (
                      <MenuItem value={specification} key={specification}>
                        {t([
                          `data:checkAttribute.specificationCodes.${specification}`,
                          `data:checkAttribute.specificationCodes.notFound`,
                        ])}
                      </MenuItem>
                    ) : null
                  )}
                </Select>
              }
              control={control}
              name="specification"
              label={t('data:checkAttribute.specification')}
              required
              inputProps={{
                'data-testid': 'specification-select',
              }}
              rules={{
                required: { value: true, message: t('form:fieldIsRequired') },
              }}
            />
            {errors.specification && errors.specification.message && (
              <FormHelperText style={{ marginLeft: '14px', marginRight: '14px' }}>
                {errors.specification.message}
              </FormHelperText>
            )}
          </FormControl>
        )}
      </GridItem>
      <GridItem s={6} xl={4}>
        <Controller
          as={<TextField variant="outlined" fullWidth={true} required />}
          control={control}
          name="checkAttributeName"
          defaultValue={''}
          label={t('data:checkAttribute.name')}
          inputProps={{
            'aria-label': t('data:checkAttribute.name'),
            'data-testid': 'checkAttributeName-input',
          }}
          rules={{
            required: { value: true, message: t('form:fieldIsRequired') },
            validate: (value: string) =>
              value.trim() ? true : (t('form:fieldIsRequired') as string),
            minLength: { value: 1, message: t('form:minLength', { min: '1' }) },
            maxLength: { value: 100, message: t('form:maxLength', { max: '100' }) },
          }}
          error={errors.checkAttributeName !== undefined}
          helperText={errors.checkAttributeName && errors.checkAttributeName.message}
        />
      </GridItem>
    </>
  );
};
