import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AxisTickProps } from '@nivo/axes';
import {
  ResponsiveBar,
  BarSvgProps,
  BarDatum,
  ComputedDatum,
  BarTooltipProps,
  ComputedBarDatum,
} from '@nivo/bar';
import { BasicTooltip } from '@nivo/tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer } from 'react-virtualized';

import { getFertigPackVStatistics } from '../../helper';
import { ProductionRun } from '../../model';
import { irisCheckResultColors, irisCustomColors } from '../../theme';
import { getPercentage } from '../../utils/calc-utils';

const PREFIX = 'BarChartComponent';
const classes = {
  barContainer: `${PREFIX}-barContainer`,
};

const height = 250;
export const minWidth = 650;

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.barContainer}`]: {
    height: `${height}px`,
    backgroundColor: irisCustomColors.irisWhite,
    minWidth: `${minWidth}px`,
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
  noCheckSamplesText: string;
  forPrint?: boolean;
}

type nivoLayout = 'horizontal' | 'vertical';

export const BarChartComponent = (props: OwnProps) => {
  const { productionRun, noCheckSamplesText, forPrint } = props;
  const { t } = useTranslation(['data', 'common']);
  const statistics = getFertigPackVStatistics(productionRun);
  const data: BarDatum[] = [];
  const suffix = '%';
  const widthForPrint = 700;

  const getBarProps = (): Omit<BarSvgProps<BarDatum>, 'width'> => {
    const props = {
      data: data,
      layout: 'horizontal' as nivoLayout,
      height: height,
      colors: (d: ComputedDatum<BarDatum>) => {
        switch (d.indexValue as string) {
          case t('data:check.fertigPackVCategories.1'):
            return irisCheckResultColors.failed;
          case t('data:check.fertigPackVCategories.2'):
            return irisCheckResultColors.sufficient;
          case t('data:check.fertigPackVCategories.3'):
            return irisCheckResultColors.passed;
          case t('data:check.fertigPackVCategories.4'):
            return irisCheckResultColors.sufficient;
          case t('data:check.fertigPackVCategories.5'):
            return irisCheckResultColors.failed;
          default:
            return irisCustomColors.irisGrayBright;
        }
      },
      animate: false,
      margin: { top: 0, right: 50, left: 50, bottom: 30 },
      enableGridX: true,
      enableGridY: false,
      axisLeft: {
        tickSize: 0,
      },
      axisBottom: {
        tickSize: 0,
      },
      enableLabel: false,
      maxValue: 100,
      tooltip: (datum: BarTooltipProps<BarDatum>) => (
        <BasicTooltip
          id={datum.indexValue}
          value={`${datum.value}${suffix}`}
          color={datum.color}
          enableChip
        ></BasicTooltip>
      ),
    };

    data.push({
      id: t('data:check.fertigPackVCategories.1') as string,
      value:
        statistics?.checkSamplesTO2 && statistics?.checkSamplesDone
          ? getPercentage(statistics.checkSamplesTO2, statistics.checkSamplesDone)
          : 0,
      absolute: statistics?.checkSamplesTO2 ?? 0,
    });

    data.push({
      id: t('data:check.fertigPackVCategories.2') as string,
      value:
        statistics?.checkSamplesTO1 && statistics?.checkSamplesDone
          ? getPercentage(statistics.checkSamplesTO1, statistics.checkSamplesDone)
          : 0,
      absolute: statistics?.checkSamplesTO1 ?? 0,
    });

    data.push({
      id: t('data:check.fertigPackVCategories.3') as string,
      value:
        statistics?.checkSamplesOK && statistics?.checkSamplesDone
          ? getPercentage(statistics.checkSamplesOK, statistics.checkSamplesDone)
          : 0,
      absolute: statistics?.checkSamplesOK ?? 0,
    });

    data.push({
      id: t('data:check.fertigPackVCategories.4') as string,
      value:
        statistics?.checkSamplesTU1 && statistics?.checkSamplesDone
          ? getPercentage(statistics.checkSamplesTU1, statistics.checkSamplesDone)
          : 0,
      absolute: statistics?.checkSamplesTU1 ?? 0,
    });

    data.push({
      id: t('data:check.fertigPackVCategories.5') as string,
      value:
        statistics?.checkSamplesTU2 && statistics?.checkSamplesDone
          ? getPercentage(statistics.checkSamplesTU2, statistics.checkSamplesDone)
          : 0,
      absolute: statistics?.checkSamplesTU2 ?? 0,
    });

    return props;
  };

  const CustomTick = (
    tick: AxisTickProps<string>,
    xOff: number,
    yOff: number,
    hasSuffix?: boolean
  ) => {
    return (
      <g transform={`translate(${tick.x + xOff},${tick.y + yOff})`}>
        <text textAnchor="middle" dominantBaseline="middle" style={{ fontSize: 18 }}>
          {`${tick.value}${hasSuffix ? suffix : ''}`}
        </text>
      </g>
    );
  };

  const CustomTickLeft = (tick: AxisTickProps<string>) => {
    return CustomTick(tick, -30, 0);
  };

  const CustomTickBottom = (tick: AxisTickProps<string>) => {
    return CustomTick(tick, 0, 20, true);
  };

  const infoText = (text: string, yOffset: number) => {
    return (
      <text
        y={yOffset}
        textAnchor="left"
        dominantBaseline="central"
        style={{
          fontSize: 12,
          pointerEvents: 'none',
        }}
      >
        {text}
      </text>
    );
  };

  const labelContent = (d: ComputedDatum<BarDatum>) => {
    const absolute = d.data.absolute ?? 0;
    const relative = d.value ?? 0;

    return (
      <>
        {infoText(`${absolute} ${t('common:piece')}`, -8)}
        {infoText(`${relative}%`, 8)}
      </>
    );
  };

  const createLabel = (width: number, height: number, y: number, data: ComputedDatum<BarDatum>) => {
    return <g transform={`translate(${width + 8}, ${y + height / 2})`}>{labelContent(data)}</g>;
  };

  // For further info on custom labels via "layers" see: https://github.com/plouc/nivo/issues/146 and https://codesandbox.io/s/m4ro13jjn8
  const CustomLabelLayer = (obj: { bars: ComputedBarDatum<BarDatum>[] }) => {
    return (
      <>
        {obj.bars.map(({ index, width, height, y, data }) => {
          return (
            <g key={`bar${index}`} data-testid="barChartCustomLabel">
              {createLabel(width, height, y, data)}
            </g>
          );
        })}
      </>
    );
  };

  const calcMarginRight = () => {
    const baseValue = statistics?.checkSamplesDone ?? 0;
    const digits = baseValue.toString().length;
    const digitPixel = 7;

    return 49 + digits * digitPixel;
  };

  return (
    <Root data-testid={'barChartComponent'}>
      {statistics?.checkSamplesDone && statistics.checkSamplesDone > 0 ? (
        <div className={classes.barContainer}>
          <AutoSizer>
            {({ height, width }) => (
              <Box
                sx={{ width: forPrint ? widthForPrint : width, height: height }}
                data-testid="barChartBox"
              >
                <ResponsiveBar
                  {...getBarProps()}
                  axisLeft={{ renderTick: CustomTickLeft }}
                  axisBottom={{ renderTick: CustomTickBottom }}
                  borderWidth={0}
                  padding={0.4}
                  margin={{ top: 0, right: calcMarginRight(), bottom: 30, left: 50 }}
                  layers={[
                    'grid',
                    'axes',
                    'bars',
                    'markers',
                    'legends',
                    'annotations',
                    CustomLabelLayer,
                  ]}
                />
              </Box>
            )}
          </AutoSizer>
        </div>
      ) : (
        <Box>{noCheckSamplesText}</Box>
      )}
    </Root>
  );
};
