import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createHashHistory } from 'history';
import { createStore, combineReducers, applyMiddleware, Action } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import { articlesReducer } from './articles/articles.reducer';
import articlesSaga from './articles/articles.saga';
import { authenticationReducer } from './authentication/authentication.reducer';
import authenticationSaga from './authentication/authentication.saga';
import { checkAttributeTypesReducer } from './check-attribute-types/check-attribute-types.reducer';
import checkAttributeTypesSaga from './check-attribute-types/check-attribute-types.saga';
import { checkAttributesReducer } from './check-attributes/check-attributes.reducer';
import checkAttributesSaga from './check-attributes/check-attributes.saga';
import { checksReducer } from './checks/checks.reducer';
import checksSaga from './checks/checks.saga';
import { devicesReducer } from './devices/devices.reducer';
import devicesSaga from './devices/devices.saga';
import { licenseReducer } from './license/license.reducer';
import licenseSaga from './license/license.saga';
import { linesReducer } from './lines/lines.reducer';
import linesSaga from './lines/lines.saga';
import { notificationsReducer } from './notifications/notifications.reducer';
import { productionOrdersReducer } from './production-orders/production-orders.reducer';
import productionOrdersSaga from './production-orders/production-orders.saga';
import { productionRunsReducer } from './production-runs/production-runs.reducer';
import productionRunsSaga from './production-runs/production-runs.saga';
import productionSaga from './production/production.saga';
import { systemReducer } from './system/system.reducer';
import systemSaga from './system/system.saga';
import { usersReducer } from './users/users.reducer';
import usersSaga from './users/users.saga';
import { websocketsMiddleware } from './websockets/websockets.middleware';
import { websocketConnectionsReducer } from './websockets/websockets.reducer';

export const history = createHashHistory();

const reducers = {
  articles: articlesReducer,
  checkAttributeTypes: checkAttributeTypesReducer,
  checkAttributes: checkAttributesReducer,
  checks: checksReducer,
  productionOrders: productionOrdersReducer,
  productionRuns: productionRunsReducer,
  notifications: notificationsReducer,
  lines: linesReducer,
  devices: devicesReducer,
  users: usersReducer,
  authentication: authenticationReducer,
  license: licenseReducer,
  system: systemReducer,
  websocketConnections: websocketConnectionsReducer,
  router: connectRouter(history),
};

function* rootSaga() {
  yield all([
    articlesSaga(),
    checkAttributeTypesSaga(),
    checkAttributesSaga(),
    checksSaga(),
    productionSaga(),
    productionOrdersSaga(),
    productionRunsSaga(),
    linesSaga(),
    devicesSaga(),
    usersSaga(),
    authenticationSaga(),
    licenseSaga(),
    systemSaga(),
  ]);
}

const allReducers = combineReducers({ ...reducers });
export type AppState = ReturnType<typeof allReducers>;

const rootReducer = (state: any, action: Action) => {
  return allReducers(state, action);
};

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history), sagaMiddleware, websocketsMiddleware)
  )
);

sagaMiddleware.run(rootSaga);
