import { push } from 'connected-react-router';
import { Action } from 'redux';
import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { CheckAttribute, tableDataQueryToUrl } from '../../model';
import { AppRoutePath } from '../../routes/routes';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  fetchCheckAttributes,
  fetchCheckAttributesSuccess,
  fetchCheckAttributesFailure,
  fetchCheckAttributeSuccess,
  fetchCheckAttributeFailure,
  addCheckAttribute as addCheckAttributeAction,
  addCheckAttributeSuccess,
  addCheckAttributeFailure,
  editCheckAttribute as editCheckAttributeAction,
  editCheckAttributeSuccess,
  editCheckAttributeFailure,
  CheckAttributesActionType,
  fetchCheckAttribute,
  deleteCheckAttribute as deleteCheckAttributeAction,
  deleteCheckAttributeSuccess,
  deleteCheckAttributeFailure,
  duplicateCheckAttribute as duplicateCheckAttributeAction,
  duplicateCheckAttributeSuccess,
  duplicateCheckAttributeFailure,
} from './check-attributes.actions';

const checkAttributesUrl = 'checkAttributes';
const duplicateUrl = 'copy';

const buildFormData = (
  checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'> & { id?: string },
  yesNoImage?: File,
  trafficLightGreenImage?: File,
  trafficLightYellowImage?: File,
  trafficLightRedImage?: File,
  freeTextImage?: File
) => {
  const formData = new FormData();

  checkAttribute.id && formData.append('id', checkAttribute.id);
  formData.append('checkAttributeType', checkAttribute.checkAttributeType.toString());
  formData.append('name', checkAttribute.name);
  formData.append('specification', checkAttribute.specification.toString());
  checkAttribute.yesNoQuestion && formData.append('yesNoQuestion', checkAttribute.yesNoQuestion);
  checkAttribute.yesNoSuccessAnswer &&
    formData.append('yesNoSuccessAnswer', checkAttribute.yesNoSuccessAnswer ? '1' : '0');
  checkAttribute.yesNoImageUrl && formData.append('yesNoImageUrl', checkAttribute.yesNoImageUrl);
  yesNoImage && formData.append('yesNoImage', yesNoImage);
  checkAttribute.trafficLightDescription &&
    formData.append('trafficLightDescription', checkAttribute.trafficLightDescription);

  checkAttribute.trafficLightGreenDescription &&
    formData.append('trafficLightGreenDescription', checkAttribute.trafficLightGreenDescription);
  checkAttribute.trafficLightGreenImageUrl &&
    formData.append('trafficLightGreenImageUrl', checkAttribute.trafficLightGreenImageUrl);
  trafficLightGreenImage && formData.append('trafficLightGreenImage', trafficLightGreenImage);

  checkAttribute.trafficLightYellowDescription &&
    formData.append('trafficLightYellowDescription', checkAttribute.trafficLightYellowDescription);
  checkAttribute.trafficLightYellowImageUrl &&
    formData.append('trafficLightYellowImageUrl', checkAttribute.trafficLightYellowImageUrl);
  trafficLightYellowImage && formData.append('trafficLightYellowImage', trafficLightYellowImage);

  checkAttribute.trafficLightRedDescription &&
    formData.append('trafficLightRedDescription', checkAttribute.trafficLightRedDescription);
  checkAttribute.trafficLightRedImageUrl &&
    formData.append('trafficLightRedImageUrl', checkAttribute.trafficLightRedImageUrl);
  trafficLightRedImage && formData.append('trafficLightRedImage', trafficLightRedImage);
  checkAttribute.fertigPackVDescription &&
    formData.append('fertigPackVDescription', checkAttribute.fertigPackVDescription);

  checkAttribute.weightDescription &&
    formData.append('weightDescription', checkAttribute.weightDescription);
  if (checkAttribute.weightNominalValue !== undefined)
    formData.append('weightNominalValue', checkAttribute.weightNominalValue.toString());
  if (checkAttribute.weightMinValue !== undefined)
    formData.append('weightMinValue', checkAttribute.weightMinValue.toString());
  if (checkAttribute.weightMaxValue !== undefined)
    formData.append('weightMaxValue', checkAttribute.weightMaxValue.toString());
  if (checkAttribute.weightTareValue !== undefined)
    formData.append('weightTareValue', checkAttribute.weightTareValue.toString());

  checkAttribute.volumeDescription &&
    formData.append('volumeDescription', checkAttribute.volumeDescription.toString());
  if (checkAttribute.volumeNominalValue !== undefined)
    formData.append('volumeNominalValue', checkAttribute.volumeNominalValue.toString());
  if (checkAttribute.volumeDensityValue !== undefined)
    formData.append('volumeDensityValue', checkAttribute.volumeDensityValue.toString());
  if (checkAttribute.volumeMinValue !== undefined)
    formData.append('volumeMinValue', checkAttribute.volumeMinValue.toString());
  if (checkAttribute.volumeMaxValue !== undefined)
    formData.append('volumeMaxValue', checkAttribute.volumeMaxValue.toString());
  if (checkAttribute.volumeTareValue !== undefined)
    formData.append('volumeTareValue', checkAttribute.volumeTareValue.toString());

  checkAttribute.temperatureDescription &&
    formData.append('temperatureDescription', checkAttribute.temperatureDescription);
  if (checkAttribute.temperatureNominalValue !== undefined)
    formData.append('temperatureNominalValue', checkAttribute.temperatureNominalValue.toString());
  if (checkAttribute.temperatureMinValue !== undefined)
    formData.append('temperatureMinValue', checkAttribute.temperatureMinValue.toString());
  if (checkAttribute.temperatureMaxValue !== undefined)
    formData.append('temperatureMaxValue', checkAttribute.temperatureMaxValue.toString());

  checkAttribute.freeTextDescription &&
    formData.append('freeTextDescription', checkAttribute.freeTextDescription);
  checkAttribute.freeTextImageUrl &&
    formData.append('freeTextImageUrl', checkAttribute.freeTextImageUrl);
  freeTextImage && formData.append('freeTextImage', freeTextImage);

  checkAttribute.barcodeDescription &&
    formData.append('barcodeDescription', checkAttribute.barcodeDescription);
  checkAttribute.barcodeType &&
    formData.append('barcodeType', checkAttribute.barcodeType.toString());
  checkAttribute.barcode && formData.append('barcode', checkAttribute.barcode);

  formData.append('sampleSize', checkAttribute.sampleSize.toString());

  return formData;
};

function* getCheckAttributes(action: Action) {
  const { query } = (action as ReturnType<typeof fetchCheckAttributes>).payload;
  const tableDataQueryUrl = tableDataQueryToUrl(query);
  try {
    const response = yield* call<
      [boolean, string],
      SagaRequest<{ data: CheckAttribute[]; metadata: { total: number } }>
    >(SagaRequestHelper.get, true, `${checkAttributesUrl}${tableDataQueryUrl}`);
    yield put(fetchCheckAttributesSuccess(response.data, response.metadata.total));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchCheckAttributesFailure);
  }
}

function* getCheckAttributeById(action: Action) {
  const { id } = (action as ReturnType<typeof fetchCheckAttribute>).payload;
  try {
    const checkAttribute = yield* call<[boolean, string], SagaRequest<CheckAttribute>>(
      SagaRequestHelper.get,
      true,
      `${checkAttributesUrl}/${id}`
    );
    yield put(fetchCheckAttributeSuccess(checkAttribute));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchCheckAttributeFailure);
  }
}

function* addCheckAttribute(action: Action) {
  const {
    checkAttribute,
    yesNoImage,
    trafficLightGreenImage,
    trafficLightYellowImage,
    trafficLightRedImage,
    freeTextImage,
  } = (action as ReturnType<typeof addCheckAttributeAction>).payload;
  const formData = buildFormData(
    checkAttribute,
    yesNoImage,
    trafficLightGreenImage,
    trafficLightYellowImage,
    trafficLightRedImage,
    freeTextImage
  );

  try {
    const checkAttribute = yield* call<
      [boolean, string, { body: FormData }],
      SagaRequest<CheckAttribute>
    >(SagaRequestHelper.post, true, checkAttributesUrl, {
      body: formData,
    });
    yield put(addCheckAttributeSuccess(checkAttribute));
  } catch (e: any) {
    yield sagaErrorHandler(e, addCheckAttributeFailure);
  }
}

function* editCheckAttribute(action: Action) {
  const editAction = action as ReturnType<typeof editCheckAttributeAction>;
  const {
    checkAttribute,
    yesNoImage,
    trafficLightGreenImage,
    trafficLightYellowImage,
    trafficLightRedImage,
    freeTextImage,
  } = editAction.payload;
  const formData = buildFormData(
    checkAttribute,
    yesNoImage,
    trafficLightGreenImage,
    trafficLightYellowImage,
    trafficLightRedImage,
    freeTextImage
  );
  try {
    yield* call<[boolean, string, { body: FormData }], SagaRequest<CheckAttribute>>(
      SagaRequestHelper.put,
      true,
      `${checkAttributesUrl}/${checkAttribute.id}`,
      {
        body: formData,
      }
    );
    yield put(editCheckAttributeSuccess(checkAttribute));
  } catch (e: any) {
    yield sagaErrorHandler(e, editCheckAttributeFailure);
  }
}

function* deleteCheckAttribute(action: Action) {
  const { id } = (action as ReturnType<typeof deleteCheckAttributeAction>).payload;
  try {
    yield* call<[boolean, string, {}], SagaRequest<CheckAttribute>>(
      SagaRequestHelper.delete,
      true,
      `${checkAttributesUrl}/${id}`,
      {}
    );
    yield put(deleteCheckAttributeSuccess(id));
  } catch (e: any) {
    yield sagaErrorHandler(e, deleteCheckAttributeFailure);
  }
}

function* duplicateCheckAttribute(action: Action) {
  try {
    const { id } = (action as ReturnType<typeof duplicateCheckAttributeAction>).payload;
    const checkAttribute = yield* call<
      [boolean, string, { body: string }],
      SagaRequest<CheckAttribute>
    >(SagaRequestHelper.post, true, `${checkAttributesUrl}/${id}/${duplicateUrl}`, {
      body: JSON.stringify((action as ReturnType<typeof duplicateCheckAttributeAction>).payload.id),
    });
    yield put(duplicateCheckAttributeSuccess(checkAttribute));
  } catch (e: any) {
    yield sagaErrorHandler(e, duplicateCheckAttributeFailure);
  }
}

function* redirectOnSuccess() {
  yield put(push(`/${AppRoutePath.checkAttributes}`));
}

export function* fetchCheckAttributesSaga() {
  yield takeLatest(CheckAttributesActionType.checkAttributesFetchAll, getCheckAttributes);
}

export function* fetchCheckAttributeSaga() {
  yield takeLatest(CheckAttributesActionType.checkAttributeFetch, getCheckAttributeById);
}

export function* addCheckAttributeSaga() {
  yield takeLatest(CheckAttributesActionType.checkAttributeAdd, addCheckAttribute);
}

export function* editCheckAttributeSaga() {
  yield takeLatest(CheckAttributesActionType.checkAttributeEdit, editCheckAttribute);
}

export function* redirectCheckAttributeOnSuccessSaga() {
  yield takeLatest(
    [
      CheckAttributesActionType.checkAttributeAddSuccess,
      CheckAttributesActionType.checkAttributeEditSuccess,
    ],
    redirectOnSuccess
  );
}

export function* deleteCheckAttributeSaga() {
  yield takeLatest(CheckAttributesActionType.checkAttributeDelete, deleteCheckAttribute);
}

export function* duplicateCheckAttributeSaga() {
  yield takeLatest(CheckAttributesActionType.checkAttributeDuplicate, duplicateCheckAttribute);
}

export default function* checkAttributesSaga() {
  yield fork(fetchCheckAttributesSaga);
  yield fork(fetchCheckAttributeSaga);
  yield fork(addCheckAttributeSaga);
  yield fork(editCheckAttributeSaga);
  yield fork(redirectCheckAttributeOnSuccessSaga);
  yield fork(deleteCheckAttributeSaga);
  yield fork(duplicateCheckAttributeSaga);
}
