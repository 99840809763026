import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AddButtonComponent } from '../../../components/buttons/add-button.component';
import { GridContainer } from '../../../components/grid/grid.container';
import { GridItem } from '../../../components/grid/grid.item';
import { LoadingIndicatorComponent } from '../../../components/loading-indicator/loading-indicator.component';
import {
  HeaderContainer,
  BodyStructure,
  HeaderStructure,
  PageStructure,
} from '../../../components/structure';
import { AppRoutePath } from '../../../routes/routes';
import {
  getProductionTotals,
  productionRunsRunningTotalCountSelector,
  productionOrdersTotalCountSelector,
  productionRunsFinishedTotalCountSelector,
  startWsConnection,
  productionWebsocketConfig,
  productionRunsTotalFetchingSelector,
  productionOrdersTotalFetchingSelector,
} from '../../../store';

const FlexColumn = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const LinkContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2.5),
  height: '100%',
}));

const InfoContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const ProductionOverviewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);

  const runningProductionRunsCount = useSelector(productionRunsRunningTotalCountSelector);
  const plannedProductionOrdersCount = useSelector(productionOrdersTotalCountSelector);
  const finishedProductionRunsCount = useSelector(productionRunsFinishedTotalCountSelector);
  const loadingRunsTotal = useSelector(productionRunsTotalFetchingSelector);
  const loadingOrdersTotal = useSelector(productionOrdersTotalFetchingSelector);

  useEffect(() => {
    dispatch(getProductionTotals());
    dispatch(startWsConnection(productionWebsocketConfig));
  }, [dispatch]);

  const goToNewProductionOrder = () => {
    dispatch(
      push(
        `/${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}/${AppRoutePath.new}/`
      )
    );
  };

  const goToRunningProductionRuns = () => {
    dispatch(push(`/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.running}/`));
  };

  const goToPlannedProductionOrders = () => {
    dispatch(push(`/${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}/`));
  };

  const goToFinishedProductionRuns = () => {
    dispatch(push(`/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.finished}/`));
  };

  const getLink = (text: string, clickHandler: () => void, testId: string) => {
    return (
      <Link
        component="button"
        variant="body2"
        fontSize="18px"
        underline="hover"
        onClick={clickHandler}
        data-testid={testId}
      >
        <LinkContainer>
          <Typography align="left">{text}</Typography>
          <ArrowForwardIcon fontSize="medium" sx={{ ml: 1 }} />
        </LinkContainer>
      </Link>
    );
  };

  return (
    <PageStructure>
      <HeaderStructure>
        <HeaderContainer>
          <Typography variant="h1">{t('data:productionOrder.productionOrders')}</Typography>
          <AddButtonComponent
            text={t('data:productionOrder.new')}
            onClick={goToNewProductionOrder}
          />
        </HeaderContainer>
      </HeaderStructure>
      <BodyStructure>
        <GridContainer>
          <GridItem s={4}>
            <StyledPaper data-testid="runningProductionRunsInfo">
              <FlexColumn>
                <Typography variant="h4">
                  {t('data:productionRun.runningProductionRunsShort')}
                </Typography>
                <InfoContainer>
                  {loadingRunsTotal ? (
                    <LoadingIndicatorComponent entity={t('data:productionRun.productionRuns')} />
                  ) : (
                    t('data:productionRun.runningProductionRunsCount', {
                      count: runningProductionRunsCount || 0,
                    })
                  )}
                </InfoContainer>
                {getLink(
                  t('data:productionRun.runningProductionRunsShort'),
                  goToRunningProductionRuns,
                  'runningProductionRuns-btn'
                )}
              </FlexColumn>
            </StyledPaper>
          </GridItem>
          <GridItem s={4}>
            <StyledPaper data-testid="plannedProductionOrdersInfo">
              <FlexColumn>
                <Typography variant="h4">
                  {t('data:productionOrder.plannedProductionOrdersShort')}
                </Typography>
                <InfoContainer>
                  {loadingOrdersTotal ? (
                    <LoadingIndicatorComponent
                      entity={t('data:productionOrder.productionOrders')}
                    />
                  ) : (
                    t('data:productionOrder.plannedProductionOrdersCount', {
                      count: plannedProductionOrdersCount || 0,
                    })
                  )}
                </InfoContainer>
                {getLink(
                  t('data:productionOrder.plannedProductionOrdersShort'),
                  goToPlannedProductionOrders,
                  'plannedProductionOrders-btn'
                )}
              </FlexColumn>
            </StyledPaper>
          </GridItem>
          <GridItem s={4}>
            <StyledPaper data-testid="finishedProductionRunsInfo">
              <FlexColumn>
                <Typography variant="h4">
                  {t('data:productionRun.finishedProductionRunsShort')}
                </Typography>
                <InfoContainer>
                  {loadingRunsTotal ? (
                    <LoadingIndicatorComponent entity={t('data:productionRun.productionRuns')} />
                  ) : (
                    t('data:productionRun.finishedProductionRunsCount', {
                      count: finishedProductionRunsCount || 0,
                    })
                  )}
                </InfoContainer>
                {getLink(
                  t('data:productionRun.finishedProductionRunsShort'),
                  goToFinishedProductionRuns,
                  'finishedProductionRuns-btn'
                )}
              </FlexColumn>
            </StyledPaper>
          </GridItem>
        </GridContainer>
      </BodyStructure>
    </PageStructure>
  );
};
