import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DateInfoComponent } from '../../../components/date-info/date-info.component';
import { InfoboxComponent } from '../../../components/infobox/infobox.component';
import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { ContainerOutside } from '../../../components/structure';
import { User } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import {
  editUser,
  currentUserSelector,
  fetchUser,
  usernameAlreadyInUseSelector,
  clearUsernameAlreadyInUse,
} from '../../../store';
import { irisSpacing } from '../../../theme';

import { UserFormComponent } from './user-form.component';

export const UserEditPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(['data']);
  const userId = id;
  const currentUser = useSelector(currentUserSelector);
  const usernameAlreadyInUse = useSelector(usernameAlreadyInUseSelector);
  useEffect(() => {
    userId && dispatch(fetchUser(userId));
    return () => {
      dispatch(clearUsernameAlreadyInUse());
    };
  }, [dispatch, userId]);
  const submit = (user: Omit<User, 'id' | 'lastModified' | 'passwordResetRequested'>) => {
    currentUser &&
      dispatch(
        editUser({
          ...user,
          id: currentUser.id,
          lastModified: currentUser.lastModified,
          passwordResetRequested: currentUser.passwordResetRequested,
        })
      );
  };

  const cancel = () => {
    dispatch(push(`/${AppRoutePath.users}`));
  };

  return (
    <DetailLayoutComponent
      backLinkText={t('data:user.backToOverview')}
      backLinkTarget={`/${AppRoutePath.users}`}
      headlineText={t('data:user.edit')}
    >
      {currentUser && (
        <>
          {usernameAlreadyInUse && (
            <ContainerOutside sx={{ marginBottom: irisSpacing.container.space }}>
              <InfoboxComponent subline={t('data:user.usernameAlreadyInUseError')} type={'error'} />
            </ContainerOutside>
          )}
          <UserFormComponent submit={submit} cancel={cancel} user={currentUser}></UserFormComponent>
          <DateInfoComponent dateVal={new Date(currentUser.lastModified)} />
        </>
      )}
    </DetailLayoutComponent>
  );
};
