import { Line } from './line.model';

export interface Scale {
  id: string;
  name: string;
  productionLine?: Line;
  sraNumber?: string;
  serialNumber?: string;
  lastModified: string;
  deviceType: DeviceType;
  connectedScale?: ConnectedScale;
  weighingBridges?: WeighingBridge[];
  weighingMode?: WeighingMode;
}

export interface VirtualDevice {
  id: string;
  name: string;
  productionLine?: Line;
  sraNumber?: string;
  serialNumber?: string;
  lastModified: string;
  deviceType: DeviceType;
}

export interface Device extends Scale, VirtualDevice {}

export interface DisruptionDevice {
  id: string;
  name: string;
  deviceType: DeviceType;
}

export enum DeviceType {
  scale = 1,
  virtual = 2,
}

export interface ConnectedScale {
  id: string;
  name: string;
}

export interface WeighingBridge {
  firstMinimalLoad?: number;
  firstMaximalLoad?: number;
  firstDigitStep?: number;
  secondMinimalLoad?: number;
  secondMaximalLoad?: number;
  secondDigitStep?: number;
  thirdMinimalLoad?: number;
  thirdMaximalLoad?: number;
  thirdDigitStep?: number;
}

export enum WeighingMode {
  Automatic = 1,
  Manual = 2,
}
