import React from 'react';
import { useTranslation } from 'react-i18next';

import { EventsTableComponent } from '../../../components/events-table/events-table.component';
import { ContainerOutside } from '../../../components/structure';
import { ProductionRun } from '../../../model';

interface OwnProps {
  productionRun: ProductionRun;
}

export const ProductionRunRunningEventsTab = (props: OwnProps) => {
  const { productionRun } = props;
  const { t } = useTranslation(['common', 'data']);

  return (
    <ContainerOutside>
      <EventsTableComponent
        events={productionRun.events}
        noEventsText={t('data:productionRun.events.noCurrentEvents')}
      />
    </ContainerOutside>
  );
};
