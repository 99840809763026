import { CheckAttribute, TableDataQuery } from '../../model';

export enum CheckAttributesActionType {
  checkAttributesFetchAll = 'checkAttributes/fetchAll',
  checkAttributesFetchAllSuccess = 'checkAttributes/fetchAll/success',
  checkAttributesFetchAllFailure = 'checkAttributes/fetchAll/failure',
  checkAttributeFetch = 'checkAttribute/fetch',
  checkAttributeFetchSuccess = 'checkAttribute/fetch/success',
  checkAttributeFetchFailure = 'checkAttribute/fetch/failure',
  checkAttributeAdd = 'checkAttribute/add',
  checkAttributeAddSuccess = 'checkAttribute/add/success',
  checkAttributeAddFailure = 'checkAttribute/add/failure',
  checkAttributeEdit = 'checkAttribute/edit',
  checkAttributeEditSuccess = 'checkAttribute/edit/success',
  checkAttributeEditFailure = 'checkAttribute/edit/failure',
  checkAttributeDelete = 'checkAttribute/delete',
  checkAttributeDeleteSuccess = 'checkAttribute/delete/success',
  checkAttributeDeleteFailure = 'checkAttribute/delete/failure',
  checkAttributeDuplicate = 'checkAttribute/duplicate',
  checkAttributeDuplicateSuccess = 'checkAttribute/duplicate/success',
  checkAttributeDuplicateFailure = 'checkAttribute/duplicate/failure',
}

export const fetchCheckAttributes = (query?: TableDataQuery) => ({
  type: CheckAttributesActionType.checkAttributesFetchAll,
  payload: {
    query,
  },
});

export const fetchCheckAttributesSuccess = (checkAttributes: CheckAttribute[], total: number) => ({
  type: CheckAttributesActionType.checkAttributesFetchAllSuccess,
  payload: { checkAttributes, total },
});

export const fetchCheckAttributesFailure = () => ({
  type: CheckAttributesActionType.checkAttributesFetchAllFailure,
});

export const fetchCheckAttribute = (id: string) => ({
  type: CheckAttributesActionType.checkAttributeFetch,
  payload: { id },
});

export const fetchCheckAttributeSuccess = (checkAttribute: CheckAttribute) => ({
  type: CheckAttributesActionType.checkAttributeFetchSuccess,
  payload: { checkAttribute },
});

export const fetchCheckAttributeFailure = () => ({
  type: CheckAttributesActionType.checkAttributeFetchFailure,
});

export const addCheckAttribute = (
  checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>,
  yesNoImage?: File,
  trafficLightGreenImage?: File,
  trafficLightYellowImage?: File,
  trafficLightRedImage?: File,
  freeTextImage?: File
) => ({
  type: CheckAttributesActionType.checkAttributeAdd,
  payload: {
    checkAttribute,
    yesNoImage,
    trafficLightGreenImage,
    trafficLightYellowImage,
    trafficLightRedImage,
    freeTextImage,
  },
});

export const addCheckAttributeSuccess = (checkAttribute: CheckAttribute) => ({
  type: CheckAttributesActionType.checkAttributeAddSuccess,
  payload: { checkAttribute },
});

export const addCheckAttributeFailure = () => ({
  type: CheckAttributesActionType.checkAttributeAddFailure,
});

export const editCheckAttribute = (
  checkAttribute: CheckAttribute,
  yesNoImage?: File,
  trafficLightGreenImage?: File,
  trafficLightYellowImage?: File,
  trafficLightRedImage?: File,
  freeTextImage?: File
) => ({
  type: CheckAttributesActionType.checkAttributeEdit,
  payload: {
    checkAttribute,
    yesNoImage,
    trafficLightGreenImage,
    trafficLightYellowImage,
    trafficLightRedImage,
    freeTextImage,
  },
});

export const editCheckAttributeSuccess = (checkAttribute: CheckAttribute) => ({
  type: CheckAttributesActionType.checkAttributeEditSuccess,
  payload: { checkAttribute },
});

export const editCheckAttributeFailure = () => ({
  type: CheckAttributesActionType.checkAttributeEditFailure,
});

export const deleteCheckAttribute = (id: string) => ({
  type: CheckAttributesActionType.checkAttributeDelete,
  payload: { id },
});

export const deleteCheckAttributeSuccess = (id: string) => ({
  type: CheckAttributesActionType.checkAttributeDeleteSuccess,
  payload: { id },
});

export const deleteCheckAttributeFailure = () => ({
  type: CheckAttributesActionType.checkAttributeDeleteFailure,
});

export const duplicateCheckAttribute = (id: string) => ({
  type: CheckAttributesActionType.checkAttributeDuplicate,
  payload: { id },
});

export const duplicateCheckAttributeSuccess = (checkAttribute: CheckAttribute) => ({
  type: CheckAttributesActionType.checkAttributeDuplicateSuccess,
  payload: { checkAttribute },
});

export const duplicateCheckAttributeFailure = () => ({
  type: CheckAttributesActionType.checkAttributeDuplicateFailure,
});
