import { styled } from '@mui/material';

import { irisCustomColors } from '../../theme';

export const disruptionDimension = 80;
const hypothenuse = Math.sqrt(2 * Math.pow(2 * disruptionDimension, 2));
const hypothenuseAdjusted = hypothenuse - 10;

const PREFIX = 'MessageFlagComponent';
export const messageFlagClasses = {
  diagonalShadow: `${PREFIX}-diagonalShadow`,
  messageFlagContainer: `${PREFIX}-messageFlagContainer`,
  messageFlagContent: `${PREFIX}-messageFlagContent`,
  whiteBackground: `${PREFIX}-whiteBackground`,
  svgIndex: `${PREFIX}-svgIndex`,
  imageBox: `${PREFIX}-imageBox`,
  testRunBox: `${PREFIX}-testRunBox`,
};

export const StyledDiv = styled('div')(({ theme }) => ({
  [`& .${messageFlagClasses.messageFlagContainer}`]: {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    right: 0,
    borderBottom: `${disruptionDimension}px solid transparent`,
    borderLeft: `${disruptionDimension}px solid transparent`,
    borderRadius: 0,
  },

  [`& .${messageFlagClasses.messageFlagContent}`]: {
    zIndex: 6,
    position: 'absolute',
    top: 0,
    right: 0,
    height: disruptionDimension * 1.25,
    width: disruptionDimension * 1.25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${messageFlagClasses.diagonalShadow}`]: {
    zIndex: 4,
    position: 'absolute',
    top: 0,
    right: 0,
    width: hypothenuseAdjusted,
    boxShadow: `0px 0px 10px 1px ${irisCustomColors.irisBlack}`,
    marginTop: disruptionDimension,
    marginRight: disruptionDimension - hypothenuseAdjusted / 2,
    transform: 'rotate(225deg)',
  },

  [`& .${messageFlagClasses.whiteBackground}`]: {
    minHeight: '26px',
    minWidth: '26px',
    backgroundColor: irisCustomColors.irisWhite,
    borderRadius: '50%',
    zIndex: 1,
    position: 'absolute',
  },

  [`& .${messageFlagClasses.svgIndex}`]: {
    zIndex: 4,
    position: 'absolute',
  },

  [`& .${messageFlagClasses.imageBox}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '18px',
    marginTop: '16px',
  },

  [`& .${messageFlagClasses.testRunBox}`]: {
    marginLeft: theme.spacing(1),
  },
}));
