import { push } from 'connected-react-router';
import { Action } from 'redux';
import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { Line } from '../../model';
import { tableDataQueryToUrl } from '../../model';
import { AppRoutePath } from '../../routes/routes';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  LinesActionType,
  fetchLinesSuccess,
  fetchLinesFailure,
  fetchLineSuccess,
  fetchLineFailure,
  addLine as addLineAction,
  addLineSuccess,
  addLineFailure,
  editLine as editLineAction,
  editLineSuccess,
  editLineFailure,
  deleteLine as deleteLineAction,
  deleteLineSuccess,
  deleteLineFailure,
  fetchLine,
  fetchLines,
} from './lines.actions';

const linesUrl = 'lines';

function* getLines(action: Action) {
  const { query } = (action as ReturnType<typeof fetchLines>).payload;
  const tableDataQueryUrl = tableDataQueryToUrl(query);
  try {
    const response = yield* call<
      [boolean, string],
      SagaRequest<{ data: Line[]; metadata: { total: number } }>
    >(SagaRequestHelper.get, true, `${linesUrl}${tableDataQueryUrl}`);
    yield put(fetchLinesSuccess(response.data, response.metadata.total));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchLinesFailure);
  }
}

function* getLineById(action: Action) {
  const { id } = (action as ReturnType<typeof fetchLine>).payload;
  try {
    const line = yield* call<[boolean, string], SagaRequest<Line>>(
      SagaRequestHelper.get,
      true,
      `${linesUrl}/${id}`
    );
    yield put(fetchLineSuccess(line));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchLineFailure);
  }
}

function* addLine(action: Action) {
  try {
    const line = yield* call<[boolean, string, { body: string }], SagaRequest<Line>>(
      SagaRequestHelper.post,
      true,
      linesUrl,
      {
        body: JSON.stringify((action as ReturnType<typeof addLineAction>).payload.line),
      }
    );
    yield put(addLineSuccess(line));
  } catch (e: any) {
    yield sagaErrorHandler(e, addLineFailure);
  }
}

function* editLine(action: Action) {
  const editAction = action as ReturnType<typeof editLineAction>;
  const { line } = editAction.payload;
  try {
    yield* call<[boolean, string, { body: string }], SagaRequest<Line>>(
      SagaRequestHelper.put,
      true,
      `${linesUrl}/${line.id}`,
      {
        body: JSON.stringify(editAction.payload.line),
      }
    );
    yield put(editLineSuccess(line));
  } catch (e: any) {
    yield sagaErrorHandler(e, editLineFailure);
  }
}

function* deleteLine(action: Action) {
  const { id } = (action as ReturnType<typeof deleteLineAction>).payload;
  try {
    yield* call<[boolean, string, {}], SagaRequest<Line>>(
      SagaRequestHelper.delete,
      true,
      `${linesUrl}/${id}`,
      {}
    );
    yield put(deleteLineSuccess(id));
  } catch (e: any) {
    yield sagaErrorHandler(e, deleteLineFailure);
  }
}

function* redirectOnSuccess() {
  yield put(push(`/${AppRoutePath.lines}`));
}

export function* redirectLineOnSuccessSaga() {
  yield takeLatest(
    [LinesActionType.lineAddSuccess, LinesActionType.lineEditSuccess],
    redirectOnSuccess
  );
}

export function* fetchLinesSaga() {
  yield takeLatest(LinesActionType.linesFetchAll, getLines);
}

export function* addLineSaga() {
  yield takeLatest(LinesActionType.lineAdd, addLine);
}

export function* fetchLineSaga() {
  yield takeLatest(LinesActionType.lineFetch, getLineById);
}

export function* editLineSaga() {
  yield takeLatest(LinesActionType.lineEdit, editLine);
}

export function* deleteLineSaga() {
  yield takeLatest(LinesActionType.lineDelete, deleteLine);
}

export default function* linesSaga() {
  yield fork(fetchLinesSaga);
  yield fork(addLineSaga);
  yield fork(redirectLineOnSuccessSaga);
  yield fork(fetchLineSaga);
  yield fork(editLineSaga);
  yield fork(deleteLineSaga);
}
