import LockIcon from '@mui/icons-material/Lock';
import { Box, Link, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { dataTableClasses, DataTableRoot } from '../../../components/data-table/data-table.styles';
import { ContainerInside } from '../../../components/structure';
import {
  tempUserPasswordSelector,
  resetUserPassword,
  clearTempUserPassword,
} from '../../../store/users';

export interface OwnProps {
  userId: string;
  username: string;
}

const PREFIX = 'UserAccessDataComponent';
const classes = {
  linkButton: `${PREFIX}-linkButton`,
  linkIcon: `${PREFIX}-linkIcon`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.linkButton}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.linkIcon}`]: {
    marginLeft: '-4px',
    marginRight: '4px',
  },
}));

export const UserAccessDataComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { userId, username } = props;
  const { t } = useTranslation(['form', 'data']);
  const tempPassword = useSelector(tempUserPasswordSelector);

  useEffect(() => {
    return () => {
      dispatch(clearTempUserPassword());
    };
  }, [dispatch]);

  const generatePassword = (id: string) => {
    dispatch(resetUserPassword(userId));
  };

  return (
    <Root>
      <DataTableRoot>
        <Typography variant="h2">{t('data:user.accessData')}</Typography>
        <Paper>
          <ContainerInside>
            {tempPassword && (
              <>
                <Typography variant="body2">{t('data:user.accessDataInstruction')}</Typography>
                <Box pt={1} pb={1}>
                  <table className={dataTableClasses.dataTable}>
                    <tbody>
                      <tr>
                        <td>{t('data:user.username')}:</td>
                        <td>{username}</td>
                      </tr>
                      <tr>
                        <td>{t('data:user.password')}:</td>
                        <td>{tempPassword}</td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
              </>
            )}
            <Box>
              <Link
                className={classes.linkButton}
                component="button"
                type="button"
                onClick={() => generatePassword(userId)}
                underline="hover"
              >
                <Box className={classes.linkIcon}>
                  <LockIcon />
                </Box>
                <Typography variant="body2">{t('data:user.generateAccessData')}</Typography>
              </Link>
            </Box>
          </ContainerInside>
        </Paper>
      </DataTableRoot>
    </Root>
  );
};
