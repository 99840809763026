import CloseIcon from '@mui/icons-material/HighlightOff';
import PlayIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FormRoot, formClasses } from '../../../components/components-styles/form.styles';
import { InfoboxComponent } from '../../../components/infobox/infobox.component';
import { ContainerInside } from '../../../components/structure';
import { getFertigPackVStatistics, hasIssue } from '../../../helper';
import { IssueCode, ProductionRun } from '../../../model';
import { continueProductionRun, stopProductionRun } from '../../../store';
import { irisSpacing } from '../../../theme';

interface OwnProps {
  productionRun: ProductionRun;
}

type FormData = {
  reason: string;
};

const getFormValues = (): FormData => ({
  reason: '',
});

enum Action {
  Stop = 1,
  Continue = 2,
}

export const ViolationFormComponent = (props: OwnProps) => {
  const { productionRun } = props;
  const { t, i18n } = useTranslation(['form', 'data']);
  const dispatch = useDispatch();
  const { handleSubmit, errors, control } = useForm<FormData>({
    defaultValues: getFormValues(),
  });
  const [action, setAction] = useState<Action | undefined>(undefined);

  const statistics = getFertigPackVStatistics(productionRun);
  const tu1 = !!productionRun.fertigPackVData.hasCriticalTU1Violation;
  const tu2 = hasIssue(productionRun, IssueCode.FertigPackVTU2Violation);
  const hasViolation = tu1 || tu2;
  const tu1Percentage = statistics?.percentageSamplesTU1;

  const requiredValidation =
    action === Action.Continue ? { value: true, message: t('form:fieldIsRequired') } : undefined;
  const maxLengthValidation = action === Action.Continue ? 973 : 1000;

  const stopRun = handleSubmit(({ reason }) => {
    dispatch(stopProductionRun(productionRun.id, reason));
  });

  const continueRun = handleSubmit(({ reason }) => {
    dispatch(continueProductionRun(productionRun.id, reason));
  });

  useEffect(() => {
    switch (action) {
      case Action.Continue:
        setAction(undefined);
        continueRun();
        break;
      case Action.Stop:
        setAction(undefined);
        stopRun();
        break;
      default:
        return;
    }
  }, [action, continueRun, stopRun]);

  const errorBoxHeadline = tu2
    ? t('data:productionRun.tu2.violation')
    : t('data:productionRun.tu1.violation');

  const errorBoxSubline = tu2
    ? t('data:productionRun.tu2.resultBelowMinimum')
    : tu1Percentage
    ? t('data:productionRun.tu1.currentPercentage', {
        percentage: tu1Percentage.toLocaleString(i18n.language),
      })
    : t('data:productionRun.tu1.currentPercentageFallback');

  const infoText = tu2
    ? t('data:productionRun.tu2.continuationNote')
    : t('data:productionRun.tu1.continuationNote');

  const infoTextBold = tu2
    ? t('data:productionRun.tu2.notForSale')
    : t('data:productionRun.tu1.notForSale');

  return (
    <>
      {hasViolation && (
        <FormRoot>
          <Paper data-testid={'productionRunViolationForm'}>
            <ContainerInside>
              <Grid container spacing={irisSpacing.input.space} flexDirection={'column'}>
                <Grid item>
                  <InfoboxComponent
                    type="error"
                    headline={errorBoxHeadline}
                    subline={errorBoxSubline}
                    direction={'row'}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {`${infoText} `}
                    <b>{infoTextBold}</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <form>
                    <Controller
                      as={<TextField />}
                      control={control}
                      name="reason"
                      label={t('data:productionRun.reason')}
                      placeholder={t('form:multilineTextPlaceholder')}
                      multiline
                      rows={2}
                      fullWidth
                      variant="outlined"
                      inputProps={{
                        'data-testid': 'reason-input',
                      }}
                      rules={{
                        required: requiredValidation,
                        maxLength: {
                          value: maxLengthValidation,
                          message: t('form:maxLength', { max: `${maxLengthValidation}` }),
                        },
                      }}
                      error={errors.reason !== undefined}
                      helperText={errors.reason && errors.reason.message}
                    />
                    <Box className={formClasses.buttonsInside}>
                      <Button
                        data-testid="continue-btn"
                        type="button"
                        color="secondary"
                        variant="contained"
                        startIcon={<PlayIcon />}
                        onClick={() => setAction(Action.Continue)}
                      >
                        {t('data:productionRun.continueProduction')}
                      </Button>

                      <Button
                        data-testid="stop-btn"
                        type="button"
                        color="primary"
                        variant="contained"
                        startIcon={<CloseIcon />}
                        onClick={() => setAction(Action.Stop)}
                      >
                        {t('data:productionRun.stopProduction')}
                      </Button>
                    </Box>
                  </form>
                </Grid>
              </Grid>
            </ContainerInside>
          </Paper>
        </FormRoot>
      )}
    </>
  );
};
