import { createTheme } from '@mui/material/styles';
import { palette } from '@mui/system';

const irisSpacingFactor: number = 8;
export const irisSidebarWidth = 260;
export const irisBoxShadowLevel = 5;
const irisBoxShadow = createTheme().shadows[irisBoxShadowLevel];

export const getPixels = (spacing: number) => spacing * irisSpacingFactor;

// Three breakpoints are considered at 900px (MUI default: md),
// 1200px (MUI default: lg) and 1536px (MUI default: xl)
// resulting in four possible screen categories:
// - 'small'  --> MUI: xs + sm (0px - 899px)
// - 'medium' --> MUI: md (900px - 1199px)
// - 'large'  --> MUI: lg (1200px - 1535px)
// - 'huge'   --> MUI: xl (1536px - ∞)
// Important: 'medium' and 'large' only differ in open or closed sidebar component
export enum Screen {
  small = 'xs',
  medium = 'md',
  large = 'lg',
  huge = 'xl',
}

export const sidebarBreakpoint = Screen.large;

export const responsiveness = {
  grid: {
    page: {
      space: {
        x: 7,
        y: 7,
      },
    },
    inputPaper: {
      space: {
        x: 4,
        y: 4,
      },
    },
  },
  page: {
    padding: {
      x: 7.5,
      y: 5,
    },
    width: {
      min: 900, // MUI default: md
      max: 1920,
    },
  },
};

export const irisSpacing = {
  h1: {
    mt: 9,
    mb: 9,
  },
  h2: {
    mt: 7,
    mb: 3,
  },
  h3: {
    mt: 0,
    mb: 2,
  },
  button: {
    mt: 3,
    mb: 3,
    space: 3,
    boxed: {
      mt: 4, // inside boxes - e.g. login form
    },
  },
  input: {
    space: 4,
  },
  structure: {
    m: 0,
    p: 2,
  },
  container: {
    space: 7,
    p: 3,
  },
};

export const irisCustomColors = {
  irisBlack: '#111111',
  irisWhite: '#FFFFFF',

  irisGreen: '#96C11F',
  irisGreenLight: '#EAF2D2',

  irisYellow: '#FFDB29',
  irisYellowLight: '#FFF8D4',

  irisRed: '#F92626',
  irisRedLight: '#FED3D3',

  irisGold: '#AB9579',
  irisGoldLight: '#DDD4C9',

  irisBlueGray: '#284755',

  irisGrayDimmed: '#666666',
  irisGray: '#CCCCCC',
  irisGrayLight: '#EEEEEE',
  irisGrayBright: '#EFEFEF',
};

const irisFunctionalColors = {
  text: irisCustomColors.irisBlack,
  primary: irisCustomColors.irisGreen,
  onPrimary: irisCustomColors.irisWhite,
  secondary: irisCustomColors.irisBlueGray,
  onSecondary: irisCustomColors.irisWhite,
  info: irisCustomColors.irisGreen,
  disabled: irisCustomColors.irisGray,
  background: irisCustomColors.irisGrayBright,
  paper: irisCustomColors.irisWhite,
};

export const irisCheckResultColors = {
  passed: irisCustomColors.irisGreen,
  sufficient: irisCustomColors.irisYellow,
  failed: irisCustomColors.irisRed,
  unrated: irisCustomColors.irisBlueGray,
  skipped: irisCustomColors.irisGold,
};

export const irisContainerColors = {
  tableLine: irisCustomColors.irisGray,
  tableHeader: irisCustomColors.irisGoldLight,
  tableBorder: irisCustomColors.irisGrayLight,
  boxBorder: irisCustomColors.irisGrayLight,
};

export const irisSpecialColors = {
  attributeCaption: irisCustomColors.irisGrayDimmed,
};

const irisFontSizes = {
  h1: '2.5rem',
  h2: '2.125rem',
  h3: '1.75rem',
  h4: '1.375rem',
  h5: '1rem',
  h6: '1rem',
  body1: '1rem',
  body2: '1rem',
  button: '1rem',
};

const irisPalette = {
  ...palette,
  text: { primary: irisFunctionalColors.text, disabled: irisFunctionalColors.disabled },
  primary: { main: irisFunctionalColors.primary },
  secondary: { main: irisFunctionalColors.secondary },
  success: { main: irisCustomColors.irisGreen, light: irisCustomColors.irisGreenLight },
  warning: { main: irisCustomColors.irisYellow, light: irisCustomColors.irisYellowLight },
  error: { main: irisCustomColors.irisRed, light: irisCustomColors.irisRedLight },
  info: { main: irisFunctionalColors.info },
  action: {
    disabled: irisFunctionalColors.disabled,
  },
  background: {
    default: irisFunctionalColors.background,
  },
};

export const irisTheme = createTheme({
  spacing: irisSpacingFactor,
  palette: irisPalette,
  typography: {
    h1: {
      fontSize: irisFontSizes.h1,
      fontWeight: 900,
    },
    h2: {
      fontSize: irisFontSizes.h2,
      fontWeight: 900,
    },
    h3: {
      fontSize: irisFontSizes.h3,
      fontWeight: 900,
    },
    h4: {
      fontSize: irisFontSizes.h4,
      fontWeight: 900,
    },
    h5: {
      fontSize: irisFontSizes.h5,
      fontWeight: 900,
    },
    h6: {
      fontSize: irisFontSizes.h6,
    },
    body1: {
      fontSize: irisFontSizes.body1,
    },
    body2: {
      fontSize: irisFontSizes.body2,
    },
    button: {
      fontSize: irisFontSizes.button,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: false,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '11px 20px',
          borderRadius: 0,
          fontWeight: 900,
          boxShadow: irisBoxShadow,
        },
        containedPrimary: {
          color: irisFunctionalColors.onPrimary,
          '&:disabled': {
            color: irisFunctionalColors.onPrimary,
          },
        },
        containedSecondary: {
          color: irisFunctionalColors.onSecondary,
          '&:disabled': {
            color: irisFunctionalColors.onSecondary,
          },
        },
        outlinedSecondary: {
          color: irisFunctionalColors.secondary,
          backgroundColor: irisFunctionalColors.onSecondary,
          borderColor: irisFunctionalColors.secondary,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: irisFunctionalColors.primary,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: { backgroundColor: irisFunctionalColors.background },
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: irisCustomColors.irisBlack,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiPaper-root': {
            backgroundColor: irisFunctionalColors.paper,
            borderRadius: 0,
            boxShadow: irisBoxShadow,
            border: '0px',
          },
          '&.MuiAlert-root': {
            '&.MuiAlert-filledError': {
              backgroundColor: irisPalette.error.main,
            },
            '&.MuiAlert-filledSuccess': {
              backgroundColor: irisPalette.success.main,
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: irisFunctionalColors.primary,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: irisContainerColors.tableLine,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: irisContainerColors.tableHeader,
        },
      },
    },
    MuiTableRow: {
      defaultProps: {
        hover: true,
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.MuiTypography-h1': {
            marginTop: 0,
            marginBottom: 0,
          },
          '&.MuiTypography-h2': {
            marginTop: getPixels(irisSpacing.h2.mt),
            marginBottom: getPixels(irisSpacing.h2.mb),
          },
          '&.MuiTypography-h3': {
            marginTop: getPixels(irisSpacing.h3.mt),
            marginBottom: getPixels(irisSpacing.h3.mb),
          },
          '&.MuiTypography-h5': {
            lineHeight: `calc(${irisFontSizes.h5} + 8px)`,
          },
          '&.MuiTypography-h6': {
            lineHeight: `calc(${irisFontSizes.h6} + 8px)`,
          },
          '&.MuiTypography-body1': {
            lineHeight: `calc(${irisFontSizes.body1} + 8px)`,
          },
        },
      },
    },
  },
});
