import { Box, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formClasses, FormRoot } from '../../../../components/components-styles/form.styles';
import { FormattedTextFieldComponent } from '../../../../components/formatted-text-field/formatted-text-field.component';
import { GridContainer, GridLevel } from '../../../../components/grid/grid.container';
import { GridItem } from '../../../../components/grid/grid.item';
import { InfoboxComponent } from '../../../../components/infobox/infobox.component';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../../components/structure';
import { DENSITY_ENTITY, VOLUME_ENTITY, WEIGHT_ENTITY } from '../../../../constants';
import { CheckAttribute, CheckAttributeSpecification, CheckAttributeType } from '../../../../model';

import { ButtonsFormComponent } from './buttons-form.component';
import { DescriptionFrameComponent } from './description-frame.component';
import { SampleSizeComponent } from './sample-size.component';

const PREFIX = 'VolumeFormComponent';
const classes = {
  inputItemExtraShort: `${PREFIX}-inputItemExtraShort`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.inputItemExtraShort}`]: {
    width: 'inherit',
    maxWidth: 150,
  },
}));

export interface OwnProps {
  checkAttribute?: CheckAttribute;
  submit: (checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>) => void;
  cancel: () => void;
}

type FormData = {
  checkAttributeName: string;
  specification: CheckAttributeSpecification | '';
  volumeDescription: string;
  volumeDensityValue: number | string;
  volumeNominalValue: number | string;
  volumeMinValue: number | string;
  volumeMaxValue: number | string;
  volumeTareValue: number | string;
  limitValues: string;
  sampleSize: number;
};

const getFormValues = (checkAttribute?: CheckAttribute): FormData => ({
  checkAttributeName: checkAttribute?.name || '',
  specification: checkAttribute?.specification || '',
  volumeDescription: checkAttribute?.volumeDescription || '',
  volumeNominalValue:
    checkAttribute?.volumeNominalValue !== undefined ? checkAttribute.volumeNominalValue : '',
  volumeMinValue: checkAttribute?.volumeMinValue !== undefined ? checkAttribute.volumeMinValue : '',
  volumeMaxValue: checkAttribute?.volumeMaxValue !== undefined ? checkAttribute.volumeMaxValue : '',
  volumeDensityValue:
    checkAttribute?.volumeDensityValue !== undefined ? checkAttribute.volumeDensityValue : '',
  volumeTareValue:
    checkAttribute?.volumeTareValue !== undefined ? checkAttribute.volumeTareValue : '',
  limitValues: '',
  sampleSize: checkAttribute?.sampleSize || 1,
});

export const VolumeFormComponent = (props: OwnProps) => {
  const { t } = useTranslation(['form', 'data']);
  const { checkAttribute } = props;
  const methods = useForm<FormData>({
    defaultValues: getFormValues(checkAttribute),
  });
  const { handleSubmit, errors, control, register, getValues, setValue } = methods;

  const [volumeNominalValueInput, setVolumeNominalValueInput] = useState<string | undefined>(
    checkAttribute?.volumeNominalValue?.toString() || ''
  );

  const [volumeDensityValueInput, setVolumeDensityValueInput] = useState<string | undefined>(
    checkAttribute?.volumeDensityValue?.toString() || ''
  );

  const [volumeMinValueInput, setVolumeMinValueInput] = useState<string | undefined>(
    checkAttribute?.volumeMinValue?.toString() || ''
  );

  const [volumeMaxValueInput, setVolumeMaxValueInput] = useState<string | undefined>(
    checkAttribute?.volumeMaxValue?.toString() || ''
  );

  const [volumeTareValueInput, setVolumeTareValueInput] = useState<string | undefined>(
    checkAttribute?.volumeTareValue?.toString() || ''
  );

  useEffect(() => {
    // Custom register for FormattedTextFields
    register(
      {
        name: 'volumeNominalValue',
      },
      {
        required: { value: true, message: t('form:fieldIsRequired') },
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: { value: 1.0, message: t('form:minValue', { min: 1.0 }) },
        max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
      }
    );
    register(
      {
        name: 'volumeDensityValue',
      },
      {
        required: { value: true, message: t('form:fieldIsRequired') },
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: { value: 0.1, message: t('form:minValue', { min: 0.1 }) },
        max: { value: 10.0, message: t('form:maxValue', { max: 10.0 }) },
      }
    );
    register(
      {
        name: 'volumeMinValue',
      },
      {
        required: { value: true, message: t('form:fieldIsRequired') },
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: { value: 1.0, message: t('form:minValue', { min: 1.0 }) },
        max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
      }
    );
    register(
      {
        name: 'volumeMaxValue',
      },
      {
        required: { value: true, message: t('form:fieldIsRequired') },
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: { value: 1.0, message: t('form:minValue', { min: 1.0 }) },
        max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
      }
    );
    register(
      {
        name: 'volumeTareValue',
      },
      {
        required: { value: false, message: t('form:fieldIsRequired') },
        validate: (value: string) => {
          return !Number.isNaN(Number(value)) ? true : (t('form:numberValue') as string);
        },
        min: { value: 0.0, message: t('form:minValue', { min: 0.0 }) },
        max: { value: 1500000.0, message: t('form:maxValue', { max: 1500000.0 }) },
      }
    );
  }, [register, t]);

  const onSubmit = handleSubmit((formData: FormData) => {
    props.submit({
      checkAttributeType: CheckAttributeType.Volume,
      name: formData.checkAttributeName,
      specification: CheckAttributeSpecification.Volume,
      sampleSize: Number(formData.sampleSize),
      volumeDescription: formData.volumeDescription,
      volumeNominalValue:
        volumeNominalValueInput && volumeNominalValueInput !== ''
          ? +volumeNominalValueInput
          : undefined,
      volumeDensityValue:
        volumeDensityValueInput && volumeDensityValueInput !== ''
          ? +volumeDensityValueInput
          : undefined,
      volumeMinValue:
        volumeMinValueInput && volumeMinValueInput !== '' ? +volumeMinValueInput : undefined,
      volumeMaxValue:
        volumeMaxValueInput && volumeMaxValueInput !== '' ? +volumeMaxValueInput : undefined,
      volumeTareValue:
        volumeTareValueInput && volumeTareValueInput !== '' ? +volumeTareValueInput : undefined,
    });
  });

  const volumeNominalValueInputChanged = (value: string) => {
    if (volumeNominalValueInput !== value) {
      setVolumeNominalValueInput(value);
      setValue('volumeNominalValue', value);
    }
  };

  const volumeDensityValueInputChanged = (value: string) => {
    if (volumeDensityValueInput !== value) {
      setVolumeDensityValueInput(value);
      setValue('volumeDensityValue', value);
    }
  };

  const volumeMinValueInputChanged = (value: string) => {
    if (volumeMinValueInput !== value) {
      setVolumeMinValueInput(value);
      setValue('volumeMinValue', value);
    }
  };

  const volumeMaxValueInputChanged = (value: string) => {
    if (volumeMaxValueInput !== value) {
      setVolumeMaxValueInput(value);
      setValue('volumeMaxValue', value);
    }
  };

  const volumeTareValueInputChanged = (value: string) => {
    if (volumeTareValueInput !== value) {
      setVolumeTareValueInput(value);
      setValue('volumeTareValue', value);
    }
  };

  const validateLimitValues = (): boolean => {
    const formData = getValues();

    const hasMinValue = formData.volumeMinValue && formData.volumeMinValue !== '';
    const hasNominalValue = formData.volumeNominalValue && formData.volumeNominalValue !== '';
    const hasMaxValue = formData.volumeMaxValue && formData.volumeMaxValue !== '';
    // const hasDensityValue = formData.volumeDensity && formData.volumeDensity !== '';

    if (!(hasMinValue && hasNominalValue && hasMaxValue)) return true;

    const minValue = Number(formData.volumeMinValue);
    const nominalValue = Number(formData.volumeNominalValue);
    const maxValue = Number(formData.volumeMaxValue);

    const isValid = minValue < nominalValue && nominalValue < maxValue;

    const returnValue = !!isValid || false;
    return returnValue;
  };

  return (
    <Root>
      <FormRoot>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className={formClasses.root}>
            <ContainerOutsideWithHeader>
              <Typography variant="h2">{t('data:checkAttribute.description')}</Typography>
              <Paper>
                <ContainerInside>
                  <GridContainer>
                    <GridItem>
                      <GridContainer level={GridLevel.InputPaper}>
                        <DescriptionFrameComponent checkAttributeType={CheckAttributeType.Volume} />
                        <GridItem s={12} xl={8}>
                          <Controller
                            as={<TextField variant="outlined" fullWidth={true} />}
                            control={control}
                            defaultValue={''}
                            name="volumeDescription"
                            label={t('data:checkAttribute.volumeDescription')}
                            inputProps={{
                              'aria-label': t('data:checkAttribute.volumeDescription'),
                              'data-testid': 'volumeDescription-input',
                            }}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: t('form:maxLength', { max: '256' }),
                              },
                            }}
                            error={errors.volumeDescription !== undefined}
                            helperText={
                              errors.volumeDescription && errors.volumeDescription.message
                            }
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </ContainerInside>
              </Paper>
            </ContainerOutsideWithHeader>
            <ContainerOutsideWithHeader>
              <Typography variant="h2">{t('data:checkAttribute.limits')}</Typography>
              <Paper>
                <ContainerInside>
                  <GridContainer level={GridLevel.InputPaper}>
                    <input
                      type="hidden"
                      name="limitValues"
                      defaultValue={' '}
                      ref={register({
                        validate: () =>
                          validateLimitValues() ||
                          (t('data:checkAttribute.limitValuesNotValid') as string),
                      })}
                    />
                    {errors.limitValues && errors.limitValues.message && (
                      <GridItem>
                        <InfoboxComponent
                          headline={errors.limitValues.message}
                          type="error"
                        ></InfoboxComponent>
                      </GridItem>
                    )}
                    <GridItem>
                      <GridContainer level={GridLevel.InputPaper}>
                        <GridItem s={3} xl={2}>
                          <Box>
                            <FormattedTextFieldComponent
                              name="volumeNominalValue"
                              onInputChange={volumeNominalValueInputChanged}
                              defaultValue={checkAttribute?.volumeNominalValue?.toString() || ''}
                              label={t('data:checkAttribute.nominalValue')}
                              ariaLabel={t('data:checkAttribute.nominalValue')}
                              required
                              fullWidth={true}
                              suffixUnit={VOLUME_ENTITY}
                              hasError={errors.volumeNominalValue !== undefined}
                              errorMessage={
                                errors.volumeNominalValue && errors.volumeNominalValue.message
                              }
                            />
                          </Box>
                        </GridItem>
                        <GridItem s={3} xl={2}>
                          <Box>
                            <FormattedTextFieldComponent
                              name="volumeTareValue"
                              onInputChange={volumeTareValueInputChanged}
                              defaultValue={checkAttribute?.volumeTareValue?.toString() || ''}
                              label={t('data:checkAttribute.tareValue')}
                              ariaLabel={t('data:checkAttribute.tareValue')}
                              fullWidth={true}
                              suffixUnit={WEIGHT_ENTITY}
                              hasError={errors.volumeTareValue !== undefined}
                              errorMessage={
                                errors.volumeTareValue && errors.volumeTareValue.message
                              }
                            />
                          </Box>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem>
                      <GridContainer level={GridLevel.InputPaper}>
                        <GridItem s={3} xl={2}>
                          <Box>
                            <FormattedTextFieldComponent
                              name="volumeDensityValue"
                              onInputChange={volumeDensityValueInputChanged}
                              defaultValue={checkAttribute?.volumeDensityValue?.toString() || ''}
                              label={t('data:checkAttribute.densityValue')}
                              ariaLabel={t('data:checkAttribute.densityValue')}
                              required
                              fullWidth={true}
                              suffixUnit={DENSITY_ENTITY}
                              decimalScale={2}
                              hasError={errors.volumeDensityValue !== undefined}
                              errorMessage={
                                errors.volumeDensityValue && errors.volumeDensityValue.message
                              }
                            />
                          </Box>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem>
                      <GridContainer level={GridLevel.InputPaper}>
                        <GridItem s={3} xl={2}>
                          <Box>
                            <FormattedTextFieldComponent
                              name="volumeMinValue"
                              onInputChange={volumeMinValueInputChanged}
                              defaultValue={checkAttribute?.volumeMinValue?.toString() || ''}
                              label={t('data:checkAttribute.minValue')}
                              ariaLabel={t('data:checkAttribute.minValue')}
                              required
                              fullWidth={true}
                              suffixUnit={VOLUME_ENTITY}
                              hasError={errors.volumeMinValue !== undefined}
                              errorMessage={errors.volumeMinValue && errors.volumeMinValue.message}
                            />
                          </Box>
                        </GridItem>
                        <GridItem s={3} xl={2}>
                          <Box>
                            <FormattedTextFieldComponent
                              name="volumeMaxValue"
                              onInputChange={volumeMaxValueInputChanged}
                              defaultValue={checkAttribute?.volumeMaxValue?.toString() || ''}
                              label={t('data:checkAttribute.maxValue')}
                              ariaLabel={t('data:checkAttribute.maxValue')}
                              required
                              fullWidth={true}
                              suffixUnit={VOLUME_ENTITY}
                              hasError={errors.volumeMaxValue !== undefined}
                              errorMessage={errors.volumeMaxValue && errors.volumeMaxValue.message}
                            />
                          </Box>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </ContainerInside>
              </Paper>
            </ContainerOutsideWithHeader>
            <SampleSizeComponent />
            <GridItem>
              <ButtonsFormComponent cancel={props.cancel} />
            </GridItem>
          </form>
        </FormProvider>
      </FormRoot>
    </Root>
  );
};
