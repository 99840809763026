import { clearInterval, setInterval } from 'timers';

import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  entity: string;
  animated?: boolean;
}

export const LoadingIndicatorComponent = (props: OwnProps) => {
  const { entity, animated } = props;
  const { t } = useTranslation(['common']);
  const [dots, setDots] = useState(2);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (animated) {
      intervalId = setInterval(() => {
        setDots((dot) => dot + 1);
      }, 1000);
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [animated]);

  const createText = () => {
    return `${t('common:loading')} ${entity} ${'.'.repeat((dots % 3) + 1)}`;
  };

  return <Typography data-testid="loadingIndicator">{createText()}</Typography>;
};
