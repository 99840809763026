import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ResultType } from '../../model';
import { closeProductionRunSkipReasonDialog, currentCheckResultSelector } from '../../store';
import { irisCustomColors } from '../../theme';

const PREFIX = 'SkipReasonComponent';
const classes = {
  headline: `${PREFIX}-headline`,
  subline: `${PREFIX}-subline`,
  reason: `${PREFIX}-reason`,
  closeButton: `${PREFIX}-closeButton`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.headline}`]: {
    fontSize: '1.75rem',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.subline}`]: {
    fontSize: '1.375rem',
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.reason}`]: {
    marginBottom: theme.spacing(1.5),
  },

  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: irisCustomColors.irisBlack,
  },
}));

export const SkipReasonComponent = () => {
  const { t } = useTranslation(['data']);

  const dispatch = useDispatch();

  const currentCheckResult = useSelector(currentCheckResultSelector);
  const result = currentCheckResult?.execution;
  const resultType = currentCheckResult?.resultType;

  const handleClose = () => {
    dispatch(closeProductionRunSkipReasonDialog());
  };

  return (
    <StyledBox>
      <IconButton
        aria-label={t('common:close')}
        className={classes.closeButton}
        onClick={() => handleClose()}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <Typography className={classes.headline}>
        {resultType === ResultType.Sample
          ? t('data:checkResult.skipSampleReasonHeader')
          : t('data:checkResult.skipCheckReasonHeader')}
      </Typography>
      <Typography className={classes.subline}>{t('data:checkResult.reason')}</Typography>
      {result && result.skipReason && (
        <Typography className={classes.reason}>{result.skipReason}</Typography>
      )}
    </StyledBox>
  );
};
