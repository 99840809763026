import { createSelector } from 'reselect';

import { AppState } from '..';

export const systemStateSelector = (state: AppState) => state.system;

export const serverTimeSelector = createSelector(systemStateSelector, (state) => state.serverTime);

export const serverTimeDeltaSelector = createSelector(
  systemStateSelector,
  (state) => state.serverTimeDelta
);

export const apiKeySelector = createSelector(systemStateSelector, (state) => state.apiKey);
