import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SERVICE_MAIL } from '../../constants';
import { irisBoxShadowLevel, irisCustomColors } from '../../theme';

const PREFIX = 'LicenseExpirationInfoComponent';
const classes = {
  container: `${PREFIX}-container`,
  mainText: `${PREFIX}-mainText`,
  mailTo: `${PREFIX}-mailTo`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.container}`]: {
    width: '100%',
    height: `${height}px`,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: irisCustomColors.irisGold,
    color: theme.palette.text.primary,
    position: 'fixed',
    justifyContent: 'center',
    zIndex: 6,
    flexWrap: 'wrap',
  },

  [`& .${classes.mainText}`]: {
    fontWeight: 'bold',
    minWidth: 'fit-content',
  },

  [`& .${classes.mailTo}`]: {
    marginLeft: '4px',
    color: theme.palette.text.primary,
    textDecorationLine: 'underline',
    fontWeight: 'bold',
  },
}));

export const height = 64;

export interface OwnProps {
  expirationDays: Number;
}

export const LicenseExpirationInfoComponent = (props: OwnProps) => {
  const { expirationDays } = props;
  const { t } = useTranslation(['license']);

  return (
    <StyledBox className={classes.container} sx={{ boxShadow: irisBoxShadowLevel }}>
      <Typography className={classes.mainText} variant="body2">
        {t('license:licenseExpiring', { days: expirationDays })}
      </Typography>
      <Link
        className={classes.mailTo}
        component={'a'}
        href={'mailto:' + SERVICE_MAIL}
        underline="hover"
      >
        {t('license:systemConsultant')}
      </Link>
      <Typography className={classes.mainText} variant="body2">
        .
      </Typography>
    </StyledBox>
  );
};
