import produce from 'immer';
import { Action } from 'redux';

import {
  createApiKeySuccess,
  fetchApiKeySuccess,
  fetchSystemTimeSuccess,
  SystemActionType,
} from './system.actions';

export interface SystemState {
  serverTime?: Date;
  serverTimeDelta?: number;
  apiKey?: string;
}

export const getInitialState = (): SystemState => {
  return {};
};

export const systemReducer = (
  previousState: SystemState = getInitialState(),
  action: Action<SystemActionType>
) => {
  let nextState;

  switch (action.type) {
    case SystemActionType.systemTimeFetchSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchServerTimeAction = action as ReturnType<typeof fetchSystemTimeSuccess>;
        draftState.serverTime = new Date(fetchServerTimeAction.payload.systemData.systemDateTime);
        draftState.serverTimeDelta = new Date().valueOf() - draftState.serverTime.valueOf();
      });
      break;

    case SystemActionType.systemApiKeyFetchSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchApiKeyAction = action as ReturnType<typeof fetchApiKeySuccess>;
        draftState.apiKey = fetchApiKeyAction.payload.apiKey;
      });
      break;

    case SystemActionType.systemApiKeyCreateSuccess:
      nextState = produce(previousState, (draftState) => {
        const createApiKeyAction = action as ReturnType<typeof createApiKeySuccess>;
        draftState.apiKey = createApiKeyAction.payload.apiKey;
      });
      break;

    case SystemActionType.systemApiKeyClear:
      nextState = produce(previousState, (draftState) => {
        delete draftState.apiKey;
      });
      break;

    default:
      nextState = previousState;
  }

  return nextState;
};
