import { CheckAttribute } from './check-attribute.model';

export interface Check {
  id: string;
  afterStart: boolean;
  afterDisruption: boolean;
  afterProduction: boolean;
  periodically: boolean;
  checkPeriodicity?: number;
  checkAttribute: CheckAttribute;
}

export enum CheckInterval {
  ProductionStart = 1,
}
