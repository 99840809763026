import { Dialog, DialogContent } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { eventDescriptionDialogOpenSelector } from '../../store';

import { EventInfoComponent } from './event-info.component';

export const EventDialogComponent = () => {
  const isDialogOpen = useSelector(eventDescriptionDialogOpenSelector);
  return (
    <Dialog open={isDialogOpen} fullWidth maxWidth="sm" data-testid="eventDialog">
      <DialogContent>
        <EventInfoComponent />
      </DialogContent>
    </Dialog>
  );
};
