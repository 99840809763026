import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { dateAndTimeOptions, getDurationText } from '../../utils/date-utils';

export interface OwnProps {
  startDate: Date;
  endDate: Date;
}

export const DurationInfoComponent = (props: OwnProps) => {
  const { i18n } = useTranslation(['common']);
  const { t } = useTranslation('form');

  const getEndTime = () => {
    return props.endDate.toLocaleString(i18n.language, dateAndTimeOptions) + ' ' + t('common:time');
  };

  return (
    <Box display="flex" alignItems="center">
      <Box>
        <Typography variant="body2">{getEndTime()}</Typography>
        <Typography variant="body2">
          {getDurationText(props.startDate, props.endDate, t('common:hours'))}
        </Typography>
      </Box>
    </Box>
  );
};
