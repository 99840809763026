import { Box, Paper } from '@mui/material';
import React from 'react';

import { BarChartInfoComponent } from '../../../components/bar-chart/bar-chart-info.component';
import { IssuesComponent } from '../../../components/issue/issues.component';
import { ContainerInside } from '../../../components/structure';
import { filterIssues } from '../../../helper';
import { IssueCode, ProductionRun } from '../../../model';

export interface OwnProps {
  productionRun: ProductionRun;
}

export const FertigPackVCategoriesComponent = (props: OwnProps) => {
  const { productionRun } = props;
  const filteredIssues = filterIssues(productionRun.issues, [IssueCode.FertigPackVTU2Violation]);

  return (
    <>
      <Paper data-testid={'fertigPackVCategoriesComponent'}>
        <ContainerInside>
          <BarChartInfoComponent productionRun={productionRun} />
          {filteredIssues.length > 0 && (
            <Box sx={{ marginTop: 3 }}>
              <IssuesComponent issues={filteredIssues} />
            </Box>
          )}
        </ContainerInside>
      </Paper>
    </>
  );
};
