import { Paper } from '@mui/material';
import React from 'react';

import { IssuesComponent } from '../../../components/issue/issues.component';
import { ContainerInside } from '../../../components/structure';
import { ToleranceBarInfoComponent } from '../../../components/tolerance-bar/tolerance-bar-info.component';
import { getFertigPackVStatistics } from '../../../helper';
import { IssueCode, ProductionRun } from '../../../model';

export interface OwnProps {
  headlineText: string;
  productionRun: ProductionRun;
}

export const ProductionRunAverageWeightComponent = (props: OwnProps) => {
  const { headlineText, productionRun } = props;
  const statistics = getFertigPackVStatistics(productionRun);

  return (
    <>
      {statistics?.checkSamplesDone && statistics.checkSamplesDone > 0 && (
        <>
          <Paper>
            <ContainerInside data-testid="AverageWeightToleranceBar">
              <ToleranceBarInfoComponent
                headlineText={headlineText}
                productionRun={productionRun}
              />
              <IssuesComponent
                issues={productionRun.issues}
                allowedIssueCodes={[
                  IssueCode.AverageWeightBelowNominal,
                  IssueCode.FertigPackVTU2Violation,
                ]}
              />
            </ContainerInside>
          </Paper>
        </>
      )}
    </>
  );
};
