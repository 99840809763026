import { styled } from '@mui/material/styles';

import { irisSpacing } from '../../theme';

export const BacklinkStructure = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(irisSpacing.structure.m),
}));

export const HeaderStructure = styled('div')(({ theme }) => ({
  margin: theme.spacing(irisSpacing.structure.m),
  marginTop: theme.spacing(irisSpacing.h1.mt),
  marginBottom: theme.spacing(irisSpacing.h1.mb),
}));

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
