import { styled, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FreeTextDialogComponent } from '../../../components/free-text/free-text-dialog.component';
import { SkipReasonDialogComponent } from '../../../components/skip-reason/skip-reason-dialog.component';
import { TablePaginationComponent } from '../../../components/table-pagination/table-pagination.component';
import { PaperTable } from '../../../components/table/paper-table.component';
import { checkHistoryListSelector, checkResultsTotalCountSelector } from '../../../store';

import { CheckResultsTableDisruptionRowComponent } from './check-results-table-disruption-row.component';
import { CheckResultsTableResultRowComponent } from './check-results-table-result-row.component';

const FirstCell = styled(TableCell)(({ theme }) => ({
  width: '10px',
  padding: 0,
}));

export interface OwnProps {
  handlePageChange: (newPage: number) => void;
  page: number;
}

export const CheckResultsTableComponent = (props: OwnProps) => {
  const { t } = useTranslation(['common', 'table', 'data']);
  const checkResults = useSelector(checkHistoryListSelector);
  const total = useSelector(checkResultsTotalCountSelector);
  const checkResultCount = checkResults?.filter((cr) => cr.execution).length || 0;

  return (
    <>
      <SkipReasonDialogComponent />
      <FreeTextDialogComponent />
      <PaperTable ariaLabel={t('data:checkResult.table')} dataTestId={'checkResultsTable'}>
        <TableHead>
          <TableRow>
            <FirstCell />
            <TableCell>{t('data:checkResult.checkedOn')}</TableCell>
            <TableCell>{t('data:checkResult.name')}</TableCell>
            <TableCell>{t('data:checkResult.description')}</TableCell>
            <TableCell>{t('data:checkResult.checkAttributeType')}</TableCell>
            <TableCell>{t('data:checkResult.result')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {checkResults && checkResults.length > 0 ? (
            checkResults.map((result) => {
              if (result.disruption) {
                return (
                  <CheckResultsTableDisruptionRowComponent
                    disruption={result.disruption}
                    key={`disruption_${result.disruption.id}`}
                  />
                );
              } else if (result.execution) {
                return (
                  <CheckResultsTableResultRowComponent
                    execution={result.execution}
                    key={`execution_${result.execution.id}`}
                  />
                );
              } else {
                return undefined;
              }
            })
          ) : (
            <TableRow>
              <TableCell colSpan={2}>
                {checkResults ? t('table:noMatch') : t('common:noDataAvailable')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </PaperTable>
      <TablePaginationComponent
        currentItemsCount={checkResultCount}
        count={total || 0}
        page={props.page}
        onChangePage={(event: unknown, newPage: number) => props.handlePageChange(newPage)}
      />
    </>
  );
};
