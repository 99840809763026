import { styled } from '@mui/material/styles';

import { irisCustomColors, irisSpacing } from '../../../theme';

const PREFIX = 'StyledArticleForm';
export const articleFormClasses = {
  itemContainer: `${PREFIX}-itemContainer`,
  inputItem: `${PREFIX}-inputItem`,
  inputItemExtraShort: `${PREFIX}-inputItemExtraShort`,
  checkPeriodicityItemContainer: `${PREFIX}-checkPeriodicityItemContainer`,
  checkPeriodicityItem: `${PREFIX}-checkPeriodicityItem`,
  validationError: `${PREFIX}-validationError`,
  settingsContainer: `${PREFIX}-settingsContainer`,
  hidden: `${PREFIX}-hidden`,
  radioContent: `${PREFIX}-radioContent`,
};

export const ArticleFormRoot = styled('div')(({ theme }) => ({
  [`& .${articleFormClasses.itemContainer}`]: {
    width: 'inherit',
    marginTop: 0,
    marginLeft: 0,
  },
  [`& .${articleFormClasses.inputItem}`]: {
    width: 'inherit',
    maxWidth: 300,
  },
  [`& .${articleFormClasses.inputItemExtraShort}`]: {
    width: 'inherit',
    maxWidth: 175,
  },
  [`& .${articleFormClasses.checkPeriodicityItemContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${articleFormClasses.checkPeriodicityItem}`]: {
    width: 82,
  },
  [`& .${articleFormClasses.validationError}`]: {
    color: irisCustomColors.irisRed,
    fontSize: theme.spacing(1.5),
    marginTop: '3px',
  },
  [`& .${articleFormClasses.settingsContainer}`]: {
    minWidth: 320,
  },
  [`& .${articleFormClasses.hidden}`]: {
    display: 'none',
  },
  [`& .${articleFormClasses.radioContent}`]: {
    paddingBottom: theme.spacing(irisSpacing.input.space),
  },
}));
