import produce from 'immer';
import { Action } from 'redux';

import { InternalErrorCode } from '../../http/http-error-codes';
import { addArticleFailure, ArticlesActionType, editArticleFailure } from '../articles';
import { AuthenticationActionType } from '../authentication';
import { CheckAttributeTypesActionType } from '../check-attribute-types';
import { CheckAttributesActionType } from '../check-attributes';
import { ChecksActionType } from '../checks';
import { addDeviceFailure, DevicesActionType, editDeviceFailure } from '../devices';
import { addLineFailure, editLineFailure, LinesActionType } from '../lines';
import {
  addProductionOrderFailure,
  editProductionOrderFailure,
  ProductionOrdersActionType,
} from '../production-orders';
import { occuredProductionRunIssue, ProductionRunsActionType } from '../production-runs';
import { SystemActionType } from '../system';

import { Notification, NotificationType } from './../../model/';
import { ProductionActionType } from './../production';
import { UsersActionType } from './../users/users.actions';
import { NotificationsActionType } from './notifications.actions';

export interface NotificationsState {
  currentNotification?: Notification;
}

export const getInitialState = (): NotificationsState => ({});

const addErrorNotification = (
  draftState: NotificationsState,
  type: any,
  errorCode?: InternalErrorCode
) => {
  draftState.currentNotification = errorCode
    ? {
        messageKey: type,
        type: NotificationType.error,
        errorCode: errorCode,
      }
    : {
        messageKey: type,
        type: NotificationType.error,
      };
};

export const notificationsReducer = (
  previousState: NotificationsState = getInitialState(),
  action: Action
) => {
  const { type } = action;
  let nextState;

  switch (type) {
    case ArticlesActionType.articlesFetchAllFailure:
    case ArticlesActionType.articleFetchFailure:
    case ArticlesActionType.articleDeleteFailure:
    case ArticlesActionType.articleDuplicateFailure:
    case ArticlesActionType.articleCalcFertigPackTolerancesFailure:
    case CheckAttributeTypesActionType.checkAttributeTypesFetchAllFailure:
    case CheckAttributesActionType.checkAttributesFetchAllFailure:
    case CheckAttributesActionType.checkAttributeFetchFailure:
    case CheckAttributesActionType.checkAttributeAddFailure:
    case CheckAttributesActionType.checkAttributeEditFailure:
    case CheckAttributesActionType.checkAttributeDeleteFailure:
    case CheckAttributesActionType.checkAttributeDuplicateFailure:
    case ChecksActionType.checksFetchAllForArticleFailure:
    case ChecksActionType.checkFetchForArticleFailure:
    case ChecksActionType.checkAddForArticleFailure:
    case ChecksActionType.checkEditForArticleFailure:
    case ChecksActionType.checkDeleteFromArticleFailure:
    case ChecksActionType.checkChangeOrderForArticleFailure:
    case ProductionActionType.productionGetTotalsFailure:
    case ProductionOrdersActionType.productionOrdersFetchAllFailure:
    case ProductionOrdersActionType.productionOrderFetchFailure:
    case ProductionOrdersActionType.productionOrderDeleteFailure:
    case ProductionOrdersActionType.productionOrderChangeOrderFailure:
    case ProductionRunsActionType.productionRunsRunningFetchAllFailure:
    case ProductionRunsActionType.productionRunRunningFetchFailure:
    case ProductionRunsActionType.productionRunsFinishedFetchAllFailure:
    case ProductionRunsActionType.productionRunFinishedFetchFailure:
    case ProductionRunsActionType.productionRunStopFailure:
    case ProductionRunsActionType.productionRunContinueFailure:
    case ProductionRunsActionType.productionRunCheckResultsFetchAllFailure:
    case ProductionRunsActionType.productionRunCheckResultsExportFailure:
    case ProductionRunsActionType.productionRunEventsExportFailure:
    case LinesActionType.linesFetchAllFailure:
    case LinesActionType.lineFetchFailure:
    case LinesActionType.lineDeleteFailure:
    case DevicesActionType.devicesFetchAllFailure:
    case DevicesActionType.deviceFetchFailure:
    case DevicesActionType.deviceDeleteFailure:
    case DevicesActionType.connectedScalesFetchAllFailure:
    case SystemActionType.systemTimeFetchFailure:
    case SystemActionType.systemApiKeyFetchFailure:
    case SystemActionType.systemApiKeyCreateFailure:
    case UsersActionType.usersFetchAllFailure:
    case UsersActionType.userFetchFailure:
    case UsersActionType.userAddFailure:
    case UsersActionType.userEditFailure:
    case UsersActionType.userDeleteFailure:
    case UsersActionType.userResetPasswordFailure:
    case AuthenticationActionType.profileInfoFetchFailure:
      nextState = produce(previousState, (draftState) => {
        addErrorNotification(draftState, type);
      });
      break;
    case ArticlesActionType.articleAddSuccess:
    case SystemActionType.systemApiKeyCreateSuccess:
      nextState = produce(previousState, (draftState) => {
        draftState.currentNotification = {
          messageKey: type,
          type: NotificationType.success,
        };
      });
      break;
    case NotificationsActionType.notificationClear:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentNotification;
      });
      break;
    case ProductionRunsActionType.productionRunIssueOccured:
      nextState = produce(previousState, (draftState) => {
        const issueOccuredAction = action as ReturnType<typeof occuredProductionRunIssue>;
        const issue = issueOccuredAction.payload.issue;
        draftState.currentNotification = {
          messageKey: type,
          // Currently an issue is always displayed as an error notification regardless of its issueType
          type: NotificationType.error,
          issue: issue,
        };
      });
      break;
    case DevicesActionType.deviceAddFailure:
    case DevicesActionType.deviceEditFailure:
      nextState = produce(previousState, (draftState) => {
        const failureAction = action as ReturnType<
          typeof addDeviceFailure | typeof editDeviceFailure
        >;
        const { e } = failureAction.payload;
        if (e.internalErrorCode !== InternalErrorCode.ProductionLineStaticScaleDeviceAlreadySet) {
          draftState.currentNotification = {
            messageKey: type,
            type: NotificationType.error,
          };
        }
      });
      break;
    case ArticlesActionType.articleAddFailure:
    case ArticlesActionType.articleEditFailure:
      nextState = produce(previousState, (draftState) => {
        const failureAction = action as ReturnType<
          typeof addArticleFailure | typeof editArticleFailure
        >;
        const { e } = failureAction.payload;
        if (e.internalErrorCode === InternalErrorCode.ArticleNumberAlreadyUsed) {
          addErrorNotification(draftState, type, e.internalErrorCode);
        } else {
          addErrorNotification(draftState, type);
        }
      });
      break;
    case ProductionOrdersActionType.productionOrderAddFailure:
    case ProductionOrdersActionType.productionOrderEditFailure:
      nextState = produce(previousState, (draftState) => {
        const failureAction = action as ReturnType<
          typeof addProductionOrderFailure | typeof editProductionOrderFailure
        >;
        const { e } = failureAction.payload;
        if (e.internalErrorCode === InternalErrorCode.ProductionOrderNumberAlreadyUsed) {
          addErrorNotification(draftState, type, e.internalErrorCode);
        } else {
          addErrorNotification(draftState, type);
        }
      });
      break;
    case LinesActionType.lineAddFailure:
    case LinesActionType.lineEditFailure:
      nextState = produce(previousState, (draftState) => {
        const failureAction = action as ReturnType<typeof addLineFailure | typeof editLineFailure>;
        const { e } = failureAction.payload;
        if (e.internalErrorCode === InternalErrorCode.LineNameAlreadyUsed) {
          addErrorNotification(draftState, type, e.internalErrorCode);
        } else {
          addErrorNotification(draftState, type);
        }
      });
      break;
    default:
      nextState = previousState;
  }

  return nextState;
};
