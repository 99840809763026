import { Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formClasses, FormRoot } from '../../../../components/components-styles/form.styles';
import { GridContainer, GridLevel } from '../../../../components/grid/grid.container';
import { GridItem } from '../../../../components/grid/grid.item';
import { ResponsiveImageInputPreviewComponent } from '../../../../components/image/responsive-image/responsive-image-input-preview.component';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../../components/structure';
import { CheckAttribute, CheckAttributeSpecification, CheckAttributeType } from '../../../../model';
import { CheckAttributeImages } from '../check-attribute-selection/check-attribute-select.component';

import { ButtonsFormComponent } from './buttons-form.component';
import { DescriptionFrameComponent } from './description-frame.component';
import { SampleSizeComponent } from './sample-size.component';

export interface OwnProps {
  checkAttribute?: CheckAttribute;
  submit: (
    checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>,
    images?: CheckAttributeImages
  ) => void;
  cancel: () => void;
}

type FormData = {
  checkAttributeName: string;
  specification: CheckAttributeSpecification | '';
  freeTextDescription: string;
  sampleSize: number;
};

const getFormValues = (checkAttribute?: CheckAttribute): FormData => ({
  checkAttributeName: checkAttribute?.name || '',
  specification: checkAttribute?.specification || '',
  freeTextDescription: checkAttribute?.freeTextDescription || '',
  sampleSize: checkAttribute?.sampleSize || 1,
});

export const FreeTextFormComponent = (props: OwnProps) => {
  const { t } = useTranslation(['form', 'data']);
  const { checkAttribute } = props;
  const [freeTextImageUrl, setFreeTextImageUrl] = useState<string | undefined>(
    checkAttribute?.freeTextImageUrl
  );
  const [chosenFreeTextImageFile, setChosenFreeTextImageFile] = useState<File | undefined>();

  const methods = useForm<FormData>({
    defaultValues: getFormValues(checkAttribute),
  });
  const { handleSubmit, errors, control, watch } = methods;

  const removeFreeTextImage = () => {
    setFreeTextImageUrl(undefined);
    setChosenFreeTextImageFile(undefined);
  };

  const onSubmit = handleSubmit((formData: FormData) => {
    const visual = Number(formData.specification) === CheckAttributeSpecification.Visual;

    if (formData.specification) {
      props.submit(
        {
          checkAttributeType: CheckAttributeType.FreeText,
          name: formData.checkAttributeName,
          specification: Number(formData.specification),
          sampleSize: Number(formData.sampleSize),
          freeTextDescription: formData.freeTextDescription,
          freeTextImageUrl: freeTextImageUrl,
        },
        {
          freeTextImage: visual ? chosenFreeTextImageFile : undefined,
        }
      );
    }
  });

  return (
    <FormRoot>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} className={formClasses.root}>
          <ContainerOutsideWithHeader>
            <Typography variant="h2">{t('data:checkAttribute.description')}</Typography>
            <Paper>
              <ContainerInside>
                <GridContainer>
                  <GridItem>
                    <GridContainer level={GridLevel.InputPaper}>
                      <DescriptionFrameComponent checkAttributeType={CheckAttributeType.FreeText} />
                      <GridItem s={12} xl={8}>
                        <Controller
                          as={<TextField variant="outlined" fullWidth={true} />}
                          control={control}
                          defaultValue={''}
                          name="freeTextDescription"
                          label={t('data:checkAttribute.description')}
                          inputProps={{
                            'aria-label': t('data:checkAttribute.description'),
                            'data-testid': 'freeTextDescription-input',
                          }}
                          rules={{
                            maxLength: {
                              value: 256,
                              message: t('form:maxLength', { max: '256' }),
                            },
                          }}
                          error={errors.freeTextDescription !== undefined}
                          helperText={
                            errors.freeTextDescription && errors.freeTextDescription.message
                          }
                        />
                      </GridItem>
                      {Number(watch('specification')) === CheckAttributeSpecification.Visual && (
                        <GridItem>
                          <GridContainer level={GridLevel.InputPaper}>
                            <GridItem s={6} xl={4}>
                              <ResponsiveImageInputPreviewComponent
                                changeImage={setChosenFreeTextImageFile}
                                chosenImageFile={chosenFreeTextImageFile}
                                removeImage={removeFreeTextImage}
                                imageUrl={freeTextImageUrl}
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      )}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </ContainerInside>
            </Paper>
          </ContainerOutsideWithHeader>
          <SampleSizeComponent />
          <GridItem>
            <ButtonsFormComponent cancel={props.cancel} />
          </GridItem>
        </form>
      </FormProvider>
    </FormRoot>
  );
};
