import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingIndicatorComponent } from '../../../components/loading-indicator/loading-indicator.component';
import { SearchFieldComponent } from '../../../components/search-field/search-field.component';
import {
  HeaderContainer,
  BodyStructure,
  HeaderStructure,
  PageStructure,
  ContainerOutside,
} from '../../../components/structure';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../constants';
import {
  fetchProductionRunsFinished,
  productionRunsFinishedFetchingSelector,
  productionRunsFinishedListSelector,
} from '../../../store';

import { ProductionRunsFinishedTableComponent } from './production-runs-finished-table.component';

export const ProductionRunsFinishedOverviewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const loading = useSelector(productionRunsFinishedFetchingSelector);
  const productionRuns = useSelector(productionRunsFinishedListSelector);
  const hasRuns = !!productionRuns && productionRuns.length > 0;

  useEffect(() => {
    dispatch(
      fetchProductionRunsFinished({
        search: searchTerm,
        pagination: { page, perPage: DEFAULT_ITEMS_PER_PAGE },
      })
    );
  }, [dispatch, searchTerm, page]);

  const searchValueChanged = (value: string) => {
    if (searchTerm !== value) {
      setPage(0);
      setSearchTerm(value);
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <PageStructure>
      <HeaderStructure>
        <HeaderContainer>
          <Typography variant="h1">{t('data:productionRun.finishedProductionRuns')}</Typography>
        </HeaderContainer>
      </HeaderStructure>
      <BodyStructure>
        <Box display="flex" justifyContent="flex-end">
          <SearchFieldComponent onSearch={searchValueChanged} />
        </Box>
        <ContainerOutside>
          {loading && !hasRuns ? (
            <LoadingIndicatorComponent entity={t('data:productionRun.productionRuns')} />
          ) : (
            <ProductionRunsFinishedTableComponent handlePageChange={handlePageChange} page={page} />
          )}
        </ContainerOutside>
      </BodyStructure>
    </PageStructure>
  );
};
