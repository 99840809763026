import { MILLIS_MINUTE, MILLIS_HOUR } from './../constants';
declare type dateTimeFormat = 'numeric' | '2-digit';

export const dateOptions: {
  year: dateTimeFormat;
  month: dateTimeFormat;
  day: dateTimeFormat;
} = { year: 'numeric', month: '2-digit', day: '2-digit' } as const;
export const timeOptions: {
  hour: dateTimeFormat;
  minute: dateTimeFormat;
} = { hour: '2-digit', minute: '2-digit' } as const;
export const dateAndTimeOptions = { ...dateOptions, ...timeOptions } as const;

export const getDurationText = (startDate: Date, endDate: Date, hoursText: string) => {
  const duration = endDate.valueOf() - startDate.valueOf();
  const minutes = Math.floor((duration / MILLIS_MINUTE) % 60);
  const hours = Math.floor(duration / MILLIS_HOUR);

  const hoursStr = hours < 10 ? '0' + hours : hours;
  const minutesStr = minutes < 10 ? '0' + minutes : minutes;

  return `${hoursStr}:${minutesStr} ${hoursText}`;
};

export const getDurationMinutes = (startDate: Date, endDate: Date) => {
  const duration = endDate.valueOf() - startDate.valueOf();
  const minutes = Math.floor(duration / MILLIS_MINUTE);
  return minutes >= 0 ? minutes : 0;
};
