import { Grid } from '@mui/material';
import React from 'react';

import { responsiveness } from '../../theme';

export enum GridLevel {
  Page = 1,
  InputPaper = 2,
}

export interface OwnProps {
  children: React.ReactNode;
  level?: GridLevel;
}

const getSpacing = (level?: GridLevel) => {
  let columnSpacing;
  let rowSpacing;

  switch (level) {
    case GridLevel.InputPaper:
      columnSpacing = responsiveness.grid.inputPaper.space.x;
      rowSpacing = responsiveness.grid.inputPaper.space.y;
      break;
    case GridLevel.Page:
    default:
      columnSpacing = responsiveness.grid.page.space.x;
      rowSpacing = responsiveness.grid.page.space.y;
  }

  return { columnSpacing, rowSpacing };
};

export const GridContainer = (props: OwnProps) => {
  const { children, level } = props;
  const { columnSpacing, rowSpacing } = getSpacing(level);

  return (
    <Grid container columnSpacing={columnSpacing} rowSpacing={rowSpacing}>
      {children}
    </Grid>
  );
};
