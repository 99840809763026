import { createSelector } from 'reselect';

import { AppState } from '..';

export const devicesStateSelector = (state: AppState) => state.devices;
export const deviceListSelector = createSelector(devicesStateSelector, (state) => state.devices);
export const currentDeviceSelector = createSelector(
  devicesStateSelector,
  (state) => state.currentDevice
);
export const devicesTotalCountSelector = createSelector(
  devicesStateSelector,
  (state) => state.total
);
export const connectedScaleListSelector = createSelector(
  devicesStateSelector,
  (state) => state.connectedScales
);
export const lineAlreadySetSelector = createSelector(
  devicesStateSelector,
  (state) => state.lineAlreadySet
);
export const devicesFetchingSelector = createSelector(
  devicesStateSelector,
  (state) => !!state.fetchingAll
);
