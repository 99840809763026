import { createSelector } from 'reselect';

import { AppState } from '..';

import { hasFertigPackChecksInState } from './checks.reducer';

export const checksStateSelector = (state: AppState) => state.checks;

export const checksForArticleSelector = createSelector(
  checksStateSelector,
  (state) => state.checksForArticle
);
export const currentCheckForArticleSelector = createSelector(
  checksStateSelector,
  (state) => state.currentCheckForArticle
);

export const isAddCheckDialogOpenSelector = createSelector(
  checksStateSelector,
  (state) => state.isAddCheckDialogOpen
);

export const isEditCheckDialogOpenSelector = createSelector(
  checksStateSelector,
  (state) => state.isEditCheckDialogOpen
);

export const hasFertigPackChecksSelector = createSelector(checksStateSelector, (state) =>
  hasFertigPackChecksInState(state.checksForArticle)
);
