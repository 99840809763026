import { Box, Typography } from '@mui/material';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AddButtonComponent } from '../../../components/buttons/add-button.component';
import { LoadingIndicatorComponent } from '../../../components/loading-indicator/loading-indicator.component';
import { SearchFieldComponent } from '../../../components/search-field/search-field.component';
import {
  HeaderContainer,
  BodyStructure,
  HeaderStructure,
  PageStructure,
  ContainerOutside,
} from '../../../components/structure';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../constants';
import { AppRoutePath } from '../../../routes/routes';
import { articleListSelector, articlesFetchingSelector, fetchArticles } from '../../../store';

import { ArticlesTableComponent } from './articles-table.component';

export const ArticlesOverviewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const loading = useSelector(articlesFetchingSelector);
  const articles = useSelector(articleListSelector);
  const hasArticles = !!articles && articles.length > 0;

  useEffect(() => {
    dispatch(
      fetchArticles({ search: searchTerm, pagination: { page, perPage: DEFAULT_ITEMS_PER_PAGE } })
    );
  }, [dispatch, searchTerm, page]);

  const goToNewArticle = () => {
    dispatch(push(`/${AppRoutePath.articles}/${AppRoutePath.new}/`));
  };

  const searchValueChanged = (value: string) => {
    if (searchTerm !== value) {
      setPage(0);
      setSearchTerm(value);
    }
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <PageStructure>
      <HeaderStructure>
        <HeaderContainer>
          <Typography variant="h1">{t('data:article.articles')}</Typography>
          <AddButtonComponent text={t('data:article.new')} onClick={goToNewArticle} />
        </HeaderContainer>
      </HeaderStructure>
      <BodyStructure>
        <Box display="flex" justifyContent="flex-end">
          <SearchFieldComponent onSearch={searchValueChanged} />
        </Box>
        <ContainerOutside>
          {loading && !hasArticles ? (
            <LoadingIndicatorComponent entity={t('data:article.articles')} />
          ) : (
            <ArticlesTableComponent handlePageChange={handlePageChange} page={page} />
          )}
        </ContainerOutside>
      </BodyStructure>
    </PageStructure>
  );
};
