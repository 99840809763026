import {
  CheckHistoryEntry,
  ProductionRunCheckExecution,
  ProductionRun,
  ProductionRunIssueNotification,
  TableDataQuery,
  ResultType,
  ProductionRunEvent,
} from '../../model';

export enum ProductionRunsActionType {
  productionRunsRunningFetchAll = 'productionRunsRunning/fetchAll/',
  productionRunsRunningFetchAllSuccess = 'productionRunsRunning/fetchAll/success',
  productionRunsRunningFetchAllFailure = 'productionRunsRunning/fetchAll/failure',
  productionRunRunningFetch = 'productionRunRunning/fetch/',
  productionRunRunningFetchSuccess = 'productionRunRunning/fetch/success',
  productionRunRunningFetchFailure = 'productionRunRunning/fetch/failure',
  productionRunRunningClear = 'productionRunRunning/clear/',
  productionRunRunningDisruptionStarted = 'productionRunRunning/fetch/failure',
  productionRunsFinishedFetchAll = 'productionRunsFinished/fetchAll/',
  productionRunsFinishedFetchAllSuccess = 'productionRunsFinished/fetchAll/success',
  productionRunsFinishedFetchAllFailure = 'productionRunsFinished/fetchAll/failure',
  productionRunFinishedFetch = 'productionRunFinished/fetch/',
  productionRunFinishedFetchSuccess = 'productionRunFinished/fetch/success',
  productionRunFinishedFetchFailure = 'productionRunFinished/fetch/failure',
  productionRunStop = 'productionRun/stop/',
  productionRunStopSuccess = 'productionRun/stop/success',
  productionRunStopFailure = 'productionRun/stop/failure',
  productionRunContinue = 'productionRun/continue/',
  productionRunContinueSuccess = 'productionRun/continue/success',
  productionRunContinueFailure = 'productionRun/continue/failure',
  productionRunOpenSkipReasonDialog = 'productionRunsFinished/skipReasonDialog/open',
  productionRunCloseSkipReasonDialog = 'productionRunsFinished/skipReasonDialog/close',
  productionRunOpenFreeTextDialog = 'productionRunsFinished/freeTextDialog/open',
  productionRunCloseFreeTextDialog = 'productionRunsFinished/freeTextDialog/close',
  productionRunCheckResultsFetchAll = 'productionCheckResults/fetchAll/',
  productionRunCheckResultsFetchAllSuccess = 'productionCheckResults/fetchAll/success',
  productionRunCheckResultsFetchAllFailure = 'productionCheckResults/fetchAll/failure',
  productionRunCheckResultsClear = 'productionCheckResults/clear/',
  productionRunCheckResultsExport = 'productionCheckResults/export/',
  productionRunCheckResultsExportSuccess = 'productionCheckResults/export/success',
  productionRunCheckResultsExportFailure = 'productionCheckResults/export/failure',
  productionRunEventsExport = 'productionEvents/export/',
  productionRunEventsExportSuccess = 'productionEvents/export/success',
  productionRunEventsExportFailure = 'productionEvents/export/failure',
  productionRunOpenEventDescriptionDialog = 'productionRuns/notificationReason/open',
  productionRunCloseEventDescriptionDialog = 'productionRuns/notificationReason/close',

  // websocket events
  productionRunRunningChanged = 'productionRunRunning/changed/',
  productionRunRunningTestRunChanged = 'productionRunRunning/testRunChanged/',
  productionRunRunningFinished = 'productionRunRunning/finished/',
  productionRunIssueOccured = 'productionRun/issueOccured',
}
export const fetchProductionRunsRunning = () => ({
  type: ProductionRunsActionType.productionRunsRunningFetchAll,
});

export const fetchProductionRunsRunningSuccess = (productionRuns: ProductionRun[]) => ({
  type: ProductionRunsActionType.productionRunsRunningFetchAllSuccess,
  payload: { productionRuns },
});

export const fetchProductionRunsRunningFailure = () => ({
  type: ProductionRunsActionType.productionRunsRunningFetchAllFailure,
});

export const fetchProductionRunRunning = (id: string) => ({
  type: ProductionRunsActionType.productionRunRunningFetch,
  payload: { id },
});

export const fetchProductionRunRunningSuccess = (productionRun: ProductionRun | undefined) => ({
  type: ProductionRunsActionType.productionRunRunningFetchSuccess,
  payload: { productionRun },
});

export const fetchProductionRunRunningFailure = () => ({
  type: ProductionRunsActionType.productionRunRunningFetchFailure,
});

export const clearProductionRunRunning = () => ({
  type: ProductionRunsActionType.productionRunRunningClear,
});

export const fetchProductionRunsFinished = (query?: TableDataQuery) => ({
  type: ProductionRunsActionType.productionRunsFinishedFetchAll,
  payload: {
    query,
  },
});

export const fetchProductionRunsFinishedSuccess = (
  productionRuns: ProductionRun[],
  total: number
) => ({
  type: ProductionRunsActionType.productionRunsFinishedFetchAllSuccess,
  payload: { productionRuns, total },
});

export const fetchProductionRunsFinishedFailure = () => ({
  type: ProductionRunsActionType.productionRunsFinishedFetchAllFailure,
});

export const fetchProductionRunFinished = (id: string) => ({
  type: ProductionRunsActionType.productionRunFinishedFetch,
  payload: { id },
});

export const fetchProductionRunFinishedSuccess = (productionRun: ProductionRun | undefined) => ({
  type: ProductionRunsActionType.productionRunFinishedFetchSuccess,
  payload: { productionRun },
});

export const fetchProductionRunFinishedFailure = () => ({
  type: ProductionRunsActionType.productionRunFinishedFetchFailure,
});

export const stopProductionRun = (id: string, reason?: string) => ({
  type: ProductionRunsActionType.productionRunStop,
  payload: { id, reason },
});

export const stopProductionRunSuccess = (productionRun: ProductionRun) => ({
  type: ProductionRunsActionType.productionRunStopSuccess,
  payload: { productionRun },
});

export const stopProductionRunFailure = () => ({
  type: ProductionRunsActionType.productionRunStopFailure,
});

export const continueProductionRun = (id: string, reason: string) => ({
  type: ProductionRunsActionType.productionRunContinue,
  payload: { id, reason },
});

export const continueProductionRunSuccess = (productionRun: ProductionRun) => ({
  type: ProductionRunsActionType.productionRunContinueSuccess,
  payload: { productionRun },
});

export const continueProductionRunFailure = () => ({
  type: ProductionRunsActionType.productionRunContinueFailure,
});

export const openProductionRunSkipReasonDialog = (
  execution: ProductionRunCheckExecution,
  resultType: ResultType
) => ({
  type: ProductionRunsActionType.productionRunOpenSkipReasonDialog,
  payload: {
    execution,
    resultType,
  },
});

export const openProductionRunFreeTextDialog = (execution: ProductionRunCheckExecution) => ({
  type: ProductionRunsActionType.productionRunOpenFreeTextDialog,
  payload: {
    execution,
    resultType: ResultType.Check,
  },
});

export const closeProductionRunSkipReasonDialog = () => ({
  type: ProductionRunsActionType.productionRunCloseSkipReasonDialog,
});

export const closeProductionRunFreeTextDialog = () => ({
  type: ProductionRunsActionType.productionRunCloseFreeTextDialog,
});

export const fetchProductionRunCheckResults = (id: string, query?: TableDataQuery) => ({
  type: ProductionRunsActionType.productionRunCheckResultsFetchAll,
  payload: {
    id,
    query,
  },
});

export const fetchProductionRunCheckResultsSuccess = (
  checkHistory: CheckHistoryEntry[],
  total: number,
  page?: number
) => ({
  type: ProductionRunsActionType.productionRunCheckResultsFetchAllSuccess,
  payload: { checkHistory, total, page },
});

export const fetchProductionRunCheckResultsFailure = () => ({
  type: ProductionRunsActionType.productionRunCheckResultsFetchAllFailure,
});

export const clearProductionRunCheckResults = () => ({
  type: ProductionRunsActionType.productionRunCheckResultsClear,
});

export const exportProductionRunCheckResults = (id: string, filename: string) => ({
  type: ProductionRunsActionType.productionRunCheckResultsExport,
  payload: { id, filename },
});

export const exportProductionRunCheckResultsSuccess = () => ({
  type: ProductionRunsActionType.productionRunCheckResultsExportSuccess,
});

export const exportProductionRunCheckResultsFailure = () => ({
  type: ProductionRunsActionType.productionRunCheckResultsExportFailure,
});

export const exportProductionRunEvents = (id: string, filename: string) => ({
  type: ProductionRunsActionType.productionRunEventsExport,
  payload: { id, filename },
});

export const exportProductionRunEventsSuccess = () => ({
  type: ProductionRunsActionType.productionRunEventsExportSuccess,
});

export const exportProductionRunEventsFailure = () => ({
  type: ProductionRunsActionType.productionRunEventsExportFailure,
});

export const openProductionRunEventDescriptionDialog = (event: ProductionRunEvent) => ({
  type: ProductionRunsActionType.productionRunOpenEventDescriptionDialog,
  payload: { event },
});

export const closeProductionRunEventDescriptionDialog = () => ({
  type: ProductionRunsActionType.productionRunCloseEventDescriptionDialog,
});

// websocket events
export const changedProductionRunRunning = (productionRun: ProductionRun) => ({
  type: ProductionRunsActionType.productionRunRunningChanged,
  payload: { productionRun },
});
export const changedProductionTestRun = (productionRun: ProductionRun) => ({
  type: ProductionRunsActionType.productionRunRunningTestRunChanged,
  payload: { productionRun },
});

export const finishedProductionRunRunning = (productionRun: ProductionRun) => ({
  type: ProductionRunsActionType.productionRunRunningFinished,
  payload: { productionRun },
});

export const occuredProductionRunIssue = (issue: ProductionRunIssueNotification) => ({
  type: ProductionRunsActionType.productionRunIssueOccured,
  payload: { issue },
});
