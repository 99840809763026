import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AddButtonComponent } from '../../../components/buttons/add-button.component';
import { getPredecessor, getSuccessor } from '../../../components/order-utils';
import { Check } from '../../../model';
import {
  checksForArticleSelector,
  fetchChecksForArticle,
  changeCheckOrderForArticle,
  deleteCheckFromArticle,
  openAddCheckDialog,
  openEditCheckDialog,
  closeAddCheckDialog,
  closeEditCheckDialog,
} from '../../../store';
import { irisSpacing } from '../../../theme';
import { CheckEditDialogComponent } from '../check/check-edit-dialog.component';
import { CheckNewDialogComponent } from '../check/check-new-dialog.component';

import { ChecksListItemComponent } from './checks-list-item.component';

const PREFIX = 'ChecksListComponent';
const classes = {
  info: `${PREFIX}-info`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.info}`]: {
    fontWeight: 'bold',
  },
}));

export interface OwnProps {
  articleId?: string;
  checkPeriodicity: number;
}

export const ChecksListComponent = (props: OwnProps) => {
  const { articleId, checkPeriodicity } = props;
  const { t } = useTranslation(['data', 'common']);

  const dispatch = useDispatch();
  const checks = useSelector(checksForArticleSelector);

  useEffect(() => {
    articleId && dispatch(fetchChecksForArticle(articleId));
  }, [dispatch, articleId]);

  const [selectedCheck, setSelectedCheck] = React.useState<undefined | Check>(undefined);

  const openAddDialog = () => {
    dispatch(openAddCheckDialog());
  };

  const openEditDialog = (check: Check) => {
    setSelectedCheck(check);
    dispatch(openEditCheckDialog());
  };

  const handleAddCheckDialogCancel = () => {
    dispatch(closeAddCheckDialog());
  };

  const handleEditCheckDialogCancel = () => {
    setSelectedCheck(undefined);
    dispatch(closeEditCheckDialog());
  };

  const moveUp = (check: Check) => {
    articleId &&
      dispatch(changeCheckOrderForArticle(articleId, check.id, getPredecessor(checks, check)));
  };

  const moveDown = (check: Check) => {
    articleId &&
      dispatch(changeCheckOrderForArticle(articleId, check.id, getSuccessor(checks, check)));
  };

  const deleteCheckById = (id: string) => {
    articleId && dispatch(deleteCheckFromArticle(articleId, id));
  };

  return (
    <Root>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{t('data:check.checksForArticle')}</Typography>
        <Box mt={3}>
          <AddButtonComponent
            disabled={!articleId}
            text={t('data:check.add')}
            onClick={openAddDialog}
          />
        </Box>
      </Box>
      {articleId ? (
        <>
          <CheckNewDialogComponent
            checkPeriodicity={checkPeriodicity}
            articleId={articleId}
            handleCancel={handleAddCheckDialogCancel}
          />
          <CheckEditDialogComponent
            checkPeriodicity={checkPeriodicity}
            check={selectedCheck}
            articleId={articleId}
            handleCancel={handleEditCheckDialogCancel}
          />
          {checks && checks.length > 0 ? (
            <Box mt={-irisSpacing.h2.mb}>
              {checks.map((check) => (
                <ChecksListItemComponent
                  key={check.id}
                  check={check}
                  checks={checks}
                  checkPeriodicity={checkPeriodicity}
                  editCheck={openEditDialog}
                  moveUp={moveUp}
                  getPredecessor={getPredecessor}
                  moveDown={moveDown}
                  getSuccessor={getSuccessor}
                  deleteCheckById={deleteCheckById}
                />
              ))}
            </Box>
          ) : (
            <Box className={classes.info}>{t('data:check.noChecks')}</Box>
          )}
        </>
      ) : (
        <Box className={classes.info}>{t('data:check.articleNotSavedYet')}</Box>
      )}
    </Root>
  );
};
