import { Alert, Link, Snackbar, SnackbarCloseReason } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { InternalErrorCode } from '../../http/http-error-codes';
import {
  IssueCode,
  Notification,
  NotificationType,
  ProductionRunIssueNotification,
} from '../../model';
import { AppRoutePath } from '../../routes/routes';
import { currentNotificationSelector, clearNotification } from '../../store';
import { irisCustomColors } from '../../theme';

const PREFIX = 'SnackbarComponent';

const classes = {
  snackbar: `${PREFIX}-snackbar`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.snackbar}`]: {
    color: irisCustomColors.irisWhite,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiAlert-action': {
      padding: 0,
      marginLeft: theme.spacing(1.5),
    },
    '& .MuiLink-root': {
      color: irisCustomColors.irisWhite,
    },
  },
}));

export const SnackbarComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['notifications']);
  const currentNotification = useSelector(currentNotificationSelector);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    currentNotification ? setOpen(true) : setOpen(false);
  }, [currentNotification]);

  const handlSnackbarClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearNotification());
  };

  const handleAlertClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearNotification());
  };

  const translateMessageKey = (currentNotification: Notification) => {
    const actionParts = (currentNotification.messageKey as string).split('/');
    let messageString = t(
      [
        `notifications:${currentNotification.type}:${actionParts[1]}`,
        `notifications:${currentNotification.type}.default`,
      ],
      { entity: t([`notifications:entities:${actionParts[0]}`, 'notifications:entities.default']) }
    );

    return messageString;
  };

  const translateIssue = (issue: ProductionRunIssueNotification) => {
    switch (issue.issueCode) {
      case IssueCode.FertigPackVTU2Violation:
        return `${t('data:productionRun.tu2.violationForRun')} ${issue.orderNumber}`;
      default:
        return t('data:productionRun.unknownIssueCode');
    }
  };

  const translateErrorCode = (errorCode: InternalErrorCode) => {
    return t([`notifications:error.code.${errorCode}`, `notifications:error.code.notFound`]);
  };

  const getProductionRunLink = (productionRunId: string) => {
    return (
      <Link
        component={NavLink}
        to={`/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.running}/${productionRunId}`}
        underline={'none'}
        onClick={() => {
          dispatch(clearNotification());
        }}
      >
        {t('data:productionRun.goToRun')}
      </Link>
    );
  };

  const getAlert = (currentNotification: Notification) => {
    const issue = currentNotification.issue;
    const errorCode = currentNotification.errorCode;
    const action = issue ? getProductionRunLink(issue.productionRunId) : undefined;

    return (
      <Alert
        elevation={6}
        variant="filled"
        severity={currentNotification.type}
        onClose={handleAlertClose}
        className={classes.snackbar}
        action={action}
      >
        {issue
          ? translateIssue(issue)
          : errorCode
          ? translateErrorCode(errorCode)
          : t(translateMessageKey(currentNotification))}
      </Alert>
    );
  };

  return (
    <Root>
      {currentNotification && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          autoHideDuration={currentNotification.type === NotificationType.success ? 3000 : null}
          onClose={handlSnackbarClose}
        >
          {getAlert(currentNotification)}
        </Snackbar>
      )}
    </Root>
  );
};
