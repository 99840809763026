import { styled } from '@mui/material/styles';

import { irisSpacing } from '../../theme';

const PREFIX = 'StyledForm';
export const formClasses = {
  root: `${PREFIX}-root`,
  buttons: `${PREFIX}-buttons`,
  buttonsInside: `${PREFIX}-buttonsInside`,
};

export const FormRoot = styled('div')(({ theme }) => ({
  [`& .${formClasses.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${formClasses.buttons}`]: {
    display: 'flex',
    marginTop: theme.spacing(irisSpacing.button.mt),
    marginBottom: theme.spacing(irisSpacing.button.mb),
    justifyContent: 'flex-end',
    '& button:not(:last-child)': {
      marginRight: theme.spacing(irisSpacing.button.space),
    },
  },
  [`& .${formClasses.buttonsInside}`]: {
    display: 'flex',
    marginTop: theme.spacing(irisSpacing.button.boxed.mt),
    justifyContent: 'flex-end',
    '& button:not(:last-child)': {
      marginRight: theme.spacing(irisSpacing.button.space),
    },
  },
}));
