import { Typography } from '@mui/material';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AddButtonComponent } from '../../../components/buttons/add-button.component';
import { LoadingIndicatorComponent } from '../../../components/loading-indicator/loading-indicator.component';
import {
  HeaderContainer,
  BodyStructure,
  HeaderStructure,
  PageStructure,
  ContainerOutside,
} from '../../../components/structure';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../constants';
import { AppRoutePath } from '../../../routes/routes';
import { fetchLines, lineListSelector, linesFetchingSelector } from '../../../store';

import { LinesTableComponent } from './lines-table.component';

export const LinesOverviewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const [page, setPage] = useState<number>(0);
  const loading = useSelector(linesFetchingSelector);
  const lines = useSelector(lineListSelector);
  const hasLines = !!lines && lines.length > 0;

  useEffect(() => {
    dispatch(fetchLines({ pagination: { page, perPage: DEFAULT_ITEMS_PER_PAGE } }));
  }, [dispatch, page]);

  const goToNewLine = () => {
    dispatch(push(`/${AppRoutePath.lines}/${AppRoutePath.new}/`));
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <PageStructure>
      <HeaderStructure>
        <HeaderContainer>
          <Typography variant="h1">{t('data:line.lines')}</Typography>
          <AddButtonComponent text={t('data:line.new')} onClick={goToNewLine} />
        </HeaderContainer>
      </HeaderStructure>
      <BodyStructure>
        <ContainerOutside>
          {loading && !hasLines ? (
            <LoadingIndicatorComponent entity={t('data:line.lines')} />
          ) : (
            <LinesTableComponent handlePageChange={handlePageChange} page={page} />
          )}
        </ContainerOutside>
      </BodyStructure>
    </PageStructure>
  );
};
