import CloseIcon from '@mui/icons-material/Close';
import MuiPauseIcon from '@mui/icons-material/PauseCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ProductionRunEventType } from '../../model';
import { closeProductionRunEventDescriptionDialog, openDialogEventSelector } from '../../store';
import { irisCustomColors } from '../../theme';

const PREFIX = 'EventInfoComponent';
const classes = {
  headline: `${PREFIX}-headline`,
  closeButton: `${PREFIX}-closeButton`,
  disruptionType: `${PREFIX}-disruptionType`,
  reason: `${PREFIX}-reasonBox`,
  pauseIcon: `${PREFIX}-pauseIcon`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: irisCustomColors.irisBlack,
  },

  [`& .${classes.headline}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.disruptionType}`]: {
    marginLeft: theme.spacing(2),
    fontSize: '1.75rem',
  },

  [`& .${classes.reason}`]: {
    fontSize: '1.375rem',
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.pauseIcon}`]: {
    color: irisCustomColors.irisGold,
  },
}));

export const EventInfoComponent = () => {
  const { t } = useTranslation(['data']);
  const event = useSelector(openDialogEventSelector);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeProductionRunEventDescriptionDialog());
  };

  const getIcon = (iconType: ProductionRunEventType) => {
    switch (iconType) {
      case ProductionRunEventType.Error:
        return <WarningIcon fontSize="large" color="error" data-testid="errorIconEventInfo" />;
      case ProductionRunEventType.Warning:
        return <WarningIcon fontSize="large" color="warning" data-testid="warningIconEventInfo" />;
      case ProductionRunEventType.Pause:
        return (
          <MuiPauseIcon
            fontSize="large"
            className={classes.pauseIcon}
            data-testid="pauseIconEventInfo"
          />
        );
    }
  };

  return (
    <StyledBox data-testid="eventInfoCmp">
      <IconButton
        aria-label={t('common:close')}
        className={classes.closeButton}
        onClick={() => handleClose()}
        data-testid="closeBtn"
      >
        <CloseIcon />
      </IconButton>
      {event && (
        <>
          <Box className={classes.headline}>
            {getIcon(event.iconType)}
            <Typography className={classes.disruptionType} data-testid="disruptionType">
              {t([
                `data:productionRun.events.eventCode.${event?.eventCode}`,
                `data:productionRun.events.eventCode.notFound`,
              ])}
            </Typography>
          </Box>
          <Typography className={classes.reason}>
            {t('data:productionRun.events.reason')}
          </Typography>
          <Typography>{event.description}</Typography>
        </>
      )}
    </StyledBox>
  );
};
