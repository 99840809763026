import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { ProductionOverview } from '../../model/production-overview.model';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  ProductionActionType,
  getProductionTotalsSuccess,
  getProductionTotalsFailure,
} from './production.actions';

const productionUrl = 'production';

function* getProductionTotals() {
  try {
    const response = yield* call<[boolean, string], SagaRequest<ProductionOverview>>(
      SagaRequestHelper.get,
      true,
      productionUrl
    );
    yield put(getProductionTotalsSuccess(response));
  } catch (e: any) {
    yield sagaErrorHandler(e, getProductionTotalsFailure);
  }
}

export function* getProductionTotalsSaga() {
  yield takeLatest(ProductionActionType.productionGetTotals, getProductionTotals);
}

export default function* productionSaga() {
  yield fork(getProductionTotalsSaga);
}
