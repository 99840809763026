import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useDebounce from '../../hooks/debounce.hook';

export interface OwnProps {
  onSearch: (value: string) => void;
}

export const SearchFieldComponent = (props: OwnProps) => {
  const { t } = useTranslation(['table']);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { onSearch } = props;

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, onSearch]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <TextField
      variant="outlined"
      id="search-field"
      placeholder={t('table:search')}
      type="search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="large" />
          </InputAdornment>
        ),
        onChange: handleChange,
      }}
    />
  );
};
