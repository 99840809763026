export enum InternalErrorCode {
  ProductionRunAlreadyStarted = 100,
  ProductionInvalidStateChange = 101,
  ProductionRunDoneException = 102,
  ProductionOrderNotStartable = 103,
  ProductionRunCheckExecutionAlreadyDone = 104,
  ProductionRunCheckExecutionSampleSizeMismatch = 105,
  ProductionLineStaticScaleDeviceAlreadySet = 106,
  StaticScaleCommunicationFailed = 107,
  UsernameAlreadyUsed = 108,
  CurrentPasswordIncorrect = 109,
  LicenseReadFailed = 110,
  LicenseInvalid = 111,
  LicenseInsufficient = 112,
  LicenseExpired = 113,
  ProductionRunCheckExecutionSampleAlreadyDoneException = 114,
  ProductionRunOpenDisruptionsException = 115,
  ProductionRunWrongStateException = 116,
  ArticleNumberAlreadyUsed = 117,
  ProductionOrderNumberAlreadyUsed = 118,
  LineNameAlreadyUsed = 119,
}
