import { Check } from '../../model';

export enum ChecksActionType {
  checksFetchAllForArticle = 'checks/fetchAll',
  checksFetchAllForArticleSuccess = 'checks/fetchAll/success',
  checksFetchAllForArticleFailure = 'checks/fetchAll/failure',
  checkFetchForArticle = 'check/fetch',
  checkFetchForArticleSuccess = 'check/fetch/success',
  checkFetchForArticleFailure = 'check/fetch/failure',
  checkAddForArticle = 'check/add',
  checkAddForArticleSuccess = 'check/add/success',
  checkAddForArticleFailure = 'check/add/failure',
  checkEditForArticle = 'check/edit',
  checkEditForArticleSuccess = 'check/edit/success',
  checkEditForArticleFailure = 'check/edit/failure',
  checkDeleteFromArticle = 'check/delete',
  checkDeleteFromArticleSuccess = 'check/delete/success',
  checkDeleteFromArticleFailure = 'check/delete/failure',
  checkChangeOrderForArticle = 'check/changeOrder',
  checkChangeOrderForArticleSuccess = 'check/changeOrder/success',
  checkChangeOrderForArticleFailure = 'check/changeOrder/failure',
  checkOpenAddCheckDialog = 'check/addDialog/open',
  checkCloseAddCheckDialog = 'check/addDialog/close',
  checkOpenEditCheckDialog = 'check/editDialog/open',
  checkCloseEditCheckDialog = 'check/editDialog/close',
  checksForArticleClear = 'checks/clearForArticle',
}

export const fetchChecksForArticle = (id: string) => ({
  type: ChecksActionType.checksFetchAllForArticle,
  payload: { id },
});

export const fetchChecksForArticleSuccess = (checks: Check[]) => ({
  type: ChecksActionType.checksFetchAllForArticleSuccess,
  payload: { checks },
});

export const fetchChecksForArticleFailure = () => ({
  type: ChecksActionType.checksFetchAllForArticleFailure,
});

export const fetchCheckForArticle = (articleId: string, checkId: string) => ({
  type: ChecksActionType.checkFetchForArticle,
  payload: { articleId, checkId },
});

export const fetchCheckForArticleSuccess = (articleId: string, check: Check) => ({
  type: ChecksActionType.checkFetchForArticleSuccess,
  payload: { articleId, check },
});

export const fetchCheckForArticleFailure = () => ({
  type: ChecksActionType.checkFetchForArticleFailure,
});

export const addCheckForArticle = (articleId: string, check: Omit<Check, 'id'>) => ({
  type: ChecksActionType.checkAddForArticle,
  payload: {
    articleId,
    check,
  },
});

export const addCheckForArticleSuccess = (articleId: string, check: Check) => ({
  type: ChecksActionType.checkAddForArticleSuccess,
  payload: { articleId, check },
});

export const addCheckForArticleFailure = () => ({
  type: ChecksActionType.checkAddForArticleFailure,
});

export const editCheckForArticle = (articleId: string, check: Check) => ({
  type: ChecksActionType.checkEditForArticle,
  payload: {
    articleId,
    check,
  },
});

export const editCheckForArticleSuccess = (articleId: string, check: Check) => ({
  type: ChecksActionType.checkEditForArticleSuccess,
  payload: { articleId, check },
});

export const editCheckForArticleFailure = () => ({
  type: ChecksActionType.checkEditForArticleFailure,
});

export const deleteCheckFromArticle = (articleId: string, checkId: string) => ({
  type: ChecksActionType.checkDeleteFromArticle,
  payload: { articleId, checkId },
});

export const deleteCheckFromArticleSuccess = (articleId: string, checkId: string) => ({
  type: ChecksActionType.checkDeleteFromArticleSuccess,
  payload: { articleId, checkId },
});

export const deleteCheckFromArticleFailure = () => ({
  type: ChecksActionType.checkDeleteFromArticleFailure,
});

export const changeCheckOrderForArticle = (
  articleId: string,
  sourceCheckId: string,
  destinationCheckId: string
) => ({
  type: ChecksActionType.checkChangeOrderForArticle,
  payload: { articleId, sourceCheckId, destinationCheckId },
});

export const changeCheckOrderForArticleSuccess = (
  articleId: string,
  sourceCheckId: string,
  destinationCheckId: string
) => ({
  type: ChecksActionType.checkChangeOrderForArticleSuccess,
  payload: { articleId, sourceCheckId, destinationCheckId },
});

export const changeCheckOrderForArticleFailure = () => ({
  type: ChecksActionType.checkChangeOrderForArticleFailure,
});

export const openAddCheckDialog = () => ({
  type: ChecksActionType.checkOpenAddCheckDialog,
});

export const closeAddCheckDialog = () => ({
  type: ChecksActionType.checkCloseAddCheckDialog,
});

export const openEditCheckDialog = () => ({
  type: ChecksActionType.checkOpenEditCheckDialog,
});

export const closeEditCheckDialog = () => ({
  type: ChecksActionType.checkCloseEditCheckDialog,
});

export const clearChecksForArticle = () => ({
  type: ChecksActionType.checksForArticleClear,
});
