import { createSelector } from 'reselect';

import { AppState } from '..';

export const productionRunsStateSelector = (state: AppState) => state.productionRuns;
export const productionRunsRunningListSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.productionRunsRunning
);

export const productionRunsTotalFetchingSelector = createSelector(
  productionRunsStateSelector,
  (state) => !!state.fetchingTotal
);

export const productionRunsRunningTotalCountSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.productionRunsRunningTotal
);

export const productionRunsRunningFetchingSelector = createSelector(
  productionRunsStateSelector,
  (state) => !!state.fetchingAllRunning
);

export const productionRunRunningSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.currentProductionRunRunning
);

export const productionRunsFinishedListSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.productionRunsFinished
);

export const productionRunsFinishedTotalCountSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.productionRunsFinishedTotal
);

export const productionRunsFinishedFetchingSelector = createSelector(
  productionRunsStateSelector,
  (state) => !!state.fetchingAllFinished
);

export const productionRunFinishedSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.currentProductionRunFinished
);

export const skipReasonDialogOpenSelector = createSelector(
  productionRunsStateSelector,
  (state) => !!state.isSkipReasonDialogOpen
);

export const freeTextDialogOpenSelector = createSelector(
  productionRunsStateSelector,
  (state) => !!state.isFreeTextDialogOpen
);

export const currentCheckResultSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.currentProductionRunCheckResult
);

export const checkHistoryListSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.currentProductionRunCheckHistory
);

export const checkResultsTotalCountSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.currentProductionRunCheckResultsTotal
);

export const checkResultsPageSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.currentProductionRunCheckHistoryPage
);

export const eventDescriptionDialogOpenSelector = createSelector(
  productionRunsStateSelector,
  (state) => !!state.isEventDescriptionDialogOpen
);

export const openDialogEventSelector = createSelector(
  productionRunsStateSelector,
  (state) => state.openDialogEvent
);
