import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { ProductionRun } from '../../model';
import { irisSpecialColors } from '../../theme';
import { getDurationText, dateAndTimeOptions } from '../../utils/date-utils';

const PREFIX = 'ProductionDetailsDataComponent';
const classes = {
  limitText: `${PREFIX}-limitText`,
  charge: `${PREFIX}-charge`,
  flexRow: `${PREFIX}-flexRow`,
  dataTable: `${PREFIX}-dataTable`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.dataTable}`]: {
    '& td:nth-of-type(1)': {
      color: irisSpecialColors.attributeCaption,
      paddingRight: theme.spacing(2),
    },
    marginTop: theme.spacing(1),
    borderSpacing: 0,
  },
  [`& .${classes.limitText}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [`& .${classes.flexRow}`]: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  [`& .${classes.charge}`]: {
    color: irisSpecialColors.attributeCaption,
    paddingRight: theme.spacing(2),
  },
}));

interface OwnProps {
  productionRun: ProductionRun;
  extended?: boolean;
}

export const ProductionDetailsDataComponent = (props: OwnProps) => {
  const { productionRun, extended } = props;
  const { t, i18n } = useTranslation(['common', 'data']);
  const startDate = new Date(productionRun.started);
  const finishedDate = new Date(productionRun.finished!);

  return (
    <Root>
      {extended ? (
        <Box className={classes.flexRow} data-testid="productionDataBatchnumberOverview">
          {productionRun.batchNumber && (
            <>
              <Box className={classes.charge}>{t('data:productionOrder.batchNumberShort')}:</Box>
              <Box className={classes.limitText}>{productionRun.batchNumber}</Box>
            </>
          )}
        </Box>
      ) : (
        <table className={classes.dataTable}>
          <tbody>
            <tr data-testid="productionDataStartTime">
              <td>{t('data:productionRun.start')}:</td>
              <td>
                {`${startDate.toLocaleString(i18n.language, dateAndTimeOptions)} ${t(
                  'common:time'
                )}`}
              </td>
            </tr>
            {productionRun.finished && (
              <>
                <tr data-testid="productionDataFinishTime">
                  <td>{t('data:productionOrder.end')}:</td>
                  <td>
                    {`${finishedDate.toLocaleString(i18n.language, dateAndTimeOptions)} ${t(
                      'common:time'
                    )}`}
                  </td>
                </tr>
                <tr data-testid="productionDataDuration">
                  <td>{t('data:productionRun.duration')}:</td>
                  <td>{getDurationText(startDate, finishedDate, t('common:hours'))}</td>
                </tr>
              </>
            )}
            {productionRun.amount && (
              <tr data-testid="productionDataAmount">
                <td>{t('data:productionOrder.quantity')}:</td>
                <td>{productionRun.amount}</td>
              </tr>
            )}
            {productionRun.batchNumber && (
              <tr data-testid="productionDataBatchNumber">
                <td>{t('data:productionOrder.batchNumberShort')}:</td>
                <td>{productionRun.batchNumber}</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </Root>
  );
};
