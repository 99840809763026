import { WebsocketConfig } from './../websockets';
import { changedProductionOverview } from './production.actions';
export const productionWebsocketConfig: WebsocketConfig = {
  url: 'production',
  actions: [
    {
      methodName: 'ProductionOverviewChanged',
      actionCreator: changedProductionOverview,
    },
  ],
};
