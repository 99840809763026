import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DateInfoComponent } from '../../../components/date-info/date-info.component';
import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { Line } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import { editLine, currentLineSelector, fetchLine } from '../../../store';

import { LineFormComponent } from './line-form.component';

export const LineEditPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(['data']);
  const lineId = id;
  const currentLine = useSelector(currentLineSelector);
  useEffect(() => {
    lineId && dispatch(fetchLine(lineId));
  }, [dispatch, lineId]);
  const submit = (line: Omit<Line, 'id' | 'lastModified'>) => {
    currentLine &&
      dispatch(
        editLine({
          ...line,
          id: currentLine.id,
          lastModified: currentLine.lastModified,
        })
      );
  };

  const cancel = () => {
    dispatch(push(`/${AppRoutePath.lines}`));
  };

  return (
    <DetailLayoutComponent
      backLinkText={t('data:line.backToLines')}
      backLinkTarget={`/${AppRoutePath.lines}`}
      headlineText={t('data:line.edit')}
    >
      {currentLine && (
        <>
          <LineFormComponent submit={submit} cancel={cancel} line={currentLine}></LineFormComponent>
          <DateInfoComponent dateVal={new Date(currentLine.lastModified)} />
        </>
      )}
    </DetailLayoutComponent>
  );
};
