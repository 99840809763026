import { createSelector } from 'reselect';

import { AppState } from '..';
import { ProductionOrder } from '../../model';

export const productionOrdersStateSelector = (state: AppState) => state.productionOrders;
export const productionOrderListSelector = createSelector(
  productionOrdersStateSelector,
  (state) => state.productionOrders
);

export const productionOrdersTotalCountSelector = createSelector(
  productionOrdersStateSelector,
  (state) => state.productionOrdersTotal
);

export const currentProductionOrderSelector = createSelector(
  productionOrdersStateSelector,
  (state) => state.currentProductionOrder
);

export const hasNotStartableProductionOrdersSelector = createSelector(
  productionOrderListSelector,
  (productionOrders: ProductionOrder[] | undefined) =>
    productionOrders
      ? productionOrders.some((productionOrder) => !productionOrder.startable)
      : false
);

export const productionOrdersFetchingSelector = createSelector(
  productionOrdersStateSelector,
  (state) => !!state.fetchingAll
);

export const productionOrdersTotalFetchingSelector = createSelector(
  productionOrdersStateSelector,
  (state) => !!state.fetchingTotal
);
