import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formClasses, FormRoot } from '../../../components/components-styles/form.styles';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../components/structure';
import { Device, DeviceType } from '../../../model';
import { irisSpacing } from '../../../theme';

import { getDeviceFormComponent } from './device.component';

const PREFIX = 'DeviceSelectComponent';
const classes = {
  img: `${PREFIX}-img`,
  deviceTypeHeadline: `${PREFIX}-deviceTypeHeadline`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.img}`]: {
    maxWidth: '100%',
  },
  [`& .${classes.deviceTypeHeadline}`]: {
    '& .MuiTypography-root.MuiTypography-h2': {
      marginTop: 0,
    },
  },
}));

export interface OwnProps {
  device?: Device;
  submit: (device: Omit<Device, 'id' | 'lastModified'>) => void;
  cancel: () => void;
}

export const DeviceSelectComponent = (props: OwnProps) => {
  const { device } = props;
  const { t } = useTranslation(['form', 'data']);

  const [currentDeviceType, setCurrentDeviceType] = useState<DeviceType | undefined>(
    device?.deviceType
  );

  const deviceFormProps = {
    ...props,
    deviceType: currentDeviceType,
  };

  return (
    <FormRoot>
      <Root>
        <ContainerOutsideWithHeader>
          <div className={classes.deviceTypeHeadline}>
            <Typography variant="h2">{t('data:device:deviceType')}</Typography>
          </div>
          <Paper>
            <ContainerInside>
              <Grid container spacing={irisSpacing.input.space}>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth={true} id="deviceTypeSelect" disabled={!!device}>
                    <InputLabel id="deviceType-label" variant="outlined" required>
                      {t('data:device.deviceType')}
                    </InputLabel>
                    <Select
                      labelId="deviceType-label"
                      label={`${t('data:device.deviceType')} *`}
                      variant="outlined"
                      value={currentDeviceType ? currentDeviceType : ''}
                      onChange={(event) => setCurrentDeviceType(event.target.value as number)}
                      data-testid="selectDeviceType"
                    >
                      <MenuItem
                        value={DeviceType.scale}
                        key={1}
                        data-testid={`deviceType-${DeviceType.scale}`}
                      >
                        {t([
                          `data:device.deviceTypes.${DeviceType.scale}`,
                          `data:device.deviceTypes.notFound`,
                        ])}
                      </MenuItem>
                      <MenuItem
                        value={DeviceType.virtual}
                        key={2}
                        data-testid={`deviceType-${DeviceType.virtual}`}
                      >
                        {t([
                          `data:device.deviceTypes.${DeviceType.virtual}`,
                          `data:device.deviceTypes.notFound`,
                        ])}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </ContainerInside>
          </Paper>
        </ContainerOutsideWithHeader>
        {currentDeviceType ? (
          getDeviceFormComponent(deviceFormProps)
        ) : (
          <Grid item xs={12} sm={12}>
            <Box className={formClasses.buttons}>
              <Button
                data-testid="cancel-btn"
                color="secondary"
                variant="contained"
                onClick={() => props.cancel()}
              >
                {t('form:cancel')}
              </Button>
              <Button
                data-testid="submit-btn"
                color="primary"
                variant="contained"
                disabled
                type="submit"
              >
                {t('form:submit')}
              </Button>
            </Box>
          </Grid>
        )}
      </Root>
    </FormRoot>
  );
};
