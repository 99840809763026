import { Box, Checkbox, FormControlLabel, FormGroup, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ContainerInside, ContainerOutside } from '../../../components/structure';
import { CheckAttributeType } from '../../../model';
import {
  checksForArticleSelector,
  clearChecksForArticle,
  fetchChecksForArticle,
} from '../../../store';

export interface OwnProps {
  articleId?: string;
  afterStart: boolean;
  afterDisruption: boolean;
  afterStartChange?: (checked: boolean) => void;
  afterDisruptionChange?: (checked: boolean) => void;
}

const PREFIX = 'TestRunFormComponent';
const classes = {
  enabled: `${PREFIX}-enabled`,
  disabled: `${PREFIX}-disabled`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [`&.${classes.enabled}`]: {},

  [`&.${classes.disabled}`]: {
    color: theme.palette.action.disabled,
  },
}));

export const TestRunFormComponent = (props: OwnProps) => {
  const { articleId, afterStart, afterDisruption, afterStartChange, afterDisruptionChange } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const articleChecks = useSelector(checksForArticleSelector);

  const hasFertigPackV = useMemo(() => {
    return (
      !!articleId &&
      !!articleChecks &&
      articleChecks.length > 0 &&
      articleChecks.some(
        (c) => c.checkAttribute.checkAttributeType === CheckAttributeType.FertigPackV
      )
    );
  }, [articleId, articleChecks]);

  useEffect(() => {
    articleId && dispatch(fetchChecksForArticle(articleId));
    return () => {
      dispatch(clearChecksForArticle());
    };
  }, [articleId, dispatch]);

  const handleAfterStartChange = (event: React.ChangeEvent<{}>, checked: boolean) => {
    afterStartChange && afterStartChange(checked);
  };

  const handleAfterDisruptionChange = (event: React.ChangeEvent<{}>, checked: boolean) => {
    afterDisruptionChange && afterDisruptionChange(checked);
  };

  return (
    <ContainerOutside>
      <Paper>
        <ContainerInside>
          <StyledBox
            data-testid={'testRunForm'}
            className={hasFertigPackV ? classes.enabled : classes.disabled}
          >
            <Typography variant="h3">{t('data:testRun.testRun')}</Typography>
            <Typography>
              {`${t('data:testRun.explanation')} ${t('data:testRun.persistenceA')} `}
              <b>{t('data:testRun.persistenceB')}</b>
              {` ${t('data:testRun.persistenceC')} ${t('data:testRun.runIn')}`}
            </Typography>

            <FormGroup aria-label={t('data:check.checkTimes')}>
              <FormControlLabel
                data-testid="afterStartCheckbox"
                aria-label={t('data:check.afterStart')}
                checked={hasFertigPackV && afterStart}
                onChange={handleAfterStartChange}
                name="productionOrderTestRunAfterStart"
                control={<Checkbox />}
                label={t('data:check.afterStart') as string}
                labelPlacement={'end'}
                disabled={!hasFertigPackV}
              />
              <FormControlLabel
                data-testid="afterDisruptionCheckbox"
                aria-label={t('data:check.afterDisruption')}
                checked={hasFertigPackV && afterDisruption}
                onChange={handleAfterDisruptionChange}
                name="productionOrderTestRunAfterDisruption"
                control={<Checkbox />}
                label={t('data:check.afterDisruption') as string}
                labelPlacement={'end'}
                disabled={!hasFertigPackV}
              />
            </FormGroup>
          </StyledBox>
        </ContainerInside>
      </Paper>
    </ContainerOutside>
  );
};
