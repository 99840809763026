import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import noImagePlaceholder from '../../../assets/img/no-image-placeholder.svg';
import noThumbnailPlaceholder from '../../../assets/img/no-thumbnail-placeholder.svg';
import {
  IMAGE_HEIGHT_SMALL,
  IMAGE_WIDTH_SMALL,
  IMAGE_HEIGHT_BIG,
  IMAGE_WIDTH_BIG,
} from '../../../constants';
import { backendUrl } from '../../../http';

export interface OwnProps {
  imageUrl?: string;
  height: number;
  width: number;
  placeholderImage?: string;
  alttext?: string;
  className?: string;
}
const PREFIX = 'CenteredImageComponent';
const classes = {
  imageContainer: `${PREFIX}-imageContainer`,
  image: `${PREFIX}-image`,
  imageInTable: `${PREFIX}-imageInTable`,
};

const CenteredImageComponentRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'props',
})<OwnProps>(({ theme, height, width }) => ({
  [`& .${classes.imageContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: `${height}px`,
    width: `${width}px`,
  },
  [`& .${classes.image}`]: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

export const CenteredImageComponent = (props: OwnProps) => {
  const { imageUrl, alttext, placeholderImage, height, width } = props;
  const { t } = useTranslation(['form']);

  return (
    <CenteredImageComponentRoot height={height} width={width}>
      <div className={`${classes.imageContainer} ${props.className}`}>
        <img
          className={classes.image}
          alt={alttext || t('form:image')}
          src={imageUrl ? `${backendUrl}${imageUrl}` : placeholderImage || ''}
        />
      </div>
    </CenteredImageComponentRoot>
  );
};

export type CenteredImageProps = Pick<OwnProps, 'alttext' | 'imageUrl'>;

export const CenteredImageProductionDetailsComponent = (props: CenteredImageProps) => {
  return (
    <CenteredImageComponent
      {...props}
      height={IMAGE_HEIGHT_BIG}
      width={IMAGE_WIDTH_BIG}
      placeholderImage={noImagePlaceholder}
    />
  );
};

export const CenteredImageProductionOverviewComponent = (props: CenteredImageProps) => {
  return (
    <CenteredImageComponent
      {...props}
      height={IMAGE_HEIGHT_SMALL}
      width={IMAGE_WIDTH_SMALL}
      placeholderImage={noThumbnailPlaceholder}
    />
  );
};

const CenteredImageInTableComponentRoot = styled('div')(({ theme }) => ({
  [`& .${classes.imageInTable}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const CenteredImageInTableComponent = (props: CenteredImageProps) => {
  return (
    <CenteredImageInTableComponentRoot>
      <CenteredImageComponent
        className={classes.imageInTable}
        {...props}
        height={IMAGE_HEIGHT_SMALL}
        width={IMAGE_WIDTH_SMALL}
        placeholderImage={noThumbnailPlaceholder}
      />
    </CenteredImageInTableComponentRoot>
  );
};
