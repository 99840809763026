import { Box, Typography } from '@mui/material';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DateInfoComponent } from '../../../components/date-info/date-info.component';
import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { CheckAttribute } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import {
  editCheckAttribute,
  currentCheckAttributeSelector,
  fetchCheckAttribute,
} from '../../../store';
import {
  CheckAttributeImages,
  CheckAttributeSelectComponent,
} from '../check-attribute/check-attribute-selection/check-attribute-select.component';

export const CheckAttributeEditPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const checkAttributeId = id;
  const currentCheckAttribute = useSelector(currentCheckAttributeSelector);
  useEffect(() => {
    checkAttributeId && dispatch(fetchCheckAttribute(checkAttributeId));
  }, [dispatch, checkAttributeId]);
  const submit = (
    checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>,
    images?: CheckAttributeImages
  ) => {
    currentCheckAttribute &&
      dispatch(
        editCheckAttribute(
          {
            ...checkAttribute,
            id: currentCheckAttribute.id,
            editable: currentCheckAttribute.editable,
            lastModified: currentCheckAttribute.lastModified,
          },
          images?.yesNoImage,
          images?.trafficLightGreenImage,
          images?.trafficLightYellowImage,
          images?.trafficLightRedImage,
          images?.freeTextImage
        )
      );
  };

  const cancel = () => {
    dispatch(push(`/${AppRoutePath.checkAttributes}`));
  };

  const { t } = useTranslation('data');

  return (
    <DetailLayoutComponent
      backLinkText={t('data:checkAttribute.backToCheckAttributes')}
      backLinkTarget={`/${AppRoutePath.checkAttributes}`}
      headlineText={t('data:checkAttribute.edit')}
    >
      {currentCheckAttribute && currentCheckAttribute.editable ? (
        <>
          {
            <CheckAttributeSelectComponent
              submit={submit}
              cancel={cancel}
              checkAttribute={currentCheckAttribute}
            ></CheckAttributeSelectComponent>
          }
          <Box ml={1}>
            <DateInfoComponent dateVal={new Date(currentCheckAttribute.lastModified)} />
          </Box>
        </>
      ) : (
        currentCheckAttribute &&
        !currentCheckAttribute.editable && (
          <Typography style={{ marginLeft: '8px' }}>{t('license:licenseMissing')}</Typography>
        )
      )}
    </DetailLayoutComponent>
  );
};
