import { push } from 'connected-react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { InfoboxComponent } from '../../../components/infobox/infobox.component';
import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { ContainerOutside } from '../../../components/structure';
import { Device } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import { addDevice, lineAlreadySetSelector } from '../../../store';
import { irisSpacing } from '../../../theme';

import { DeviceSelectComponent } from './device-select.component';

export const DeviceNewPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['data']);
  const lineAlreadySet = useSelector(lineAlreadySetSelector);

  const submit = (device: Omit<Device, 'id' | 'lastModified'>) => {
    dispatch(addDevice(device));
  };

  const cancel = () => {
    dispatch(push(`/${AppRoutePath.devices}`));
  };

  return (
    <DetailLayoutComponent
      backLinkText={t('data:device.backToDeviceManager')}
      backLinkTarget={`/${AppRoutePath.devices}`}
      headlineText={t('data:device.createNew')}
    >
      {lineAlreadySet && (
        <ContainerOutside sx={{ marginBottom: irisSpacing.container.space }}>
          <InfoboxComponent subline={t('data:device.lineAlreadySetErrorText')} type={'error'} />
        </ContainerOutside>
      )}
      <DeviceSelectComponent submit={submit} cancel={cancel} />
    </DetailLayoutComponent>
  );
};
