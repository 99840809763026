import GetAppIcon from '@mui/icons-material/GetApp';
import { Button } from '@mui/material';
import React from 'react';

export interface OwnProps {
  onClick: () => void;
  disabled?: boolean;
  text?: string;
  testId?: string;
}

export const ExportButtonComponent = (props: OwnProps) => {
  const { text, onClick, disabled, testId } = props;
  return (
    <Button
      data-testid={testId ? testId : 'exportBtn'}
      color="secondary"
      variant="contained"
      startIcon={<GetAppIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
