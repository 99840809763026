import { Article } from './article.model';
import { Check } from './check.model';
import { Disruption } from './disruption.model';
import { ProductionOrder } from './production-order.model';
import { ProductionRunEvent } from './production-run-event.model';

export interface ProductionRunArticle
  extends Omit<Article, 'id' | 'productionLine' | 'checkPeriodicity'> {
  productionLine: {
    id: string;
    name: string;
  };
}

export interface ProductionRun
  extends Omit<
    ProductionOrder,
    'lastModified' | 'article' | 'startable' | 'startErrors' | 'order'
  > {
  status: ProductionRunStatus;
  started: string;
  finished?: string;
  checks: Check[];
  openCheckExecutions: OpenCheckExecution[];
  article: ProductionRunArticle;
  disrupted: boolean;
  disruptions: Disruption[];
  events: ProductionRunEvent[];
  checkSamplesDone: number;
  checkSamplesPassed: number;
  checkSamplesSufficient: number;
  checkSamplesUnrated: number;
  checkSamplesFailed: number;
  checkSamplesSkipped: number;
  checksDone: number;
  issues: ProductionRunIssue[];
  fertigPackVData: FertigPackVData;
}

export interface FertigPackVData {
  statistics?: FertigPackVStatistics;
  testRunStatistics?: FertigPackVStatistics;
  hasCriticalTU1Violation?: boolean;
}

export interface FertigPackVStatistics {
  checkSamplesDone?: number;
  checkSamplesTO2?: number;
  checkSamplesTO1?: number;
  checkSamplesOK?: number;
  checkSamplesTU1?: number;
  checkSamplesTU2?: number;
  checkSamplesAverageWeight?: number;
  checkSamplesAverageVolume?: number;
  checkSamplesAverageWeightPassed?: boolean;
  checkSamplesAverageWeightSufficient?: boolean;
  checkSamplesAverageWeightFailed?: boolean;
  checkSamplesAverageFertigPackVWeightStatus?: FertigPackVStatus;
  checkSamplesStandardDeviation?: number;
  checkSamplesStandardVolumeDeviation?: number;
  percentageSamplesTU1?: number;
}

export enum ProductionRunStatus {
  InProduction = 1,
  Paused = 2,
  Finishing = 3,
  Done = 4,
  InTestRunAfterStart = 5,
  InTestRunAfterDisruption = 6,
}

export enum FertigPackVStatus {
  OK = 1,
  WarningLow = 2,
  CriticalLow = 3,
  WarningHigh = 4,
  CriticalHigh = 5,
}

export interface OpenCheckExecution {
  productionRunCheckId: string;
  productionRunCheckExecutionId: string;
}

export interface ProductionRunIssue {
  issueType: IssueType;
  issueCode: IssueCode;
  issueValue?: number;
}

export enum IssueType {
  Error = 1,
  Warning = 2,
  Unknown = 99,
}

export enum IssueCode {
  AverageWeightBelowNominal = 1,
  FertigPackVTU2Violation = 2,
  FertigPackVTU1Violation = 3,
  Unknown = 99,
}

export interface ProductionRunIssueNotification extends ProductionRunIssue {
  productionRunId: string;
  orderNumber: string;
}
