import React, { FunctionComponent } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { User, UserRole } from '../model';

import { AppRoutePath } from './routes';

export interface OwnProps extends RouteProps {
  loggedInUser?: User;
  licenseExpired: boolean;
}

export const PublicRoute: FunctionComponent<OwnProps> = ({
  component: Component,
  loggedInUser,
  licenseExpired,
  ...rest
}) => {
  if (loggedInUser) {
    if (licenseExpired) {
      if (loggedInUser.role === UserRole.Admin) {
        return <Redirect to={`/${AppRoutePath.license}`} />;
      }
    } else {
      return (
        <Redirect
          to={`/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.running}/`}
        />
      );
    }
  }
  return <Route {...rest} component={Component} />;
};
