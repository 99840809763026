import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getSuffix } from '../../helper';
import { ToleranceResult } from '../../model';
import { irisCheckResultColors, irisCustomColors } from '../../theme';
import { FormattedNumberOutputComponent } from '../form/formatted-number-output/formatted-number-output.component';

import { StylePropsWrapper, toleranceBarClasses, ToleranceBarRoot } from './tolerance-bar.styles';

export interface OwnProps {
  nominalValue?: number;
  tolerances?: ToleranceResult;
  lowerTolerance?: number;
  upperTolerance?: number;
  averageValue?: number;
  averagePassed?: boolean;
  averageSufficient?: boolean;
  averageFailed?: boolean;
  width?: number;
  hasVolumeValues?: boolean;
}

const getStyleProps = (props: OwnProps) => {
  const returnProps: StylePropsWrapper = {
    styleProps: {
      averageValueMarginLeft: calcAverageValueMarginLeft(props),
      lowerToleranceRatio: calcLowerToleranceRatio(props),
      upperToleranceRatio: calcUpperToleranceRatio(props),
      minWidth: props.width,
    },
  };
  return returnProps;
};

const getTolerance = (props: OwnProps, forTo1?: boolean) => {
  if (forTo1) {
    return props.tolerances?.volume ? props.tolerances.volume.tO1 : props.tolerances?.weight?.tO1;
  } else {
    return props.tolerances?.volume ? props.tolerances.volume.tU1 : props.tolerances?.weight?.tU1;
  }
};

const calcAverageValueMarginLeft = (props: OwnProps) => {
  if (props && props.tolerances && props.nominalValue && props.averageValue) {
    const percentCenter: number = 0;
    const percentOutmost: number = 100;

    const tu1Tolerance = getTolerance(props);

    const centerToOutmostDelta = Math.abs((props.nominalValue - tu1Tolerance!) * 3);

    const centerToAverageDelta: number = Math.abs(props.nominalValue - props.averageValue);
    const sign: number = props.nominalValue <= props.averageValue ? 1 : -1;

    let percentResult: number = percentCenter;
    if (centerToAverageDelta > centerToOutmostDelta) percentResult = percentOutmost;
    else percentResult = (centerToAverageDelta / centerToOutmostDelta) * percentOutmost;
    percentResult *= sign;

    return percentResult + '%';
  } else return undefined;
};

const calcLowerToleranceRatio = (props: OwnProps) => {
  const tu1Tolerance = getTolerance(props);
  return calcToleranceRatio(props.nominalValue, tu1Tolerance!, props.lowerTolerance);
};

const calcUpperToleranceRatio = (props: OwnProps) => {
  const to1Tolerance = getTolerance(props, true);
  return calcToleranceRatio(props.nominalValue, to1Tolerance!, props.upperTolerance);
};

const calcToleranceRatio = (
  nominalValue?: number,
  tolerance?: number,
  customTolerance?: number
) => {
  if (nominalValue && tolerance && customTolerance) {
    const basedTolerance = nominalValue - tolerance;
    const basedCustomTolerance = nominalValue - customTolerance;
    const customToleranceRatio = (100 / basedTolerance) * basedCustomTolerance;

    return customToleranceRatio;
  } else return 50;
};

export const ToleranceBarComponent = (props: OwnProps) => {
  const { t } = useTranslation(['table']);
  const {
    nominalValue,
    tolerances,
    lowerTolerance,
    upperTolerance,
    averageValue,
    averagePassed,
    averageSufficient,
    averageFailed,
    hasVolumeValues,
  } = props;

  const [currentTolerances, setCurrentTolerances] = useState(
    tolerances?.volume ? tolerances.volume : tolerances?.weight
  );
  const [currentSuffix, setCurrentSuffix] = useState(
    hasVolumeValues ? getSuffix(hasVolumeValues) : getSuffix(!!tolerances?.volume)
  );

  useEffect(() => {
    setCurrentTolerances(tolerances?.volume ? tolerances.volume : tolerances?.weight);
    setCurrentSuffix(
      hasVolumeValues ? getSuffix(hasVolumeValues) : getSuffix(!!tolerances?.volume)
    );
  }, [tolerances, hasVolumeValues]);

  const showLowerToleranceElements = () => {
    return (
      currentTolerances &&
      nominalValue &&
      lowerTolerance &&
      lowerTolerance >= currentTolerances?.tU1 &&
      lowerTolerance < nominalValue
    );
  };

  const showUpperToleranceElements = () => {
    return (
      currentTolerances &&
      nominalValue &&
      upperTolerance &&
      upperTolerance <= currentTolerances?.tO1 &&
      upperTolerance > nominalValue
    );
  };

  const getLowerToleranceItemClassName = (baseClassName: string) => {
    const ratio = calcLowerToleranceRatio(props);
    return ratio < 25 ? `${baseClassName} ${toleranceBarClasses.rightAlignedLabel}` : baseClassName;
  };

  const getUpperToleranceItemClassName = (baseClassName: string) => {
    const ratio = calcUpperToleranceRatio(props);
    return ratio < 25 ? `${baseClassName} ${toleranceBarClasses.leftAlignedLabel}` : baseClassName;
  };

  const innerLowerToleranceBar = () => {
    if (showLowerToleranceElements()) {
      return (
        <div className={`${toleranceBarClasses.bar}`}>
          <Grid container className={toleranceBarClasses.toleranceBarContainer}>
            <div
              className={`${toleranceBarClasses.lowerToleranceYellowBar} ${toleranceBarClasses.yellowBar}`}
            />
            <Box
              className={getLowerToleranceItemClassName(toleranceBarClasses.lineAndLabelBox)}
              data-testid={'lowerToleranceLabelItem'}
            >
              <div className={toleranceBarClasses.verticalLine} />
              <div
                className={getLowerToleranceItemClassName(
                  toleranceBarClasses.absoluteLabelItemContainer
                )}
              >
                <Typography> {t('data:article.lowerToleranceCustomShort')}</Typography>
                <FormattedNumberOutputComponent value={lowerTolerance} suffix={currentSuffix} />
              </div>
            </Box>
            <div
              className={`${toleranceBarClasses.lowerToleranceGreenBar} ${toleranceBarClasses.greenBar}`}
            />
          </Grid>
        </div>
      );
    } else {
      return <div className={`${toleranceBarClasses.bar} ${toleranceBarClasses.greenBar}`} />;
    }
  };

  const innerUpperToleranceBar = () => {
    if (showUpperToleranceElements()) {
      return (
        <div className={`${toleranceBarClasses.bar}`}>
          <Grid container className={toleranceBarClasses.toleranceBarContainer}>
            <div
              className={`${toleranceBarClasses.upperToleranceGreenBar} ${toleranceBarClasses.greenBar}`}
            />
            <Box className={getUpperToleranceItemClassName(toleranceBarClasses.lineAndLabelBox)}>
              <div className={toleranceBarClasses.verticalLine} />
              <div
                className={getUpperToleranceItemClassName(
                  toleranceBarClasses.absoluteLabelItemContainer
                )}
                data-testid={'upperToleranceLabelItem'}
              >
                <Typography> {t('data:article.upperToleranceCustomShort')}</Typography>
                <FormattedNumberOutputComponent value={upperTolerance} suffix={currentSuffix} />
              </div>
            </Box>
            <div
              className={`${toleranceBarClasses.upperToleranceYellowBar} ${toleranceBarClasses.yellowBar}`}
            />
          </Grid>
        </div>
      );
    } else {
      return <div className={`${toleranceBarClasses.bar} ${toleranceBarClasses.greenBar}`} />;
    }
  };

  return (
    <ToleranceBarRoot styleProps={getStyleProps(props).styleProps}>
      <Grid
        container
        className={toleranceBarClasses.outerContainer}
        data-testid={'toleranceBarOuterContainer'}
      >
        <Grid container className={toleranceBarClasses.labelContainer}>
          <div className={toleranceBarClasses.labelPaddingItem} />
          <div className={toleranceBarClasses.labelItemContainer}>
            <Typography> {t('data:article.lowerTolerance2Short')}</Typography>
            <FormattedNumberOutputComponent
              value={currentTolerances?.tU2 || '-'}
              suffix={currentSuffix}
            />
          </div>
          <div className={toleranceBarClasses.labelItemContainer}>
            <Typography> {t('data:article.lowerTolerance1Short')}</Typography>
            <FormattedNumberOutputComponent
              value={currentTolerances?.tU1 || '-'}
              suffix={currentSuffix}
            />
          </div>
          <div className={toleranceBarClasses.labelItemContainer}>
            <Typography> {t('data:article.nominalValue')}</Typography>
            <FormattedNumberOutputComponent value={nominalValue || '-'} suffix={currentSuffix} />
          </div>
          <div className={toleranceBarClasses.labelItemContainer}>
            <Typography> {t('data:article.upperTolerance1Short')}</Typography>
            <FormattedNumberOutputComponent
              value={currentTolerances?.tO1 || '-'}
              suffix={currentSuffix}
            />
          </div>
          <div className={toleranceBarClasses.labelItemContainer}>
            <Typography> {t('data:article.upperTolerance2Short')}</Typography>
            <FormattedNumberOutputComponent
              value={currentTolerances?.tO2 || '-'}
              suffix={currentSuffix}
            />
          </div>
          <div className={toleranceBarClasses.labelPaddingItem} />
        </Grid>
        <Grid container className={toleranceBarClasses.barContainer}>
          <div className={`${toleranceBarClasses.bar} ${toleranceBarClasses.redBar}`} />
          <div className={toleranceBarClasses.verticalLine} />
          <div className={`${toleranceBarClasses.bar} ${toleranceBarClasses.yellowBar}`} />
          <div className={toleranceBarClasses.verticalLine} />
          {innerLowerToleranceBar()}
          <div className={toleranceBarClasses.verticalLine} />
          {innerUpperToleranceBar()}
          <div className={toleranceBarClasses.verticalLine} />
          <div className={`${toleranceBarClasses.bar} ${toleranceBarClasses.yellowBar}`} />
          <div className={toleranceBarClasses.verticalLine} />
          <div className={`${toleranceBarClasses.bar} ${toleranceBarClasses.redBar}`} />
        </Grid>
        {averageValue && (
          <>
            <Grid
              container
              className={`${toleranceBarClasses.averageContainer}`}
              data-testid={'averageContainer'}
            >
              <div className={`${toleranceBarClasses.moveContainer}`} data-testid={'moveContainer'}>
                <div className={toleranceBarClasses.averageBoxContainer}>
                  <div className={toleranceBarClasses.verticalLineStrong} />
                  <Box
                    className={toleranceBarClasses.averageBox}
                    data-testid={'averageBox'}
                    bgcolor={
                      averagePassed
                        ? irisCheckResultColors.passed
                        : averageSufficient
                        ? irisCheckResultColors.sufficient
                        : averageFailed
                        ? irisCheckResultColors.failed
                        : irisCustomColors.irisGrayBright
                    }
                  >
                    <Box className={toleranceBarClasses.xAcross}>{'x̄'}</Box>
                    <FormattedNumberOutputComponent value={averageValue} suffix={currentSuffix} />
                  </Box>
                  <div className={toleranceBarClasses.verticalLineStrong} />
                </div>
              </div>
            </Grid>
          </>
        )}
      </Grid>
      {showLowerToleranceElements() || showUpperToleranceElements() ? (
        <div
          className={toleranceBarClasses.customToleranceBottomSpace}
          data-testid={'customToleranceBottomSpace'}
        />
      ) : (
        averageValue && (
          <div
            className={toleranceBarClasses.averageBoxBottomSpace}
            data-testid={'averageBoxBottomSpace'}
          />
        )
      )}
    </ToleranceBarRoot>
  );
};
