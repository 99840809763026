import { DisruptionCode } from './disruption.model';

export interface ProductionRunCheckExecutionSample
  extends Omit<
      ProductionRunCheckExecutionWeighingSampleResult,
      'weighingOn' | 'weightDisplay' | 'weightGross' | 'weightNet'
    >,
    Omit<ProductionRunCheckExecutionSampleResult, 'resultValue'> {
  checkResult: CheckResult;
  cancelledBy?: DisruptionCode;
  weighingOn?: string;
  weightDisplay?: number;
  weightGross?: number;
  weightNet?: number;
  temperature?: number;
  freeText?: string;
  barcode?: string;
  passed?: boolean;
  failed?: boolean;
  unrated?: boolean;
  sufficient?: boolean;
}

export interface ProductionRunCheckExecutionSampleResult {
  checkResult: CheckResult;
  resultValue: string;
}

export interface ProductionRunCheckExecutionWeighingSampleResult extends ScaleWeighing {
  checkResult?: CheckResult;
  fertigPackVCategory?: FertigPackVCategory;
  volumeNet?: number;
  volumeGross?: number;
  volumeDisplay?: number;
}

export interface ScaleWeighing {
  weighingOn: string;
  weightDisplay: number;
  weightGross: number;
  weightNet: number;
  alibiNumber?: number;
}

export enum CheckResult {
  Unknown = 1,
  Passed = 2,
  Sufficient = 3,
  Failed = 4,
  Unrated = 5,
  Skipped = 6,
  Cancelled = 7,
}

export enum FertigPackVCategory {
  TO2 = 1,
  TO1 = 2,
  OK = 3,
  TU1 = 4,
  TU2 = 5,
}
