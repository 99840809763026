import { InternalErrorCode } from '../http/http-error-codes';

import { ProductionRunIssueNotification } from './production-run.model';

export interface Notification {
  type: NotificationType;
  messageKey: string;
  messageParameters?: { [id: string]: string };
  issue?: ProductionRunIssueNotification;
  errorCode?: InternalErrorCode;
}

export enum NotificationType {
  error = 'error',
  success = 'success',
}
