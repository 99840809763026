import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { ProductionOrder } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import {
  addProductionOrder,
  articleListSelector,
  fetchArticles,
  fetchLines,
  lineListSelector,
} from '../../../store';

import { ProductionOrderFormComponent } from './production-order-form.component';

export const ProductionOrderNewPage = () => {
  const dispatch = useDispatch();
  const articles = useSelector(articleListSelector);
  const lines = useSelector(lineListSelector);
  const { t } = useTranslation(['data']);
  useEffect(() => {
    dispatch(fetchArticles());
    dispatch(fetchLines());
  }, [dispatch]);
  const submit = (
    productionOrder: Omit<
      ProductionOrder,
      'id' | 'order' | 'lastModified' | 'startable' | 'startErrors'
    >
  ) => {
    dispatch(addProductionOrder(productionOrder));
  };

  const cancel = () => {
    dispatch(push(`/${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}/`));
  };

  return (
    <DetailLayoutComponent
      backLinkText={t('data:productionOrder.backToOverview')}
      backLinkTarget={`/${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}/`}
      headlineText={t('data:productionOrder.new')}
    >
      {!articles ? (
        <div>{t('data:productionOrder.noArticleDataAvailable')}</div>
      ) : !lines ? (
        <div>{t('data:productionOrder.noLineDataAvailable')}</div>
      ) : (
        <ProductionOrderFormComponent
          submit={submit}
          cancel={cancel}
          articles={articles}
          lines={lines}
        ></ProductionOrderFormComponent>
      )}
    </DetailLayoutComponent>
  );
};
