import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Toolbar, useMediaQuery } from '@mui/material';
import { IconButton } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { UserRole } from '../../../model';
import privateRoutes from '../../../routes';
import {
  loggedInUserSelector,
  changePasswordAfterResetSelector,
  licenseExpireWarningSelector,
  licenseExpirationDaysSelector,
  licenseExpiredSelector,
  startWsConnection,
  productionRunIssuesWebsocketConfig,
} from '../../../store';
import { irisCustomColors, irisSidebarWidth, sidebarBreakpoint } from '../../../theme';
import { SxDiv } from '../../helper';
import {
  height as licenseInfoHeight,
  LicenseExpirationInfoComponent,
} from '../../license-info/license-expiration-info.component';
import { SidebarComponent } from '../../sidebar/sidebar/sidebar.component';

const PREFIX = 'AppLayoutComponent';
export const barRootClasses = {
  appBar: `${PREFIX}-appBar`,
  menuButton: `${PREFIX}-menuButton`,
  lowMenu: `${PREFIX}-lowMenu`,
};
export const mainRootClasses = {
  container: `${PREFIX}-container`,
  toolbar: `${PREFIX}-toolbar`,
  content: `${PREFIX}-content`,
};

const BarRoot = styled('div')(({ theme }) => ({
  [`& .${barRootClasses.appBar}`]: {
    [theme.breakpoints.up(sidebarBreakpoint)]: {
      width: `calc(100% - ${irisSidebarWidth}px)`,
      marginLeft: irisSidebarWidth,
    },
  },
  [`& .${barRootClasses.menuButton}`]: {
    color: irisCustomColors.irisWhite,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(sidebarBreakpoint)]: {
      display: 'none',
    },
    zIndex: 5,
  },
  [`& .${barRootClasses.lowMenu}`]: {
    marginTop: `${licenseInfoHeight}px`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: irisCustomColors.irisBlueGray,
}));

const MainElement = styled('main')(({ theme }) => ({
  [`&.${mainRootClasses.content}`]: {
    flexGrow: 1,
  },
  [`& .${mainRootClasses.container}`]: {
    paddingTop: `${licenseInfoHeight}px`,
  },
  [`& .${mainRootClasses.toolbar}`]: { minHeight: licenseInfoHeight, maxHeight: licenseInfoHeight },
}));

export const AppLayoutComponent = () => {
  const { t } = useTranslation(['common']);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const loggedInUser = useSelector(loggedInUserSelector);
  const showLicenseExpirationWarning =
    useSelector(licenseExpireWarningSelector) && loggedInUser?.role === UserRole.Admin;
  const licenseExpirationDays = useSelector(licenseExpirationDaysSelector) || 0;
  const licenseExpired = useSelector(licenseExpiredSelector);
  const changePasswordAfterReset = useSelector(changePasswordAfterResetSelector);
  const toolbarHeight = 56 + (showLicenseExpirationWarning ? licenseInfoHeight : 0);
  const sidebarFoldable = !useMediaQuery((theme: Theme) => theme.breakpoints.up(sidebarBreakpoint));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startWsConnection(productionRunIssuesWebsocketConfig));
  }, [dispatch]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <BarRoot>
        <SxDiv sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
          {showLicenseExpirationWarning && (
            <LicenseExpirationInfoComponent
              expirationDays={licenseExpirationDays}
            ></LicenseExpirationInfoComponent>
          )}
        </SxDiv>
        <SxDiv sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none' } }}>
          <AppBar
            position={showLicenseExpirationWarning ? 'absolute' : 'fixed'}
            className={barRootClasses.appBar}
          >
            {showLicenseExpirationWarning && (
              <LicenseExpirationInfoComponent
                expirationDays={licenseExpirationDays}
              ></LicenseExpirationInfoComponent>
            )}
            <StyledToolbar
              style={{ minHeight: toolbarHeight, maxHeight: toolbarHeight, paddingInline: 16 }}
            >
              <IconButton
                color="inherit"
                aria-label={t('common:openDrawer')}
                edge="start"
                onClick={handleDrawerToggle}
                className={`${barRootClasses.menuButton} ${
                  showLicenseExpirationWarning ? barRootClasses.lowMenu : undefined
                }`}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </StyledToolbar>
          </AppBar>
        </SxDiv>
        <SidebarComponent
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen && sidebarFoldable}
          licenseExpirationWarning={!!showLicenseExpirationWarning}
          licenseExpired={!!licenseExpired}
        />
      </BarRoot>
      <MainElement className={mainRootClasses.content}>
        <SxDiv sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none' } }}>
          <div className={mainRootClasses.toolbar} />
        </SxDiv>
        <div className={showLicenseExpirationWarning ? mainRootClasses.container : undefined}>
          {privateRoutes(changePasswordAfterReset, !!licenseExpired, loggedInUser)}
        </div>
      </MainElement>
    </>
  );
};
