import { Action } from 'redux';
import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { Check } from '../../model';
import { sagaErrorHandler } from '../saga-error-handler';

import {
  fetchChecksForArticle,
  fetchChecksForArticleSuccess,
  fetchChecksForArticleFailure,
  fetchCheckForArticle,
  fetchCheckForArticleSuccess,
  fetchCheckForArticleFailure,
  ChecksActionType,
  addCheckForArticle as addCheckForArticleAction,
  addCheckForArticleSuccess,
  addCheckForArticleFailure,
  editCheckForArticle as editCheckForArticleAction,
  editCheckForArticleSuccess,
  editCheckForArticleFailure,
  deleteCheckFromArticle as deleteCheckFromArticleAction,
  deleteCheckFromArticleSuccess,
  deleteCheckFromArticleFailure,
  changeCheckOrderForArticle as changeCheckOrderForArticleAction,
  changeCheckOrderForArticleSuccess,
  changeCheckOrderForArticleFailure,
} from './checks.actions';

const articlesUrl = 'articles';
const articlesChecksUrl = 'checks';

function* getChecksByArticleId(action: Action) {
  const { id } = (action as ReturnType<typeof fetchChecksForArticle>).payload;
  try {
    const response = yield* call<[boolean, string], SagaRequest<{ data: Check[] }>>(
      SagaRequestHelper.get,
      true,
      `${articlesUrl}/${id}/${articlesChecksUrl}`
    );
    yield put(fetchChecksForArticleSuccess(response.data));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchChecksForArticleFailure);
  }
}

function* getCheckById(action: Action) {
  const { articleId, checkId } = (action as ReturnType<typeof fetchCheckForArticle>).payload;
  try {
    const check = yield* call<[boolean, string], SagaRequest<Check>>(
      SagaRequestHelper.get,
      true,
      `${articlesUrl}/${articleId}/${articlesChecksUrl}/${checkId}`
    );
    yield put(fetchCheckForArticleSuccess(articleId, check));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchCheckForArticleFailure);
  }
}

function* addCheckForArticle(action: Action) {
  try {
    const { articleId, check } = (action as ReturnType<typeof addCheckForArticleAction>).payload;
    const response = yield* call<[boolean, string, { body: string }], SagaRequest<Check>>(
      SagaRequestHelper.post,
      true,
      `${articlesUrl}/${articleId}/${articlesChecksUrl}`,
      {
        body: JSON.stringify({
          articleId: articleId,
          afterStart: check.afterStart,
          afterDisruption: check.afterDisruption,
          afterProduction: check.afterProduction,
          periodically: check.periodically,
          checkAttributeId: check.checkAttribute.id,
          checkPeriodicity: check.checkPeriodicity,
        }),
      }
    );
    yield put(addCheckForArticleSuccess(articleId, response));
  } catch (e: any) {
    yield sagaErrorHandler(e, addCheckForArticleFailure);
  }
}

function* editCheckForArticle(action: Action) {
  try {
    const { articleId, check } = (action as ReturnType<typeof editCheckForArticleAction>).payload;
    yield* call<[boolean, string, { body: string }], SagaRequest<Check>>(
      SagaRequestHelper.put,
      true,
      `${articlesUrl}/${articleId}/${articlesChecksUrl}/${check.id}`,
      {
        body: JSON.stringify({
          articleId: articleId,
          articleCheckId: check.id,
          afterStart: check.afterStart,
          afterDisruption: check.afterDisruption,
          afterProduction: check.afterProduction,
          periodically: check.periodically,
          checkAttributeId: check.checkAttribute.id,
          checkPeriodicity: check.checkPeriodicity,
        }),
      }
    );
    yield put(editCheckForArticleSuccess(articleId, check));
  } catch (e: any) {
    yield sagaErrorHandler(e, editCheckForArticleFailure);
  }
}

function* deleteCheckFromArticle(action: Action) {
  const { articleId, checkId } = (action as ReturnType<typeof deleteCheckFromArticleAction>)
    .payload;

  try {
    yield* call<[boolean, string, {}], SagaRequest<Check>>(
      SagaRequestHelper.delete,
      true,
      `${articlesUrl}/${articleId}/${articlesChecksUrl}/${checkId}`,
      {}
    );
    yield put(deleteCheckFromArticleSuccess(articleId, checkId));
  } catch (e: any) {
    yield sagaErrorHandler(e, deleteCheckFromArticleFailure);
  }
}

function* changeCheckOrderForArticle(action: Action) {
  const { articleId, sourceCheckId, destinationCheckId } = (
    action as ReturnType<typeof changeCheckOrderForArticleAction>
  ).payload;

  try {
    yield* call<[boolean, string, { body: string }], SagaRequest<Check>>(
      SagaRequestHelper.put,
      true,
      `${articlesUrl}/${articleId}/${articlesChecksUrl}`,
      {
        body: JSON.stringify({
          articleId,
          sourceArticleCheckId: sourceCheckId,
          destinationArticleCheckId: destinationCheckId,
        }),
      }
    );
    yield put(changeCheckOrderForArticleSuccess(articleId, sourceCheckId, destinationCheckId));
  } catch (e: any) {
    yield sagaErrorHandler(e, changeCheckOrderForArticleFailure);
  }
}

export function* fetchChecksForArticleSaga() {
  yield takeLatest(ChecksActionType.checksFetchAllForArticle, getChecksByArticleId);
}

export function* fetchCheckForArticleSaga() {
  yield takeLatest(ChecksActionType.checkFetchForArticle, getCheckById);
}

export function* addCheckForArticleSaga() {
  yield takeLatest(ChecksActionType.checkAddForArticle, addCheckForArticle);
}

export function* editCheckForArticleSaga() {
  yield takeLatest(ChecksActionType.checkEditForArticle, editCheckForArticle);
}

export function* deleteCheckFromArticleSaga() {
  yield takeLatest(ChecksActionType.checkDeleteFromArticle, deleteCheckFromArticle);
}

export function* changeCheckOrderForArticleSaga() {
  yield takeLatest(ChecksActionType.checkChangeOrderForArticle, changeCheckOrderForArticle);
}

export default function* checksSaga() {
  yield fork(fetchChecksForArticleSaga);
  yield fork(fetchCheckForArticleSaga);
  yield fork(addCheckForArticleSaga);
  yield fork(editCheckForArticleSaga);
  yield fork(deleteCheckFromArticleSaga);
  yield fork(changeCheckOrderForArticleSaga);
}
