import { Link, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { push } from 'connected-react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { CommandsMenuComponent } from '../../../components/commands-menu/commands-menu.component';
import { TablePaginationComponent } from '../../../components/table-pagination/table-pagination.component';
import { PaperTable } from '../../../components/table/paper-table.component';
import { AppRoutePath } from '../../../routes/routes';
import { lineListSelector, deleteLine, linesTotalCountSelector } from '../../../store';

export interface OwnProps {
  handlePageChange: (newPage: number) => void;
  page: number;
}

export const LinesTableComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'table', 'data']);
  const lines = useSelector(lineListSelector);
  const total = useSelector(linesTotalCountSelector);

  const goToEditLine = (id: string) => {
    dispatch(push(`/${AppRoutePath.lines}/${id}/${AppRoutePath.edit}`));
  };

  const deleteLineById = (id: string) => {
    dispatch(deleteLine(id));
  };

  return (
    <>
      <PaperTable dataTestId="linesTable" ariaLabel={t('data:line.table')}>
        <TableHead>
          <TableRow>
            <TableCell>{t('data:line.name')}</TableCell>
            <TableCell>{t('')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lines && lines.length > 0 ? (
            lines.map((line) => {
              return (
                <TableRow key={line.id}>
                  <TableCell>
                    <Link
                      component={NavLink}
                      to={`/${AppRoutePath.lines}/${line.id}/${AppRoutePath.edit}`}
                      underline="hover"
                    >
                      {line.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <CommandsMenuComponent
                      handleEdit={() => goToEditLine(line.id)}
                      handleDelete={() => deleteLineById(line.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={2}>
                {lines ? t('table:noMatch') : t('common:noDataAvailable')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </PaperTable>
      <TablePaginationComponent
        currentItemsCount={lines?.length || 0}
        count={total || 0}
        page={props.page}
        onChangePage={(event: unknown, newPage: number) => props.handlePageChange(newPage)}
      />
    </>
  );
};
