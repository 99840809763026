import { TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  CheckAttribute,
  CheckAttributeType,
  ProductionRunCheckExecution,
  ResultStatus,
} from '../../../model';
import { dateAndTimeOptions } from '../../../utils/date-utils';

import { CheckResultCellContentComponent } from './check-results-cell-content.component';

const FirstCell = styled(TableCell)(({ theme }) => ({
  width: '10px',
  padding: 0,
}));

const CancelledCell = styled(FirstCell)(({ theme }) => ({
  borderStyle: 'solid',
  borderWidth: '5px',
  backgroundColor: theme.palette.error.main,
  borderColor: theme.palette.error.main,
}));

export interface OwnProps {
  execution: ProductionRunCheckExecution;
}

const getCheckAttributeDescription = (
  checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>
) => {
  switch (checkAttribute.checkAttributeType) {
    case CheckAttributeType.YesNo:
      return checkAttribute.yesNoQuestion;
    case CheckAttributeType.TrafficLight:
      return checkAttribute.trafficLightDescription;
    case CheckAttributeType.FertigPackV:
      return checkAttribute.fertigPackVDescription;
    case CheckAttributeType.Weight:
      return checkAttribute.weightDescription;
    case CheckAttributeType.Temperature:
      return checkAttribute.temperatureDescription;
    case CheckAttributeType.Volume:
      return checkAttribute.volumeDescription;
    case CheckAttributeType.FreeText:
      return checkAttribute.freeTextDescription;
    case CheckAttributeType.Barcode:
      return checkAttribute.barcodeDescription;
    default:
      return undefined;
  }
};

export const CheckResultsTableResultRowComponent = (props: OwnProps) => {
  const { t, i18n } = useTranslation(['common', 'table', 'data']);
  const { execution } = props;

  const getFormattedDate = (execution: ProductionRunCheckExecution) => {
    const checkedDate = new Date(execution.checkedOn);
    return `${checkedDate.toLocaleString(i18n.language, dateAndTimeOptions)}`;
  };

  const getCheckResultRow = (execution: ProductionRunCheckExecution) => {
    return (
      <TableRow key={`execution_row_${execution.id}`}>
        {execution.status === ResultStatus.Cancelled ? <CancelledCell /> : <FirstCell />}
        <TableCell>{getFormattedDate(execution)}</TableCell>
        <TableCell>{execution.checkAttribute.name}</TableCell>
        <TableCell>{getCheckAttributeDescription(execution.checkAttribute)}</TableCell>
        <TableCell>
          {t([
            `data:checkAttribute.checkAttributeTypeCodes.${execution.checkAttribute.checkAttributeType}`,
            `data:checkAttribute.checkAttributeTypeCodes.notFound`,
          ])}
        </TableCell>
        <TableCell>
          <CheckResultCellContentComponent execution={execution} />
        </TableCell>
      </TableRow>
    );
  };

  return getCheckResultRow(execution);
};
